import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSchoolProfileInformation from '../../hooks/useSchoolProfileInformation';
import ReviewCardWrapper from '../ReviewCardWrapper';

import './SchoolProfileReview.styles.scss';

const SchoolProfileReview = () => {
  const list = useSchoolProfileInformation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <ReviewCardWrapper
      title={t('profileInformation')}
      onEdit={() => navigate('/create-school/school-type')}
      list={list}
    />
  );
};

export default SchoolProfileReview;
