import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import References from '../References/References.component';
import { Reference } from 'models/Candidate';

import './ReferencesField.styles.scss';

type ReferencesFieldProps = {
  className?: string;
} & FieldRenderProps<Reference[], HTMLElement>;

const ReferencesField: React.FC<ReferencesFieldProps> = (props) => {
  const {
    className,
    input: { value, ...input },
    meta: { error, touched },
    ...rest
  } = props;

  const classes = classNames(
    'references-field',
    { 'references-field--error': error },
    className,
  );

  return (
    <div className={classes}>
      <References value={value || []} {...input} {...rest} />
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default ReferencesField;
