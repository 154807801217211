import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const getTPB = (state: RootState) => state.teacherProfileBuilder;

const selectAllRoutes = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const { teacherProfileBuilder } = state;

    return teacherProfileBuilder.allRoutes;
  },
);

const selectFinishedRoutes = createSelector(
  [getTPB],
  (builder) => builder.finishedRoutes || [],
);

const selectCurrentRoute = createSelector(
  [getTPB],
  (builder) => builder.currentRoute,
);

const selectUnfinishedRoutes = createSelector([getTPB], (builder) =>
  builder.allRoutes.filter((route) => !builder.finishedRoutes.includes(route)),
);

const selectStatus = createSelector([getTPB], (builder) => builder.status);

export default {
  selectAllRoutes,
  selectCurrentRoute,
  selectFinishedRoutes,
  selectUnfinishedRoutes,
  selectStatus,
};
