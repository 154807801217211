import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import SchoolsOfGroup from './components/SchoolsOfGroup';
import GroupSettings from '../GroupSettings';
import { SchoolGroupStructureType } from 'searchality-data';

const SchoolSettings: React.FC = () => {
  const user = useAppSelector(authSelectors.selectUser);

  const { schoolGroup } = user;

  const { structureType } = schoolGroup;
  return structureType === SchoolGroupStructureType.STANDALONE ? (
    <GroupSettings />
  ) : (
    <SchoolsOfGroup />
  );
};

export default SchoolSettings;
