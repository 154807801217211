import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import EditAcademicVacancyRouter from './subrouters/EditAcademicVacancy/EditAcademicVacacncy.router';
import EditAcademicVacacncyRoutes from './subrouters/EditAcademicVacancy/EditAcademicVacacncy.routes';
import EditNonAcademicVacancyRouter from './subrouters/EditNonAcademicVacacncy/EditNonAcademicVacacncy.router';
import EditNonAcademicVacacncyRoutes from './subrouters/EditNonAcademicVacacncy/EditNonAcademicVacacncy.routes';

export default [
  {
    path: 'non-academic',
    element: EditNonAcademicVacancyRouter,
    routes: EditNonAcademicVacacncyRoutes,
  },
  {
    path: 'academic',
    element: EditAcademicVacancyRouter,
    routes: EditAcademicVacacncyRoutes,
  },
  {
    index: true,
    element: <Navigate to="academic" />,
  },
] as Array<ComplexRoute>;
