import React from 'react';
import classNames from 'classnames';
import EmptyScreen from 'components/EmptyScreen';
import { useTranslation } from 'react-i18next';
import emptyScreen from 'assets/images/vacancy-empty-state.webp';

import './ApplicationEmptyState.styles.scss';

type ApplicationEmptyStateProps = {
  className?: string;
};

const ApplicationEmptyState: React.FC<ApplicationEmptyStateProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('application-empty-state', className);

  return (
    <EmptyScreen
      className={classes}
      title={t('ApplicationsEmptyScreen.title')}
      description={t('ApplicationsEmptyScreen.description')}
      headingLevel={4}
      img={<img src={emptyScreen} alt="Empty screen" />}
      imgFirst={false}
    />
  );
};

export default ApplicationEmptyState;
