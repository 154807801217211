import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { required } from 'validations';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import api from 'api';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';
import { FormApi } from 'final-form';
import classNames from 'classnames';
import { User } from 'models/User';
import { AxiosResponse } from 'axios';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import AccountEditWrapper from '../AccountEditWrapper';

import './EditPersonalInformation.styles.scss';

type FormValues = {
  firstName: string;
  lastName: string;
  userPhoto: File[];
  title: string;
};

type EditPersonalInformationProps = {
  apiRequest: (values: Partial<User>) => Promise<AxiosResponse<User, any>>;
  isTeacher?: boolean;
};

const EditPersonalInformation: React.FC<EditPersonalInformationProps> = (
  props,
) => {
  const { apiRequest, isTeacher = false } = props;

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const dispatch = useAppDispatch();

  const { firstName, lastName, imageFile, title } = useAppSelector(
    authSelectors.selectUser,
  );

  const [image, setImage] = useState<File[] | null>(null);

  const baseClass = 'edit-personal-information';

  const fetchImage = useCallback(async () => {
    if (imageFile?.url) {
      const blob = await api.user
        .convertIntoBlob(imageFile?.url)
        .then((r) => r.blob());
      const file = new File([blob], 'avatar.jpg', { type: blob.type });

      setImage([file]);
    } else {
      setImage(null);
    }
  }, [imageFile?.url]);

  const handleSubmit = useCallback(
    async (values: FormValues, formApi: FormApi<FormValues, FormValues>) => {
      setLoading(true);

      const { userPhoto, lastName, firstName, title } = values || {};
      const { getState } = formApi;
      const { dirtyFields } = getState();

      const dirtyFieldsArray = Object.keys(dirtyFields);
      if (
        dirtyFieldsArray.some(
          (el) => el === 'firstName' || el === 'lastName' || el === 'title',
        )
      ) {
        try {
          const { data } = await apiRequest({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            title: title?.trim(),
          });

          dispatch(updateUser(data));
          if (
            !userPhoto?.length ||
            !dirtyFieldsArray.some((el) => el === 'userPhoto')
          ) {
            setLoading(false);
            return dispatch(popSuccess(t('updateUserSuccess')));
          }
        } catch (e) {
          dispatch(popServerError(e));
          setLoading(false);
        }
      }

      if (
        dirtyFieldsArray.some((el) => el === 'userPhoto') &&
        userPhoto?.length
      ) {
        try {
          const { data } = await api.user.uploadUserPhoto(userPhoto[0]);
          dispatch(updateUser({ ...data, schoolGroup }));
          setLoading(false);
          return dispatch(popSuccess(t('updateUserSuccess')));
        } catch (e) {
          dispatch(popServerError(e));
          setLoading(false);
        }
      }

      if (
        dirtyFieldsArray.some((el) => el === 'userPhoto') &&
        !userPhoto?.length
      ) {
        try {
          const { data } = await api.user.deleteUserPhoto();
          dispatch(updateUser({ ...data, imageFile: null, schoolGroup }));
          setLoading(false);
          return dispatch(popSuccess(t('updateUserSuccess')));
        } catch (e) {
          dispatch(popServerError(e));
          setLoading(false);
        }
      }
    },
    [apiRequest, dispatch, schoolGroup, t],
  );

  const initialValues = useMemo(
    () => ({
      firstName,
      lastName,
      title,
      userPhoto: image,
    }),
    [firstName, image, lastName, title],
  );

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, dirty }) => (
        <AccountEditWrapper
          title={t('editPersonalInformation.title')}
          subtitle={t('editPersonalInformation.subtitle')}
          goBackTo=".."
          className={classNames({
            'edit-personal-information--loading': loading,
          })}
          handleSave={handleSubmit}
          isDirty={form.getState().dirty}
          submitting={submitting}
        >
          <form className={`${baseClass}__form`} onSubmit={handleSubmit}>
            <div className={`${baseClass}__form__photo`}>
              <p>
                {t('photo')} <span>{t('optionalPhoto')}</span>
              </p>
              <Field
                name="userPhoto"
                render={(props) => {
                  const {
                    input: { value, onChange },
                  } = props;

                  return (
                    <DropzoneProfilePhoto
                      value={value?.[0] ?? null}
                      setFileCallback={(file: File) =>
                        onChange(file ? [file] : [])
                      }
                    />
                  );
                }}
              />
            </div>
            <Field
              name="firstName"
              component={InputField}
              label={t('firstName')}
              validate={required()}
              required
            />
            <Field
              name="lastName"
              component={InputField}
              label={t('lastName')}
              validate={required()}
              required
            />
            {!isTeacher && (
              <Field
                name="title"
                component={InputField}
                label={t('yourRoleInSchool')}
                validate={required()}
                required
              />
            )}
          </form>
        </AccountEditWrapper>
      )}
    />
  );
};
export default EditPersonalInformation;
