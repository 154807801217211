import { createApi } from '@reduxjs/toolkit/query/react';
import { Vacancy } from 'models/Vacancy';
import axiosBaseQuery from './axiosBaseQuery';

const getOpenJobParams = { $populate: ['schools', 'schoolGroup'] };

export const jobsApi = createApi({
  reducerPath: 'jobsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getOpenJob: builder.query<Vacancy, string>({
      query: (jobId) => {
        return {
          url: `jobs/${jobId}/open`,
          method: 'get',
          params: getOpenJobParams,
        };
      },
    }),
  }),
});

export const { useGetOpenJobQuery } = jobsApi;
