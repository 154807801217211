import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CommunicationSettingData } from 'types';
import { Field } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import ToggleButton from 'components/ToggleButton';

import './CommunicationSetting.styles.scss';
import './CommunicationSetting.styles.responsive.scss';
import RadioButtonLabel from 'components/RadioButtonLabel';

type CommunicationSettingProps = {
  className?: string;
  data: CommunicationSettingData;
};

const CommunicationSetting: React.FC<CommunicationSettingProps> = (props) => {
  const { className, data } = props;

  const { type, title, list, hint, name } = data;

  const baseClass = 'communication-setting';
  const classes = classNames(baseClass, className);

  const communicationFields = useMemo(() => {
    if (type === 'toggle') {
      return list.map(({ name, label }) => (
        <div key={name} className={`${baseClass}__settings__toggle`}>
          <p>{label}</p>
          <ToggleButton onChange={() => {}} checked={true} disabled />
        </div>
      ));
    }
    const radioList = list.map(({ value, label }) => ({
      value,
      label: <RadioButtonLabel label={label} />,
    }));
    return (
      <Field
        name={name}
        component={RadioGroupField}
        options={radioList}
        required
      />
    );
  }, [list, name, type]);

  return (
    <div className={`${baseClass}--wrapper`}>
      <div className={classes}>
        {title && <p className={`${baseClass}__title`}>{title}</p>}
        <div className={`${baseClass}__settings`}>{communicationFields}</div>
      </div>
      {hint && <p className={`${baseClass}__hint`}>{hint}</p>}
    </div>
  );
};

export default CommunicationSetting;
