import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './CandidateProfileDocumentsEmpty.styles.scss';

type CandidateProfileDocumentsEmptyProps = {
  className?: string;
};

const CandidateProfileDocumentsEmpty: React.FC<
  CandidateProfileDocumentsEmptyProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const baseClass = 'candidate-profile-documents-empty';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>
        {t('CandidateProfileDocumentsEmpty.title')}
      </p>
      <p className={`${baseClass}__description`}>
        {t('CandidateProfileDocumentsEmpty.description')}
      </p>
      <Button
        className={`${baseClass}__button`}
        onClick={() => navigate('request-documents')}
        variant="outline"
      >
        {t('CandidateProfileDocumentsEmpty.button')}
      </Button>
    </div>
  );
};

export default CandidateProfileDocumentsEmpty;
