import api from 'api';
import subjectGrades from 'constants/subjectGrades';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCallback, useEffect, useState } from 'react';
import { popServerError } from 'store/slices/popNotifications.slice';
import { Params } from 'types';
import utils from 'utils';

export default (params: Partial<Params>): [any, boolean] => {
  const [filters, setFilters] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const fetchFilters = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.candidates.getCandidatesFilters(params);

      const { subjects } = data;

      setFilters({
        ...data,
        subjects: subjects?.reduce(
          (acc: Record<string, string>, curr: string) => ({
            ...acc,
            [utils.getKeyByValue(subjectGrades, curr)]: curr,
          }),
          {},
        ),
      });
    } catch (er) {
      dispatch(popServerError(er));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, params]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  return [filters, isLoading];
};
