import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './JobApplicationBoarding.styles.scss';
import { Field } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { required } from 'validations';

type JobApplicationBoardingProps = {
  className?: string;
  isRequired: boolean;
};

const JobApplicationBoarding: React.FC<JobApplicationBoardingProps> = (
  props,
) => {
  const { className, isRequired } = props;

  const { t } = useTranslation();

  const classes = classNames('job-application-boarding', className);

  const options = useMemo(
    () => [
      {
        value: 'true',
        label: <RadioButtonLabel label={t('yes')} />,
      },
      {
        value: 'false',
        label: <RadioButtonLabel label={t('no')} />,
      },
    ],
    [t],
  );

  return (
    <div className={classes}>
      <p className="job-application-boarding__title">
        {t('JobApplication.boardingQuestionTitle')}
      </p>
      <p className="job-application-boarding__desc">
        {t(
          isRequired
            ? 'JobApplication.boardingQuestionDescriptionReq'
            : 'JobApplication.boardingQuestionDescriptionOpt',
        )}
      </p>
      <Field
        name="isAbleToWorkAsBoardingParent"
        component={RadioGroupField}
        options={options}
        validate={required()}
      />
    </div>
  );
};

export default JobApplicationBoarding;
