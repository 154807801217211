import React from 'react';
import CandidateCard, {
  CandidateCardProps,
} from '../../CandidateCard.component';

import './CandidateCardWrapper.styles.scss';
import './CandidateCardWrapper.styles.responsive.scss';

type CandidateCardWrapperProps = {
  additionalContent?: React.ReactNode;
} & CandidateCardProps;

const CandidateCardWrapper: React.FC<CandidateCardWrapperProps> = ({
  additionalContent,
  ...cardProps
}) => {
  const baseClassname = 'searchality-candidate-card';

  return (
    <div className={baseClassname + '__wrapper'}>
      {additionalContent}
      <CandidateCard {...cardProps} />
    </div>
  );
};

export default CandidateCardWrapper;
