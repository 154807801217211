import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import { School } from 'models/School';
import { User } from 'models/User';

function getCurrentTeacherUser() {
  return httpClient.get<User>('teacher-users/current').then(({ data }) => data);
}

function getCurrentUser() {
  return httpClient
    .get<User>('users/current', {
      params: {
        $populate: [
          'schoolGroup',
          'schoolGroup.schools',
          'schools',
          'schools.associations',
          'schoolGroup.schools.associations',
        ],
      },
    })
    .then(({ data }) => data);
}

function updateUser(values: Partial<User>) {
  return httpClient.patch<User>('school-users/personal-information', values, {
    params: {
      $populate: ['schoolGroup', 'schoolGroup.schools'],
    },
  });
}

function updateAssociationUser(values: Partial<User>) {
  return httpClient.patch<User>(
    'association-users/personal-information',
    values,
  );
}

function updateTeacherUser(values: Partial<User>) {
  return httpClient.patch<User>('teacher-users/personal-information', values);
}

function deleteUserAccount() {
  return httpClient.delete('school-users/account');
}

function deleteAssociationAccount() {
  return httpClient.delete('association-users/profile');
}

function requestAccountRemoval() {
  return httpClient.post('association-users/send-removal-request');
}

function deleteTeacherAccount() {
  return httpClient.delete('teacher-users/account');
}

function uploadSchoolLogo(file: File, schoolId: string) {
  const formData = new FormData();
  formData.append('file', file);

  return httpClient.post<School>(`schools/${schoolId}/logo-image`, formData, {
    params: {
      $populate: ['associations'],
    },
  });
}

function deleteSchoolLogo(schoolId: string) {
  return httpClient.delete<School>(`schools/${schoolId}/logo-image`, {
    params: {
      $populate: ['associations'],
    },
  });
}

function uploadUserPhoto(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  return httpClient.post<User>('users/photo', formData, {
    params: {
      $populate: ['schoolGroup', 'schoolGroup.schools'],
    },
  });
}

function deleteUserPhoto() {
  return httpClient.delete<User>('users/photo');
}

function convertIntoBlob(url: string) {
  return fetch(url);
}

function uploadEducationalPhilosophy(formData: FormData) {
  return httpClient.post<User>(
    'teacher-users/profile/education-philosophy',
    formData,
  );
}

function uploadPublications(formData: FormData) {
  return httpClient.post<User>('teacher-users/profile/publications', formData);
}

function contactUs(data: FormData) {
  return httpClient.post<User>('users/contact-support', data);
}

function getPublicTeacher(id: string) {
  return publicHttpClient.get(`api/teacher-users/${id}`);
}

export default {
  getCurrentUser,
  updateUser,
  uploadUserPhoto,
  convertIntoBlob,
  deleteUserPhoto,
  getCurrentTeacherUser,
  updateTeacherUser,
  deleteUserAccount,
  uploadEducationalPhilosophy,
  contactUs,
  getPublicTeacher,
  deleteTeacherAccount,
  updateAssociationUser,
  deleteAssociationAccount,
  requestAccountRemoval,
  uploadSchoolLogo,
  deleteSchoolLogo,
  uploadPublications,
};
