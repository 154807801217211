import React from 'react';
import './TPBPageHeading.styles.scss';

const TPBPageHeading: React.FC<{ text: string; description?: string }> = ({
  text,
  description,
}) => {
  return (
    <div className="tpb-page-heading">
      <h4>{text}</h4>
      {description && <p>{description}</p>}
    </div>
  );
};

export default TPBPageHeading;
