import React from 'react';

type JobApplicationProps = {
  className?: string;
  children: React.ReactNode;
};

const JobApplicationMain: React.FC<JobApplicationProps> = ({ children }) => {
  return <main className="job-application-main">{children}</main>;
};

export default JobApplicationMain;
