import publicHttpClient from 'api/publicHttpClient';
import { AuthBody } from 'types';
import bus from '../modules/bus';
import credentialsService from './credentialsService';

export const AuthEventTypes = {
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  LOGOUT_SIGNAL: 'LOGOUT_SIGNAL',
} as const;

export default {
  refreshingAtm: false,
  triedRefresh: false,
  checkRefreshingTreshold: 10000,
  async refreshToken() {
    this.refreshingAtm = true;

    const credentialsRefreshToken = credentialsService.refreshToken;

    const { data } = await publicHttpClient.post<AuthBody>(
      'api/auth/login/refresh',
      {
        refreshToken: credentialsRefreshToken,
      },
    );

    credentialsService.saveAuthBody(data);

    bus.broadcastEvent(AuthEventTypes.REFRESH_TOKEN_SUCCESS);

    this.refreshingAtm = false;
    this.triedRefresh = true;

    setTimeout(() => {
      this.triedRefresh = false;
    }, 10000);
  },
  reinit() {
    this.refreshingAtm = false;
  },
};
