import React, { useCallback, useMemo } from 'react';
import useSubjectGrades from 'router/subrouters/Dashboard/subrouters/Vacancies/hooks/useSubjectGrades';
import { Button, Collapsible, Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useDivisionOptions from '../../hooks/useDivisionOptions';
import useSchoolFromYourGroup from '../../hooks/useSchoolFromYourGroup';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { ReactComponent as SelectArrowIcon } from 'icons/SelectArrow.icon.svg';
import useTypeOptions from '../../hooks/useTypeOptions';
import { batch } from 'react-redux';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import { useGetVacanciesFiltersQuery } from 'api/vacancies.api';
import useAppSelector from 'hooks/useAppSelector';
import classNames from 'classnames';
import { VacancyType } from 'searchality-data';
import useCategoryOptions from '../../pages/VacancyWizard/hooks/useCategoryOptions';
import { Params } from 'types';
import { FilterSelectPlaceholder } from '../../../Candidates/components/CandidatesFilters/placeholders';

import './VacanciesFilters.styles.scss';
import './VacanciesFilters.styles.responsive.scss';

type VacanciesFiltersProps = {
  noTypeFilter?: boolean;
  isNonAcademicFilters?: boolean;
  initialParams?: Partial<Params>;
  isDraftVacancies?: boolean;
  reducerPath: 'vacanciesTemplateFactoryParams' | 'vacanciesParams';
};

const VacanciesFilters: React.FC<VacanciesFiltersProps> = (props) => {
  const {
    noTypeFilter = false,
    isNonAcademicFilters,
    initialParams,
    reducerPath,
    isDraftVacancies = false,
  } = props;

  const { data: filters, isLoading } = useGetVacanciesFiltersQuery();

  const params = useAppSelector((state) => state[reducerPath]);

  const { $where } = params || {};
  const { schoolIds, positionDivision, subjectGradeLevel, type, category } =
    $where || {};

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const subjectGradeOptions = useSubjectGrades(filters?.subjects);

  const categoryOptions = useCategoryOptions(filters?.categories);

  const divisionOptions = useDivisionOptions(filters?.divisions);

  const typeOptions = useTypeOptions(filters?.types);

  const schoolsFromYourGroupOptions = useSchoolFromYourGroup(filters?.schools);

  const showResetFilters = Object.values(filters || {})?.some(
    (filter: any) => filter?.length > 1,
  );

  const className = 'vacancies-filters';

  const onReset = useCallback(() => {
    if (initialParams) {
      return dispatch(
        paramsActions.initializeParams(reducerPath, initialParams),
      );
    }
    batch(() => {
      dispatch(paramsActions.resetFilters(reducerPath));
      dispatch(paramsActions.resetSort(reducerPath));
      dispatch(paramsActions.changeCurrentPage(reducerPath, 1));
    });
  }, [dispatch, initialParams, reducerPath]);

  const onFilter = useCallback(
    (value: string, key: string) => {
      const filterValue = {
        [key]: value,
      };
      dispatch(paramsActions.onFilter(reducerPath, filterValue));
    },
    [dispatch, reducerPath],
  );

  const FilterComponent = useMemo(
    () => (
      <div className={`${className}__wrapper`}>
        <div className={`${className}`}>
          {isLoading ? (
            Array(5)
              .fill(void 0)
              .map((_, index) => (
                <FilterSelectPlaceholder width="148px" key={index} />
              ))
          ) : (
            <>
              {schoolsFromYourGroupOptions?.length > 1 && (
                <Select
                  options={schoolsFromYourGroupOptions}
                  placeholder={t('schoolName')}
                  value={schoolIds}
                  multiple={false}
                  onChange={({ value }) => onFilter(value, 'schoolIds')}
                  renderAsPortal
                  portalClassName="vacancies-filters__filter-portal"
                  icon={<SelectArrowIcon />}
                />
              )}
              {!isDraftVacancies &&
                divisionOptions?.length > 1 &&
                isNonAcademicFilters !== true && (
                  <Select
                    className={classNames({
                      disabled: type === VacancyType.NON_ACADEMIC,
                    })}
                    options={divisionOptions}
                    value={positionDivision}
                    multiple={false}
                    onChange={({ value }) => {
                      onFilter(value, 'positionDivision');
                      if (!type) {
                        onFilter(VacancyType.ACADEMIC, 'type');
                      }
                    }}
                    placeholder={t('schoolLevel')}
                    renderAsPortal
                    portalClassName="vacancies-filters__filter-portal"
                    icon={<SelectArrowIcon />}
                  />
                )}
              {!isDraftVacancies &&
                subjectGradeOptions?.length > 1 &&
                isNonAcademicFilters !== true && (
                  <Select
                    className={classNames({
                      disabled: type === VacancyType.NON_ACADEMIC,
                    })}
                    options={subjectGradeOptions}
                    value={subjectGradeLevel}
                    multiple={false}
                    onChange={({ value }) => {
                      onFilter(value, 'subjectGradeLevel');
                      if (!type) {
                        onFilter(VacancyType.ACADEMIC, 'type');
                      }
                    }}
                    placeholder={t('RoleSubject')}
                    renderAsPortal
                    portalClassName="vacancies-filters__filter-portal"
                    icon={<SelectArrowIcon />}
                  />
                )}
              {!isDraftVacancies &&
                categoryOptions?.length > 1 &&
                isNonAcademicFilters !== false && (
                  <Select
                    className={classNames({
                      disabled: type === VacancyType.ACADEMIC,
                    })}
                    options={categoryOptions}
                    value={category}
                    multiple={false}
                    onChange={({ value }) => {
                      onFilter(value, 'category');
                      if (!type) {
                        onFilter(VacancyType.NON_ACADEMIC, 'type');
                      }
                    }}
                    placeholder={t('department')}
                    renderAsPortal
                    portalClassName="vacancies-filters__filter-portal"
                    icon={<SelectArrowIcon />}
                  />
                )}
              {typeOptions?.length > 1 && !noTypeFilter && (
                <Select
                  options={typeOptions}
                  value={type}
                  multiple={false}
                  onChange={({ value }) => {
                    onFilter(value, 'type');

                    if (value === VacancyType.ACADEMIC) {
                      if (category) {
                        onFilter(undefined, 'category');
                      }
                    } else {
                      if (subjectGradeLevel) {
                        onFilter(undefined, 'subjectGradeLevel');
                      }
                      if (positionDivision) {
                        onFilter(undefined, 'positionDivision');
                      }
                    }
                  }}
                  placeholder={t('vacancyType')}
                  renderAsPortal
                  portalClassName="vacancies-filters__filter-portal"
                  icon={<SelectArrowIcon />}
                />
              )}
            </>
          )}
        </div>
        {showResetFilters && (
          <Button variant="link" onClick={onReset}>
            {t('resetFilters')}
          </Button>
        )}
      </div>
    ),
    [
      isLoading,
      schoolsFromYourGroupOptions,
      t,
      schoolIds,
      isDraftVacancies,
      divisionOptions,
      isNonAcademicFilters,
      type,
      positionDivision,
      subjectGradeOptions,
      subjectGradeLevel,
      categoryOptions,
      category,
      typeOptions,
      noTypeFilter,
      showResetFilters,
      onReset,
      onFilter,
    ],
  );

  return (
    <>
      <div className={`${className}__outside`}>{FilterComponent}</div>
      <Collapsible
        trigger={
          <div className="vacancies-filters__trigger">
            <p>{t('filters')}</p>
            <ArrowIcon />
          </div>
        }
      >
        {FilterComponent}
      </Collapsible>
    </>
  );
};

export default VacanciesFilters;
