import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import Anim from 'components/animations';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'icons/Calendar.icon.svg';
import { Button } from 'ncoded-component-library';
import DateService from 'services/Date.service';

import './DatepickerField.styles.scss';
import './DatepickerField.styles.responsive.scss';
import 'react-datepicker/dist/react-datepicker.css';

type DatepickerFieldProps = FieldRenderProps<string, HTMLElement>;

const DatePickerCustomInput = forwardRef(
  ({ value, onClick, ...rest }: any, _) => {
    return (
      <div className="react-datepicker__custom-input-wrapper">
        <input {...rest} value={value} />
        <Button icon={<CalendarIcon />} variant="link" onClick={onClick} />
      </div>
    );
  },
);
const DatepickerField: React.FC<DatepickerFieldProps> = (props) => {
  const {
    className,
    input: { onChange, value, ...restInput },
    meta: { error, touched },
    disabled,
    hint,
    showMonthDropdown = true,
    showYearDropdown = true,
    placeholder,
    onlyPast,
    onlyFuture,
  } = props;

  const classes = classNames('datepicker-field', className);

  const formattedValue = useMemo(() => {
    if (value) {
      return new Date(value);
    }
    return undefined;
  }, [value]);

  const today = new Date();

  return (
    <div className={classes}>
      <ReactDatePicker
        selected={formattedValue}
        onChange={onChange}
        disabled={disabled}
        value={value ? DateService.getInputUSAFormat(value) : undefined}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        placeholderText={placeholder}
        customInput={<DatePickerCustomInput />}
        maxDate={
          onlyPast ? new Date(today.setDate(today.getDate() - 1)) : undefined
        }
        minDate={
          onlyFuture ? new Date(today.setDate(today.getDate() + 1)) : undefined
        }
        {...restInput}
      />
      {error && touched ? (
        <Anim.Collapse active={!!error}>
          <p className="s-select__error">{error}</p>
        </Anim.Collapse>
      ) : null}
      {hint && <p className="s-select__hint">{hint}</p>}
    </div>
  );
};

export default DatepickerField;
