import api from 'api';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useSchoolProfile(schoolId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasOngoingVacancies, setHasOngoingVacancies] = useState(false);

  const params = useMemo(
    () => ({
      $where: {
        schoolIds: { $all: [schoolId] },
        status: { $in: ['Ongoing'] },
      },
    }),
    [schoolId],
  );

  const getVacancyForSchool = useCallback(async () => {
    try {
      const {
        data: { totalItems },
      } = await api.vacancies.getVacancies(params);
      if (totalItems > 0) {
        setHasOngoingVacancies(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    getVacancyForSchool();
  }, [getVacancyForSchool]);

  return { isLoading, hasOngoingVacancies };
}
