import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useListOfRoles from '../../hooks/useListOfRoles';
import { Field, Form } from 'react-final-form';
import MultipleSelectField from 'components/MultipleSelectField';
import {
  useShareVacancyMutation,
  useGenerateMagicLinkMutation,
  useLazyVacancyMembersQuery,
  useLazyPotentialInviteesQuery,
  useShareVacancyFromCandidateMutation,
} from 'api/vacancies.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import usePotentialMembersOptions from '../../hooks/usePotentialMembersOptions';
import { ReactComponent as LinkIcon } from 'icons/ShareLink.icon.svg';
import MembersTable from '../../../Members/components/MembersTable';
import { FormApi } from 'final-form';
import { OverlayRef } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import PageLoader from 'components/PageLoader';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

import './ShareVacancyModal.styles.scss';
import './ShareVacancyModal.styles.responsive.scss';

type ShareVacancyModalProps = {
  className?: string;
  title: string;
  description: string;
  onClose?: () => void;
  onShareVacancy?: () => void;
};

export type ShareVacancyModalRef = {
  openShareVacancy: (vacancyId: string, candidateId?: string) => void;
} & OverlayRef;

const params = {
  $where: {
    role: {
      $in: [Role.Reviewer],
    },
  },
  $populate: ['schools'],
};

const ShareVacancyModal: React.ForwardRefRenderFunction<
  ModalRef,
  ShareVacancyModalProps
> = (props, ref) => {
  const { className, title, description, onClose, onShareVacancy } = props;

  const [modal, modalRef] = useCallbackRef<ModalRef>();
  const [vacancyId, setVacancyId] = useState<string>();
  const [candidateId, setCandidateId] = useState<string>(undefined);

  const list = useListOfRoles();

  const listOfRoles = useMemo(
    () => list.map((element) => <li key={element.value}>{element.label}</li>),
    [list],
  );

  const {
    schoolGroup: { schools, structureType },
  } = useAppSelector(authSelectors.selectUser);

  const dispatch = useAppDispatch();

  const [potentialMembersQuery, { data: potentialMembers, isFetching }] =
    useLazyPotentialInviteesQuery();

  const [vacancyMembersQuery, { data: currentMembers, isLoading }] =
    useLazyVacancyMembersQuery();

  const potentialMembersOptions = usePotentialMembersOptions(
    potentialMembers,
    'share-vacancy-modal__dropdown-item',
  );

  const [generateMagicLink] = useGenerateMagicLinkMutation();
  const [shareVacancyFromVacancy] = useShareVacancyMutation();
  const [shareVacancyFromCandidate] = useShareVacancyFromCandidateMutation();

  const { t } = useTranslation();

  const openShareVacancy = useCallback(
    (vacancyId: string, candidateId?: string) => {
      setVacancyId(vacancyId);
      setCandidateId(candidateId);
      modal.open();
    },
    [modal],
  );

  useImperativeHandle(ref, () => ({ ...modal, openShareVacancy }), [
    modal,
    openShareVacancy,
  ]);

  const classes = classNames('share-vacancy-modal', className);

  const handleShareVacancy = useCallback(
    async (
      values: { memberIds: string[] },
      form: FormApi<{
        memberIds: string[];
      }>,
    ) => {
      const { memberIds } = values;
      if (candidateId) {
        shareVacancyFromCandidate({ vacancyId, memberIds, candidateId })
          .unwrap()
          .then(() => {
            dispatch(popSuccess(t('ShareVacancyModal.shareSuccess')));
            form.reset();
            onShareVacancy?.();
          })
          .catch(() => {
            dispatch(popError(t('ShareVacancyModal.shareFail')));
          });
      } else {
        shareVacancyFromVacancy({ vacancyId, memberIds })
          .unwrap()
          .then(() => {
            dispatch(popSuccess(t('ShareVacancyModal.shareSuccess')));
            form.reset();
            onShareVacancy?.();
          })
          .catch(() => {
            dispatch(popError(t('ShareVacancyModal.shareFail')));
          });
      }
    },
    [
      candidateId,
      dispatch,
      onShareVacancy,
      shareVacancyFromCandidate,
      shareVacancyFromVacancy,
      t,
      vacancyId,
    ],
  );

  const handleGenerateMagicLink = useCallback(async () => {
    generateMagicLink({ vacancyId, candidateId })
      .unwrap()
      .then(({ url }) => {
        dispatch(popSuccess(t('linkCopied')));
        navigator.clipboard.writeText(url);
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  }, [candidateId, dispatch, generateMagicLink, t, vacancyId]);

  useEffect(() => {
    if (vacancyId) {
      potentialMembersQuery(vacancyId);
      vacancyMembersQuery({ vacancyId, params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancyId]);

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={title}
      onClose={onClose}
      onX={onClose}
    >
      {isLoading || !vacancyId ? (
        <PageLoader />
      ) : (
        <>
          <p className="share-vacancy-modal__list-title">
            {t('ShareVacancyModal.listOfRoles')}
          </p>
          <ul className="share-vacancy-modal__list">{listOfRoles}</ul>
          <p className="share-vacancy-modal__description">{description}</p>
          <p className="share-vacancy-modal__share-title">
            {t('ShareVacancyModal.shareWithColleagues')}
          </p>
          <Form
            onSubmit={handleShareVacancy}
            render={({ handleSubmit, form: { getState } }) => (
              <form
                className="share-vacancy-modal__share-form"
                onSubmit={handleSubmit}
              >
                <Field
                  name="memberIds"
                  component={MultipleSelectField}
                  label={t('ShareVacancyModal.selectLabel')}
                  options={potentialMembersOptions}
                  // renderAsPortal
                  portalClassName="share-vacancy-modal__portal"
                  key={isFetching.toString()}
                />
                <Button
                  type="submit"
                  disabled={
                    !getState().values.memberIds?.length ||
                    getState().submitting
                  }
                >
                  {t('shareVacancy')}
                </Button>
              </form>
            )}
          />
          <div className="share-vacancy-modal__via-link">
            <p className="share-vacancy-modal__via-link__title">
              {t('ShareVacancyModal.viaLinkTitle')}
            </p>
            <p className="share-vacancy-modal__via-link__description">
              {t('ShareVacancyModal.viaLinkDescription')}
            </p>
            <Button
              variant="outline"
              icon={<LinkIcon />}
              iconPosition="left"
              onClick={handleGenerateMagicLink}
            >
              {t('copyLink')}
            </Button>
          </div>
          {!!currentMembers?.length && (
            <div className="share-vacancy-modal__members-table">
              <p className="share-vacancy-modal__members-table__title">
                {t('ShareVacancyModal.membersTableTitle')}
              </p>
              <p className="share-vacancy-modal__members-table__description">
                {t('ShareVacancyModal.membersTableDescription')}
              </p>
              <MembersTable
                members={currentMembers}
                isReadonly
                schoolName={
                  structureType === SchoolGroupStructureType.STANDALONE &&
                  schools[0].name
                }
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default forwardRef(ShareVacancyModal);
