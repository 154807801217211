import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from 'searchality-data';

const useTeacherLanguage = () => {
  const { t } = useTranslation();
  const anotherLanguage = useMemo(
    () =>
      Object.keys(Language).map((key) => ({
        label: Language[key as keyof typeof Language],
        value: Language[key as keyof typeof Language],
      })),
    [],
  );

  return [...anotherLanguage, { label: t('other'), value: 'Other' }];
};

export default useTeacherLanguage;
