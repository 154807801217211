import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { PublicationFormValue } from '../../subrouters/TeacherProfileBuilder/models/TeacherFormValues';
import Publications from '../Publications/Publications.component';

import './PublicationsField.styles.scss';
import './PublicationsField.styles.responsive.scss';

type PublicationsFieldProps = {
  className?: string;
} & FieldRenderProps<PublicationFormValue[], HTMLElement>;

const PublicationsField: React.FC<PublicationsFieldProps> = (props) => {
  const {
    className,
    input: { value, ...input },
    meta: { error, touched },
    ...rest
  } = props;

  const classes = classNames(
    'publications-field',
    { 'publications-field--error': error },
    className,
  );

  return (
    <div className={classes}>
      <Publications value={value || []} {...input} {...rest} />
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default PublicationsField;
