import React from 'react';
import classNames from 'classnames';
import SubscriptionLink from '../SubscriptionLink';
import { useTranslation } from 'react-i18next';
import useSubscriptionLinksList from '../../hooks/useSubscriptionLinksList';

import './UpgradeSubscription.styles.scss';

type UpgradeSubscriptionProps = {
  className?: string;
};

const UpgradeSubscription: React.FC<UpgradeSubscriptionProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const list = useSubscriptionLinksList();

  const classes = classNames('upgrade-subscription', className);

  return (
    <div className={classes}>
      <p>{t('UpgradeSubscription.title')}</p>
      <div className="upgrade-subscription__links">
        {list?.map((link) => (
          <SubscriptionLink key={link.description} {...link} />
        ))}
      </div>
    </div>
  );
};

export default UpgradeSubscription;
