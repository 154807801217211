import { useUpdateAssociationListMutation } from 'api/associations.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { AssociationContactDetails } from 'models/School';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AssociationType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { loadCurrentUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './EditAssociationList.styles.scss';

export type AssociationInformation = {
  nationalAssociations: string[];
  regionalAssociations: string[];
  stateAssociations: string[];
  associationContactDetails: AssociationContactDetails;
};

const EditAssociationList: React.FC = () => {
  const user = useAppSelector(authSelectors.selectUser);
  const { schoolId } = useParams();

  const [updateAssociationList] = useUpdateAssociationListMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const school = useMemo(
    () => user?.schools?.find(({ _id }) => _id === schoolId),
    [schoolId, user?.schools],
  );

  const associationInformation = useMemo(() => {
    const { associationContactDetails, associations } = school;

    if (!associations?.length) return undefined;

    const nationalAssociations = associations
      .filter(({ type }) => type === AssociationType.NATIONAL)
      .map(({ _id }) => _id);
    const regionalAssociations = associations
      .filter(({ type }) => type === AssociationType.REGIONAL)
      .map(({ _id }) => _id);
    const stateAssociations = associations
      .filter(({ type }) => type === AssociationType.STATE)
      .map(({ _id }) => _id);

    return {
      nationalAssociations,
      regionalAssociations,
      stateAssociations,
      associationContactDetails,
    };
  }, [school]);

  const handleSubmit = useCallback(
    (values: AssociationInformation) => {
      const {
        nationalAssociations,
        regionalAssociations,
        stateAssociations,
        associationContactDetails,
      } = values;

      const isEmptyArray =
        !nationalAssociations?.length &&
        !regionalAssociations?.length &&
        !stateAssociations?.length;

      const submitObject = {
        associationIds: isEmptyArray
          ? []
          : [
              ...(nationalAssociations?.length
                ? [...nationalAssociations]
                : []),
              ...(regionalAssociations?.length
                ? [...regionalAssociations]
                : []),
              ...(stateAssociations?.length ? [...stateAssociations] : []),
            ],
        associationContactDetails: associationContactDetails ?? {},
        schoolId,
      };

      updateAssociationList(submitObject)
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('successfullyUpdatedAssociationInformation')));
          setTimeout(() => {
            dispatch(loadCurrentUser());
            navigate('/my-account/school-settings');
          }, 800);
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, navigate, schoolId, t, updateAssociationList],
  );

  return (
    <div className="edit-association-list">
      <Form
        onSubmit={handleSubmit}
        initialValues={associationInformation}
        render={() => <Outlet />}
      />
    </div>
  );
};

export default EditAssociationList;
