import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { required } from 'validations';
import useCallbackRef from 'hooks/useCallbackRef';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import api from 'api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import CheckboxGroupField from 'components/CheckboxGroupField';
import { SubscriptionCancellationReason } from 'searchality-data';
import DateService from 'services/Date.service';
import Anim from 'components/animations';
import ConditionalField from 'components/ConditionalField';
import { UnsubscribeValues } from 'types';

import './UnsubscribeModal.styles.scss';

type UnsubscribeModalProps = {
  className?: string;
};

const UnsubscribeModal: ForwardRefRenderFunction<
  ModalRef,
  UnsubscribeModalProps
> = (props, ref) => {
  const { className } = props;

  const { t } = useTranslation();

  const user = useAppSelector(authSelectors.selectUser);

  const { schoolGroup } = user;

  const dispatch = useAppDispatch();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const options = useMemo(
    () =>
      Object.values(SubscriptionCancellationReason).map((value) => ({
        label: value,
        value: value,
      })),
    [],
  );

  const handleSubmit = useCallback(
    async (values: UnsubscribeValues) => {
      try {
        await api.schools.unsubscribe(values);
        dispatch(
          updateUser({
            ...user,
            schoolGroup: {
              ...schoolGroup,
              isSubscriptionCancelledAtPeriodEnd: true,
            },
          }),
        );
        dispatch(popSuccess(t('successUnsubscribed')));
        modal.close();
      } catch (error) {
        dispatch(popServerError(error));
      }
    },
    [dispatch, modal, schoolGroup, t, user],
  );

  const validate = useCallback((values: UnsubscribeValues) => {
    const { reasons } = values;

    if (!reasons?.length) {
      return { reasons: 'error' };
    }

    return {};
  }, []);

  const classes = classNames('unsubscribe-modal', className);

  return (
    <Modal
      ref={modalRef}
      className={classes}
      title={t('UnsubscribeModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          invalid,
          values: { reasons },
        }) => (
          <form onSubmit={handleSubmit}>
            <p className="description">{t('UnsubscribeModal.description')}</p>
            <Field
              name="reasons"
              component={CheckboxGroupField}
              options={options}
            />
            <Anim.Collapse
              active={reasons?.includes(
                SubscriptionCancellationReason.SOMETHING_ELSE,
              )}
            >
              <ConditionalField<string>
                name="otherReason"
                component={TextAreaField}
                validate={required()}
                maxLength={250}
                showLengthCount
                required
              />
            </Anim.Collapse>
            <p>
              {t('UnsubscribeModal.endSubscriptionInfo', {
                endDate: DateService.getUSADateFormatLong(
                  DateService.getDayBefore(schoolGroup.subscriptionEndDate),
                ),
              })}
            </p>
            <div className="actions">
              <Button
                variant="outline"
                disabled={submitting || invalid}
                type="submit"
              >
                {t('UnsubscribeModal.submit')}
              </Button>
              <Button onClick={() => modal.close()}>{t('goBack')}</Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(UnsubscribeModal);
