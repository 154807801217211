import { useGetMemberVacanciesQuery } from 'api/members.api';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import PaginationInfo from 'components/PaginationInfo';
import { useParams as useFilterParams } from 'hooks/useParams';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MemberVacancyCard from './components/MemberVacancyCard';
import './MemberVacancies.styles.scss';

const MemberVacancies = () => {
  const { memberId } = useParams();
  const { params, changeCurrentPage } = useFilterParams({
    $limit: 6,
    $page: 1,
  });
  const { data, isLoading } = useGetMemberVacanciesQuery({
    memberId,
    params,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (isLoading) {
    return <PageLoader />;
  }

  const { items: vacancies, ...pagination } = data;

  return (
    <div className="member-vacancies">
      <PaginationInfo name={t('vacanciesSmall')} {...pagination} limit={6} />

      <ul>
        {vacancies.map((vacancy) => (
          <MemberVacancyCard
            key={vacancy._id}
            {...vacancy}
            onClick={() => navigate(`/vacancies/${vacancy._id}`)}
          />
        ))}
      </ul>

      {pagination?.totalPages > 1 && (
        <Pagination {...pagination} onChange={changeCurrentPage} />
      )}
    </div>
  );
};

export default MemberVacancies;
