import React from 'react';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';

import './SliderButtons.styles.scss';

type SliderButtonsProps = {
  onPrevious: () => void;
  onNext: () => void;
  previousButtonText?: string;
  nextButtonText?: string;
  nextButtonDisabled?: boolean;
  previousButtonDisabled?: boolean;
  countText?: string;
};

const SliderButtons: React.FC<SliderButtonsProps> = (props) => {
  const {
    onPrevious,
    onNext,
    previousButtonText,
    nextButtonText,
    nextButtonDisabled,
    previousButtonDisabled,
    countText,
  } = props;

  return (
    <div className="slider-buttons">
      <button
        type="button"
        className="svg-button-wrapper rotate"
        onClick={onPrevious}
        disabled={previousButtonDisabled}
      >
        <ArrowIcon />
        {previousButtonText && <p>{previousButtonText}</p>}
      </button>
      {countText && <p>{countText}</p>}
      <button
        type="button"
        className="svg-button-wrapper"
        onClick={onNext}
        disabled={nextButtonDisabled}
      >
        {nextButtonText && <p>{nextButtonText}</p>}
        <ArrowIcon />
      </button>
    </div>
  );
};

export default SliderButtons;
