import React, { useState } from 'react';
import classNames from 'classnames';
import { HOMEPAGE_LINK } from 'constants/links';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import HeaderNavigation from 'components/HeaderNavigation';
import MobileHeaderNavigation from 'components/MobileHeaderNavigation';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role } from 'searchality-data';
import { ReactComponent as BurgerIcon } from 'icons/Burger.icon.svg';
import { ReactComponent as LogoutIcon } from 'icons/Logout.icon.svg';
import { NavLink } from 'react-router-dom';

import './Header.styles.scss';
import './Header.styles.responsive.scss';
import { logout } from 'store/slices/auth.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  shadow?: boolean;
  className?: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, shadow } = props;

  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const user = useAppSelector(authSelectors.selectUser);

  const { role, schoolGroupId, emailConfirmed } = user || {};

  const noUser = !user;

  const showJustLogout =
    !emailConfirmed || (role === Role.AccountOwner && !schoolGroupId);

  const classes = classNames('searchality-header', { shadow }, className);

  return (
    <>
      <header className={classes}>
        {!noUser && (
          <div className="searchality-header__mobile-navigation-wrapper">
            {showJustLogout ? (
              <NavLink
                className="logout-link"
                to={''}
                onClick={() => dispatch(logout())}
              >
                <>
                  {t('logout')}
                  <LogoutIcon
                    fill="none"
                    width={30}
                    height={30}
                    stroke="#768591"
                  />
                </>
              </NavLink>
            ) : (
              <button
                className="svg-button-wrapper"
                onClick={() => setOpen((prevState) => !prevState)}
              >
                <BurgerIcon />
              </button>
            )}
          </div>
        )}
        <a
          href={HOMEPAGE_LINK}
          target="_blank"
          rel="noreferrer"
          className="searchality-header__logo"
        >
          <img src={searchalityLogo} alt="Logo" />
        </a>
        {!noUser && (
          <div className="searchality-header__desktop-navigation">
            <HeaderNavigation showJustLogout={showJustLogout} />
          </div>
        )}
      </header>
      {!noUser && !showJustLogout && (
        <MobileHeaderNavigation
          closeMenu={() => setOpen(false)}
          isOpen={open}
        />
      )}
    </>
  );
};

export default Header;
