import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import CandidateCard from '../../components/CandidateCard';
import {
  useAddAdditionalDocumentMutation,
  useDeleteAdditionalDocumentMutation,
  useGetCandidateQuery,
  useRequestDocumentsMutation,
} from 'api/canidates.api';
import { useParams } from 'react-router-dom';
import CandidatePositionInfo from '../../components/CandidateCard/components/CandidatePositionInfo';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import { Field, Form } from 'react-final-form';
import FormFieldLabel from 'components/FormFieldLabel';
import CheckboxWithInputGroupField from 'components/CheckboxWithInputGroupField';
import DatepickerField from 'components/DatepickerField';
import { required, validateDateInFuture, composeValidators } from 'validations';
import useRequestDocumentOptions from '../../hooks/useRequestDocumentOptions';
import { Button } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import AdditionalDocumentModal from '../../components/AdditionalDocumentModal';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { FormApi } from 'final-form';
import DocumentsRequestedSection from '../../components/DocumentsRequestedSection';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import UploadedRequestedDocument from '../../components/UploadedRequestedDocument';
import confirm from 'modules/confirm';
import DocumentsSubmittedByCandidate from '../../components/DocumentsSubmittedByCandidate';

import './RequestCandidatesDocuments.styles.scss';
import './RequestCandidatesDocuments.styles.responsive.scss';
import RequestQuestions from '../../components/RequestQuestions';
import RequestVideo from '../../components/RequestVideo';

type RequestCandidatesDocumentsProps = {
  className?: string;
};

type FieldValue = {
  title: string;
  description: string;
};

type FormValues = {
  submitByDate: string | Date;
  documents?: FieldValue[];
  includeFollowUpQuestions?: boolean;
  includeVideoDescription?: boolean;
};

const RequestCandidatesDocuments: React.FC<RequestCandidatesDocumentsProps> = (
  props,
) => {
  const { className } = props;

  const [requestDocumentMutation] = useRequestDocumentsMutation();
  const [addAdditionalDocumentMutation] = useAddAdditionalDocumentMutation();
  const [deleteAdditionalDocumentMutation] =
    useDeleteAdditionalDocumentMutation();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const additionalDocumentModalRef = useRef<ModalRef>();

  const { candidateId } = useParams();
  const { data: candidate } = useGetCandidateQuery(candidateId);

  const options = useRequestDocumentOptions();

  const baseClass = 'request-candidates-documents';
  const classes = classNames(baseClass, className);

  const submittedDocuments = useMemo(
    () =>
      candidate?.requestedDocuments?.documents
        ?.map(({ files, _id, title, description }) =>
          files.map((file) => ({
            ...file,
            documentId: _id,
            title,
            description,
          })),
        )
        .flat(),
    [candidate?.requestedDocuments?.documents],
  );

  const handleSubmit = useCallback(
    (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
      const { change } = form;
      const {
        submitByDate,
        documents,
        includeFollowUpQuestions,
        includeVideoDescription,
      } = values;

      requestDocumentMutation({
        candidateId,
        documents,
        submitByDate,
        includeFollowUpQuestions,
        includeVideoDescription,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('RequestCandidatesDocuments.success')));
          change('documents', undefined);
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [candidateId, dispatch, requestDocumentMutation, t],
  );

  const addAdditionalDocument = useCallback(
    async (values: { file: File[] }) => {
      const { file } = values;

      const formData = new FormData();

      if (file?.length) {
        formData.append('file', file[0]);
      }

      await addAdditionalDocumentMutation({ candidateId, data: formData })
        .unwrap()
        .then(() => {
          dispatch(
            popSuccess(t('RequestCandidatesDocuments.additionalSuccess')),
          );
          additionalDocumentModalRef.current.close();
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [addAdditionalDocumentMutation, candidateId, dispatch, t],
  );

  const handleDeleteAdditionalFile = useCallback(
    async (key: string) => {
      await confirm({
        title: t('AdditionalDocumentModal.delete'),
        onSubmit: () => {
          deleteAdditionalDocumentMutation({ candidateId, key })
            .unwrap()
            .then(() => {
              dispatch(
                popSuccess(
                  t('RequestCandidatesDocuments.deleteAdditionalSuccess'),
                ),
              );
            })
            .catch((e) => dispatch(popServerError(e)));
        },
      });
    },
    [candidateId, deleteAdditionalDocumentMutation, dispatch, t],
  );

  return (
    <div className={classes}>
      <h5 className={`${baseClass}__title`}>
        <GoBackButton className={`${baseClass}__go-back`} />
        {t('RequestCandidatesDocuments.title')}
      </h5>
      <CandidateCard
        key={candidate?._id}
        {...candidate}
        suffixNode={
          <CandidatePositionInfo
            schoolName={candidate?.schools?.[0]?.name}
            positionTitle={candidate?.vacancy?.positionTitle}
          />
        }
      />
      <div className={`${baseClass}__form-container`}>
        <div className={`${baseClass}__form-container__information`}>
          <InformationIcon />
          <p>{t('RequestCandidatesDocuments.information')}</p>
        </div>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            submitByDate: candidate?.requestedDocuments?.submitByDate,
            includeVideoDescription:
              !!candidate?.requestedAdditionalInformation?.video,
            includeFollowUpQuestions:
              !!candidate?.requestedAdditionalInformation?.followUpQuestions
                ?.length,
          }}
          render={({
            handleSubmit,
            invalid,
            values: {
              documents,
              includeFollowUpQuestions,
              includeVideoDescription,
            },
            submitting,
            pristine,
          }) => (
            <form
              className={`${baseClass}__form-container__form`}
              onSubmit={handleSubmit}
            >
              <FormFieldLabel
                text={t('RequestCandidatesDocuments.deadlineLabel')}
              />
              <Field
                name="submitByDate"
                placeholder={t('selectDate')}
                component={DatepickerField}
                validate={composeValidators(required(), validateDateInFuture())}
                onlyFuture
              />
              {!!submittedDocuments?.length && (
                <DocumentsSubmittedByCandidate
                  submittedDocuments={submittedDocuments}
                />
              )}
              {!!candidate?.requestedAdditionalInformation?.followUpQuestions?.some(
                (el) => el?.answer,
              ) && (
                <RequestQuestions
                  description={t('RequestCandidatesDocuments.questionUpDesc')}
                  noToggle
                  showAnswer
                />
              )}
              {!!candidate?.requestedAdditionalInformation?.video?.videoUrl && (
                <RequestVideo
                  description={t('RequestCandidatesDocuments.videoDesc')}
                  noToggle
                  showAnswer
                />
              )}
              {!!candidate?.requestedDocuments?.documents?.length && (
                <DocumentsRequestedSection
                  documents={candidate.requestedDocuments.documents}
                />
              )}
              <FormFieldLabel text={t('requestDocuments')} />
              <Field
                name="documents"
                options={options}
                component={CheckboxWithInputGroupField}
              />
              {!candidate?.requestedAdditionalInformation?.followUpQuestions?.some(
                (el) => el?.answer,
              ) && (
                <RequestQuestions
                  description={t('RequestCandidatesDocuments.questionDesc')}
                />
              )}
              {!candidate?.requestedAdditionalInformation?.video?.videoUrl && (
                <RequestVideo
                  description={t('RequestCandidatesDocuments.videoDesc')}
                />
              )}

              <Button
                type="submit"
                disabled={
                  submitting ||
                  invalid ||
                  (includeFollowUpQuestions === undefined &&
                    includeVideoDescription === undefined &&
                    !documents) ||
                  pristine
                }
              >
                {t('RequestCandidatesDocuments.notify')}
              </Button>
            </form>
          )}
        />
        <div className={`${baseClass}__other-documents`}>
          <FormFieldLabel
            text={t('RequestCandidatesDocuments.otherDocuments')}
          />
          <p>{t('RequestCandidatesDocuments.otherDocumentsDescription')}</p>

          {!!candidate?.requestedDocuments?.additionalDocuments?.length && (
            <div className={`${baseClass}__other-documents__list`}>
              {candidate.requestedDocuments.additionalDocuments.map(
                (document) => (
                  <UploadedRequestedDocument
                    key={document._id}
                    {...document}
                    deleteKey={document.key}
                    handleDeleteFile={handleDeleteAdditionalFile}
                  />
                ),
              )}
            </div>
          )}

          <Button
            onClick={() => {
              additionalDocumentModalRef?.current?.open();
            }}
            variant={
              candidate?.requestedDocuments?.additionalDocuments?.length
                ? 'outline'
                : 'solid'
            }
            icon={
              candidate?.requestedDocuments?.additionalDocuments?.length && (
                <PlusIcon />
              )
            }
            iconPosition="right"
          >
            {candidate?.requestedDocuments?.additionalDocuments?.length > 0
              ? t('addAnotherDocument')
              : t('addDocument')}
          </Button>
        </div>
      </div>
      <AdditionalDocumentModal
        ref={additionalDocumentModalRef}
        handleSubmit={addAdditionalDocument}
        title={t('AdditionalDocumentModal.title')}
        description={t('AdditionalDocumentModal.description')}
        buttonText={t('addDocument')}
      />
    </div>
  );
};

export default RequestCandidatesDocuments;
