import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBBottomActions from '../../components/TPBBottomActions';
import { required } from 'validations';
import DropzoneField from 'components/DropzoneField';
import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';

import './EducationStatement.styles.scss';

const EducationStatementPage: React.FC = () => {
  const { t } = useTranslation();

  const baseclass = 'tpb-education-statement';

  const { submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const isSubmitable = dirtyFields['educationPhilosophy'];

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Credentials/Education');
    }
  };

  return (
    <div className={baseclass}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent>
        <TPBPageHeading
          text={t('teacherProfileBuilder.educationStatement.title')}
          description={t(
            'teacherProfileBuilder.educationStatement.description',
          )}
        />
        <Field
          name="educationPhilosophy"
          component={DropzoneField}
          multiple={false}
          validate={required()}
        />
        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default EducationStatementPage;
