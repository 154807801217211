import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { mandatorySteps } from '../constants';

export default () => {
  const unfinishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectUnfinishedRoutes,
  );

  const allRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectAllRoutes,
  );

  const mandatoryUnfinishedSteps = unfinishedRoutes.filter(
    (step) => mandatorySteps[step],
  );

  const percentageOfMandatoryUnfinishedSteps = (
    (mandatoryUnfinishedSteps.length / allRoutes.length) *
    100
  ).toFixed(0);

  return {
    mandatoryUnfinishedSteps,
    percentageOfMandatoryUnfinishedSteps,
  };
};
