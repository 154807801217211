import React from 'react';
import useSchoolInformation from '../../hooks/useSchoolInformation';
import ReviewCardWrapper from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import SliderButtons from 'components/SliderButtons';

const SchoolProfileInformation: React.FC = () => {
  const { list, schools, nextStep, previousStep, currentSchooldIndex } =
    useSchoolInformation();

  const { role, schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const editSchool = () => {
    if (schoolGroup.structureType === SchoolGroupStructureType.STANDALONE) {
      navigate(`/my-account/school-settings/${schools[0]._id}/edit`);
    } else {
      navigate(
        `/my-account/group-settings/school-information-edit/${schools[currentSchooldIndex]?._id}`,
      );
    }
  };

  return (
    <div className="school-information-preview">
      <ReviewCardWrapper
        list={list}
        title={t('schoolInformation')}
        onEdit={role === Role.AccountOwner ? editSchool : undefined}
      />
      {schools?.length > 1 && (
        <SliderButtons
          onNext={nextStep}
          onPrevious={previousStep}
          countText={t('schoolSliderCountText', {
            count: currentSchooldIndex + 1,
            maxCount: schools?.length,
          })}
        />
      )}
    </div>
  );
};

export default SchoolProfileInformation;
