import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useGetGroupedAssociationsQuery } from 'api/associations.api';
import useAssociationList from '../../hooks/useAssociationList';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import MultipleSelectField from 'components/MultipleSelectField';
import GoBackButton from 'components/GoBackButton';
import { Button } from 'ncoded-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import useSchoolInformationRoute from '../../../../hooks/useSchoolInformationRoute';
import PageLoader from 'components/PageLoader';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { SEARCHALITY_CONTACT_MAIL_SUPPORT } from 'constants/links';

import './SchoolAssociations.styles.scss';

type SchoolAssociationsProps = {
  className?: string;
  goBackRoute?: string;
  isEdit?: boolean;
  schoolState?: string;
  handleDelete?: () => Promise<void>;
};

const SchoolAssociations: React.FC<SchoolAssociationsProps> = (props) => {
  const {
    className,
    goBackRoute,
    isEdit = false,
    schoolState,
    handleDelete,
  } = props;

  const classes = classNames('school-associations', className);

  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateRoute = useSchoolInformationRoute(false);

  const { getState, submit } = useForm();

  const { values, initialValues } = useFormState();

  const { data, isLoading, isFetching } = useGetGroupedAssociationsQuery({
    state: schoolState ?? values?.school?.state,
    isForTesting: schoolGroup?.matchingInformation?.isForTesting ?? false,
  });

  const { nationalAssociations, regionalAssociations, stateAssociations } =
    values || {};

  const { National, Regional, State } = data || {};

  const national = useAssociationList(National);
  const regional = useAssociationList(Regional);
  const state = useAssociationList(State);

  const isAssociationListNotEmpty = useMemo(
    () =>
      !!initialValues?.nationalAssociations?.length ||
      !!initialValues?.stateAssociations?.length ||
      !!initialValues?.regionalAssociations?.length,
    [initialValues],
  );

  const handleContinue = useCallback(() => {
    return navigate('../association-contact-details');
  }, [navigate]);

  if (isLoading || isFetching) {
    return <PageLoader />;
  }

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        handleContinue();
      }}
    >
      <GoBackButton
        to={
          goBackRoute
            ? goBackRoute
            : pathname.includes('add-new-school')
            ? '../part-of-association'
            : `/create-school/${navigateRoute}/part-of-association`
        }
      />
      <h1>{t('CreateSchoolAssociations.SelectAssociations.title')}</h1>
      <p className="description">
        {t('CreateSchoolAssociations.SelectAssociations.description')}
      </p>
      <Field
        name="nationalAssociations"
        component={MultipleSelectField}
        label={t('nationalAssociations')}
        options={national}
        key={national?.toString()}
      />
      <Field
        name="regionalAssociations"
        component={MultipleSelectField}
        label={t('regionalAssociations')}
        options={regional}
        key={regional?.toString()}
      />
      <Field
        name="stateAssociations"
        component={MultipleSelectField}
        label={t('stateAssociations')}
        options={state}
        key={state?.toString()}
      />
      <p className="description">
        {t('CreateSchoolAssociations.SelectAssociations.description2') +
          ' ' +
          t('selectingAssociationsEsplained1')}{' '}
        <a href={`mailto:${SEARCHALITY_CONTACT_MAIL_SUPPORT}`}>
          {SEARCHALITY_CONTACT_MAIL_SUPPORT}
        </a>{' '}
        {t('selectingAssociationsEsplained2')}
      </p>
      <div>
        <Button
          type="submit"
          disabled={
            !nationalAssociations?.length &&
            !regionalAssociations?.length &&
            !stateAssociations?.length
          }
        >
          {t('continue')}
        </Button>
        {isEdit && isAssociationListNotEmpty && (
          <Button variant="outline" onClick={handleDelete}>
            {t('removeAllAssociations')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default SchoolAssociations;
