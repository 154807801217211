import React from 'react';
import classNames from 'classnames';
import { getNameInitials } from 'utils';
import PhotoPreview from 'components/PhotoPreview';
import { User } from 'models/User';

import './Comment.styles.scss';

type CommentProps = {
  className?: string;
  comment: { content: string; author: Partial<User>; isAdding: boolean };
};

const Comment: React.FC<CommentProps> = (props) => {
  const { className, comment } = props;

  const {
    content,
    author: { firstName, lastName, imageFile, systemColor },
    isAdding,
  } = comment;

  const baseClass = 'comment';

  const classes = classNames(
    baseClass,
    { 'comment--loading': isAdding },
    className,
  );

  return (
    <div className={classes}>
      <PhotoPreview
        src={imageFile?.url}
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={systemColor}
        size="m"
        className={`${baseClass}__left`}
      />
      <div className={`${baseClass}__right`}>
        <p
          className={`${baseClass}__right__name`}
        >{`${firstName} ${lastName}`}</p>
        <p className={`${baseClass}__right__info`}>{content}</p>
      </div>
    </div>
  );
};

export default Comment;
