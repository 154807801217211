import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
} from 'react';
import { useUpdateExpiredVacancyMutation } from 'api/vacancies.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { Field, Form } from 'react-final-form';
import { required } from 'validations';
import DatepickerField from 'components/DatepickerField';
import FormFieldLabel from 'components/FormFieldLabel';
import { Params } from 'types';
import { batch } from 'react-redux';
import paramsActions from 'store/actions/params.actions';

import './VacancyExpiredUpdateModal.styles.scss';

const VacancyExpiredUpdateModal: ForwardRefRenderFunction<
  ModalRef,
  {
    vacancyId: string;
    closeModal: () => void;
    initialParams?: Partial<Params>;
  }
> = (props, ref) => {
  const { vacancyId, closeModal, initialParams } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [updateVacancy] = useUpdateExpiredVacancyMutation();

  const handleSubmit = useCallback(
    async (values: { startDate: string }) => {
      const { startDate } = values;

      await updateVacancy({
        _id: vacancyId,
        startDate,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('VacancyExpiredUpdateModal.success')));
          batch(() => {
            dispatch(paramsActions.resetFilters('vacanciesParams'));
            dispatch(paramsActions.changeCurrentPage('vacanciesParams', 1));
            initialParams &&
              dispatch(
                paramsActions.initializeParams(
                  'vacanciesParams',
                  initialParams,
                ),
              );
          });
          closeModal();
        })
        .catch((err) => {
          dispatch(popServerError(err));
        });
    },
    [updateVacancy, vacancyId, dispatch, t, closeModal, initialParams],
  );

  return (
    <Modal
      ref={ref}
      title={t('VacancyExpiredUpdateModal.title')}
      className="vacancy-expired-update-modal"
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('VacancyExpiredUpdateModal.newStartDate')}</p>
            <FormFieldLabel text={t('startDate')} />
            <Field
              name="startDate"
              component={DatepickerField}
              validate={required()}
              required
              onlyFuture
            />
            <div className="actions">
              <Button variant="outline" onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button type="submit" disabled={invalid || submitting}>
                {t('confirm')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};
export default forwardRef(VacancyExpiredUpdateModal);
