import { ComplexRoute } from 'router/components/Routes/Routes';
import { Role } from 'searchality-data';
import Members from './pages/Members';
import MembersProfilePage from './pages/MembersProfile';

export default [
  {
    path: ':memberId',
    element: MembersProfilePage,
    authorizedRoles: [
      Role.AccountOwner,
      Role.HiringManager,
      Role.AccountManager,
    ],
  },
  {
    index: true,
    element: Members,
  },
] as Array<ComplexRoute>;
