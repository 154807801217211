import React from 'react';
import TeacherProfileBanner from '../../components/TeacherProfileBanner';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import UnfinishedStepsBanner from '../../components/UnfinishedStepsBanner';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import authSelectors from 'store/selectors/auth.selectors';
import PhotoPreview from 'components/PhotoPreview';
import { getNameInitials } from 'utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';

import './ProfileStatus.styles.scss';
import { useNavigate } from 'react-router-dom';

const ProfileStatus: React.FC = () => {
  const baseClass = 'teacher-profile-build__profile-status';
  const unfinishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectUnfinishedRoutes,
  );
  const { profileStatus, imageFile, systemColor, firstName, lastName } =
    useAppSelector(authSelectors.selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={baseClass}>
      <TPBTopActions noGoBack noStatus />
      <TPBPageContent centered>
        {profileStatus === 'Published' ? (
          <PhotoPreview
            src={imageFile?.url}
            backgroundColor={systemColor}
            placeHolderText={getNameInitials(firstName, lastName)}
            size="xl"
          />
        ) : undefined}
        <TeacherProfileBanner />
        {unfinishedRoutes.length > 0 ? <UnfinishedStepsBanner /> : undefined}
      </TPBPageContent>
      <Button onClick={() => navigate('../personal-information')}>
        {unfinishedRoutes.length > 0 ? t('getStarted') : t('continue')}
      </Button>
    </div>
  );
};

export default ProfileStatus;
