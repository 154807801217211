import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

import './ImageContentModal.styles.scss';
import './ImageContentModal.styles.responsive.scss';

type ImageContentModalProps = {
  className?: string;
  imgSrc: string;
  contentText: string;
};

const ImageContentModal: React.ForwardRefRenderFunction<
  ModalRef,
  ImageContentModalProps
> = (props, ref) => {
  const { className, imgSrc, contentText } = props;

  const classes = classNames('image-content-modal', className);

  return (
    <Modal ref={ref} className={classes}>
      <img src={imgSrc} alt="Matching target" />
      <p>{contentText}</p>
    </Modal>
  );
};

export default forwardRef(ImageContentModal);
