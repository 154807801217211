import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Qualifications } from 'models/Teacher';
import CandidateQualification from 'components/CandidateQualification';
import QualificationsService from 'services/Qualifications.service';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { Button } from 'ncoded-component-library';
import NegativeFeedbackModal from '../NegativeFeedbackModal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

import './OpportunityCard.styles.scss';
import './OpportunityCard.styles.responsive.scss';

type OpportunityCardProps = {
  className?: string;
  schoolName: string;
  schoolState: string;
  positionTitle: string;
  subjectGradeLevel: string;
  publishedAt: string | Date;
  startDate: Date | string | null;
  qualifications: Qualifications;
  opportunityId: string;
  isTeacherInterested?: boolean;
  onClick?: () => void;
};

const OpportunityCard: React.FC<OpportunityCardProps> = (props) => {
  const {
    className,
    schoolName,
    schoolState,
    positionTitle,
    subjectGradeLevel,
    publishedAt,
    startDate,
    qualifications,
    opportunityId,
    isTeacherInterested,
    onClick,
  } = props;

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();

  const { numberOfMatchingFields = 0, numberOfTotalFields = 0 } =
    qualifications || {};

  const [variant, message] = useMemo(
    () =>
      QualificationsService.getCandidatesQualificationVariantAndMessage(
        numberOfMatchingFields,
        numberOfTotalFields,
        true,
      ),
    [numberOfMatchingFields, numberOfTotalFields],
  );

  const startingDate = useMemo(
    () =>
      startDate
        ? DateService.getUSADateFormatLong(startDate)
        : t('immediately'),
    [startDate, t],
  );

  const postedAgo = useMemo(() => {
    const [count, key] = DateService.getAmoutOfTimeFromPresent(publishedAt);

    const translationKey = `postedX${key}Ago`;

    return t(translationKey, { count });
  }, [publishedAt, t]);

  const classes = classNames('opportunity-card', className);

  return (
    <>
      <li
        className={classes}
        onClick={(e) => {
          onClick();
        }}
      >
        <div className="opportunity-card__left">
          <div className="opportunity-card__left__top">
            <span className="opportunity-card__left__top__school-information">
              <p className="opportunity-card__left__top__school-information__name">
                {schoolName}
              </p>
              <p className="opportunity-card__left__top__school-information__state">
                {schoolState}
              </p>
            </span>
            <p className="opportunity-card__left__top__position-title">
              {positionTitle}
            </p>
            <p className="opportunity-card__left__top__subject-grade">
              {subjectGradeLevel}
            </p>
          </div>
          <div className="opportunity-card__left__bottom">
            <span className="opportunity-card__left__bottom__start-date">
              <label>{t('startingDate')}: </label>
              <p>{startingDate}</p>
            </span>
            <p className="opportunity-card__left__bottom__published-at">
              {postedAgo}
            </p>
          </div>
        </div>
        <div className="opportunity-card__right">
          <CandidateQualification
            qualificationsNumber={numberOfTotalFields}
            candidateQualifications={numberOfMatchingFields}
            variant={variant}
            message={message}
            isMatchingRate
          />
        </div>
        {!isTeacherInterested && (
          <Button
            icon={<DeleteIcon />}
            className="svg-button-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              modalRef?.current?.open();
            }}
          />
        )}
      </li>
      {!isTeacherInterested && (
        <NegativeFeedbackModal
          ref={modalRef}
          navigateAfterSubmit={false}
          opportunityId={opportunityId}
        />
      )}
    </>
  );
};

export default OpportunityCard;
