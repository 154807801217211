import { createApi } from '@reduxjs/toolkit/query/react';
import { Opportunity } from 'models/Teacher';
import { User } from 'models/User';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

export const matchingApi = createApi({
  reducerPath: 'matchingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Opportunities', 'Opportunity'],
  endpoints: (builder) => ({
    disableVacanciesBanner: builder.mutation<User, boolean>({
      query: (isMatchingAdDisabled) => {
        return {
          url: 'school-users/marketing-information',
          method: 'PATCH',
          params: {
            $populate: ['schoolGroup', 'schoolGroup.schools', 'schools'],
          },
          data: {
            isMatchingAdDisabled,
          },
        };
      },
    }),
    getMatchingOpportunities: builder.query<
      PaginatedResponse<Opportunity>,
      { params: Params }
    >({
      query: ({ params }) => {
        return {
          url: 'matches/opportunities',
          method: 'get',
          params: {
            ...params,
            $populate: [
              'vacancy',
              'teacher',
              'vacancy.schools',
              'vacancy.schoolGroup',
            ],
          },
        };
      },
      providesTags: ['Opportunities'],
    }),
    getMatchingOpportunity: builder.query<Opportunity, string>({
      query: (opportunityId) => {
        return {
          url: `matches/opportunities/${opportunityId}`,
          method: 'get',
          params: {
            $populate: [
              'vacancy',
              'teacher',
              'vacancy.schools',
              'vacancy.schoolGroup',
            ],
          },
        };
      },
      providesTags: ['Opportunity'],
    }),
    submitMatchingFeedback: builder.mutation<
      Opportunity,
      {
        matchId: string;
        isTeacherInterested: boolean;
        feedbackReasons?: string[];
      }
    >({
      query: (data) => {
        return {
          url: `matches/feedback`,
          method: 'post',
          params: {
            $populate: [
              'vacancy',
              'teacher',
              'vacancy.schools',
              'vacancy.schoolGroup',
            ],
          },
          data,
        };
      },
      invalidatesTags: ['Opportunities', 'Opportunity'],
    }),
  }),
});

export const {
  useDisableVacanciesBannerMutation,
  useGetMatchingOpportunitiesQuery,
  useGetMatchingOpportunityQuery,
  useSubmitMatchingFeedbackMutation,
} = matchingApi;
