import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as LinkIcon } from 'icons/PublicationLink.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import pdfIcon from 'assets/images/PdfImage.png';

import './PublicationLink.styles.scss';
import './PublicationLink.styles.responsive.scss';

type PublicationLinkProps = {
  name: string;
  isPdf: boolean;
  file?: File;
  url?: string;
  showFileLink?: boolean;
  className?: string;
  handleDelete?: () => void;
};

const PublicationLink: React.FC<PublicationLinkProps> = (props) => {
  const {
    className,
    isPdf,
    file,
    url,
    name,
    showFileLink = false,
    handleDelete,
  } = props;

  const classes = classNames('publication-link', className);

  const linkIcon = useMemo(
    () => (isPdf ? <img src={pdfIcon} alt="pdf" /> : <LinkIcon />),
    [isPdf],
  );

  return (
    <a
      className={classes}
      href={url ?? URL.createObjectURL(file)}
      target="_blank"
      rel="noreferrer"
    >
      {linkIcon}
      <div className="publication-link__info">
        <p className="publication-link__info__name">{name}</p>
        <p className="publication-link__info__additional">
          {!isPdf || showFileLink
            ? url
            : (file?.size / 1024).toFixed(1) + ' kB'}
        </p>
      </div>
      {handleDelete && (
        <DeleteIcon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete();
          }}
          className="publications__delete"
        />
      )}
    </a>
  );
};

export default PublicationLink;
