import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ApplicationStatus, VacancyStatus } from 'searchality-data';
import { useTranslation } from 'react-i18next';
import BasicTag from 'components/BasicTag';
import { ReactComponent as WorkExperienceIcon } from 'icons/WorkExperience.icon.svg';
import { ReactComponent as RefferalsIcon } from 'icons/Referrals.icon.svg';
import { Button } from 'ncoded-component-library';
import { DateTime } from 'luxon';

import './ApplicationMetadataCard.styles.scss';
import './ApplicationMetadataCard.styles.responsive.scss';

type ApplicationMetadata = {
  title: string;
  schoolName: string;
  date: string;
  status: ApplicationStatus;
  positionDivision: string;
  positionType: string;
  schoolType: string;
  website?: string;
  vacancyStatus: VacancyStatus;
};

type ApplicationMetadataCardProps = {
  className?: string;
  data: ApplicationMetadata;
  handleFinish?: () => void;
};

const ApplicationMetadataCard: React.FC<ApplicationMetadataCardProps> = (
  props,
) => {
  const { className, data, handleFinish } = props;

  const {
    title,
    schoolName,
    date,
    status,
    positionDivision,
    positionType,
    schoolType,
    vacancyStatus,
  } = data;

  const { t } = useTranslation();

  const baseClass = 'application-metadata-card';
  const classes = classNames(baseClass, className);

  const mapBasicTagVariant = useMemo(() => {
    if (status === ApplicationStatus.DRAFT) {
      return 'orange';
    }
    if (
      status === ApplicationStatus.REJECTED_BY_SCHOOL ||
      status === ApplicationStatus.VACANCY_CLOSED
    ) {
      return 'lightGrey';
    }
    return 'outline';
  }, [status]);

  const formatDate = useMemo(() => {
    const { monthLong, day, year } = DateTime.fromJSDate(new Date(date));
    return `${day} ${monthLong} ${year}`;
  }, [date]);

  return (
    <div className={classes}>
      <div className={`${baseClass}__left`}>
        <div className={`${baseClass}__left__title`}>
          <p className={`${baseClass}__left__title__main`}>{title}</p>
          <p className={`${baseClass}__left__title__sub`}>{schoolName}</p>
        </div>
        <div className={`${baseClass}__left__school-info`}>
          <div className={`${baseClass}__left__school-info__position`}>
            <WorkExperienceIcon />
            <p className={`${baseClass}__left__school-info__position__type`}>
              {positionType}
            </p>
            <p
              className={`${baseClass}__left__school-info__position__division`}
            >
              {positionDivision}
            </p>
          </div>
          <div className={`${baseClass}__left__school-info__refferals`}>
            <RefferalsIcon />
            <p>{schoolType}</p>
          </div>
        </div>
        <div className={`${baseClass}__left__date`}>
          <label>{t('ApplicationCard.started')}</label>
          <p>{formatDate}</p>
        </div>
      </div>
      <div className={`${baseClass}__right`}>
        <div className={`${baseClass}__right__status`}>
          <label>{t('status')}</label>
          <BasicTag text={status} variant={mapBasicTagVariant} />
        </div>
        <div className={`${baseClass}__right__actions`}>
          {status === ApplicationStatus.DRAFT && (
            <>
              {vacancyStatus === VacancyStatus.CLOSED ? (
                <BasicTag
                  text={t('closedForApplications')}
                  variant="lightGrey"
                />
              ) : (
                <Button className="finish-application" onClick={handleFinish}>
                  {t('finishApplication')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationMetadataCard;
