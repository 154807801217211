import FormFieldLabel from 'components/FormFieldLabel';
import React, { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBBottomActions from '../../components/TPBBottomActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBTopActions from '../../components/TPBTopActions';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { TagsField } from 'components/Tags/Tags.component';
import bus from 'modules/bus';

import './SportsActivities.styles.scss';

const SportsActivitiesPage: React.FC = () => {
  const { t } = useTranslation();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { submit } = useForm();
  const { submitting } = useFormState();

  const { dirtyFields, valid } = useFormState();

  const isSubmitable = ['sports', 'activities'].some(
    (fieldName) => dirtyFields[fieldName],
  );

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Languages');
    }
  };

  const onSkip = useCallback(() => {
    bus.broadcastEvent('JUMP_TO_ROUTE', 'Languages');
  }, []);

  const baseClass = 'tpb-sports-activities';

  return (
    <div className={baseClass}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent>
        <TPBPageHeading text={t('teacherProfileNavItems.sports')} />
        <FormFieldLabel text={t('teacherProfileBuilder.sports.coachLabel')} />
        <Field
          name="sports"
          component={TagsField}
          placeholder={t('teacherProfileBuilder.sports.coachPlaceholder')}
        />

        <FormFieldLabel
          text={t('teacherProfileBuilder.sports.additionalClubs')}
        />
        <Field
          component={TagsField}
          name="activities"
          placeholder={t(
            'teacherProfileBuilder.sports.additionalClubsPlaceholder',
          )}
        />

        <TPBBottomActions
          canSkip
          onSkip={onSkip}
          submitBtnDisabled={submitting}
          onSubmit={onSubmit}
        />
      </TPBPageContent>
    </div>
  );
};

export default SportsActivitiesPage;
