import React from 'react';
import ReviewCard from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCard/';
import ReviewCardItem from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCard/components/ReviewCardItem';
import { ReviewCardItemList } from 'types';
import { ReviewCardProps } from '../ReviewCard/ReviewCard.component';

type ReviewCardWrapperProps = ReviewCardProps & {
  list: ReviewCardItemList;
  isInnerTitle?: boolean;
};

const ReviewCardWrapper: React.FC<ReviewCardWrapperProps> = (props) => {
  const { className, title, list, children, onEdit, isInnerTitle } = props;

  return (
    <ReviewCard
      className={className}
      title={title}
      onEdit={onEdit}
      isInnerTitle={isInnerTitle}
    >
      {list?.map(
        ({ label, value, description, subtitle, className }, index) => (
          <ReviewCardItem
            key={index}
            label={label}
            description={description}
            subtitle={subtitle}
            className={className}
          >
            {value ? value : 'N/A'}
          </ReviewCardItem>
        ),
      )}
      {children}
    </ReviewCard>
  );
};

export default ReviewCardWrapper;
