import { ComplexRoute } from 'router/components/Routes/Routes';
import AssociationContactDetailsEditWrapper from './pages/AssociationContactDetailsEditWrapper';
import SchoolAssociationsEditWrapper from './pages/SchoolAssociationsEditWrapper';

export default [
  {
    path: 'choose-associations',
    element: SchoolAssociationsEditWrapper,
  },
  {
    path: 'association-contact-details',
    element: AssociationContactDetailsEditWrapper,
  },
] as Array<ComplexRoute>;
