import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { TeacherProfileStatus } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { mandatorySteps } from '../../constants';
import useMandatoryFinishedStepsInfo from '../../hooks/useMandatoryFinishedStepsInfo';
import useMandatoryUnfinishedStepsInfo from '../../hooks/useMandatoryUnfinishedStepsInfo';

import './TeacherProfileBanner.styles.scss';

const TeacherProfileBanner: React.FC = () => {
  const { t } = useTranslation();

  const { profileStatus } = useAppSelector(authSelectors.selectUser);

  const finishedSteps = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );

  const allRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectAllRoutes,
  );

  const { mandatoryFinishedSteps, percentageOfMandatoryFinishedSteps } =
    useMandatoryFinishedStepsInfo();

  const { percentageOfMandatoryUnfinishedSteps, mandatoryUnfinishedSteps } =
    useMandatoryUnfinishedStepsInfo();

  const optionalFinishedSteps = finishedSteps.filter(
    (step) => !mandatorySteps[step],
  );

  const percentageOfOptionalFinishedSteps = (
    (optionalFinishedSteps.length / allRoutes.length) *
    100
  ).toFixed(0);

  const baseClass = 'teacher-profile-banner';

  if (profileStatus === TeacherProfileStatus.PUBLISHED) {
    return (
      <div className={baseClass}>
        <div className={`${baseClass}__filled-container`}>
          {mandatoryFinishedSteps.length ? (
            <div
              className={`${baseClass}__filled-container--done`}
              style={{ width: `${percentageOfMandatoryFinishedSteps}%` }}
            />
          ) : undefined}
          {optionalFinishedSteps.length ? (
            <div
              className={`${baseClass}__filled-container--done`}
              style={{ width: `${percentageOfOptionalFinishedSteps}%` }}
            />
          ) : undefined}
          {mandatoryUnfinishedSteps.length ? (
            <div
              className={`${baseClass}__filled-container--mandatory-undone`}
              style={{ width: `${percentageOfMandatoryUnfinishedSteps}%` }}
            />
          ) : undefined}
        </div>
        <div className={`${baseClass}__content`}>
          <h4>{t('teacherProfileBanner.published')}</h4>
          <p>{t('teacherProfileBanner.publishedDescription')}</p>
        </div>
      </div>
    );
  }
  const isMandatoryRouteMissing = mandatoryUnfinishedSteps.length > 0;

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__filled-container`}>
        {mandatoryFinishedSteps.length ? (
          <div
            className={`${baseClass}__filled-container--done`}
            style={{ width: `${percentageOfMandatoryFinishedSteps}%` }}
          />
        ) : undefined}
        {optionalFinishedSteps.length ? (
          <div
            className={`${baseClass}__filled-container--done`}
            style={{ width: `${percentageOfOptionalFinishedSteps}%` }}
          />
        ) : undefined}
        {mandatoryUnfinishedSteps.length ? (
          <div
            className={`${baseClass}__filled-container--mandatory-undone`}
            style={{ width: `${percentageOfMandatoryUnfinishedSteps}%` }}
          />
        ) : undefined}
      </div>
      <div className={`${baseClass}__content`}>
        <h4>
          {t(
            isMandatoryRouteMissing
              ? 'teacherProfileBanner.mandatoryMissing'
              : 'teacherProfileBanner.completed',
          )}
          {(profileStatus as TeacherProfileStatus) ===
            TeacherProfileStatus.PUBLISHED &&
          allRoutes.length - finishedSteps.length !== 0 ? (
            <p>
              {t('stepsToComplete', {
                count: allRoutes.length - finishedSteps.length,
              })}
            </p>
          ) : undefined}
        </h4>
        <p>
          {t(
            isMandatoryRouteMissing
              ? 'teacherProfileBanner.mandatoryMissingDescription'
              : 'teacherProfileBanner.completedDescription',
          )}
        </p>
      </div>
    </div>
  );
};

export default TeacherProfileBanner;
