import { useTranslation } from 'react-i18next';
import { Role } from 'searchality-data';

export default () => {
  const { t } = useTranslation();
  return [
    {
      label: t('associationOwner'),
      value: Role.AssociationOwner,
    },
    {
      label: t('associationMember'),
      value: Role.AssociationMember,
    },
  ];
};
