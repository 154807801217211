import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  ApplicationRequestedAdditionalInformation,
  RequestedDocument,
} from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import { FormApi } from 'final-form';
import DateService from 'services/Date.service';
import UploadRequestedDocument from 'components/UploadRequestedDocument';
import InputField from 'components/InputField';

import './ApplicationUploadDocuments.styles.scss';

export type SubmitApplicationInformationFormValues = Record<string, File[]> & {
  videoUrl: string;
  questions: Record<string, string>;
};

type ApplicationUploadDocumentsProps = {
  className?: string;
  documents?: RequestedDocument[];
  questionsAndVideo: ApplicationRequestedAdditionalInformation;
  submitByDate: string | Date;
  handleSubmit: (
    values: SubmitApplicationInformationFormValues,
    form: FormApi<
      SubmitApplicationInformationFormValues,
      SubmitApplicationInformationFormValues
    >,
  ) => void;
};

const ApplicationUploadDocuments: React.FC<ApplicationUploadDocumentsProps> = (
  props,
) => {
  const {
    className,
    documents,
    handleSubmit,
    submitByDate,
    questionsAndVideo,
  } = props;

  const { followUpQuestions, video } = questionsAndVideo || {};

  const { t } = useTranslation();

  const classes = classNames('application-upload-documents', className);

  const initialValues = useMemo(() => {
    const questions = followUpQuestions
      ?.filter((question) => !!question?.answer)
      .reduce((prev, curr) => ({ ...prev, [curr.question]: curr.answer }), {});

    const videoUrl = video?.videoUrl;

    return { questions, videoUrl };
  }, [followUpQuestions, video?.videoUrl]);

  return (
    <div className={classes}>
      <h1>{t('Teacher.Application.Documents.requested')}</h1>
      <p>
        {t('Teacher.Application.Documents.deadline', {
          date: DateService.getUSADateFormatLong(submitByDate),
        })}
      </p>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues as SubmitApplicationInformationFormValues}
        render={({ handleSubmit, values, pristine }) => {
          let isButtonDisabled = false;

          const { questions, videoUrl, ...restValues } = values;

          if (!values || pristine) {
            isButtonDisabled = true;
          } else {
            if (
              Object.values(restValues).every((v) => v.length === 0) &&
              !Object.keys(questions ?? {})?.length &&
              !videoUrl
            ) {
              isButtonDisabled = true;
            }
          }
          return (
            <form onSubmit={handleSubmit}>
              <div className="application-upload-documents__list">
                {documents?.map((document) => (
                  <UploadRequestedDocument
                    document={document}
                    key={document._id}
                  />
                ))}
              </div>
              {!!followUpQuestions?.length && (
                <div className="application-upload-documents__questions">
                  <p>{t('VacancySettings.Add.questions')}</p>
                  {followUpQuestions.map(({ question, answer }) => (
                    <div
                      key={question}
                      className="application-upload-documents__questions__question"
                    >
                      <p>{question}</p>
                      <Field
                        name={`questions.${question}`}
                        component={InputField}
                        label={t('answer')}
                        disabled={!!answer}
                      />
                    </div>
                  ))}
                </div>
              )}
              {!!video && (
                <div className="application-upload-documents__questions">
                  <p>{t('videoRequestedBySchool')}</p>
                  <div className="application-upload-documents__questions__question">
                    <p>{video?.videoDescription}</p>
                    <Field
                      name="videoUrl"
                      component={InputField}
                      label={t('videoUrlPlaceholder')}
                      disabled={!!video?.videoUrl}
                    />
                  </div>
                </div>
              )}
              <p>{t('UploadRequestedDocument.noteBeforeSubmit')}</p>
              <Button type="submit" disabled={isButtonDisabled}>
                {t('submitAnswers')}
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

export default ApplicationUploadDocuments;
