import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import AssociationContactDetails from 'router/subrouters/Dashboard/subrouters/CreateSchool/subrouters/SchoolInformation/pages/AssociationContactDetails';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { useUpdateAssociationListMutation } from 'api/associations.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { loadCurrentUser } from 'store/slices/auth.slice';

import './AssociationContactDetailsEditWrapper.styles.scss';

const AssociationContactDetailsEditWrapper: React.FC = () => {
  const { schoolId } = useParams();

  const { submit } = useForm();

  const { t } = useTranslation();
  const [updateAssociationList] = useUpdateAssociationListMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDelete = useCallback(async () => {
    await confirm({
      title: t('confirmRemoveAssociationListTitle'),
      content: t('confirmRemoveAssociationListDesc'),
      onSubmit: () => {
        updateAssociationList({
          associationIds: [],
          associationContactDetails: {},
          schoolId,
        })
          .unwrap()
          .then(() => {
            dispatch(
              popSuccess(t('successfullyUpdatedAssociationInformation')),
            );
            setTimeout(() => {
              dispatch(loadCurrentUser());
              navigate(`/my-account/school-settings/${schoolId}`);
            }, 800);
          })
          .catch((e) => dispatch(popServerError(e)));
      },
    });
  }, [dispatch, navigate, schoolId, t, updateAssociationList]);

  return (
    <AssociationContactDetails
      goBackRoute={`/my-account/edit-association-information/${schoolId}/choose-associations`}
      handleSubmit={submit}
      handleDelete={handleDelete}
      isEdit
    />
  );
};

export default AssociationContactDetailsEditWrapper;
