import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { TeacherCertificate } from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { DateTime } from 'luxon';
import ObjectPreviewCard from 'components/ObjectPreviewCard';
import ErrorMessage from 'components/ErrorMessage';
import { Form } from 'react-final-form';
import { Button, Modal } from 'ncoded-component-library';
import CheckboxField from 'components/CheckboxField';
import { Field } from 'react-final-form';
import YearMonthInput from 'components/YearMonthInput';
import ConditionalField from 'components/ConditionalField';
import { required } from 'validations';
import InputField from 'components/InputField';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';

import './EducationCertificates.styles.scss';
import './EducationCertificates.styles.responsive.scss';

type EducationCertificatesProps = {
  className?: string;
  value: TeacherCertificate[];
  touched?: boolean;
  onChange: (value: TeacherCertificate[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
};

const EducationCertificates: React.FC<EducationCertificatesProps> = (props) => {
  const { className, value, onChange } = props;

  const { t } = useTranslation();

  const [initialValues, setInitialValues] =
    useState<Partial<TeacherCertificate>>();
  const [editIndex, setEditIndex] = useState<number>();

  const addModalRef = useRef<ModalRef>();

  const classes = classNames('education-certificates', className);

  const handleDelete = useCallback(
    (index: number) => {
      onChange(value.filter((_, ind) => ind !== index));
    },
    [onChange, value],
  );

  const handleEdit = useCallback(
    (educationCertificate: TeacherCertificate, index: number) => {
      setInitialValues(educationCertificate);
      setEditIndex(index);
      addModalRef.current.open();
    },
    [],
  );

  const certificateDateValidation = useCallback(
    (value: Date, allValues: any) => {
      if (!allValues?.isCurrentlyCompleting) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
        const year = value.getFullYear();

        if (year < 1900 || year > DateTime.now().year) {
          return t('pleaseEnterYearBetween', {
            from: 1900,
            to: DateTime.now().year,
          });
        }
      }
      return undefined;
    },
    [t],
  );

  const educationCertificateArray = useMemo(
    () =>
      value?.map((educationCertificate, index) => {
        const { certificateName, certificationDate, grantingInstitution } =
          educationCertificate;

        const { monthLong, year } = DateTime.fromJSDate(
          new Date(certificationDate),
        );

        return (
          <ObjectPreviewCard
            data={{
              certificateName,
              grantingInstitution,
              ...(certificationDate && {
                certificationDate: `${monthLong}  ${year}`,
              }),
            }}
            key={`${certificateName}-${index}`}
            handleEdit={() => handleEdit(educationCertificate, index)}
            handleDelete={() => handleDelete(index)}
          />
        );
      }),
    [handleDelete, handleEdit, value],
  );

  const handleSubmit = useCallback(
    (values: TeacherCertificate) => {
      if (editIndex === undefined) {
        onChange([...value, values]);
      } else {
        const valueCopy = structuredClone(value);
        valueCopy[editIndex] = values;

        onChange(valueCopy);
      }
      addModalRef.current.close();
    },
    [editIndex, onChange, value],
  );

  return (
    <div className={classes}>
      <Button
        variant="outline"
        icon={<PlusIcon />}
        onClick={() => {
          addModalRef.current.open();
        }}
        className="education-certificates__button"
      >
        {t('add')}
      </Button>
      {!!value?.length && (
        <div className="education-certificates__cards">
          {educationCertificateArray}
        </div>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          certificationDate: initialValues?.certificationDate
            ? new Date(initialValues.certificationDate)
            : undefined,
        }}
        render={({
          handleSubmit,
          hasSubmitErrors,
          submitError,
          modifiedSinceLastSubmit,
          values,
          form: { reset },
        }) => {
          return (
            <Modal
              className="education-certificates-modal"
              ref={addModalRef}
              title={t(
                editIndex !== undefined
                  ? 'educationCertificatesModal.editEducationCertificate'
                  : 'educationCertificatesModal.addEducationCertificate',
              )}
              onClose={() => {
                reset();
                setInitialValues({});
                setEditIndex(undefined);
              }}
              footer={
                <Button onClick={handleSubmit}>
                  {t(
                    editIndex !== undefined
                      ? 'educationCertificatesModal.editEducationCertificate'
                      : 'educationCertificatesModal.addEducationCertificate',
                  )}
                </Button>
              }
            >
              <form onSubmit={handleSubmit}>
                <div className="education-certificates-modal__basic-info">
                  <div className="education-certificates-modal__basic-info__first-row">
                    <Field
                      name="grantingInstitution"
                      component={InputField}
                      label={t('grantingInstitution')}
                      validate={required()}
                      required
                    />
                    <Field
                      name="certificateName"
                      component={InputField}
                      label={t('certificateName')}
                      validate={required()}
                      required
                    />
                  </div>
                </div>
                <div className="education-certificates-modal__date-range">
                  <ConditionalField<Date>
                    component={YearMonthInput}
                    name="certificationDate"
                    labelYear={t('enterYear')}
                    labelMonth={t('selectMonth')}
                    label={t('certificationDate')}
                    onlyPast
                    shouldClear={values?.isCurrentlyCompleting}
                    disabled={values?.isCurrentlyCompleting}
                    showError={false}
                    validate={certificateDateValidation}
                  />
                </div>
                <Field
                  name="isCurrentlyCompleting"
                  component={CheckboxField}
                  type="checkbox"
                  isStyledDefault
                  label={t('educationCertificatesModal.isCurrentlyCompleting')}
                  className="education-certificates-modal__checkbox"
                />
                {hasSubmitErrors && !modifiedSinceLastSubmit && (
                  <ErrorMessage message={submitError} />
                )}
              </form>
            </Modal>
          );
        }}
      />
    </div>
  );
};

export default EducationCertificates;
