import TwoOptionsSelectField from 'components/TwoOptionsSelectField';
import FormFieldLabel from 'components/FormFieldLabel';
import SelectField from 'components/SelectField';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import useAnotherLanguage from '../../../../../../../hooks/selectOptions/useAnotherLanguageOptions';
import useAnotherLanguageLevel from '../../../Vacancies/pages/VacancyWizard/hooks/useAnotherLanguageLevel';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBBottomActions from '../../components/TPBBottomActions';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';

import './Languages.styles.scss';

const LanguagesPage: React.FC = () => {
  const { t } = useTranslation();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const anotherLanguage = useAnotherLanguage();

  const anotherLanguageLevel = useAnotherLanguageLevel();

  const { dirtyFields, submitting, valid } = useFormState();
  const { submit } = useForm();

  const isSubmitable = ['englishLevel', 'languages'].some(
    (fieldName) => dirtyFields[fieldName],
  );

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Publications');
    }
  };

  return (
    <div className="tpb-languages">
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent>
        <TPBPageHeading text={t('teacherProfileBuilder.languages.title')} />
        <FormFieldLabel text={t('levelOf', { language: 'English' })} />
        <Field
          name="englishLevel"
          component={SelectField}
          options={anotherLanguageLevel}
          placeholder={t('LanguageLevel.NATIVE_SPEAKER')}
          validate={required()}
          required
        />

        <FormFieldLabel
          text={t('teacherProfileBuilder.languages.otherLanguageQuestion')}
        />
        <Field
          name="languages"
          component={TwoOptionsSelectField}
          firstSelectOptions={anotherLanguage}
          secondSelectOptions={anotherLanguageLevel}
          otherOptionLabel={t(
            'teacherProfileBuilder.languages.otherSelectedLabel',
          )}
          firstOptionName="language"
          secondOptionName="level"
        />

        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default LanguagesPage;
