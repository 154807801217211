import React from 'react';
import { TPBRoute } from 'models/TeacherProfileBuilder';
import { TeacherProfileStep } from 'searchality-data';
import { ReactComponent as ProfileIcon } from 'icons/Profile.icon.svg';
import { ReactComponent as ContactIcon } from 'icons/Contact.icon.svg';
import { ReactComponent as CalendarIcon } from 'icons/Calendar.icon.svg';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as SportIcon } from 'icons/Sport.icon.svg';
import { ReactComponent as LanguageIcon } from 'icons/Language.icon.svg';
import { ReactComponent as ReferralsIcon } from 'icons/Referrals.icon.svg';
import { ReactComponent as CareerExperienceIcon } from 'icons/WorkExperience.icon.svg';
import { ReactComponent as ChatIcon } from 'icons/Chat.icon.svg';
import { ReactComponent as TargetPositionIcon } from 'icons/TargetPosition.icon.svg';
import { ReactComponent as TargetLocationIcon } from 'icons/Location.icon.svg';
import { ReactComponent as CurriculumIcon } from 'icons/Curriculum.icon.svg';

export const routesMapping: { [name in TeacherProfileStep]: TPBRoute } = {
  'Personal Information': 'personal-information',
  'Contact Information': 'contact-information',
  'Work availability': 'work-availability',
  'Education Statements': 'education-statement',
  'Credentials/Education': 'credentials',
  'Career/Experience': 'career',
  'Sports/Activities': 'sports',
  Languages: 'languages',
  Publications: 'publications',
  References: 'references',
  'Target positions': 'target-positions',
  'Target locations': 'target-locations',
};

export const iconsMapping: { [name in TeacherProfileStep]: JSX.Element } = {
  'Personal Information': <ProfileIcon />,
  'Contact Information': <ContactIcon />,
  'Work availability': <CalendarIcon />,
  'Education Statements': <ChatIcon />,
  'Credentials/Education': <EducationIcon />,
  'Career/Experience': <CareerExperienceIcon />,
  'Sports/Activities': <SportIcon />,
  Languages: <LanguageIcon />,
  Publications: <CurriculumIcon />,
  References: <ReferralsIcon />,
  'Target positions': <TargetPositionIcon />,
  'Target locations': <TargetLocationIcon />,
};

export const mandatorySteps: { [name in TeacherProfileStep]: boolean } = {
  'Personal Information': true,
  'Contact Information': true,
  'Work availability': true,
  'Education Statements': true,
  'Credentials/Education': true,
  'Career/Experience': false,
  'Sports/Activities': false,
  Languages: true,
  Publications: false,
  References: true,
  'Target positions': false,
  'Target locations': false,
};

export const ProfileStatusCardMapping: {
  [name in TeacherProfileStep]: {
    title: string;
    description?: string;
  };
} = {
  'Personal Information': {
    title: 'teacherProfileNavItems.personal-information',
    description: 'teacherProfileBuilder.personalInformation.description',
  },
  'Contact Information': {
    title: 'teacherProfileNavItems.contact-information',
  },
  'Work availability': {
    title: 'teacherProfileBuilder.workAvailability.title',
  },
  'Education Statements': {
    title: 'teacherProfileBuilder.educationStatement.title',
    description: 'teacherProfileBuilder.educationStatement.description',
  },
  'Credentials/Education': {
    title: 'teacherProfileNavItems.credentials',
    description: 'teacherProfileBuilder.credentials.description',
  },
  'Career/Experience': {
    title: 'teacherProfileBuilder.careerExprexience.title',
    description: 'teacherProfileBuilder.careerExprexience.description',
  },
  'Sports/Activities': { title: 'teacherProfileNavItems.sports' },
  Languages: {
    title: 'teacherProfileBuilder.languages.title',
  },
  Publications: {
    title: 'teacherProfileBuilder.publications.title',
    description: 'teacherProfileBuilder.publications.description',
  },
  References: {
    title: 'teacherProfileBuilder.references.title',
    description: 'teacherProfileBuilder.references.description',
  },
  'Target positions': {
    title: 'teacherProfileBuilder.targetPositions.title',
    description: 'teacherProfileBuilder.targetPositions.description',
  },
  'Target locations': {
    title: 'teacherProfileBuilder.targetLocations.title',
    description: 'teacherProfileBuilder.targetLocations.description',
  },
};
