import React, { useRef } from 'react';
import classNames from 'classnames';
import './ButtonDropdown.styles.scss';
import './ButtonDropdown.styles.responsive.scss';
import { Button, Dropdown } from 'ncoded-component-library';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';

type ButtonDropdownProps = {
  className?: string;
  contentList: { label: string; onClick: () => void }[];
  buttonText: string;
  onButtonClick: () => void;
  buttonDisabled: boolean;
  disableDropdownButton?: boolean;
};

const ButtonDropdown: React.FC<ButtonDropdownProps> = (props) => {
  const {
    className,
    contentList,
    buttonText,
    onButtonClick = () => {},
    buttonDisabled,
    disableDropdownButton,
  } = props;

  const classes = classNames('button-dropdown', className);
  const dropdownRef = useRef<DropdownRef>(null);

  return (
    <div className={classes}>
      <Button disabled={buttonDisabled} onClick={onButtonClick}>
        {buttonText}
      </Button>
      <Dropdown
        ref={dropdownRef}
        trigger={
          <Button
            disabled={disableDropdownButton}
            icon={
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
                <path
                  d="M1.5 1.25L5 4.75L8.5 1.25"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            }
            iconPosition="right"
          />
        }
      >
        <ul>
          {contentList.map(({ label, onClick }) => (
            <li
              key={label}
              onClick={() => {
                onClick();
                dropdownRef.current.setIsOpen(false);
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};

export default ButtonDropdown;
