import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import VacancyWrapper from './pages/VacancyWrapper';
import vacancyProfileRoutes from './subrouters/VacancyProfile/vacancyProfile.routes';
import VacancyProfileRouter from './subrouters/VacancyProfile';
import CreateVacancyRouter from './subrouters/CreateVacancy/CreateVacancy.router';
import CreateVacancyRoutes from './subrouters/CreateVacancy/CreateVacancy.routes';
import VacancySettingsRouter from './subrouters/VacancySettings/VacancySettings.router';
import VacancySettingsRoutes from './subrouters/VacancySettings/VacancySettings.routes';
import { Role } from 'searchality-data';

export default [
  {
    element: VacancyWrapper,
    path: 'active',
  },
  {
    path: 'archived',
    element: VacancyWrapper,
  },
  {
    path: 'create-vacancy',
    element: CreateVacancyRouter,
    routes: CreateVacancyRoutes,
  },
  {
    path: 'drafted',
    element: VacancyWrapper,
  },
  {
    path: ':vacancyId',
    element: VacancyProfileRouter,
    routes: vacancyProfileRoutes,
  },
  {
    path: 'vacancy-settings',
    element: VacancySettingsRouter,
    routes: VacancySettingsRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.AccountManager,
      Role.HiringManager,
    ],
  },
  {
    index: true,
    element: <Navigate to="active" replace />,
  },
] as Array<ComplexRoute>;
