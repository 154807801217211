import { useUpdateTeacherUserEmailSettingsMutation } from 'api/users.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { TeacherUserEmailSettings, User } from 'models/User';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CommunicationSettings from 'router/subrouters/Dashboard/components/CommunicationSettings';
import authSelectors from 'store/selectors/auth.selectors';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useTeacherCommunicationSettings from '../../hooks/useTeacherCommunicationSettings';

const TeacherCommunicationSettings: React.FC = () => {
  const teacherSettingsList = useTeacherCommunicationSettings();

  const user = useAppSelector(authSelectors.selectUser);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [updateTeacherUserEmailSettings] =
    useUpdateTeacherUserEmailSettingsMutation();

  const handleSubmit = useCallback(
    async (values: TeacherUserEmailSettings) => {
      updateTeacherUserEmailSettings(values)
        .unwrap()
        .then(({ communicationPreference }) => {
          dispatch(updateUser({ communicationPreference } as User));
          dispatch(popSuccess(t('communicationSettingsUpdatedSuccess')));
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, t, updateTeacherUserEmailSettings],
  );

  return (
    <CommunicationSettings
      communicationList={teacherSettingsList}
      handleSubmit={handleSubmit}
      initialValues={user?.communicationPreference}
    />
  );
};

export default TeacherCommunicationSettings;
