import React from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import InfoWithLabel from 'components/InfoWithLabel';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import PersonalInformationEditWrapper from 'router/subrouters/Dashboard/components/PersonalInformationEditWrapper';

import './LoginInformation.styles.scss';
import './LoginInformation.styles.responsive.scss';

type LoginInformationProps = {
  className?: string;
};

const LoginInformation: React.FC<LoginInformationProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { email } = useAppSelector(authSelectors.selectUser);

  const baseClass = 'login-information';
  const classes = classNames(baseClass, className);

  return (
    <PersonalInformationEditWrapper
      title={t('LoginInformation.title')}
      goBackTo=".."
      isDirty={false}
    >
      <div className={classes}>
        <div className={`${baseClass}__email`}>
          <InfoWithLabel
            label={t('email')}
            text={email}
            className={`${baseClass}__info-text`}
          />
          <Button
            variant="outline"
            onClick={() => {
              navigate('change-email');
            }}
          >
            {t('changeEmail')}
          </Button>
        </div>
        <div className={`${baseClass}__password`}>
          <InfoWithLabel
            label={t('password')}
            text={'xxxxxxxxxxxxxxx'}
            className={`${baseClass}__info-text`}
          />
          <Button
            variant="outline"
            onClick={() => {
              navigate('change-password');
            }}
          >
            {t('changePassword')}
          </Button>
        </div>
      </div>
    </PersonalInformationEditWrapper>
  );
};

export default LoginInformation;
