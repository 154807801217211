import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type MyAccountLink = {
  label: string;
  target: string;
};

export default (): MyAccountLink[] => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const isAssociation = pathname.includes('association-account');

  return [
    {
      label: t('personalDetails'),
      target: 'personal-details',
    },
    {
      label: t('loginInformation'),
      target: 'login-information',
    },
    ...(!isAssociation
      ? [
          {
            label: t('communicationAndEmailSettings'),
            target: 'communication-settings',
          },
        ]
      : []),
  ];
};
