import React, { useCallback } from 'react';
import classNames from 'classnames';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import FormFieldLabel from 'components/FormFieldLabel';
import { Field, useForm, useFormState } from 'react-final-form';
import TPBBottomActions from '../../components/TPBBottomActions';
import { useTranslation } from 'react-i18next';
import WorkReferenceImage from 'assets/images/work-references.webp';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';
import PublicationsField from 'router/subrouters/Dashboard/components/PublicationsField';

import './Publications.styles.scss';
import './Publications.styles.responsive.scss';

type PublicationsProps = {
  className?: string;
};

const Publications: React.FC<PublicationsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('tpb-publications', className);

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { dirtyFields, submitting, valid, values } = useFormState();
  const { submit } = useForm();

  const isSubmitable = dirtyFields['publications'];

  const onSkip = useCallback(async () => {
    bus.broadcastEvent('JUMP_TO_ROUTE', 'References');
  }, []);

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'References');
    }
  };
  return (
    <div className={classes}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <img src={WorkReferenceImage} alt="medal" />
        <TPBPageHeading
          text={t('teacherProfileBuilder.publications.title')}
          description={t('teacherProfileBuilder.publications.description')}
        />
        {!values?.publications?.length && (
          <FormFieldLabel
            text={t(`teacherProfileBuilder.publications.${'fieldLabelFirst'}`)}
          />
        )}
        <Field name="publications" component={PublicationsField} />
        <TPBBottomActions
          canSkip
          onSkip={onSkip}
          onSubmit={onSubmit}
          submitBtnDisabled={submitting}
        />
      </TPBPageContent>
    </div>
  );
};

export default Publications;
