export default {
  GENERAL_INTERVENTIONIST: 'General Interventionist',
  GUIDANCE_COUNSELOR: 'Guidance Counselor',
  INFORMATION_TECHNOLOGY: 'Information Technology Specialist',
  INSTRUCTIONAL_COACHES: 'Instructional Coaches',
  LEARNING_SUPPORT: 'Learning Support/Special Education',
  LIBRARIAN_MEDIA_SPECIALIST: 'Librarian/Media Specialist',
  OCCUPATIONAL_THERAPIST: 'Occupational Therapist',
  PSYCHOLOGIST: 'Psychologist',
  READING_SPECIALIST: 'Reading Specialist',
  SOCIAL_WORKER: 'Social Worker',
  SPEECH_THERAPIST: 'Speech/Language Therapist',
  PRE_K: 'Pre-K',
  SPECIAL_EDUCATION: 'Special Education',
  KINDERGARTEN: 'Kindergarten',
  GRADE_1: '1st Grade',
  GRADE_2: '2nd Grade',
  GRADE_3: '3rd Grade',
  GRADE_4: '4th Grade',
  GRADE_5: '5th Grade',
  GIFTED_AND_TALENTED: 'Gifted and Talented',
  ART: 'Art',
  COMPUTERS: 'Computers/Information Technology',
  FOREIGN_LANGUAGE: 'Foreign Language',
  MUSIC: 'Music',
  PHYSICAL_EDUCATION: 'Physical Education',
  THEOLOGY: 'Theology',
  APPLIED_TECHNOLOGY: 'Applied Technology',
  BUSINESS_STUDIES: 'Business Studies',
  DRAMA: 'Drama',
  ENGLISH_ARTS: 'English/Language Arts',
  FAMILY_AND_CONSUMER_SCIENCES: 'Family & Consumer Sciences',
  MATHEMATICS: 'Mathematics',
  SCIENCE: 'Science',
  SOCIAL_STUDIES: 'Social Studies',
  ETHICS: 'Ethics',
  RELIGIOUS_STUDIES: 'Religious Studies',
  PRINCIPAL: 'Principal',
  VICE_ASSISTANT_PRINCIPAL: 'Vice / Assistant Principal',
  DEAN: 'Dean',
  CURRICULUM_DIRECTOR_COORDINATOR: 'Curriculum Director/Coordinator',
  SUPERINTENDENT: 'Superintendent',
  DEPUTY_SUPERINTENDENT: 'Deputy Superintendent',
  HEAD_OF_SCHOOL: 'Head of School',
  DEPUTY_ASSISTANT_HEAD_OF_SCHOOL: 'Deputy/Assistant Head of School',
  CURRICULUM_DIRECTOR: 'Curriculum Director/Coordinator',
};
