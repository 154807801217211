import React, { useMemo } from 'react';
import classNames from 'classnames';
import { NavLink, To } from 'react-router-dom';
import { ReactComponent as SubscriptionLinkIcon } from 'icons/SubscriptionLink.icon.svg';

import './SubscriptionLink.styles.scss';

export type SubscriptionLinkProps = {
  className?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  priceText: string;
  description: string;
  to?: To;
  disabled?: boolean;
  errorMessage?: string;
  onClick?: () => void;
};

const SubscriptionLink: React.FC<SubscriptionLinkProps> = (props) => {
  const {
    className,
    to,
    iconLeft,
    iconRight = <SubscriptionLinkIcon />,
    description,
    priceText,
    disabled,
    errorMessage,
    onClick,
  } = props;

  const classes = classNames(
    'subscription-link',
    { 'subscription-link--disabled': disabled },
    className,
  );

  const content = useMemo(
    () => (
      <>
        <div className="subscription-link__left">
          {iconLeft}
          <div className="subscription-link__left__text">
            <p className="subscription-link__left__text__description">
              {description}
            </p>
            <p className="subscription-link__left__text__price">{priceText}</p>
          </div>
        </div>
        {iconRight}
      </>
    ),
    [description, iconLeft, iconRight, priceText],
  );

  return (
    <div className="subscription-link__wrapper">
      {to ? (
        <NavLink className={classes} to={to}>
          {content}
        </NavLink>
      ) : (
        <div onClick={() => !disabled && onClick()} className={classes}>
          {content}
        </div>
      )}
      {errorMessage && (
        <p className="subscription-link__error">{errorMessage}</p>
      )}
    </div>
  );
};

export default SubscriptionLink;
