import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Modal } from 'ncoded-component-library';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import env from 'env';
import { useTranslation } from 'react-i18next';
import api from 'api';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';

import './AddCardMethodModal.styles.scss';

type AddCardMethodModalProps = {
  className?: string;
};

const AddCardMethodModal: ForwardRefRenderFunction<
  ModalRef,
  AddCardMethodModalProps
> = (props, ref) => {
  const { className } = props;

  const { updatePaymentInfo } = useContext(PaymentMethodsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const stripe = useStripe();

  const elements = useElements();

  const { t } = useTranslation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const dispatch = useAppDispatch();

  const classes = classNames('add-card-method-modal', className);

  const handlePayment = useCallback(async () => {
    if (!stripe || !elements) return;

    setIsLoading(true);
    setErrMessage('');

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: env.PLATFORM_ENDPOINT,
      },
    });

    if (error) {
      setErrMessage(error.message);
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await api.schools.getPaymentInfo();
      updatePaymentInfo(data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(popSuccess(t('paymentMethodUpdated')));
      modal.close();
    }

    setIsLoading(false);
  }, [dispatch, elements, modal, stripe, t, updatePaymentInfo]);

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('AddCardMethodModal.title')}
      footer={
        <Button type="submit" onClick={handlePayment} disabled={isLoading}>
          {t('AddCardMethodModal.submit')}
        </Button>
      }
    >
      {errMessage && <p className="error">{errMessage}</p>}
      <div>
        <AddressElement options={{ mode: 'billing' }} />
      </div>
      <div>
        <PaymentElement />
      </div>
    </Modal>
  );
};

export default forwardRef(AddCardMethodModal);
