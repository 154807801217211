import React, { useCallback, useMemo } from 'react';
import SchoolAssociations from 'router/subrouters/Dashboard/subrouters/CreateSchool/subrouters/SchoolInformation/pages/SchoolAssociations';
import { useNavigate, useParams } from 'react-router-dom';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { useUpdateAssociationListMutation } from 'api/associations.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { loadCurrentUser } from 'store/slices/auth.slice';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';

import './SchoolAssociationsEditWrapper.styles.scss';

const SchoolAssociationsEditWrapper: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { schoolId } = useParams();
  const user = useAppSelector(authSelectors.selectUser);

  const [updateAssociationList] = useUpdateAssociationListMutation();
  const dispatch = useAppDispatch();

  const school = useMemo(
    () => user?.schools?.find(({ _id }) => _id === schoolId),
    [schoolId, user?.schools],
  );

  const handleDelete = useCallback(async () => {
    await confirm({
      title: t('confirmRemoveAssociationListTitle'),
      content: t('confirmRemoveAssociationListDesc'),
      onSubmit: () => {
        updateAssociationList({
          associationIds: [],
          associationContactDetails: {},
          schoolId,
        })
          .unwrap()
          .then(() => {
            dispatch(
              popSuccess(t('successfullyUpdatedAssociationInformation')),
            );
            setTimeout(() => {
              dispatch(loadCurrentUser());
              navigate(`/my-account/school-settings`);
            }, 800);
          })
          .catch((e) => dispatch(popServerError(e)));
      },
    });
  }, [dispatch, navigate, schoolId, t, updateAssociationList]);

  return (
    <SchoolAssociations
      goBackRoute={'/my-account/school-settings'}
      isEdit
      handleDelete={handleDelete}
      schoolState={school?.state}
    />
  );
};

export default SchoolAssociationsEditWrapper;
