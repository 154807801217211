import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'ncoded-component-library';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import InputField from 'components/InputField';
import DropzoneField from 'components/DropzoneField';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { PublicationFormValue } from '../../subrouters/TeacherProfileBuilder/models/TeacherFormValues';
import { WEBSITE_REGEX_WITH_PATH } from 'validations';
import confirm from 'modules/confirm';
import PublicationLink from '../PublicationLink';
import { FORM_ERROR } from 'final-form';
import ErrorMessage from 'components/ErrorMessage';

import './Publications.styles.scss';
import './Publications.styles.responsive.scss';

type PublicationsProps = {
  className?: string;
  value: PublicationFormValue[];
  touched?: boolean;
  onChange: (value: PublicationFormValue[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
};

const Publications: React.FC<PublicationsProps> = (props) => {
  const { className, value, onChange } = props;
  const { t } = useTranslation();

  const classes = classNames('publications', className);

  const addModalRef = useRef<ModalRef>();

  const handleLinkDelete = useCallback(
    async (index: number) => {
      await confirm({
        title: t('PublicationDeleteLinkTitle'),
        onSubmit: () => {
          onChange(value?.filter((_, ind) => ind !== index));
        },
      });
    },
    [onChange, t, value],
  );

  const publicationLinks = useMemo(() => {
    return value.map(({ url, file, name }, index) => (
      <PublicationLink
        key={name}
        file={file?.[0]}
        name={name}
        url={url}
        isPdf={!!file?.length}
        handleDelete={() => {
          handleLinkDelete(index);
        }}
      />
    ));
  }, [handleLinkDelete, value]);

  const handleSubmit = useCallback(
    (values: PublicationFormValue) => {
      const { file, url } = values;
      if (!url && !file?.length) {
        return {
          [FORM_ERROR]: 'You need to enter pdf file or url.',
        };
      }

      if (url && !WEBSITE_REGEX_WITH_PATH.test(url)) {
        return {
          [FORM_ERROR]: 'Not a valid link',
        };
      }
      onChange([...value, values]);

      addModalRef.current.close();
    },
    [onChange, value],
  );

  return (
    <div className={classes}>
      <Button
        variant="outline"
        icon={<PlusIcon />}
        iconPosition="right"
        onClick={() => addModalRef.current.open()}
        className="publications__button"
      >
        {t('addPublication')}
      </Button>
      {value.length > 0 && (
        <div className="publications__cards--wrapper">
          <p>{t('uploaded')}</p>
          <div className="publications__cards">{publicationLinks}</div>
        </div>
      )}
      <Form
        onSubmit={handleSubmit}
        render={({
          handleSubmit,
          form: { reset },
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          submitError,
          values: { file, url },
        }) => {
          return (
            <Modal
              className="publications-modal"
              ref={addModalRef}
              onClose={() => {
                reset();
              }}
            >
              <form
                onSubmit={handleSubmit}
                className="publications-modal__form"
              >
                <div className="publications-modal__form__title">
                  <p className="publications-modal__form__title__main">
                    {t('addAPublication')}
                  </p>
                  <p className="publications-modal__form__title__sub">
                    {t('addAPublicationDescription')}
                  </p>
                </div>
                <div className="publications-modal__form__fields">
                  <Field
                    name="name"
                    component={InputField}
                    label={t('nameOfThePublication')}
                    validate={required()}
                    maxLength={100}
                    required
                  />
                  <Field
                    name="url"
                    component={InputField}
                    label={t('addLink')}
                    disabled={!!file?.length}
                    key={file?.length.toString()}
                  />
                  <p>{t('or')}</p>
                  <Field
                    name="file"
                    component={DropzoneField}
                    disabled={!!url}
                    key={url}
                  />
                  {hasSubmitErrors && !modifiedSinceLastSubmit && (
                    <ErrorMessage message={submitError} />
                  )}
                </div>
                <Button onClick={handleSubmit}>{t('addPublication')}</Button>
              </form>
            </Modal>
          );
        }}
      />
    </div>
  );
};

export default Publications;
