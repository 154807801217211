import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import useNavOptions from './hooks/useNavOptions';
import { useForm } from 'react-final-form';

import './SideNavigation.styles.scss';

type SideNavigationProps = {
  className?: string;
};

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
  const { className } = props;

  const { completedSteps, currentStepIndex } = useContext(
    CreateSchoolWizardContext,
  );

  const NAV_OPTIONS = useNavOptions();

  const classes = classNames('side-navigation', className);

  const { getState } = useForm();

  const { invalid } = getState();

  const navigationItems = useMemo(() => {
    return NAV_OPTIONS.map(({ to, label }, ind) => {
      const isCompleted = completedSteps.includes(ind);

      return (
        <NavLink
          key={to}
          to={to}
          className={() => {
            return classNames('side-navigation__nav-link', {
              active: ind === currentStepIndex,
              completed: isCompleted,
              disabled:
                !isCompleted ||
                (ind > currentStepIndex && isCompleted && invalid),
            });
          }}
        >
          {label}
        </NavLink>
      );
    });
  }, [NAV_OPTIONS, completedSteps, invalid, currentStepIndex]);

  return <nav className={classes}>{navigationItems}</nav>;
};

export default SideNavigation;
