import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as EditIcon } from 'icons/Edit.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

import './ObjectPreviewCard.styles.scss';

type ObjectPreviewCardProps = {
  className?: string;
  data: Record<string, any>;
  handleEdit: () => void;
  handleDelete: () => void;
};

const ObjectPreviewCard: React.FC<ObjectPreviewCardProps> = (props) => {
  const { className, data, handleEdit, handleDelete } = props;

  const { t } = useTranslation();

  const baseClass = 'object-preview-card';
  const classes = classNames(baseClass, className);

  const dataPreview = useMemo(
    () =>
      Object.keys(data).map((key) => (
        <div key={key} className={`${baseClass}__data__container`}>
          <label>
            {`${t(
              key === 'positionDivision' ? 'positionDivisionLabel' : key,
            )}: `}
          </label>
          <p>{data[key]}</p>
        </div>
      )),
    [data, t],
  );

  return (
    <div className={classes}>
      <div className={`${baseClass}__actions`}>
        <Button
          icon={<EditIcon />}
          onClick={handleEdit}
          className={`${baseClass}__actions__button`}
        />
        <Button
          icon={<DeleteIcon />}
          onClick={handleDelete}
          className={`${baseClass}__actions__button`}
        />
      </div>
      <div className={`${baseClass}__data`}>{dataPreview}</div>
    </div>
  );
};

export default ObjectPreviewCard;
