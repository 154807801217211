import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBBottomActions from '../../components/TPBBottomActions';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { Field, useForm, useFormState } from 'react-final-form';
import bus from 'modules/bus';
import TargetPositionsField from '../../components/TargetPositionsField';
import CheckboxGroupField from 'components/CheckboxGroupField';
import {
  SchoolResidentialType,
  TeacherPreferredResidentialRoles,
} from 'searchality-data';
import RadioGroupField from 'components/RadioGroupField';
import RadioButtonLabel from 'components/RadioButtonLabel';

import './TargetPositions.styles.scss';
import './TargetPositions.styles.responsive.scss';

type TargetPositionsProps = {
  className?: string;
};

const TargetPositions: React.FC<TargetPositionsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('target-positions', className);

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { submit } = useForm();
  const {
    dirtyFields,
    submitting,
    valid,
    values: { preferredResidentialTypes },
  } = useFormState();

  const isSubmitable =
    dirtyFields['targetPositions'] ||
    dirtyFields['preferredResidentialTypes'] ||
    dirtyFields['preferredResidentialRole'];

  const options = useMemo(
    () =>
      Object.entries(SchoolResidentialType).map(([key, value]) => ({
        label: t(`ResidentialType.${key}`),
        value,
      })),
    [t],
  );

  const preferredResidentialRoleOptions = useMemo(
    () =>
      Object.entries(TeacherPreferredResidentialRoles).map(([key, value]) => ({
        label: (
          <RadioButtonLabel
            label={t(`TeacherPreferredResidentialRoles.${key}`)}
          />
        ),
        value,
      })),
    [t],
  );

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Target locations');
    }
  };

  const onSkip = useCallback(async () => {
    bus.broadcastEvent('JUMP_TO_ROUTE', 'Target locations');
  }, []);

  return (
    <div className={classes}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <TPBPageHeading
          text={t('teacherProfileBuilder.targetPositions.title')}
          description={t('teacherProfileBuilder.targetPositions.description')}
        />
        <Field name="targetPositions" component={TargetPositionsField} />
        <TPBPageHeading
          text={t('teacherProfileBuilder.targetPositions.schoolTypeTitle')}
          description={t(
            'teacherProfileBuilder.targetPositions.schoolTypeDescription',
          )}
        />
        <Field
          name="preferredResidentialTypes"
          component={CheckboxGroupField}
          options={options}
        />
        {preferredResidentialTypes?.some((type: SchoolResidentialType) =>
          [
            SchoolResidentialType.BOARDING_SCHOOL,
            SchoolResidentialType.MIXED,
          ].includes(type),
        ) && (
          <>
            <TPBPageHeading
              text={t('teacherProfileBuilder.targetPositions.boardingTitle')}
              description={t(
                'teacherProfileBuilder.targetPositions.boardingDescription',
              )}
            />
            <Field
              name="preferredResidentialRole"
              component={RadioGroupField}
              options={preferredResidentialRoleOptions}
            />
          </>
        )}

        <TPBBottomActions
          canSkip
          onSkip={onSkip}
          onSubmit={onSubmit}
          submitBtnDisabled={submitting}
        />
      </TPBPageContent>
    </div>
  );
};

export default TargetPositions;
