import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import {
  useGetApplicationQuery,
  useUploadAdditionalInformationMutation,
  useUploadTeacherRequestedFilesMutation,
} from 'api/teacherApplications.api';
import { ApplicationStatus } from 'searchality-data';
import PageLoader from 'components/PageLoader';
import { useTranslation } from 'react-i18next';
import ApplicationUploadDocuments from 'components/ApplicationUploadDocuments';
import GoBackButton from 'components/GoBackButton';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { FormApi } from 'final-form';
import { SubmitApplicationInformationFormValues } from 'components/ApplicationUploadDocuments/ApplicationUploadDocuments.component';

import './ApplicationUploadDocumentsTeacherWrapper.styles.scss';
import './ApplicationUploadDocumentsTeacherWrapper.styles.responsive.scss';

type ApplicationUploadDocumentsTeacherWrapperProps = {
  className?: string;
};

const ApplicationUploadDocumentsTeacherWrapper: React.FC<
  ApplicationUploadDocumentsTeacherWrapperProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [uploadTeacherDocuments] = useUploadTeacherRequestedFilesMutation();
  const [uploadQuestionsAndVideo] = useUploadAdditionalInformationMutation();

  const { id: applicationId } = useParams() || {};
  const { _id: teacherId } = useAppSelector(authSelectors.selectUser) || {};

  const {
    data: application,
    isLoading,
    refetch,
  } = useGetApplicationQuery({
    applicationId,
    teacherId,
  });

  const { status, requestedDocuments, requestedAdditionalInformation } =
    application || {};

  const { documents, submitByDate } = requestedDocuments || {};

  const onSubmit = useCallback(
    async (
      values: SubmitApplicationInformationFormValues,
      form: FormApi<
        SubmitApplicationInformationFormValues,
        SubmitApplicationInformationFormValues
      >,
    ) => {
      const { questions, videoUrl, ...restValues } = values;

      const keys = Object.keys(restValues).filter(
        (key) => !!restValues[key].length,
      );

      const submitQuestions = Object.entries(questions).map(([key, value]) => ({
        question: key,
        answer: value,
      }));

      const submitVideo = videoUrl
        ? {
            videoDescription:
              requestedAdditionalInformation?.video?.videoDescription,
            videoUrl: videoUrl,
          }
        : undefined;

      if (submitQuestions?.length || submitVideo) {
        await uploadQuestionsAndVideo({
          applicationId,
          data: { followUpQuestions: submitQuestions, video: submitVideo },
        });
      }

      const formData = new FormData();

      let res: any[] = [];

      try {
        for (const key of keys) {
          formData.append('id', key);
          restValues[key].forEach((file) => {
            formData.append('files', file);
          });
          res = [
            ...res,
            await uploadTeacherDocuments({ applicationId, data: formData }),
          ];

          formData.delete('id');
          formData.delete('files');
        }

        refetch();
        form.reset();
        dispatch(popSuccess(t('succesDocumentsSubmitted')));
        if (
          res.find(
            (el: any) =>
              el.data.status === ApplicationStatus.INFORMATION_SUBMITTED,
          )
        ) {
          navigate(`/applications/${applicationId}`);
        }
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [
      applicationId,
      dispatch,
      navigate,
      refetch,
      requestedAdditionalInformation?.video?.videoDescription,
      t,
      uploadQuestionsAndVideo,
      uploadTeacherDocuments,
    ],
  );

  useEffect(() => {
    if (status && status !== ApplicationStatus.INFORMATION_REQUESTED) {
      navigate(`/applications/${applicationId}`);
    }
  }, [applicationId, navigate, status]);

  if (isLoading) {
    return <PageLoader />;
  }

  const baseClass = 'application-upload-documents-teacher-wrapper';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <GoBackButton to={`/applications/${applicationId}`} />
      <ApplicationUploadDocuments
        handleSubmit={onSubmit}
        documents={documents}
        submitByDate={submitByDate}
        questionsAndVideo={requestedAdditionalInformation}
      />
    </div>
  );
};

export default ApplicationUploadDocumentsTeacherWrapper;
