import React from 'react';
import classNames from 'classnames';
import { useGetCandidateQuery } from 'api/canidates.api';
import { useParams } from 'react-router-dom';
import QuestionVideoEmpty from '../QuestionVideoEmpty';
import { ReactComponent as VideoIcon } from 'icons/Video.icon.svg';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import QuestionVideoTag from '../../../Vacancies/subrouters/VacancySettings/components/QuestionVideoTag';

import './RequestVideo.styles.scss';
import ViewGuard from 'router/components/ViewGuard';
import { Role } from 'searchality-data';

type RequestVideoProps = {
  className?: string;
  description: string;
  noToggle?: boolean;
  showAnswer?: boolean;
};

const RequestVideo: React.FC<RequestVideoProps> = (props) => {
  const { className, description, noToggle, showAnswer } = props;

  const { candidateId } = useParams();
  const { t } = useTranslation();

  const { values } = useFormState();

  const { includeVideoDescription } = values;

  const { data: candidate } = useGetCandidateQuery(candidateId);

  const schoolVideo =
    candidate?.schools?.[0]?.requestedAdditionalInformation?.videoDescription;

  const candidateVideo = candidate?.requestedAdditionalInformation?.video;

  const classes = classNames('request-video', className);

  if (!schoolVideo) {
    return (
      <ViewGuard roles={[Role.Reviewer]} permission="forbid">
        <QuestionVideoEmpty
          icon={<VideoIcon />}
          description={t('RequestCandidatesDocuments.videoEmptyDesc')}
          title={t('RequestCandidatesDocuments.videoTitle')}
          buttonText={t('RequestCandidatesDocuments.videoEmptyButton')}
          schoolId={candidate?.schools?.[0]?._id}
        />
      </ViewGuard>
    );
  }

  return (
    <div className={classes}>
      <div className="request-video__toggle-container">
        <div className="request-video__toggle-container__text">
          <p className="request-video__toggle-container__text__title">
            {t('RequestCandidatesDocuments.videoTitle')}
          </p>
          <p className="request-video__toggle-container__text__desc">
            {description}
          </p>
        </div>
        {!noToggle && (
          <Field
            name="includeVideoDescription"
            component={CheckboxField}
            type="checkbox"
            isToggleButton
            isStyledDefault
          />
        )}
      </div>
      {!showAnswer ? (
        <QuestionVideoTag
          className={classNames({ transparent: !includeVideoDescription })}
          value={schoolVideo}
          description={t('VacancySettings.Add.videoTagDesc')}
        />
      ) : (
        <div className="request-video__video-wrapper">
          <p className="request-video__video-wrapper__desc">
            {candidateVideo?.videoDescription}
          </p>
          <a
            className="request-video__video-wrapper__link"
            href={candidateVideo?.videoUrl}
            target="_blank"
            rel="noreferrer"
          >
            {candidateVideo?.videoUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default RequestVideo;
