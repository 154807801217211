import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import VacancyEmptyStateImage from 'assets/images/vacancy-empty-state.webp';

import './CandidatesEmptyState.styles.scss';

type CandidatesEmptyStateProps = {
  className?: string;
};

const CandidatesEmptyState: React.FC<CandidatesEmptyStateProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('candidates-empty-state', className);

  return (
    <div className={classes}>
      <p className={`candidates-empty-state__title`}>
        {t('CandidatesEmptyScreen.title')}
      </p>
      <p className={`candidates-empty-state__subtitle`}>
        {t('CandidatesEmptyScreen.description')}
      </p>
      <img src={VacancyEmptyStateImage} alt="vacancy empty state" />
    </div>
  );
};

export default CandidatesEmptyState;
