import React from 'react';
import ReviewCardWrapper from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import useCard from './hooks/useCard';

const SubscriptionCard: React.FC = () => {
  const { t } = useTranslation();

  const list = useCard();

  return <ReviewCardWrapper list={list} title={t('subscriptionCardTitle')} />;
};

export default SubscriptionCard;
