import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import VacancyCandidates from './pages/VacancyCandidates';
import VacancyCollaborators from './pages/VacancyCollaborators';
import VacancyInformation from './pages/VacancyInformation';
import VacancyPreview from './pages/VacancyPreview';
import EditVacancyRouter from './subrouters/EditVacancy/EditVacancy.router';
import EditVacancyRoutes from './subrouters/EditVacancy/EditVacancy.routes';

export default [
  {
    path: 'candidates',
    element: VacancyCandidates,
  },
  {
    path: 'information',
    element: VacancyInformation,
  },
  {
    path: 'vacancy-collaborators',
    element: VacancyCollaborators,
  },
  {
    path: 'preview',
    element: VacancyPreview,
  },
  {
    path: 'edit',
    element: EditVacancyRouter,
    routes: EditVacancyRoutes,
  },
  {
    index: true,
    element: <Navigate to="candidates" replace />,
  },
] as Array<ComplexRoute>;
