import InputField from 'components/InputField';
import PasswordField from 'components/PasswordField';
import { Button } from 'ncoded-component-library';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LoginData } from 'types';
import {
  composeValidators,
  emailValidation,
  passwordValidation,
  required,
} from 'validations';
import AuthSection from '../AuthSection';

type LoginFormProps = {
  onSubmit: (values: LoginData) => Promise<void>;
};

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { onSubmit } = props;

  const { t } = useTranslation();

  const { pathname } = useLocation();

  return (
    <AuthSection className="auth-form">
      <h1>{t('LoginPage.welcomeBack')}</h1>
      <div className="login__form-title">
        <p className="descriptive">{t('LoginPage.newInSearchality')}</p>
        <Link to="../teacher-signup">{t('LoginPage.createAccount')}</Link>
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit} className="login__form">
            <Field
              name="email"
              component={InputField}
              label={t('email')}
              required
              validate={composeValidators(required(), emailValidation())}
            />

            <Field
              name="password"
              component={PasswordField}
              label={t('password')}
              required
              validate={composeValidators(required(), passwordValidation())}
            />

            <Button
              className="login__button"
              type="submit"
              disabled={invalid || submitting}
            >
              {t('login')}
            </Button>
            <Link
              className="login__forgot-password"
              to={'/auth/forgot-password'}
              state={{
                isTeacher: pathname.includes('teachers'),
              }}
            >
              {t('forgotPassword')}
            </Link>
          </form>
        )}
      />
    </AuthSection>
  );
};

export default LoginForm;
