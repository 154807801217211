import React from 'react';

import './ReviewCardItem.styles.scss';

type ReviewCardItemProps = {
  label: string;
  children: React.ReactNode | React.ReactNode[];
  description?: string;
  subtitle?: string;
  className?: string;
};

const ReviewCardItem: React.FC<ReviewCardItemProps> = (props) => {
  const { label, children, description, className, subtitle } = props;
  const baseClass = 'searchality-review-card-item';

  return (
    <div className={baseClass}>
      {label && <label>{label}</label>}
      {subtitle && <p className={`${baseClass}__subtitle`}>{subtitle}</p>}
      <p className={className}>{children}</p>
      {description && (
        <p className={`${baseClass}__description`}>{description}</p>
      )}
    </div>
  );
};

export default ReviewCardItem;
