import React from 'react';
import { Outlet } from 'react-router-dom';

import './Auth.styles.scss';
import './Auth.styles.responsive.scss';

const AuthRouter: React.FC = () => {
  return <Outlet />;
};

export default AuthRouter;
