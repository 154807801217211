import React, { useCallback, useEffect, useState } from 'react';
import CreateSchoolWizardContext from './CreateSchoolWizard.context';

type SignupStepperProps = {
  children?: JSX.Element;
  initialStep?: number;
};

const CreateSchoolWizard: React.FC<SignupStepperProps> = (props) => {
  const { children, initialStep } = props;

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(initialStep);

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const completeStep = useCallback(
    (stepIndex: number) => {
      if (!completedSteps.some((step) => step === stepIndex)) {
        setCompletedSteps((prev) => [...prev, stepIndex]);
        setCurrentStepIndex(stepIndex);
      }
    },
    [completedSteps],
  );

  const updateCurrentStep = useCallback((stepIndex: number) => {
    setCurrentStepIndex(stepIndex);
  }, []);

  const updateIntialStepsCompleted = useCallback(() => {
    setCompletedSteps(() => {
      const steps: number[] = [];

      if (initialStep === null) {
        return steps;
      }

      for (let i = 0; i <= initialStep; i++) {
        steps.push(i);
      }

      return steps;
    });
  }, [initialStep]);

  const resetCompletedSteps = useCallback(
    (newSteps: number[]) => setCompletedSteps(newSteps),
    [],
  );

  useEffect(() => {
    updateIntialStepsCompleted();
  }, [updateIntialStepsCompleted]);

  return (
    <CreateSchoolWizardContext.Provider
      value={{
        currentStepIndex,
        completedSteps,
        updateCurrentStep,
        completeStep,
        resetCompletedSteps,
      }}
    >
      {children}
    </CreateSchoolWizardContext.Provider>
  );
};

export default CreateSchoolWizard;
