import { useTranslation } from 'react-i18next';
import {
  CollaboratorsUpdatesSubscription,
  EventsAndGeneralMarketingSubscription,
  NewApplicationsSubscription,
  NewsletterAndBlogSubscription,
} from 'searchality-data';
import { CommunicationSettingData } from 'types';

export default (): CommunicationSettingData[] => {
  const { t } = useTranslation();
  return [
    {
      id: '1',
      title: t('Communication.essentialInformation'),
      type: 'toggle',
      hint: t('Communication.essentialInformationHint'),
      list: [
        {
          name: 'personalAndSecurityInformation',
          label: t('Communication.personalAndSecurityInformation'),
        },
        {
          name: 'renewal',
          label: t('Communication.renewal'),
        },
        {
          name: 'criticalUpdates',
          label: t('Communication.criticalUpdates'),
        },
      ],
    },
    {
      id: '2',
      title: t('Communication.newsLetters'),
      type: 'group',
      name: 'newsletterAndBlogSubscription',
      list: Object.entries(NewsletterAndBlogSubscription).map(
        ([key, value]) => ({
          label: t(`Communication.${key}`),
          value,
        }),
      ),
    },
    {
      id: '3',
      title: t('Communication.generalMarketing'),
      type: 'group',
      name: 'eventsAndGeneralMarketingSubscription',
      list: Object.entries(EventsAndGeneralMarketingSubscription).map(
        ([key, value]) => ({
          label: t(`Communication.${key}`),
          value,
        }),
      ),
    },
    {
      id: '4',
      title: t('Communication.newApplications'),
      type: 'group',
      name: 'newApplicationsSubscription',
      list: Object.entries(NewApplicationsSubscription).map(([key, value]) => ({
        label: t(`Communication.NewApplications.${key}`),
        value,
      })),
    },
    {
      id: '5',
      title: t('Communication.collaborators'),
      type: 'group',
      name: 'collaboratorUpdatesSubscription',
      list: Object.entries(CollaboratorsUpdatesSubscription).map(
        ([key, value]) => ({
          label: t(`Communication.${key}`),
          value,
        }),
      ),
    },
    {
      id: '6',
      type: 'toggle',
      hint: t('Communication.VacancyExpirationHint'),
      list: [
        {
          name: 'vacancyExpiration',
          label: t('Communication.VacancyExpiration'),
        },
      ],
    },
  ];
};
