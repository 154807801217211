import React from 'react';
import api from 'api';
import EditPersonalInformation from '../../../../components/EditPersonalInformation';

const TeacherPersonalDetails: React.FC = () => {
  return (
    <EditPersonalInformation
      apiRequest={api.user.updateTeacherUser}
      isTeacher
    />
  );
};

export default TeacherPersonalDetails;
