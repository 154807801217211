import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default (types: string[]) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return types?.map((value) => ({
      label: t(`ApplicationType.${value}`),
      value: value,
    }));
  }, [t, types]);

  return options;
};
