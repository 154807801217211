import { PreviewSubscriptionPrice } from 'types';

export const calculatePricePerYear = (
  previewSubscriptionPrice: PreviewSubscriptionPrice,
) => {
  return previewSubscriptionPrice?.lines?.data?.reduce(
    (prev, curr) =>
      curr?.price?.type === 'recurring' ? prev + curr?.amount : prev,
    0,
  );
};
