import { useCallback, useState } from 'react';
import { MongoWhere, Params } from 'types';

export function useParams(initialParams?: Params, resetPreviouslWhere = false) {
  const [params, setParams] = useState<Params>(initialParams);

  const filterBy = useCallback(
    (key: string, value: MongoWhere | any) => {
      setParams((prev) => ({
        ...prev,
        $where: { ...(!resetPreviouslWhere ? prev?.$where : {}), [key]: value },
        $page: 1,
      }));
    },
    [resetPreviouslWhere],
  );

  const addWhere = useCallback(
    ($where: any) => {
      setParams((prev) => ({
        ...prev,
        $where: { ...(!resetPreviouslWhere ? prev?.$where : {}), ...$where },
        $page: 1,
      }));
    },
    [resetPreviouslWhere],
  );

  const sortBy = useCallback((value: string) => {
    setParams((prev) => ({
      ...prev,
      $sort: value,
      $page: 1,
    }));
  }, []);

  const changeCurrentPage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, $page: page }));
  }, []);

  const resetFilters = useCallback(() => {
    setParams(initialParams);
  }, [initialParams]);

  return {
    params,
    sortBy,
    filterBy,
    changeCurrentPage,
    resetFilters,
    addWhere,
  };
}
