import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import ActiveApplications from './pages/ActiveApplications';
import ApplicationPreview from './pages/ApplicationPreview';
import ApplicationUploadDocumentsTeacherWrapper from './pages/ApplicationUploadDocumentsTeacherWrapper';
import MatchedOpportunities from './pages/MatchedOpportunities';
import Opportunity from './pages/Opportunity';
import PreviousApplications from './pages/PreviousApplications';
import SuccessfulApplications from './pages/SuccessfulApplications';

export default [
  {
    path: 'matched',
    element: MatchedOpportunities,
  },
  {
    path: 'active',
    element: ActiveApplications,
  },
  {
    path: 'previous',
    element: PreviousApplications,
  },
  {
    path: 'successful',
    element: SuccessfulApplications,
  },
  {
    path: ':id',
    element: ApplicationPreview,
  },
  {
    path: ':id/upload-documents',
    element: ApplicationUploadDocumentsTeacherWrapper,
  },
  {
    path: 'matched/:opportunityId',
    element: Opportunity,
  },
  {
    index: true,
    element: <Navigate to="matched" replace />,
  },
] as Array<ComplexRoute>;
