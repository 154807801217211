import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import { VacancyType } from 'searchality-data';
import AcademicJobPositions from '../AcademicJobPositions';
import NonAcademicJobPositions from '../NonAcademicJobPositions';

type JobPositionTypeFieldsProps = {
  className: string;
  isDisabled?: boolean;
};

const JobPositionTypeFields: React.FC<JobPositionTypeFieldsProps> = (props) => {
  const { className, isDisabled = false } = props;

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const isAcademicVacancy = type === VacancyType.ACADEMIC;

  return isAcademicVacancy ? (
    <AcademicJobPositions isDisabled={isDisabled} className={className} />
  ) : (
    <NonAcademicJobPositions isDisabled={isDisabled} className={className} />
  );
};

export default JobPositionTypeFields;
