import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useGetCandidateQuery } from 'api/canidates.api';
import { useParams } from 'react-router-dom';
import QuestionVideoEmpty from '../QuestionVideoEmpty';
import { ReactComponent as DashboardIcon } from 'icons/Dashboard.icon.svg';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import QuestionVideoTag from '../../../Vacancies/subrouters/VacancySettings/components/QuestionVideoTag';

import './RequestQuestions.styles.scss';
import ViewGuard from 'router/components/ViewGuard';
import { Role } from 'searchality-data';

type RequestQuestionsProps = {
  className?: string;
  noToggle?: boolean;
  description: string;
  showAnswer?: boolean;
};

const RequestQuestions: React.FC<RequestQuestionsProps> = (props) => {
  const { className, description, noToggle, showAnswer } = props;

  const { candidateId } = useParams();
  const { t } = useTranslation();

  const { values } = useFormState();

  const { includeFollowUpQuestions } = values;

  const { data: candidate } = useGetCandidateQuery(candidateId);

  const schoolsQuestions =
    candidate?.schools?.[0]?.requestedAdditionalInformation?.followUpQuestions;

  const candidateQuestions =
    candidate?.requestedAdditionalInformation?.followUpQuestions;

  const questionDescription = useCallback(
    (question: string) => {
      if (!showAnswer) return t('VacancySettings.Add.questionTagDesc');

      const answer = candidateQuestions?.find(
        (cq) => cq.question === question,
      )?.answer;

      return answer ?? t('notYetAnswered');
    },
    [candidateQuestions, showAnswer, t],
  );

  const classes = classNames('request-questions', className);

  if (!schoolsQuestions?.length) {
    return (
      <ViewGuard roles={[Role.Reviewer]} permission="forbid">
        <QuestionVideoEmpty
          icon={<DashboardIcon />}
          description={t(
            'RequestCandidatesDocuments.questionsEmptyDescription',
          )}
          title={t('RequestCandidatesDocuments.questionEmptyTitle')}
          buttonText={t('RequestCandidatesDocuments.questionEmptyButton')}
          schoolId={candidate?.schools?.[0]?._id}
        />
      </ViewGuard>
    );
  }

  return (
    <div className={classes}>
      <div className="request-questions__toggle-container">
        <div className="request-questions__toggle-container__text">
          <p className="request-questions__toggle-container__text__title">
            {t('RequestCandidatesDocuments.questionEmptyTitle')}
          </p>
          <p className="request-questions__toggle-container__text__desc">
            {description}
          </p>
        </div>
        {!noToggle && (
          <Field
            name="includeFollowUpQuestions"
            component={CheckboxField}
            type="checkbox"
            isToggleButton
            isStyledDefault
          />
        )}
      </div>
      {schoolsQuestions.map((question) => (
        <QuestionVideoTag
          className={classNames({
            transparent: !includeFollowUpQuestions && !showAnswer,
            answered: showAnswer,
          })}
          key={question}
          value={question}
          description={questionDescription(question)}
        />
      ))}
    </div>
  );
};

export default RequestQuestions;
