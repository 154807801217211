import React, { useMemo } from 'react';
import { Button, Collapsible, Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { FavouriteOptions, MongoWhere, Params } from 'types';
import useSubjectOptions from '../../hooks/useSubjectOptions';
import useFetchCandidatesFilters from '../../hooks/useFetchCandidatesFilters';
import useSchoolFromYourGroup from '../../hooks/useSchoolFromYourGroup';
import useFavouriteOptions from '../../hooks/useFavouriteOptions';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import useApplicationTypeOptions from '../../hooks/useApplicationTypeOptions';
import useCategoryOptions from '../../../Vacancies/pages/VacancyWizard/hooks/useCategoryOptions';
import { FilterSelectPlaceholder } from './placeholders';
import { ReactComponent as SelectArrowIcon } from 'icons/SelectArrow.icon.svg';
import { ApplicationType } from 'searchality-data';
import classNames from 'classnames';

import './CandidatesFilters.styles.scss';
import './CandidatesFilters.styles.responsive.scss';

type CandidatesFiltersProps = {
  onFilter: (key: string, value: MongoWhere) => void;
  onReset: () => void;
  filteredBy?: any;
  filterParams: Partial<Params>;
};

const CandidatesFilters: React.FC<CandidatesFiltersProps> = (props) => {
  const { onFilter, filteredBy = {}, onReset, filterParams } = props;

  const { t } = useTranslation();

  const [filters, isLoading] = useFetchCandidatesFilters(filterParams);

  const schoolFromYourGroup = useSchoolFromYourGroup(filters?.schools);
  const subjectOptions = useSubjectOptions(filters?.subjects);
  const favoriteOptions = useFavouriteOptions();
  const applicationTypeOptions = useApplicationTypeOptions(filters?.types);
  const categoryOptions = useCategoryOptions(filters?.categories);

  const className = 'candidates-filters';

  const { schoolIds, subjectGradeLevel, isFavourite, type, category } =
    filteredBy;

  const schoolsValue = useMemo(() => {
    if (!schoolIds) return;
    return schoolIds['$all'][0];
  }, [schoolIds]);

  const selectedSubject = useMemo(() => {
    if (!subjectGradeLevel) return;
    return subjectGradeLevel['$in'][0];
  }, [subjectGradeLevel]);

  const isFavouriteValue = useMemo(() => {
    if (!isFavourite) return;
    return isFavourite['$in'][0] === false
      ? FavouriteOptions.ALL_CANDIDATES
      : FavouriteOptions.FAV_CANDIDATES;
  }, [isFavourite]);

  const typeValue = useMemo(() => {
    if (!type) return;
    return type['$in'][0];
  }, [type]);

  const categoryValue = useMemo(() => {
    if (!category) return;
    return category['$in'][0];
  }, [category]);

  const FilterComponent = useMemo(
    () => (
      <div className={`${className}__wrapper`}>
        <div className={`${className}`}>
          {isLoading ? (
            Array(5)
              .fill(void 0)
              .map((_, index) => (
                <FilterSelectPlaceholder width="148px" key={index} />
              ))
          ) : (
            <>
              <Select
                options={favoriteOptions}
                placeholder={t('favorites')}
                value={isFavouriteValue}
                multiple={false}
                onChange={({ value }) =>
                  onFilter('isFavourite', {
                    $in:
                      value === FavouriteOptions.FAV_CANDIDATES
                        ? [true]
                        : [false, true, undefined],
                  })
                }
                icon={<SelectArrowIcon />}
                renderAsPortal
                portalClassName="candidates-filters__filter-portal"
              />
              {schoolFromYourGroup?.length > 1 && (
                <Select
                  options={schoolFromYourGroup}
                  placeholder={t('schoolName')}
                  value={schoolsValue}
                  portalClassName="candidates-filters__filter-portal"
                  multiple={false}
                  onChange={({ value }) =>
                    onFilter('schoolIds', { $all: [value] })
                  }
                  renderAsPortal
                  icon={<SelectArrowIcon />}
                />
              )}
              {subjectOptions?.length > 1 && (
                <Select
                  options={subjectOptions}
                  value={selectedSubject}
                  multiple={false}
                  onChange={({ value }) => {
                    onFilter('subjectGradeLevel', { $in: [value] });
                    if (!typeValue) {
                      onFilter('type', { $in: [ApplicationType.ACADEMIC] });
                    }
                  }}
                  placeholder={t('subjectLabel')}
                  className={classNames({
                    disabled: typeValue === ApplicationType.NON_ACADEMIC,
                  })}
                  renderAsPortal
                  portalClassName="candidates-filters__filter-portal"
                  icon={<SelectArrowIcon />}
                />
              )}
              {categoryOptions?.length > 1 && (
                <Select
                  options={categoryOptions}
                  value={categoryValue}
                  multiple={false}
                  onChange={({ value }) => {
                    onFilter('category', { $in: [value] });
                    if (!typeValue) {
                      onFilter('type', { $in: [ApplicationType.NON_ACADEMIC] });
                    }
                  }}
                  className={classNames({
                    disabled: typeValue === ApplicationType.ACADEMIC,
                  })}
                  placeholder={t('category')}
                  renderAsPortal
                  portalClassName="candidates-filters__filter-portal"
                  icon={<SelectArrowIcon />}
                />
              )}
              {applicationTypeOptions?.length > 1 && (
                <Select
                  options={applicationTypeOptions}
                  value={typeValue}
                  multiple={false}
                  onChange={({ value }) => {
                    onFilter('type', { $in: [value] });

                    if (value === ApplicationType.ACADEMIC) {
                      if (categoryValue) {
                        onFilter('category', undefined);
                      }
                    } else {
                      if (selectedSubject) {
                        onFilter('subjectGradeLevel', undefined);
                      }
                    }
                  }}
                  placeholder={t('vacancyType')}
                  renderAsPortal
                  portalClassName="candidates-filters__filter-portal"
                  icon={<SelectArrowIcon />}
                />
              )}
            </>
          )}
        </div>
        <Button variant="link" onClick={onReset}>
          {t('resetFilters')}
        </Button>
      </div>
    ),
    [
      isLoading,
      favoriteOptions,
      t,
      isFavouriteValue,
      schoolFromYourGroup,
      schoolsValue,
      subjectOptions,
      selectedSubject,
      categoryOptions,
      categoryValue,
      applicationTypeOptions,
      typeValue,
      onReset,
      onFilter,
    ],
  );

  const content = FilterComponent;

  return (
    <>
      <div className={`${className}__outside`}>{content}</div>
      <Collapsible
        trigger={
          <div className="candidates-filters__trigger">
            <p>{t('filters')}</p>
            <ArrowIcon />
          </div>
        }
      >
        {content}
      </Collapsible>
    </>
  );
};

export default CandidatesFilters;
