import { STORAGE_KEYS } from 'services/storageService';

// 3MB max file size for image
export const PROFILE_IMAGE_MAX_FILE_SIZE = 3 * 1024 * 1024;

export const FAVOURITE = 'Favourite';

export const MATCHING = 'Matching';

export const RENEW_DAYS = 14;

export const DEFAULT_COUNTRY_CODE = '+1';

export const MAX_ACADEMIC_SALARY = 500000;

export const MAX_NON_ACADEMIC_SALARY = 200000;

export const TEMPORARY_NAME = 'temporary name';

export const USA_PROXIMITY = '-98.5795,39.8283';

export const STORAGE_KEYS_CONSTANT: Record<
  'dontShowUntilNextLogin',
  STORAGE_KEYS
> = {
  dontShowUntilNextLogin: 'DONT_SHOW_UNTIL_NEXT_LOGIN',
};

export const LANDING_VIDEO_SRC =
  'https://searchality.com/hubfs/Searchality%20Video.mp4';
