import React from 'react';
import classNames from 'classnames';

import './OrderItem.styles.scss';

type OrderItemProps = {
  className?: string;
  name: React.ReactNode;
  price: React.ReactNode;
  children?: React.ReactNode;
};

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { className, name, children, price } = props;

  const classes = classNames('order-item', className);

  return (
    <div className={classes}>
      <p className="order-item__name">
        <span className="order-item__name__price">{price}</span>
        {name}
      </p>
      {children}
    </div>
  );
};

export default OrderItem;
