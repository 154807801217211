import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SubjectGradeLevel,
  LeadershipRole,
  PositionType,
  SchoolGroupType,
  PositionDivision,
} from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';

type SubjectGradeOption = { label: string; value: string };
const useGradeOptions = (
  positionType: PositionType,
  positionDivision: PositionDivision,
) => {
  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { type } = schoolGroup || {};

  const getSubjectLevelOptions = useCallback(
    (source: Record<string, string>, prefix = 'subjectGrades') => {
      return Object.entries(source).map(({ 0: key, 1: value }) => ({
        label: t(`${prefix}.${value}`),
        value: value,
      }));
    },
    [t],
  );

  const paraprofessionalRoleSubjectGradeMapping = useMemo(() => {
    const obj: { [name in `${PositionDivision}`]: any } = {
      'Pre-K': getSubjectLevelOptions(SubjectGradeLevel.PARAPROFESSIONAL_PRE_K),

      'Elementary School': getSubjectLevelOptions(
        SubjectGradeLevel.PARAPROFESSIONAL_ELEMENTARY,
      ),
      'Middle School/Junior High': getSubjectLevelOptions(
        SubjectGradeLevel.PARAPROFESSIONAL_MIDDLE_SCHOOL,
      ),
      'High School': getSubjectLevelOptions(
        SubjectGradeLevel.PARAPROFESSIONAL_HIGH_SCHOOL,
      ),
      'Schoolwide Administration': [],
    };
    return obj;
  }, [getSubjectLevelOptions]);

  const teacherRoleSubjectGradeMapping = useMemo(() => {
    const obj: { [name in `${PositionDivision}`]: any } = {
      'Pre-K': getSubjectLevelOptions(SubjectGradeLevel.TEACHER_PRE_K),
      'Elementary School': getSubjectLevelOptions(
        SubjectGradeLevel.TEACHER_ELEMENTARY,
      ),
      'Middle School/Junior High': getSubjectLevelOptions(
        SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL,
      ),
      'High School': getSubjectLevelOptions(
        SubjectGradeLevel.TEACHER_HIGH_SCHOOL,
      ),
      'Schoolwide Administration': [],
    };

    return obj;
  }, [getSubjectLevelOptions]);

  const specialisRoleSubjectGrades = useMemo(() => {
    return getSubjectLevelOptions(SubjectGradeLevel.SPECIALIST);
  }, [getSubjectLevelOptions]);

  const options: SubjectGradeOption[] = useMemo(() => {
    if (!positionType || !positionDivision) {
      return [];
    }

    if (positionType === PositionType.LEADERSHIP_ADMINISTRATOR) {
      if (positionDivision === PositionDivision.SCHOOLWIDE_ADMINISTRATION) {
        return getSubjectLevelOptions(LeadershipRole.SCHOOLWIDE);
      }
      return getSubjectLevelOptions(
        LeadershipRole.PRE_K_ELEMENTARY_MIDDLE_SCHOOL_HIGH_SCHOOL,
      );
    }
    if (positionType === PositionType.TEACHER) {
      const teacherGradesOptions =
        teacherRoleSubjectGradeMapping[positionDivision];
      if (type !== SchoolGroupType.RELIGIOUS) {
        return teacherGradesOptions
          ? teacherGradesOptions?.filter(
              (element: SubjectGradeOption) =>
                element?.value !==
                  SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.THEOLOGY &&
                element?.value !==
                  SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.RELIGIOUS_STUDIES &&
                element?.value !==
                  SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.ETHICS,
            )
          : [];
      }
      return teacherGradesOptions;
    }
    if (positionType === PositionType.SPECIALIST) {
      return specialisRoleSubjectGrades;
    }
    if (positionType === PositionType.PARAPROFFESIONAL) {
      return paraprofessionalRoleSubjectGradeMapping[positionDivision];
    }
    return [];
  }, [
    positionType,
    positionDivision,
    getSubjectLevelOptions,
    teacherRoleSubjectGradeMapping,
    type,
    specialisRoleSubjectGrades,
    paraprofessionalRoleSubjectGradeMapping,
  ]);

  return options;
};

export default useGradeOptions;
