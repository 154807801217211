import React from 'react';
import classNames from 'classnames';
import useMyAccountLinks from '../../hooks/useMyAccountLinks';
import { Link } from 'react-router-dom';
import { ReactComponent as ToggleIcon } from 'icons/Toggle.icon.svg';

import './MyAccountLinksContainer.styles.scss';
import './MyAccountLinksContainer.styles.responsive.scss';

type MyAccountLinksContainerProps = {
  className?: string;
};

const MyAccountLinksContainer: React.FC<MyAccountLinksContainerProps> = (
  props,
) => {
  const { className } = props;

  const classes = classNames('my-account-links-container', className);

  const links = useMyAccountLinks();

  return (
    <div className={classes}>
      {links.map(({ target, label }) => (
        <Link key={target} to={target}>
          {label}
          <ToggleIcon />
        </Link>
      ))}
    </div>
  );
};

export default MyAccountLinksContainer;
