import React, { useMemo } from 'react';
import classNames from 'classnames';
import CandidateProfileDocumentsEmpty from '../CandidateProfileDocumentsEmpty';
import {
  ApplicationRequestedAdditionalInformation,
  RequestedDocument,
} from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ncoded-component-library';
import CandidateUploadedDocuments from '../CandidateUploadedDocuments';
import CandidateReqDocuments from '../CandidateReqDocuments';
import { ReactComponent as DashboardIcon } from 'icons/Dashboard.icon.svg';
import { ReactComponent as VideoIcon } from 'icons/Video.icon.svg';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';

import './CandidateProfileDocuments.styles.scss';

type CandidateProfileDocumentsProps = {
  className?: string;
  requestedDocuments: RequestedDocument[];
  requestedInformation: ApplicationRequestedAdditionalInformation;
};

const CandidateProfileDocuments: React.FC<CandidateProfileDocumentsProps> = (
  props,
) => {
  const { className, requestedDocuments, requestedInformation } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const baseClass = 'candidate-profile-documents';
  const classes = classNames(baseClass, className);

  const uploadedDocuments = useMemo(
    () => requestedDocuments?.filter(({ files }) => !!files?.length),

    [requestedDocuments],
  );

  const reqDocuments = useMemo(
    () => requestedDocuments?.filter(({ files }) => !files?.length),
    [requestedDocuments],
  );

  const answeredQuestion = requestedInformation?.followUpQuestions?.filter(
    (q) => !!q?.answer,
  )?.length;

  const numberOfQuestions = requestedInformation?.followUpQuestions?.length;

  if (
    !requestedDocuments?.length &&
    !requestedInformation?.followUpQuestions?.length &&
    !requestedInformation?.video
  ) {
    return <CandidateProfileDocumentsEmpty />;
  }

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>
        {t('CandidateProfileDocuments.title')}
      </p>
      <p className={`${baseClass}__description`}>
        {t('CandidateProfileDocuments.description')}
      </p>
      {!!uploadedDocuments?.length && (
        <CandidateUploadedDocuments
          className={`${baseClass}__uploaded`}
          documentRequests={uploadedDocuments}
        />
      )}
      {!!reqDocuments?.length && (
        <CandidateReqDocuments pendingDocumentRequests={reqDocuments} />
      )}
      {(!!requestedInformation?.followUpQuestions?.length ||
        requestedInformation?.video) && (
        <div className={`${baseClass}__vq-container`}>
          <p>{t('CandidateProfileDocuments.followup')}</p>
          {!!requestedInformation?.followUpQuestions?.length && (
            <div className={`${baseClass}__vq-container__wrapper`}>
              <DashboardIcon />
              <div className={`${baseClass}__vq-container__wrapper__info`}>
                <p>{t('CandidateProfileDocuments.question')}</p>
                <p>
                  {t('CandidateProfileDocuments.questionDesc', {
                    answered: answeredQuestion,
                    numberOf: numberOfQuestions,
                  })}
                </p>
              </div>
              {answeredQuestion === numberOfQuestions && <DoneIcon />}
            </div>
          )}
          {requestedInformation?.video && (
            <div className={`${baseClass}__vq-container__wrapper`}>
              <VideoIcon />
              <div className={`${baseClass}__vq-container__wrapper__info`}>
                <p>{requestedInformation?.video?.videoDescription}</p>
                <p>
                  {requestedInformation?.video?.videoUrl
                    ? t('uploaded')
                    : t('notUploadedYet')}
                </p>
              </div>
              {requestedInformation?.video?.videoUrl && <DoneIcon />}
            </div>
          )}
        </div>
      )}
      <Button
        className={`${baseClass}__button`}
        onClick={() => navigate('request-documents')}
        variant="outline"
      >
        {t('CandidateProfileDocuments.button')}
      </Button>
    </div>
  );
};

export default CandidateProfileDocuments;
