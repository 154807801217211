import React, { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import DropzoneField from 'components/DropzoneField';

import './AdditionalDocumentModal.styles.scss';
import { required } from 'validations';
import { useTranslation } from 'react-i18next';

type AdditionalDocumentModalProps = {
  className?: string;
  handleSubmit: (values: { file: File[] }) => void;
  title: string;
  description: string;
  buttonText: string;
};

const AdditionalDocumentModal: React.ForwardRefRenderFunction<
  ModalRef,
  AdditionalDocumentModalProps
> = (props, ref) => {
  const { className, handleSubmit, title, description, buttonText } = props;

  const { t } = useTranslation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('additional-document-modal', className);

  return (
    <Modal ref={modalRef} className={classes}>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, invalid }) => (
          <form
            onSubmit={handleSubmit}
            className="additional-document-modal__form"
          >
            <p className="additional-document-modal__form__title">{title}</p>
            <p className="additional-document-modal__form__description">
              {description}
            </p>
            <Field
              name="file"
              component={DropzoneField}
              accept={{
                'image/jpeg': [],
                'image/png': [],
                'application/pdf': [],
              }}
              uploadFileText={t('uploadAPdfPngJpgFile')}
              validate={required()}
            />
            <Button type="submit" disabled={invalid || submitting}>
              {buttonText}
            </Button>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(AdditionalDocumentModal);
