import React, { useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { composeValidators, required, requiredRange } from 'validations';
import YesNoExplainField from 'components/YesNoExplainField';
import InputField from 'components/InputField';
import {
  BoardingPositionRequirementStatus,
  SchoolGroupType,
  VacancyStatus,
  VacancyType,
} from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import RangeSliderField from 'components/RangeSliderField';
import SelectField from 'components/SelectField';
import useApplicantStageOptions from '../../hooks/useApplicantStageOptions';
import FormFieldLabel from 'components/FormFieldLabel';
import {
  MAX_ACADEMIC_SALARY,
  MAX_NON_ACADEMIC_SALARY,
} from 'constants/general';
import AcademicContractDaysFields from './components/AcademicContractDaysFields';
import NonAcademicContractDaysFields from './components/NonAcademicContractDaysFields';
import classNames from 'classnames';
import TextAreaField from 'components/TextAreaField';

import './TeacherOffersForm.styles.scss';

const TeacherOffersForm: React.FC = () => {
  const { t } = useTranslation();

  const applicantStageOptions = useApplicantStageOptions();

  const {
    values: { salaryInfo, status, boardingPositionRequirementStatus },
    initialValues,
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const { change } = useForm();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const baseClass = 'teacher-offers-fields';

  const isAcademic = useMemo(() => type === VacancyType.ACADEMIC, [type]);

  const MAX_SALARY = useMemo(
    () => (isAcademic ? MAX_ACADEMIC_SALARY : MAX_NON_ACADEMIC_SALARY),
    [isAcademic],
  );

  useEffect(() => {
    if (!Object.keys(initialValues).length) {
      change('salaryInfo.to', MAX_SALARY.toString());
      change('salaryInfo.from', '0');
    }
  }, [MAX_SALARY, change, initialValues]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__salary`}>
        <FormFieldLabel text={t('salaryRange')} />
        <Field
          name="salaryInfo"
          className={classNames({ 'disable-slider': isOngoingVacancyEdit })}
          component={RangeSliderField}
          min={0}
          max={MAX_SALARY}
          minIcon={'$'}
          maxIcon={'$'}
        />
        <div className={`${baseClass}__salary__inputs`}>
          <Field
            name="salaryInfo.from"
            component={InputField}
            label={t('from')}
            validate={composeValidators(
              required(),
              requiredRange(MAX_SALARY, 0),
            )}
            disabled={isOngoingVacancyEdit}
          />
          <Field
            name="salaryInfo.to"
            component={InputField}
            label={t('to')}
            validate={composeValidators(
              required(),
              requiredRange(MAX_SALARY, 0),
            )}
            disabled={isOngoingVacancyEdit}
          />
        </div>
        {boardingPositionRequirementStatus &&
          boardingPositionRequirementStatus !==
            BoardingPositionRequirementStatus.NOT_REQUIRED && (
            <div className={`${baseClass}__salary__boarding`}>
              <FormFieldLabel
                text={t('vacancyWizard.step3.additionalSalary')}
                hint={t('vacancyWizard.step3.additionalSalaryHint')}
              />
              <p className="boarding-description">
                {t('vacancyWizard.step3.additionalSalaryDescription')}
              </p>
              <Field
                name="boardingPositionBenefits.salary"
                component={InputField}
                label={t('vacancyWizard.step3.additionalSalaryHintLabel')}
                validate={required()}
                onlyNumbers
                required
                disabled={isOngoingVacancyEdit}
              />
            </div>
          )}
        <Field
          name="salaryInfo.applicantStage"
          component={SelectField}
          label={t('selectApplicantStage')}
          options={applicantStageOptions}
          validate={required()}
          required
          disabled={isOngoingVacancyEdit}
        />
        {salaryInfo?.applicantStage === 'Other' && (
          <Field
            name="applicantStageOther"
            component={InputField}
            label={t('pleaseSpecify')}
            validate={required()}
            required
            disabled={isOngoingVacancyEdit}
          />
        )}
      </div>
      {isAcademic ? (
        <AcademicContractDaysFields
          isDisabled={isOngoingVacancyEdit}
          baseClass={baseClass}
        />
      ) : (
        <NonAcademicContractDaysFields
          baseClass={baseClass}
          isDisabled={isOngoingVacancyEdit}
        />
      )}
      <YesNoExplainField
        name="isPrivateHealthInsuranceOffered"
        inputName="privateHealthInsuranceOffered"
        label={t('vacancyWizard.step4.healthInsurance')}
        inputLabel={t('vacancyWizard.step4.privateHealthInsuranceOffered')}
        inputDescription={t(
          'vacancyWizard.step4.privateHealthInsuranceOfferedDescription',
        )}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isPensionContributionOffered"
        inputName="pensionContributionOffered"
        label={t('vacancyWizard.step4.pensionContribution')}
        inputLabel={t('vacancyWizard.step4.pensionContributionOffered')}
        inputDescription={t(
          'vacancyWizard.step4.pensionContributionOfferedDescription',
        )}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isFinancialSupportForRelocationOffered"
        inputName="financialSupportForRelocationOffered"
        label={t('vacancyWizard.step4.teachersRelocation')}
        inputLabel={t(
          'vacancyWizard.step4.financialSupportForRelocationOffered',
        )}
        inputDescription={t(
          'vacancyWizard.step4.financialSupportForRelocationOfferedDescription',
        )}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isProfessionalDevelopmentOffered"
        inputName="professionalDevelopmentOffered"
        label={t('vacancyWizard.step4.proffesionalDevelopment')}
        inputLabel={t('vacancyWizard.step4.specifyDevelopment')}
        inputDescription={t(
          'vacancyWizard.step4.specifyDevelopmentDescription',
        )}
        required
        disabled={isOngoingVacancyEdit}
      />
      {schoolGroup.type === SchoolGroupType.INDEPENDENT && isAcademic && (
        <YesNoExplainField
          name="isTuitionForDependentsOffered"
          inputName="dependentsDevelopmentOffered"
          label={t('vacancyWizard.step4.tuition')}
          inputLabel={t('vacancyWizard.step4.specifyTuition')}
          inputDescription={t('vacancyWizard.step4.specifyTuitionDescription')}
          required
          disabled={isOngoingVacancyEdit}
        />
      )}
      <YesNoExplainField
        name="isAdditionalBenefitOffered"
        inputName="additionalBenefitOffered"
        label={t('vacancyWizard.step4.benefits')}
        inputLabel={t('vacancyWizard.step4.specifyBenefitsDescriptionLabel')}
        inputDescription={t('vacancyWizard.step4.specifyBenefitsDescription')}
        required
        disabled={isOngoingVacancyEdit}
      />
      {boardingPositionRequirementStatus &&
        boardingPositionRequirementStatus !==
          BoardingPositionRequirementStatus.NOT_REQUIRED && (
          <div className={`${baseClass}__boarding`}>
            <FormFieldLabel
              text={t('vacancyWizard.step3.additionalBenefits')}
            />
            <Field
              name="boardingPositionBenefits.additionalBenefits"
              component={TextAreaField}
              placeholder={t('vacancyWizard.step3.additionalBenefitsLabel')}
              required
              disabled={isOngoingVacancyEdit}
            />
          </div>
        )}
    </div>
  );
};

export default TeacherOffersForm;
