import { createAction, createSlice } from '@reduxjs/toolkit';

type Payment = {
  coupon: string;
  clientSecret: string;
};

const initialState: Payment = {
  coupon: undefined,
  clientSecret: '',
};

export const updateCouponValue = createAction<string>(
  'payment/updateCouponValue',
);

export const updateClientSecretValue = createAction<string>(
  'payment/updateClientSecretValue',
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCouponValue, (state, action) => {
      state.coupon = action.payload;
    });
    builder.addCase(updateClientSecretValue, (state, action) => {
      state.clientSecret = action.payload;
    });
  },
});

export default paymentSlice.reducer;
