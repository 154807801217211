import React from 'react';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './JobApplicationNotLoggedIn.styles.scss';
import './JobApplicationNotLoggedIn.styles.responsive.scss';

const JobApplicationLoggedIn: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const baseClass = 'job-application-not-logged-in';

  return (
    <div className={baseClass}>
      <div>
        <h3>{t('autofillApplication.title')}</h3>
        <p>{t('autofillApplication.description')}</p>
      </div>
      <Button onClick={() => navigate('login')}>
        {t('autofillApplication.buttonText')}
      </Button>
    </div>
  );
};

export default JobApplicationLoggedIn;
