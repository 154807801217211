import React, { useMemo } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import useNavOptions from './hooks/useNavOptions';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { PaymentStatus, Role } from 'searchality-data';
import ViewGuard from 'router/components/ViewGuard';
import { ReactComponent as LogoutIcon } from 'icons/Logout.icon.svg';
import { logout } from 'store/slices/auth.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './HeaderNavigation.styles.scss';

type HeaderNavigationProps = {
  className?: string;
  showJustLogout?: boolean;
};

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const { className, showJustLogout } = props;

  const classes = classNames('header-navigation', className);
  const dispatch = useAppDispatch();

  const NAV_OPTIONS = useNavOptions();

  const { t } = useTranslation();

  const user = useAppSelector(authSelectors.selectUser);

  const finalNavOptions = useMemo(() => {
    if (
      NAV_OPTIONS.some(({ isWidgetLink }) => isWidgetLink) &&
      !user?.schoolGroup?.isWidgetEnabled
    ) {
      return NAV_OPTIONS?.filter(({ isWidgetLink }) => !isWidgetLink);
    }

    return NAV_OPTIONS;
  }, [NAV_OPTIONS, user]);

  const navigationItems = useMemo(() => {
    if (showJustLogout) {
      return (
        <NavLink
          className="logout-link"
          to={''}
          onClick={() => dispatch(logout())}
        >
          <>
            {t('logout')}
            <LogoutIcon fill="none" width={30} height={30} stroke="#768591" />
          </>
        </NavLink>
      );
    }

    return finalNavOptions?.map(
      ({ to, label, Icon, isWidgetLink, roles, permission, disabled }) => (
        <ViewGuard key={to} roles={roles} permission={permission}>
          <NavLink
            to={isWidgetLink ? undefined : to}
            className={({ isActive }) =>
              classNames('header-navigation__nav-link', {
                active: !isWidgetLink && isActive,
              })
            }
            onClick={(ev) => {
              if (disabled) {
                ev.preventDefault();
              }
              isWidgetLink &&
                window.open(`${to}${user?.schoolGroup?.clientId}`, '_blank');
            }}
          >
            <>
              {Icon}
              <span
                className={classNames({
                  'red-dot':
                    to === '/my-account' &&
                    user?.role === Role.AccountOwner &&
                    user?.schoolGroup?.paymentStatus === PaymentStatus.NOT_PAID,
                })}
              >
                {t(label)}
              </span>
            </>
          </NavLink>
        </ViewGuard>
      ),
    );
  }, [
    dispatch,
    finalNavOptions,
    showJustLogout,
    t,
    user?.role,
    user?.schoolGroup?.clientId,
    user?.schoolGroup?.paymentStatus,
  ]);

  return <nav className={classes}>{navigationItems}</nav>;
};

export default HeaderNavigation;
