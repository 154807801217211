import React, { useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  ContentBlock,
  convertToRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditorField.styles.scss';
import './TextEditorField.styles.responsive.scss';

type TextEditorFieldProps = {
  className?: string;
  placeholder?: string;
} & FieldRenderProps<string>;

const TextEditorField: React.FC<TextEditorFieldProps> = (props) => {
  const { input, meta, placeholder } = props;

  const [editorState, setEditorState] = useState(() =>
    input.value
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(input.value) as unknown as ContentBlock[],
          ),
        )
      : EditorState.createEmpty(),
  );

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    // Convert EditorState to HTML and update the form field value

    input.onChange(
      draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
    );
  };

  const toolbarOptions = useMemo(
    () => ({
      options: [
        'inline',
        'blockType',
        'fontSize',
        'fontFamily',
        'list',
        'textAlign',
        'colorPicker',
        'history',
      ],
    }),
    [],
  );

  return (
    <div className="text-editor-field">
      <Editor
        toolbar={toolbarOptions}
        editorState={editorState as EditorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="text-editor-field__wrapper-class"
        editorClassName="text-editor-field__editor-class"
        toolbarClassName="text-editor-field__toolbar-class"
        placeholder={placeholder}
        onBlur={input.onBlur}
      />
      {meta.touched && meta.error && (
        <div className="text-editor-field__error">{meta.error}</div>
      )}
    </div>
  );
};

export default TextEditorField;
