import React from 'react';
import classNames from 'classnames';
import TemplateIllustration from 'components/TemplateIllustration';
import { TemplateIllustrationProps } from 'types';

import './EmptyScreen.styles.scss';

const EmptyScreen: React.FC<TemplateIllustrationProps> = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames('empty-screen', className);

  return (
    <TemplateIllustration className={classes} {...rest}>
      {children}
    </TemplateIllustration>
  );
};

export default EmptyScreen;
