import { useMemo } from 'react';

export default (subjects: Record<string, string>) =>
  useMemo(
    () =>
      Object.values(subjects || {}).map((value) => ({
        value,
        label: value,
      })),
    [subjects],
  );
