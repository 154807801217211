import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PositionType } from 'searchality-data';

const usePositionTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.entries(PositionType).map(({ 0: key, 1: value }) => {
        return {
          label: t(`positionTypes.${key}`),
          value: PositionType[key as keyof typeof PositionType],
        };
      }),
    [t],
  );
};

export default usePositionTypeOptions;
