import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolCommunicationSettings from './pages/SchoolCommunicationSettings';
import LoginInformation, {
  LoginInformationRoutes,
} from './subrouters/LoginInformation';
import SchoolPersonalDetails from './pages/SchoolPersonalDetails';
import SchoolPersonalInformation from './pages/SchoolPersonalInformation';

export default [
  {
    path: 'personal-details',
    element: SchoolPersonalDetails,
  },
  {
    path: 'login-information',
    routes: LoginInformationRoutes,
    element: LoginInformation,
  },
  {
    path: 'communication-settings',
    element: SchoolCommunicationSettings,
  },
  {
    index: true,
    element: SchoolPersonalInformation,
  },
] as Array<ComplexRoute>;
