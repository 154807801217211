import TagManager from 'react-gtm-module';
import env from 'env';

const tagManagerArgs = {
  gtmId: env.GTM_ID,
};

env.GTM_ID && TagManager.initialize(tagManagerArgs);

export default TagManager;
