import React, { useCallback, useMemo } from 'react';
import JobPreviewComponent from 'components/JobPreviewComponent';
import PageLoader from 'components/PageLoader';
import { useTranslation } from 'react-i18next';
import VacancyPreviewCard from '../../../../components/VacancyPreviewCard';
import { Role, VacancyStatus } from 'searchality-data';
import {
  useGetVacancyQuery,
  usePublishVacancyMutation,
} from 'api/vacancies.api';
import { useNavigate, useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import vacancyConstants from 'constants/vacancy';
import useMatchMedia from 'hooks/useMatchMedia';

type VacancyPreviewProps = {
  className?: string;
};

const VacancyPreview: React.FC<VacancyPreviewProps> = () => {
  const { vacancyId } = useParams();
  const dispatch = useAppDispatch();
  const [publishVacancy] = usePublishVacancyMutation();
  const { data: vacancy, isLoading } = useGetVacancyQuery(vacancyId);
  const user = useAppSelector(authSelectors.selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isPhablet = useMatchMedia('isPhablet');

  const showBanner = useMemo(() => {
    const { status } = vacancy || {};
    return (
      [VacancyStatus.DRAFT, VacancyStatus.PUBLISHED].includes(status) &&
      !isPhablet
    );
  }, [isPhablet, vacancy]);

  const noEdit = useMemo(() => {
    const { _id, role } = user;
    const { creatorId } = vacancy || {};

    return role === Role.HiringManager && _id !== creatorId;
  }, [user, vacancy]);

  const noPublish = useMemo(() => {
    const { status } = vacancy || {};
    return status !== VacancyStatus.DRAFT;
  }, [vacancy]);

  const title = useMemo(() => {
    const { status } = vacancy || {};
    if (status === VacancyStatus.DRAFT)
      return t('VacancyPreviewCard.beforePublishTitle');
    return t('VacancyPreviewCard.publishedTitle');
  }, [t, vacancy]);

  const subtitle = useMemo(() => {
    const { status } = vacancy || {};

    if (status === VacancyStatus.DRAFT)
      return t('VacancyPreviewCard.beforePublishSubtitle1');
    return t('VacancyPreviewCard.publishedSubtitle');
  }, [t, vacancy]);

  const handlePublish = useCallback(async () => {
    publishVacancy(vacancyId)
      .unwrap()
      .then(() => {
        dispatch(popError(t('VacancyPreviewCard.publishError')));
      })
      .catch((e) => {
        dispatch(popServerError(e));
      });
  }, [dispatch, publishVacancy, t, vacancyId]);

  const handleOnEdit = useCallback(
    () =>
      navigate(
        `/vacancies/${vacancyId}/edit/${
          vacancyConstants.vacancyTypeToRouteMapping[vacancy?.type]
        }`,
      ),
    [navigate, vacancy?.type, vacancyId],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      {showBanner && (
        <VacancyPreviewCard
          title={title}
          subtitle={subtitle}
          noPublish={noPublish}
          noEdit={noEdit}
          onPublish={handlePublish}
          onEdit={handleOnEdit}
        />
      )}
      <JobPreviewComponent
        vacancy={vacancy}
        previousPageButtonText={t('goBackToVacancy')}
        previousPage={() => navigate('..')}
        noActions
        noPreviousButton={user.role === Role.Teacher}
      />
    </>
  );
};

export default VacancyPreview;
