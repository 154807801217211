import React from 'react';
import { useTranslation } from 'react-i18next';

export type AcademicContractTermsProps = {
  studentContractDays: number;
  studentNonContractDays: number;
};

const AcademicContractTerms: React.FC<AcademicContractTermsProps> = (props) => {
  const { studentNonContractDays, studentContractDays } = props;
  const { t } = useTranslation();

  const renderContractTerms = studentNonContractDays || studentContractDays;

  if (!renderContractTerms) {
    return null;
  }

  return (
    <>
      <b>{t('jobPreview.ContractTerms')}</b>
      <ul>
        {studentContractDays && (
          <li>
            {t('jobPreview.contractDays', {
              days: studentContractDays,
            })}
          </li>
        )}
        {studentNonContractDays && (
          <li>
            {t('jobPreview.nonContractDays', {
              days: studentNonContractDays,
            })}
          </li>
        )}
      </ul>
    </>
  );
};

export default AcademicContractTerms;
