import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import DropzoneContent from './components/DropzoneContent';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DocumentIcon } from 'icons/Document.icon.svg';
import UploadedRequestedDocument from 'router/subrouters/Dashboard/subrouters/Candidates/components/UploadedRequestedDocument';

import './Dropzone.styles.scss';

type DropzoneProps = {
  className?: string;
  value?: File[];
  Icon?: ReactNode;
  uploadFileText?: string;
  onChange?: (files: File[]) => void;
  isPdfPreview?: boolean;
  showLinks?: boolean;
} & DropzoneOptions;

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const { t } = useTranslation();

  const {
    className,
    disabled,
    maxFiles,
    multiple,
    isPdfPreview,
    value = null,
    accept = { 'application/pdf': [] },
    uploadFileText = t('uploadAPdfFile'),
    showLinks = false,
    Icon = <DocumentIcon />,
    onChange,
    ...rest
  } = props;

  const [files, setFiles] = useState<File[]>(value);

  const onDrop = useCallback(
    (uploadedFiles: File[]) => {
      if (!multiple) {
        setFiles(uploadedFiles);
        onChange(uploadedFiles);
      } else {
        onChange([...files, ...uploadedFiles]);
        setFiles((prev) => {
          return [...prev, ...uploadedFiles];
        });
      }
    },
    [files, multiple, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled,
    accept,
    multiple: multiple || maxFiles > 1,
    ...rest,
  });

  const { onClick, ...restGetRootProps } = getRootProps();

  const handleRemoveFile = useCallback(
    (fileToRemove: File) =>
      setFiles((prev) => {
        const newState = prev.filter((file) => file !== fileToRemove);
        onChange?.(newState);
        return newState;
      }),
    [onChange],
  );

  const classes = classNames('dropzone', className);

  return (
    <div className={classes}>
      {!(!multiple && files?.length && showLinks) && (
        <div {...restGetRootProps} className="dropzone__upload">
          <input {...getInputProps()} />
          {!showLinks ? (
            <DropzoneContent
              onClick={onClick}
              disabled={disabled}
              handleRemoveFile={handleRemoveFile}
              files={files}
              uploadFileText={uploadFileText}
            />
          ) : (
            <>
              <Button
                onClick={onClick}
                disabled={disabled}
                icon={Icon}
                variant="outline"
              />
              <p>
                {uploadFileText}
                <span>{t('orDragAndDrop')}</span>
              </p>
            </>
          )}
        </div>
      )}
      {showLinks && !!files?.length && (
        <div className="dropzone__links">
          {files?.map((file, index) => (
            <UploadedRequestedDocument
              key={index.toString()}
              handleDeleteFile={() => handleRemoveFile(file)}
              deleteKey={''}
              fileType={file.type}
              fileName={file.name}
              fileSize={file.size}
              _id={''}
              url={URL.createObjectURL(file)}
              createdAt={(file as any).lastModifiedDate}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropzone;
