import React, { useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetVacancyMembersQuery } from 'api/members.api';
import PhotoPreview from 'components/PhotoPreview';
import { ReactComponent as ShareIcon } from 'icons/Share.icon.svg';
import { getNameInitials } from 'utils';
import { Button } from 'ncoded-component-library';
import ShareVacancyModal from 'router/subrouters/Dashboard/subrouters/Vacancies/components/ShareVacancyModal';
import { ShareVacancyModalRef } from 'router/subrouters/Dashboard/subrouters/Vacancies/components/ShareVacancyModal/ShareVacancyModal.component';

import './ProfileInvite.styles.scss';

type ProfileInviteProps = {
  className?: string;
  vacancyId: string;
  candidateId: string;
};

const ProfileInvite: React.FC<ProfileInviteProps> = (props) => {
  const { className, vacancyId, candidateId } = props;
  const { data, refetch } = useGetVacancyMembersQuery({ vacancyId });
  const { items: members } = data || {};
  const { t } = useTranslation();

  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();

  const classes = classNames('searchality-profile-invite', className);

  return (
    <div className={classes}>
      <p className="title">{t('Profile.Invite.title')}</p>
      <div className="members">
        {members
          ?.slice(0, 6)
          ?.map(({ imageFile, firstName, lastName, _id, systemColor }) => (
            <PhotoPreview
              src={imageFile?.url}
              backgroundColor={systemColor}
              key={_id}
              size="m"
              placeHolderText={getNameInitials(firstName, lastName)}
            />
          ))}
      </div>
      <Button
        iconPosition="right"
        icon={<ShareIcon />}
        variant="outline"
        onClick={() =>
          shareVacancyModalRef.current.openShareVacancy(vacancyId, candidateId)
        }
      >
        {t('Profile.Invite.share')}
      </Button>
      <ShareVacancyModal
        ref={shareVacancyModalRef}
        title={t('ShareVacancyModal.candidateTitle')}
        description={t('ShareVacancyModal.candidateDescription')}
        onShareVacancy={refetch}
      />
    </div>
  );
};

export default ProfileInvite;
