import useAppDispatch from 'hooks/useAppDispatch';
import React, { useState, useCallback } from 'react';
import { Form } from 'react-final-form';
import { updateCouponValue } from 'store/slices/payment.slice';

type DiscountCodeWrapperProps = {
  children: React.ReactNode;
};

const DiscountCodeWrapper: React.FC<DiscountCodeWrapperProps> = (props) => {
  const { children } = props;

  const [initialValues, setInitialValues] = useState<{
    discountCode: string;
  }>(undefined);

  const dispatch = useAppDispatch();

  const onDiscountCodeApply = useCallback(
    (value: { discountCode: string }) => {
      const { discountCode } = value;
      dispatch(updateCouponValue(discountCode));
      setInitialValues({ discountCode });
    },
    [dispatch],
  );

  return (
    <Form
      onSubmit={onDiscountCodeApply}
      initialValues={initialValues}
      render={() => children}
    />
  );
};

export default DiscountCodeWrapper;
