import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ViewGuard from 'router/components/ViewGuard';
import { Role } from 'searchality-data';

import './TabsNavigation.styles.scss';
import './TabsNavigation.styles.responsive.scss';

export type TabItem = {
  to: string;
  label: string;
  roles?: Role[];
  permission?: 'allow' | 'forbid';
};

type TabsNavigationProps = {
  tabs: TabItem[];
};

const TabsNavigation: React.FC<TabsNavigationProps> = ({ tabs = [] }) => {
  const { t } = useTranslation();

  const getNavlinkClassname = ({ isActive }: { isActive: boolean }) =>
    classNames('searchality-tabs-navigation__link', {
      'searchality-tabs-navigation__link--active': isActive,
    });

  return (
    <nav className="searchality-tabs-navigation">
      {tabs.map(({ to, label, permission, roles }) =>
        roles ? (
          <ViewGuard key={to} roles={roles} permission={permission}>
            <NavLink className={getNavlinkClassname} to={to}>
              {t(label)}
            </NavLink>
          </ViewGuard>
        ) : (
          <NavLink key={to} className={getNavlinkClassname} to={to}>
            {t(label)}
          </NavLink>
        ),
      )}
    </nav>
  );
};

export default TabsNavigation;
