import React from 'react';
import { useGetMemberQuery } from 'api/members.api';
import PhotoPreview from 'components/PhotoPreview';
import { useParams } from 'react-router-dom';
import MembersActions from './components/MemberActions';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role } from 'searchality-data';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { getNameInitials } from 'utils';

import './MemberInformation.styles.scss';
import './MemberInformation.styles.responsive.scss';

const MemberInformation: React.FC = () => {
  const { memberId } = useParams();
  const { t } = useTranslation();
  const { data: member } = useGetMemberQuery(memberId);
  const { _id: currentUserId, role: currentUserRole } = useAppSelector(
    authSelectors.selectUser,
  );

  const baseClass = 'member-information';

  if (!member) return <></>;

  const isMe = currentUserId === member._id;

  const {
    imageFile,
    firstName,
    lastName,
    systemColor,
    updatedAt,
    invitedAt,
    createdAt,
  } = member;

  const showPastTime = () => {
    const pastTime = DateService.calculatePastTime(updatedAt);
    return t(pastTime.type, { count: pastTime.num });
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__left`}>
        <PhotoPreview
          src={imageFile?.url}
          placeHolderText={getNameInitials(firstName, lastName)}
          backgroundColor={systemColor}
        />
        <div className={`${baseClass}__data`}>
          <h3>
            {firstName} {lastName}
          </h3>
          {isMe ? (
            <>
              <p className={`${baseClass}__data__last-activity`}>
                {`${t('lastActivity')} ${showPastTime()}`}
              </p>
            </>
          ) : null}
          {isMe && currentUserRole === Role.AccountOwner ? (
            <span className={`${baseClass}__data__bullet-span`}>
              <strong>{`${Role.AccountOwner}`}</strong>
            </span>
          ) : (
            <span className={`${baseClass}__data__bullet-span`}>
              <strong>{t('invitedBy')}</strong>
              {Role.AccountOwner}
            </span>
          )}
          <span>
            <strong>{`${t('joined')}`}</strong>
            {DateService.getDayMonthYear(invitedAt || createdAt)}
          </span>
        </div>
      </div>

      <div className={`${baseClass}__right`}>
        <MembersActions />
      </div>
    </div>
  );
};

export default MemberInformation;
