import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import {
  composeValidators,
  emailValidation,
  passwordValidation,
  required,
} from 'validations';
import PasswordField from 'components/PasswordField';
import { Button } from 'ncoded-component-library';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import useAppDispatch from 'hooks/useAppDispatch';
import { LoginData } from 'types';
import { loadCurrentUser, loginUser } from 'store/slices/auth.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE_LINK } from 'constants/links';
import { popError, popServerError } from 'store/slices/popNotifications.slice';

import './JobApplicationLogin.styles.scss';
import './JobApplicationLogin.styles.responsive.scss';

type JobApplicationLoginProps = {
  className?: string;
};

const JobApplicationLogin: React.FC<JobApplicationLoginProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { jobId } = useParams();

  const classes = classNames('job-application-login', className);

  const handleSubmit = useCallback(
    async (values: LoginData) => {
      const actionResult = await dispatch(loginUser(values));

      if (loginUser.fulfilled.match(actionResult)) {
        try {
          await dispatch(loadCurrentUser());

          navigate(`/job-application/${jobId}`);
        } catch (err) {
          dispatch(popServerError(err));
        }
      } else {
        dispatch(popError(t(actionResult.payload as string)));
      }
    },
    [dispatch, jobId, navigate, t],
  );

  return (
    <div className={classes}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <a href={HOMEPAGE_LINK} target="_blank" rel="noreferrer">
              <img src={searchalityLogo} alt="Logo" />
            </a>
            <Field
              name="email"
              component={InputField}
              label={t('email')}
              required
              validate={composeValidators(required(), emailValidation())}
            />
            <Field
              name="password"
              component={PasswordField}
              label={t('password')}
              required
              validate={composeValidators(required(), passwordValidation())}
              hint={t('passwordHint')}
            />
            <Button type="submit" disabled={invalid || submitting}>
              {t('login')}
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                navigate('/auth/teacher-signup');
              }}
            >
              {t('createAccount')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  );
};

export default JobApplicationLogin;
