import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { batch } from 'react-redux';
import QuestionVideoTag from '../QuestionVideoTag';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError } from 'store/slices/popNotifications.slice';

import './FollowupQuestionsField.styles.scss';

type FollowupQuestionsFieldProps = {
  className?: string;
};

const FollowupQuestionsField: React.FC<FollowupQuestionsFieldProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const { values } = useFormState();
  const { change } = useForm();

  const dispatch = useAppDispatch();

  const { followUpQuestions, question } = values;

  const baseClass = 'followup-questions-field';
  const classes = classNames(baseClass, className);

  const onAdd = useCallback(() => {
    if (!question?.length) return;

    const isThereDuplicateQuestions = followUpQuestions?.includes(
      question.trim(),
    );

    if (isThereDuplicateQuestions) {
      dispatch(popError(t('duplicateQuestionsDetected')));

      return;
    }

    batch(() => {
      change('followUpQuestions', [
        ...(followUpQuestions ?? []),
        question.trim(),
      ]);
      change('question', '');
    });
  }, [change, dispatch, followUpQuestions, question, t]);

  const renderAddQuestionField =
    !followUpQuestions || followUpQuestions?.length < 5;

  return (
    <div className={classes}>
      {!!followUpQuestions?.length && (
        <div className={`${baseClass}__questions`}>
          {followUpQuestions?.map((question: string, index: number) => (
            <QuestionVideoTag
              value={question}
              description={t('VacancySettings.Add.questionTagDesc')}
              key={question}
              handleDelete={() => {
                const filteredQuestions = followUpQuestions?.filter(
                  (_: string, ind: number) => index !== ind,
                );
                change('followUpQuestions', filteredQuestions);
              }}
            />
          ))}
        </div>
      )}
      {renderAddQuestionField && (
        <div className={`${baseClass}__question-wrapper`}>
          <Field
            name="question"
            component={InputField}
            label={t('VacancySettings.Add.questionLabel')}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onAdd();
              }
            }}
          />
          <Button icon={<AddIcon />} iconPosition="right" onClick={onAdd} />
        </div>
      )}
      <Field name="followUpQuestions" render={() => null} hidden />
    </div>
  );
};

export default FollowupQuestionsField;
