import React, { useCallback, useState } from 'react';
import CheckboxField from 'components/CheckboxField';
import { CheckboxFieldProps } from 'components/CheckboxField/CheckboxField.component';

import './NotToAnswerCheckboxField.styles.scss';
import './NotToAnswerCheckboxField.styles.responsive.scss';

type NotToAnswerCheckboxProps = {
  className?: string;
  children: any;
} & CheckboxFieldProps;

const NotToAnswerCheckboxField: React.FC<NotToAnswerCheckboxProps> = (
  props,
) => {
  const { className, children, ...rest } = props;
  const [isDisabled, setIsDisabled] = useState(false);

  const onCheckboxChange = useCallback(
    (value: any) => {
      rest.input.onChange(!rest.input.checked);
      setIsDisabled(!rest.input.checked);
    },
    [rest.input],
  );

  return (
    <React.Fragment>
      {React.cloneElement(children, { disabled: isDisabled })}
      <CheckboxField
        {...rest}
        onChange={onCheckboxChange}
        checked={rest.input.checked}
      />
    </React.Fragment>
  );
};

export default NotToAnswerCheckboxField;
