import React, { useMemo } from 'react';
import { Button } from 'ncoded-component-library';
import { ReactComponent as DocumentIcon } from 'icons/Document.icon.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AttachmentIcon } from 'icons/Attachment.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';

import './DropzoneContent.styles.scss';

type DropzoneContentProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
  Icon?: JSX.Element;
  files?: File[];
  uploadFileText?: string;
  handleRemoveFile: (file: File) => void;
};

const DropzoneContent: React.FC<DropzoneContentProps> = (props) => {
  const { t } = useTranslation();

  const {
    onClick,
    handleRemoveFile,
    disabled,
    files,
    Icon = <DocumentIcon />,
    uploadFileText = t('uploadAPdfFile'),
  } = props;

  const baseClass = 'dropzone-content';

  const renderFiles = useMemo(() => {
    if (!files?.length) {
      return null;
    }

    return files.map((file) => (
      <div key={file.name} className={`${baseClass}__file-container`}>
        <div className={`${baseClass}__file-container__iframe-container`}>
          <div
            className={`${baseClass}__file-container__iframe-container__overlay`}
          />
          <iframe
            title={file.name}
            src={URL.createObjectURL(file) + '#toolbar=0'}
            className={`${baseClass}__file-container__iframe-container__iframe`}
            frameBorder="0"
          />
        </div>
        <div className={`${baseClass}__file-container__file-name`}>
          <AttachmentIcon />
          <p onClick={() => window.open(URL.createObjectURL(file), '_blank')}>
            {file.name}
          </p>
          <DeleteIcon onClick={() => handleRemoveFile(file)} />
        </div>
      </div>
    ));
  }, [files, handleRemoveFile]);

  return !files?.length ? (
    <>
      <Button
        onClick={onClick}
        disabled={disabled}
        icon={Icon}
        variant="outline"
      />
      <p>
        {!files?.length ? uploadFileText : t('replaceFile')}
        <span>{t('orDragAndDrop')}</span>
      </p>
    </>
  ) : (
    <>
      <div className={`${baseClass}__file-array`}>{renderFiles}</div>
      <p className={`${baseClass}__replace-files`}>
        <span onClick={onClick}>
          <b>{t('replaceFiles', { count: files?.length })}</b>
        </span>
        <span>{t('orDragAndDrop')}</span>
      </p>
    </>
  );
};

export default DropzoneContent;
