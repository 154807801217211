import { useArchiveVacancyMutation } from 'api/vacancies.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import paramsActions from 'store/actions/params.actions';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { Params } from 'types';
import './VacancyArchiveConfirmationModal.styles.scss';

const VacancyArchiveConfirmationModal: React.ForwardRefRenderFunction<
  ModalRef,
  {
    vacancyId: string;
    closeModal: () => void;
    onArchiveSuccess?: (vacancyId: string) => void;
    initialParams?: Partial<Params>;
  }
> = ({ vacancyId, closeModal, onArchiveSuccess, initialParams }, ref) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [archiveVacancy] = useArchiveVacancyMutation();

  const handleArchiveVacancy = useCallback(async () => {
    await archiveVacancy(vacancyId)
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('vacancyArchiveSuccess')));
        onArchiveSuccess?.(vacancyId);
        batch(() => {
          dispatch(paramsActions.resetFilters('vacanciesParams'));
          dispatch(paramsActions.changeCurrentPage('vacanciesParams', 1));
          initialParams &&
            dispatch(
              paramsActions.initializeParams('vacanciesParams', initialParams),
            );
        });
      })
      .catch((err) => {
        dispatch(popServerError(err));
      });
  }, [archiveVacancy, vacancyId, dispatch, t, onArchiveSuccess, initialParams]);

  return (
    <Modal
      ref={ref}
      title={t('vacancyArchive.title')}
      className="vacancy-archive-confirmation-modal"
      footer={
        <>
          <Button variant="outline" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleArchiveVacancy}>{t('Confirm')}</Button>
        </>
      }
    >
      <p>{t('vacancyArchive.question')}</p>
    </Modal>
  );
};
export default React.forwardRef(VacancyArchiveConfirmationModal);
