import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import Educations from '../Educations/Educations.component';
import { EducationType } from 'models/Candidate';

import './EducationsField.styles.scss';

type EducationFieldProps = {
  className?: string;
} & FieldRenderProps<EducationType[], HTMLElement>;

const EducationsField: React.FC<EducationFieldProps> = (props) => {
  const {
    className,
    input: { value, ...input },
    meta: { error, touched },
  } = props;

  const classes = classNames(
    'education-field',
    { 'education-field--error': error },
    className,
  );

  return (
    <div className={classes}>
      <Educations value={value || []} {...input} />
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default EducationsField;
