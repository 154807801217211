import { ComplexRoute } from 'router/components/Routes/Routes';
import { Role } from 'searchality-data';
import JobApplicationPage from './pages/JobApplication';
import JobApplicationLoginPage from './pages/JobApplicationLogin';
import JobApplicationPreviewPage from './pages/JobApplicationPreview';

export default [
  {
    path: ':jobId',
    element: JobApplicationPage,
  },
  {
    path: ':jobId/:applicationId',
    element: JobApplicationPage,
  },
  {
    path: ':jobId/login',
    element: JobApplicationLoginPage,
    validate: ({ auth: { user } }, { schoolGroupId, jobId }) => {
      if (user?.role === Role.Teacher) {
        return `/job-application/${jobId}`;
      }
      return '';
    },
  },
  {
    path: ':jobId/preview',
    element: JobApplicationPreviewPage,
  },
  {
    path: ':jobId/:applicationId/preview',
    element: JobApplicationPreviewPage,
  },
] as Array<ComplexRoute>;
