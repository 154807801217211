import React, { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PaymentMethods from '../../components/PaymentMethods';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import api from 'api';
import PageLoader from 'components/PageLoader';
import GoBackButton from 'components/GoBackButton';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { Button } from 'ncoded-component-library';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import {
  useLazyPreviewSubscriptionPriceQuery,
  useLazyRemoveCouponQuery,
} from 'api/payment.api';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import DateService from 'services/Date.service';
import { numberPriceToCurrencyString } from 'utils';

import './EditUpcomingInvoiceDetails.styles.scss';

type EditUpcomingInvoiceDetailsProps = {
  className?: string;
};

const EditUpcomingInvoiceDetails: React.FC<EditUpcomingInvoiceDetailsProps> = (
  props,
) => {
  const { className } = props;

  const { isLoading, paymentInfo, updatePaymentInfo } = useContext(
    PaymentMethodsContext,
  );

  const {
    schoolGroup: { isSubscriptionCancelledAtPeriodEnd },
  } = useAppSelector(authSelectors.selectUser);

  const [previewRequest, { data, isLoading: isPreviewLoading }] =
    useLazyPreviewSubscriptionPriceQuery();

  const [removeCoupon] = useLazyRemoveCouponQuery();

  const { amount_due, period_end } = data || {};

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const defualtPaymentMethod = paymentInfo?.paymentMethods?.find(
    ({ isDefault }) => isDefault,
  )?.id;

  const handleDefault = useCallback(
    async (id: string) => {
      if (id === defualtPaymentMethod) return;
      await confirm({
        title: t('DefaultPaymentMethod.title'),
        content: <p>{t('DefaultPaymentMethod.description')}</p>,
        onSubmit: async () => {
          try {
            await api.schools.setDefaultPaymentMethod(id);
            const paymentMethods = paymentInfo.paymentMethods.map((el) =>
              el.id === id
                ? { ...el, isDefault: true }
                : { ...el, isDefault: false },
            );

            updatePaymentInfo({
              ...paymentInfo,
              paymentMethods,
            });
            dispatch(
              popSuccess(
                t(
                  'EditUpcomingInvoiceDetails.defaultPaymentMethodUpdateSuccess',
                ),
              ),
            );
          } catch (error) {
            console.error(error);
          }
        },
      });
    },
    [defualtPaymentMethod, dispatch, paymentInfo, t, updatePaymentInfo],
  );

  const handlePoNumber = useCallback(
    async (values: { purchaseOrder: string }) => {
      const { purchaseOrder: poNumber } = values;
      const purchaseOrder = poNumber ? poNumber : null;

      try {
        await api.schools.addPaymentInfo({
          purchaseOrder,
        });

        updatePaymentInfo({
          ...paymentInfo,
          purchaseOrder,
        });
        dispatch(popSuccess(t('EditUpcomingInvoiceDetails.poSuccess')));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, paymentInfo, t, updatePaymentInfo],
  );

  const handlePromoCode = useCallback(
    async (values: { promoCode: string }) => {
      const { promoCode } = values;

      try {
        const { data } = await api.schools.applyCoupon(promoCode);
        if (!data.isPromoCodeValid) {
          return dispatch(popError(t('addPromoCodeError')));
        }
        const coupon = data.discount.coupon.metadata.description;
        updatePaymentInfo({ ...paymentInfo, coupon });
        dispatch(popSuccess(t('addPromoCodeSuccess')));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, paymentInfo, t, updatePaymentInfo],
  );

  const handleRemoveCoupon = useCallback(
    (reset: () => void) => {
      removeCoupon()
        .unwrap()
        .then(() => {
          updatePaymentInfo({ ...paymentInfo, coupon: undefined });
          reset();
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, paymentInfo, removeCoupon, updatePaymentInfo],
  );

  const classes = classNames('edit-upcoming-invoice-details', className);

  const handlePreviewRequest = useCallback(() => {
    previewRequest().unwrap();
  }, [previewRequest]);

  useEffect(() => {
    handlePreviewRequest();
  }, [handlePreviewRequest, paymentInfo]);

  return (
    <div className={classes}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <GoBackButton to="../" />
          <h1>{t('EditUpcomingInvoiceDetails.title')}</h1>
          <PaymentMethods
            showDelete
            checked={defualtPaymentMethod}
            onClick={handleDefault}
          />
          <Form
            onSubmit={handlePoNumber}
            initialValues={{ purchaseOrder: paymentInfo?.purchaseOrder }}
            render={({ handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit} className="po-number">
                <Field
                  name="purchaseOrder"
                  component={InputField}
                  label={t('purchaseOrderNumber')}
                />
                <Button type="submit" disabled={submitting || pristine}>
                  {t('change')}
                </Button>
              </form>
            )}
          />
          <Form
            onSubmit={handlePromoCode}
            render={({
              handleSubmit,
              submitting,
              pristine,
              form: { reset },
            }) => (
              <>
                <form onSubmit={handleSubmit} className="coupon">
                  <Field
                    name="promoCode"
                    component={InputField}
                    label={t('promoCode')}
                  />
                  <Button type="submit" disabled={submitting || pristine}>
                    {paymentInfo?.coupon ? t('applyNewCode') : t('applyCode')}
                  </Button>
                </form>
                {paymentInfo?.coupon && (
                  <Button
                    variant="link"
                    onClick={() => handleRemoveCoupon(reset)}
                  >
                    {t('removeCoupon')}
                  </Button>
                )}
              </>
            )}
          />
          {paymentInfo?.coupon && (
            <p className="coupon-description">
              {t('alreadyHaveCoupon', { coupon: paymentInfo.coupon })}
            </p>
          )}
          {!isSubscriptionCancelledAtPeriodEnd && !isPreviewLoading && (
            <p className="subscription-description">
              {t('subscriptionDescription', {
                date: DateService.getUSADateFormatLong(period_end),
                price: numberPriceToCurrencyString(amount_due),
              })}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default EditUpcomingInvoiceDetails;
