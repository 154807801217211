import React from 'react';
import classNames from 'classnames';
import { CommunicationSettingData } from 'types';
import { Form } from 'react-final-form';
import CommunicationSetting from './components/CommunicationSetting';
import AccountEditWrapper from '../AccountEditWrapper';
import { useTranslation } from 'react-i18next';
import { SchoolUserEmailSettings, TeacherUserEmailSettings } from 'models/User';

import './CommunicationSettings.styles.scss';
import './CommunicationSettings.styles.responsive.scss';

type CommunicationSettingsProps = {
  className?: string;
  communicationList: CommunicationSettingData[];
  initialValues: Partial<SchoolUserEmailSettings & TeacherUserEmailSettings>;
  handleSubmit: (values: any) => Promise<void>;
};

const CommunicationSettings: React.FC<CommunicationSettingsProps> = (props) => {
  const { className, communicationList, handleSubmit, initialValues } = props;

  const { t } = useTranslation();

  const classes = classNames('communication-settings', className);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, form }) => (
        <AccountEditWrapper
          className={classes}
          goBackTo={'..'}
          isDirty={form.getState().dirty}
          handleSave={handleSubmit}
          title={t('Communication.emailSettings')}
          subtitle={t('Communication.emailSettingsDescription')}
          submitting={submitting}
        >
          <form onSubmit={handleSubmit}>
            {communicationList.map((setting) => (
              <CommunicationSetting data={setting} key={setting.id} />
            ))}
          </form>
        </AccountEditWrapper>
      )}
    />
  );
};

export default CommunicationSettings;
