import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PhotoPreview from 'components/PhotoPreview';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import { getNameInitials } from 'utils';

import './ImageSection.styles.scss';

type ImageSectionProps = {
  className?: string;
};

const ImageSection: React.FC<ImageSectionProps> = (props) => {
  const { className } = props;

  const { role, firstName, lastName, imageFile, systemColor } = useAppSelector(
    authSelectors.selectUser,
  );

  const { t } = useTranslation();

  const baseClass = 'image-section';
  const classes = classNames(baseClass, className);

  return (
    <section className={classes}>
      <PhotoPreview
        src={imageFile?.url}
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={systemColor}
      />
      <div className={`${baseClass}__information`}>
        <p>{`${firstName} ${lastName}`}</p>
        <div className={`${baseClass}__information__permission`}>
          <p>{t('role')}</p>
          <p className={`${baseClass}__information__permission__role`}>
            {role}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
