import React from 'react';
import useFinishSubscribe from '../../hooks/useFinishSubscribe';

type StripeSubscribeProps = {
  selectedPaymentMethodId: string;
};

const StripeSubscribe: React.FC<StripeSubscribeProps> = (props) => {
  const { selectedPaymentMethodId } = props;

  useFinishSubscribe(selectedPaymentMethodId);

  return null;
};

export default StripeSubscribe;
