import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import { Vacancy } from 'models/Vacancy';
import { GlobalFilters, PaginatedResponse, Params } from 'types';

function getVacancies(params?: Params) {
  return httpClient.get<PaginatedResponse<Vacancy>>('/vacancies', { params });
}

function getVacancyFilters(params?: Partial<Params>) {
  return httpClient.get<GlobalFilters>('vacancies/filters', { params });
}

function getVacancyCandidates(vacancyId: string, params: Params) {
  return httpClient.get(`vacancies/${vacancyId}/candidates`, { params });
}

function getOpenVacancy(vacancyId: string, params: Params) {
  return publicHttpClient.get(`api/jobs/${vacancyId}/open`, { params });
}

export default {
  getVacancies,
  getVacancyFilters,
  getVacancyCandidates,
  getOpenVacancy,
};
