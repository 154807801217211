import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import LoginInformation, {
  LoginInformationRoutes,
} from '../MyAccount/subrouters/PersonalInformation/subrouters/LoginInformation';
import TeacherCommunicationSettings from './pages/TeacherCommunicationSettings';
import TeacherPersonalDetails from './pages/TeacherPersonalDetails';
import TeacherPersonalInformation from './pages/TeacherPersonalInformation';

export default [
  {
    path: 'personal-information',
    element: TeacherPersonalInformation,
  },
  {
    path: 'personal-information/personal-details',
    element: TeacherPersonalDetails,
  },
  {
    path: 'personal-information/login-information',
    routes: LoginInformationRoutes,
    element: LoginInformation,
  },
  {
    path: 'personal-information/communication-settings',
    element: TeacherCommunicationSettings,
  },
  {
    index: true,
    element: <Navigate to="personal-information" replace />,
  },
] as Array<ComplexRoute>;
