import { createContext } from 'react';

type CreateSchoolWizardContextProps = {
  currentStepIndex: number;
  completedSteps: number[];
  updateCurrentStep: (stepIndex: number) => void;
  completeStep: (stepIndex: number) => void;
  resetCompletedSteps: (newSteps: number[]) => void;
};

export default createContext<Partial<CreateSchoolWizardContextProps>>({});
