import React, { useCallback, useMemo, useRef } from 'react';
import {
  useDeleteMemberMutation,
  useGetMemberQuery,
  useUpdateMemberPermissionMutation,
} from 'api/members.api';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import { useNavigate, useParams } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { useTranslation } from 'react-i18next';
import RolesSelect from 'router/subrouters/Dashboard/subrouters/Members/components/RolesSelect';
import useAppDispatch from 'hooks/useAppDispatch';
import { Role } from 'searchality-data';
import confirm from 'modules/confirm';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import SelectVacanciesModal from 'router/subrouters/Dashboard/components/SelectVacanciesModal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import paramsActions from 'store/actions/params.actions';

import './MembersActions.styles.scss';
import './MembersActions.styles.responsive.scss';

const MembersActions: React.FC = () => {
  const { memberId } = useParams();
  const { _id: currentUserId, role: currentUserRole } = useAppSelector(
    authSelectors.selectUser,
  );

  const dispatch = useAppDispatch();
  const [deleteMember] = useDeleteMemberMutation();
  const [updateMemberPermission] = useUpdateMemberPermissionMutation();

  const selectVacanciesModalRef = useRef<ModalRef>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: member } = useGetMemberQuery(memberId);

  const { role: memberRole, _id } = member;

  const isMe = member._id === currentUserId;

  const baseClass = 'members-actions';

  const handleOnDelete = async () => {
    await confirm({
      title: t('DeleteMemberModal.title'),
      content: t('DeleteMemberModal.content'),
      confirmBtnText: t('DeleteMemberModal.delete'),
      onSubmit: () => {
        deleteMember(memberId)
          .unwrap()
          .then(() => {
            dispatch(popSuccess(t('memberDeletedSuccesfuly')));
            setTimeout(() => {
              navigate('/members');
            }, 500);
          })
          .catch((err) => dispatch(popServerError(err)));
      },
    });
  };

  const handleManageReviewerVacancies = useCallback(
    (selectedIds: string[]) => {
      updateMemberPermission({
        userId: _id,
        role: Role.Reviewer,
        vacancyIds: selectedIds,
      })
        .unwrap()
        .then(() => {
          selectVacanciesModalRef.current.close();
          dispatch(popSuccess(t('updateReviewerVacanciesSuccess')));
        })
        .catch(() => dispatch(popError(t('updateReviewerVacanciesFail'))));
    },
    [_id, dispatch, t, updateMemberPermission],
  );

  const vacanciesParams = useMemo(
    () => ({
      $where: {
        status: { $in: ['Published', 'Ongoing'] },
      },
      $populate: ['schools', 'schoolGroup'],
    }),
    [],
  );

  const isRolesSelectReadonly = useMemo(() => {
    return isMe || currentUserRole !== Role.AccountOwner;
  }, [currentUserRole, isMe]);

  return (
    <div className={baseClass}>
      {(currentUserRole === Role.HiringManager &&
        member.inviterId === currentUserId) ||
      currentUserId === member?._id ||
      member?.role === Role.AccountOwner ? null : (
        <Button
          icon={<DeleteIcon />}
          iconPosition="right"
          onClick={handleOnDelete}
          className={`${baseClass}__delete-button`}
        >
          {t('deleteUser')}
        </Button>
      )}
      {memberRole === Role.Reviewer && (
        <Button
          variant="outline"
          onClick={() => {
            dispatch(
              paramsActions.initializeParams(
                'vacanciesParams',
                vacanciesParams,
              ),
            );
            selectVacanciesModalRef.current.open();
          }}
        >
          {t('manageVacancies')}
        </Button>
      )}
      {!isRolesSelectReadonly && (
        <RolesSelect currentRole={memberRole} userId={memberId} />
      )}
      <ValueContextProvider
        isMultiple
        initialIds={member?.vacancies?.map((vacancy) => vacancy._id)}
      >
        <SelectVacanciesModal
          ref={selectVacanciesModalRef}
          onSubmit={(selectedIds) => {
            handleManageReviewerVacancies(selectedIds);
          }}
          onClose={() => {
            selectVacanciesModalRef.current.close();
          }}
          onCancel={() => {
            selectVacanciesModalRef.current.close();
          }}
          initialParams={vacanciesParams}
          submitBtnText={t('confirm')}
        />
      </ValueContextProvider>
    </div>
  );
};

export default MembersActions;
