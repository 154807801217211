import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import PhotoPreview from 'components/PhotoPreview';
import { User } from 'models/User';
import { getNameInitials } from 'utils';

import './FileInformationModal.styles.scss';

type FileInformationModalProps = {
  className?: string;
  fileName: string;
  fileSize: number;
  createdAt: string;
  owner?: User;
};

const FileInformationModal: React.ForwardRefRenderFunction<
  ModalRef,
  FileInformationModalProps
> = (props, ref) => {
  const { className, fileName, fileSize, createdAt, owner } = props;

  const { t } = useTranslation();

  const classes = classNames('file-information-modal', className);

  return (
    <Modal ref={ref} className={classes} title={t('documentInformation')}>
      <div className="file-information-modal__info">
        <label>{t('name')}</label>
        <p>{fileName}</p>
      </div>
      <div className="file-information-modal__info">
        <label>{t('size')}</label>
        <p>{(fileSize / 1024).toFixed(1) + ' kB'}</p>
      </div>
      {createdAt && (
        <div className="file-information-modal__info">
          <label>{t('dateUploadedCreated')}</label>
          <p>{DateService.getUSADateFormatLong(createdAt)}</p>
        </div>
      )}
      {owner && (
        <div className="file-information-modal__info">
          <label>{t('uploadedBy')}</label>
          <PhotoPreview
            src={owner.imageFile?.url}
            backgroundColor={owner.systemColor}
            key={owner._id}
            size="m"
            placeHolderText={getNameInitials(owner.firstName, owner.lastName)}
          />
        </div>
      )}
    </Modal>
  );
};

export default forwardRef(FileInformationModal);
