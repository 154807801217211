import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'ncoded-component-library';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { useTranslation } from 'react-i18next';
import InviteMemberModal from './components/InviteMemberModal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Form } from 'react-final-form';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import SelectVacanciesModal from '../SelectVacanciesModal';
import SelectSchoolModal from '../SelectSchoolModal';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { popError } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import classNames from 'classnames';
import paramsActions from 'store/actions/params.actions';

export type InviteMemberValues = {
  role: Role;
  emails?: string[];
  vacancyIds?: string[];
  schoolId?: string;
  userId?: string;
  candidateId?: string;
  vacancyId?: string;
  schoolIds?: string[];
};

const InviteMember: React.FC<{
  addMember: (
    values: InviteMemberValues,
    onSucces: () => void,
    onError?: (error: string) => void,
  ) => void;
  inviteMemberModalChildren?: any;
  triggerClassName?: string;
}> = ({ addMember, inviteMemberModalChildren, triggerClassName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [mounted, setIsMounted] = useState(false);

  const {
    schoolGroup: { structureType, schools },
  } = useAppSelector(authSelectors.selectUser);

  const selectVacanciesModalRef = useRef<ModalRef>(null);
  const selectSchoolModalRef = useRef<ModalRef>(null);

  const inviteModalRef = useRef<ModalRef>();

  /**
   * if school profile is STANDALONE
   * we invite member directly without selecting
   * school id
   */
  const inviteHiringManager = useCallback(
    (memberValues: InviteMemberValues) => {
      if (structureType === SchoolGroupStructureType.GROUP) {
        inviteModalRef.current.close();
        return selectSchoolModalRef.current.open();
      }
      const { emails } = memberValues;

      addMember(
        { role: Role.HiringManager, schoolId: schools?.[0]?._id, emails },
        () => {
          memberValues = {} as InviteMemberValues;
          selectSchoolModalRef.current.close();
          inviteModalRef.current.close();
        },
        (error: string) => dispatch(popError(error)),
      );
    },
    [addMember, dispatch, schools, structureType],
  );

  const onHiringManagerSchoolSubmit = useCallback(
    (selectedSchoolId: string, memberValues: InviteMemberValues) => {
      const { emails } = memberValues;

      addMember(
        {
          role: Role.HiringManager,
          schoolId: selectedSchoolId,
          emails,
        },
        () => {
          selectSchoolModalRef.current.close();
          inviteModalRef.current.close();
        },
        (error: string) => dispatch(popError(error)),
      );
    },
    [addMember, dispatch],
  );

  const onReviewerVacanciesSubmit = useCallback(
    (selectedIds: string[], memberValues: InviteMemberValues) => {
      const { emails } = memberValues;

      addMember(
        { role: Role.Reviewer, emails, vacancyIds: selectedIds },
        () => {
          selectVacanciesModalRef.current.close();
          inviteModalRef.current.close();
        },
        (error: string) => dispatch(popError(error)),
      );
    },
    [addMember, dispatch],
  );

  const onAccountManagerInvite = useCallback(
    (memberValues: InviteMemberValues) => {
      const { emails } = memberValues;
      addMember(
        { role: Role.AccountManager, emails },
        () => {
          inviteModalRef.current.close();
        },
        (error: string) => dispatch(popError(error)),
      );
    },
    [addMember, dispatch],
  );

  const vacanciesParams = useMemo(
    () => ({
      $where: {
        status: { $in: ['Published', 'Ongoing'] },
      },
      $populate: ['schools', 'schoolGroup'],
    }),
    [],
  );

  const onInviteModalConfirm = useCallback(
    (values: InviteMemberValues) => {
      const { role } = values;

      if (role === Role.Reviewer) {
        inviteModalRef.current.close();
        dispatch(
          paramsActions.initializeParams('vacanciesParams', vacanciesParams),
        );
        selectVacanciesModalRef.current.open();
      }
      if (role === Role.HiringManager) {
        inviteHiringManager(values);
      }
      if (role === Role.AccountManager) {
        onAccountManagerInvite(values);
      }
    },
    [dispatch, inviteHiringManager, onAccountManagerInvite, vacanciesParams],
  );

  return (
    <>
      <Button
        iconPosition="right"
        icon={<AddIcon />}
        onClick={() => {
          setIsMounted(true);
          setTimeout(() => {
            inviteModalRef.current.open();
          }, 100);
        }}
        className={classNames(triggerClassName, {
          open: mounted,
        })}
      >
        {t('inviteNewMember')}
      </Button>
      {!mounted ? undefined : (
        <Form
          onSubmit={onInviteModalConfirm}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <InviteMemberModal
                onSubmit={handleSubmit}
                ref={inviteModalRef}
                onClose={() => {
                  inviteModalRef.current.close();
                  setIsMounted(false);
                }}
                onCancel={() => {
                  inviteModalRef.current.close();
                  setIsMounted(false);
                }}
              >
                {inviteMemberModalChildren}
              </InviteMemberModal>
              <ValueContextProvider isMultiple>
                <SelectVacanciesModal
                  ref={selectVacanciesModalRef}
                  onSubmit={(selectedIds) => {
                    onReviewerVacanciesSubmit(selectedIds, values);
                  }}
                  onClose={() => {
                    inviteModalRef.current.close();
                    selectVacanciesModalRef.current.close();
                    setIsMounted(false);
                  }}
                  onCancel={() => {
                    inviteModalRef.current.close();
                    selectVacanciesModalRef.current.close();
                    setIsMounted(false);
                  }}
                  initialParams={vacanciesParams}
                  submitBtnText={t('invite')}
                />
              </ValueContextProvider>
              <ValueContextProvider>
                <SelectSchoolModal
                  ref={selectSchoolModalRef}
                  onSubmit={(selectedSchoolId) =>
                    onHiringManagerSchoolSubmit(selectedSchoolId, values)
                  }
                  onClose={() => {
                    selectSchoolModalRef.current.close();
                    inviteModalRef.current.close();
                    setIsMounted(false);
                  }}
                  onCancel={() => {
                    selectSchoolModalRef.current.close();
                    inviteModalRef.current.close();
                    setIsMounted(false);
                  }}
                  submitBtnText={t('invite')}
                />
              </ValueContextProvider>
            </form>
          )}
        />
      )}
    </>
  );
};

export default InviteMember;
