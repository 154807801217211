import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import GoBackButton from 'components/GoBackButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import confirm from 'modules/confirm';

import './PersonalInformationEditWrapper.styles.scss';
import './PersonalInformationEditWrapper.styles.responsive.scss';

type PersonalInformationEditWrapperProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  goBackTo: string;
  isDirty: boolean;
};

const PersonalInformationEditWrapper: React.FC<
  PersonalInformationEditWrapperProps
> = (props) => {
  const { className, children, title, subtitle, goBackTo, isDirty } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const baseClass = 'personal-information-edit-wrapper';
  const classes = classNames(baseClass, className);

  const handleClose = useCallback(async () => {
    if (!isDirty) {
      return navigate('..');
    }
    await confirm({
      title: t('myAccount.SchoolGroupInfo.cancel'),
      onSubmit: () => {
        navigate('..');
      },
    });
  }, [isDirty, navigate, t]);

  return (
    <div className={classes}>
      <GoBackButton to={goBackTo} />
      <div className={`${baseClass}__content`}>
        {title && <h1>{title}</h1>}
        {subtitle && <p>{subtitle}</p>}
        {children}
      </div>
      <Button
        className="text-button"
        icon={<CloseIcon />}
        iconPosition="left"
        onClick={handleClose}
      >
        {t('cancel')}
      </Button>
    </div>
  );
};

export default PersonalInformationEditWrapper;
