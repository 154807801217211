import React from 'react';
import classNames from 'classnames';

import './InfoWithLabel.styles.scss';

type InfoWithLabelProps = {
  className?: string;
  label: string;
  text: string;
};

const InfoWithLabel: React.FC<InfoWithLabelProps> = (props) => {
  const { className, label, text } = props;

  const baseClass = 'info-with-label';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <p className={`${baseClass}__label`}>{label}</p>
      <p>{text}</p>
    </div>
  );
};

export default InfoWithLabel;
