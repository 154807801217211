import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ProfileTabs from '../ProfileTabs';
import { getNameInitials } from 'utils';
import PhotoPreview from 'components/PhotoPreview';
import DateService from 'services/Date.service';
import { ProfileCardType } from 'models/ProfileCard.type';
import useProfileTabs from './hooks/useProfileTabs.hook';
import { Button } from 'ncoded-component-library';
import { ReactComponent as ShareIcon } from 'icons/Share.icon.svg';
import { ReactComponent as DownloadIcon } from 'icons/Download.icon.svg';
import env from 'env';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { TeacherProfileStatus } from 'searchality-data';
import { useLocation } from 'react-router';

import './ProfileMain.styles.scss';
import './ProfileMain.styles.responsive.scss';
import { getFileFromURL } from 'components/ImageCropper/utils';

type ProfileMainProps = {
  className?: string;
  cardInfo: ProfileCardType;
};

const ProfileMain: React.FC<ProfileMainProps> = (props) => {
  const { className, cardInfo } = props;

  const {
    firstName,
    lastName,
    startDate,
    imageUrl,
    profilePdfUrl,
    systemColor,
    profileStatus,
    teacherId,
  } = cardInfo;

  const { activeTab, tabs, currentSection } = useProfileTabs(cardInfo);

  const [file, setFile] = useState<File>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const showShareButton = teacherId && !pathname.includes('teacher-profile');

  const disableShare = profileStatus !== TeacherProfileStatus.PUBLISHED;

  const baseClass = 'searchality-profile-main';

  const classes = classNames(baseClass, className);

  const fetchFile = useCallback(async () => {
    try {
      const file = await getFileFromURL(
        profilePdfUrl,
        'profile',
        'application/pdf',
      );
      setFile(file);
      return file;
    } catch (e) {
      console.error(e);
    }
  }, [profilePdfUrl]);

  useEffect(() => {
    if (profilePdfUrl) {
      fetchFile();
    }
  }, [fetchFile, profilePdfUrl]);

  return (
    <div className={classes}>
      <PhotoPreview
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={systemColor}
        src={imageUrl}
        size="xxl"
        className={`${baseClass}__avatar`}
      />
      {showShareButton && (
        <div className={`${baseClass}__contact`}>
          <Button
            variant="outline"
            iconPosition="right"
            icon={<ShareIcon />}
            disabled={disableShare}
            onClick={() => {
              navigator.clipboard.writeText(
                `${env.PLATFORM_ENDPOINT}/teacher-profile/${teacherId}`,
              );
              dispatch(popSuccess(t('copyToClipboard')));
            }}
          >
            {t('Profile.Main.shareProfile')}
          </Button>
          <Button
            icon={<DownloadIcon />}
            iconPosition="right"
            disabled={disableShare || !profilePdfUrl}
            variant="outline"
            onClick={async () => {
              try {
                let fileToDownload = file;

                if (!fileToDownload) {
                  fileToDownload = await fetchFile();
                }

                const a = document.createElement('a');
                a.href = URL.createObjectURL(fileToDownload);
                a.download = 'profile';
                a.click();
              } catch (e) {
                dispatch(popError(t('pleaseTryAgainLater')));
              }
            }}
          >
            {t('downloadAsPdf')}
          </Button>
        </div>
      )}
      <div className={`${baseClass}__personal-info`}>
        <p
          className={`${baseClass}__personal-info__name`}
        >{`${firstName} ${lastName}`}</p>
        <div className={`${baseClass}__personal-info__info`}>
          <p className={`${baseClass}__personal-info__info__main`}>
            {t('Profile.Main.startDate')}
          </p>
          <p>
            {startDate
              ? DateService.getDayMonthYear(startDate)
              : startDate === null
              ? t('immediately')
              : t('noData')}
          </p>
        </div>
      </div>
      <ProfileTabs tabs={tabs} activeTab={activeTab} />
      <hr />
      {currentSection}
    </div>
  );
};

export default ProfileMain;
