import { useTranslation } from 'react-i18next';
import { Role } from 'searchality-data';

export default () => {
  const { t } = useTranslation();

  return [
    { value: Role.Reviewer, label: t('ShareVacancyModal.Reviewer') },
    { value: Role.HiringManager, label: t('ShareVacancyModal.HiringManager') },
    { value: Role.AccountOwner, label: t('ShareVacancyModal.AccountOwner') },
  ];
};
