import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserGender } from 'searchality-data';

export function useGenderOptions() {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.entries(UserGender).map(({ 0: key, 1: value }) => ({
      label: t(`UserGender.${key}`),
      value: value,
    }));
  }, [t]);

  return options;
}
