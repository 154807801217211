import { useParams } from 'react-router-dom';

export default () => {
  const { vacancyId } = useParams();
  return [
    {
      to: `/vacancies/${vacancyId}/candidates`,
      label: 'candidatesCapital',
    },
    {
      to: `/vacancies/${vacancyId}/information`,
      label: 'vacancyInformation',
    },
    {
      to: `/vacancies/${vacancyId}/vacancy-collaborators`,
      label: 'vacancyCollaborators',
    },
  ];
};
