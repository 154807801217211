import React from 'react';
import classNames from 'classnames';
import { User } from 'models/User';
import MemberCard from './MemberCard';

import './MemberCards.styles.scss';

type MemberCardsProps = {
  className?: string;
  members: User[];
  isReadonly?: boolean;
  schoolName?: string;
};

const MemberCards: React.FC<MemberCardsProps> = (props) => {
  const { className, members, isReadonly, schoolName } = props;

  const classes = classNames('member-cards', className);

  return (
    <ul className={classes}>
      {members?.map((member) => (
        <MemberCard
          member={member}
          key={member?._id}
          isReadonly={isReadonly}
          schoolName={schoolName}
        />
      ))}
    </ul>
  );
};

export default MemberCards;
