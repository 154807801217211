import React from 'react';
import FormFieldLabel from 'components/FormFieldLabel';
import { VacancyType } from 'searchality-data';
import { Field } from 'react-final-form';
import DropzoneField from 'components/DropzoneField';
import { required } from 'validations';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOpenJobQuery } from 'api/jobs.api';

import './EducationPhilosophySection.styles.scss';

const EducationPhilosophySection: React.FC = () => {
  const { t } = useTranslation();

  const { jobId } = useParams();
  const { data: job } = useGetOpenJobQuery(jobId);

  return (
    <>
      {job?.type === VacancyType.ACADEMIC &&
        job?.isEducationPhilosophyRequired && (
          <>
            <FormFieldLabel
              text={t('educationPhilosophy')}
              hint={
                <>
                  <p>{t('educationPhilosophyTippy.title')}</p>
                  <ol>
                    <li>{t('educationPhilosophyTippy.li1')}</li>
                    <li>{t('educationPhilosophyTippy.li2')}</li>
                    <li>{t('educationPhilosophyTippy.li3')}</li>
                    <li>{t('educationPhilosophyTippy.li4')}</li>
                    <li>{t('educationPhilosophyTippy.li5')}</li>
                    <li>{t('educationPhilosophyTippy.li6')}</li>
                  </ol>
                </>
              }
            />
            <Field
              name="yourProfile.educationPhilosophy"
              key="educationPhilosophy"
              component={DropzoneField}
              validate={required()}
            />
          </>
        )}
      {(job?.type === VacancyType.NON_ACADEMIC || job?.isResumeRequired) && (
        <>
          <FormFieldLabel
            text={t(t('resume'))}
            isOptional={job?.type === VacancyType.NON_ACADEMIC}
            optionalText={
              job?.type === VacancyType.NON_ACADEMIC && t('optionalResumeText')
            }
            hint={
              job?.type === VacancyType.ACADEMIC &&
              'Please attach a recent copy of your resume for the school to have on file with your application. Your application will be processed with the data from your profile, but your resume will be used as a supporting/additional document.'
            }
          />
          <Field
            name="yourProfile.resume"
            key="resume"
            component={DropzoneField}
            validate={required()}
          />
        </>
      )}
    </>
  );
};

export default EducationPhilosophySection;
