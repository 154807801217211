import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const usePaymentDetails = (): any[] => {
  const { isLoading, paymentInfo } = useContext(PaymentMethodsContext);

  const { t } = useTranslation();

  const defualtPaymentMethod = useMemo(
    () => paymentInfo?.paymentMethods?.find(({ isDefault }) => isDefault),
    [paymentInfo],
  );

  if (isLoading || !defualtPaymentMethod) {
    return [];
  }

  const { type, card, usBankAccount } = defualtPaymentMethod;

  if (type === 'card') {
    const { brand, country, exp_month, exp_year, last4 } = card;
    return [
      { label: t('cardType'), value: brand.toUpperCase() },
      { label: t('country'), value: country },
      { label: t('expiryDate'), value: `${exp_month}/${exp_year}` },
      { label: t('last4Digits'), value: last4 },
    ];
  }

  const { bank_name, last4 } = usBankAccount;
  return [
    { label: t('bankName'), value: bank_name },
    { label: t('last4Digits'), value: last4 },
  ];
};

export default usePaymentDetails;
