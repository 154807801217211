import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Vacancy } from 'models/Vacancy';
import DateService from 'services/Date.service';
import { useTranslation } from 'react-i18next';

import './MemberVacancyCard.styles.scss';
import './MemberVacancyCard.styles.responsive.scss';

type MemberVacancyCardProps = Vacancy & {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

const MemberVacancyCard: React.FC<MemberVacancyCardProps> = (props) => {
  const { className, onClick, ...vacancy } = props;

  const { t } = useTranslation();

  const {
    schools,
    positionDivision,
    positionTitle,
    subjectGradeLevel,
    editedAt,
    createdAt,
  } = vacancy;

  const classes = classNames(
    'searchality-vacancy-card',
    'member-vacancy-card',
    className,
  );

  const schoolNamesList = useMemo(() => {
    if (!schools.length) return;
    return (
      <ul>
        {schools.map(({ name, _id }) => (
          <li key={_id}>
            <p>{name}</p>
          </li>
        ))}
      </ul>
    );
  }, [schools]);

  const showPastTime = useCallback(
    (updatedAt: string) => {
      const pastTime = DateService.calculatePastTime(updatedAt);
      return t(pastTime.type, { count: pastTime.num });
    },
    [t],
  );

  return (
    <li className={classes} onClick={onClick}>
      <div className="searchality-vacancy-card__left">
        <p className="searchality-vacancy-card__left__school-type">
          {positionDivision}
        </p>
        <p className="searchality-vacancy-card__left__position">
          {positionTitle}
        </p>
        <span className="searchality-vacancy-card__left__type">
          {subjectGradeLevel}
        </span>
        <span className="searchality-vacancy-card__left__school-name">
          {schoolNamesList}
        </span>
      </div>
      <div className="searchality-vacancy-card__right">
        <p>{t('lastActivity')}</p>
        <p>{showPastTime(editedAt ?? createdAt)}</p>
      </div>
    </li>
  );
};

export default MemberVacancyCard;
