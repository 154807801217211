import React from 'react';
import classNames from 'classnames';
import MatchingTarget from 'assets/images/matching-target.webp';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';

import './MatchingAdvertisementWizardForm.styles.scss';

type MatchingAdvertisementWizardFormProps = {
  className?: string;
};

const MatchingAdvertisementWizardForm: React.FC<
  MatchingAdvertisementWizardFormProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'matching-advertisement-wizard-form';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <img src={MatchingTarget} alt="Matching target" />
      <div className={`${baseClass}__p-container`}>
        <p className={`${baseClass}__p-container__title`}>
          {t('vacancyWizard.step5.advertisement.title')}
        </p>
        <p className={`${baseClass}__p-container__subtitle`}>
          {t('vacancyWizard.step5.advertisement.subtitle')}
        </p>
      </div>
      <div className={`${baseClass}__actions-container`}>
        <Field
          name="disableAdd"
          component={CheckboxField}
          type="checkbox"
          isStyledDefault
          label={t('dontShowAgain')}
        />
        <a
          href="https://searchality.com/pricing"
          target="_blank"
          rel="noreferrer"
        >
          {t('learnMore')}
        </a>
      </div>
    </div>
  );
};

export default MatchingAdvertisementWizardForm;
