import React from 'react';
import classNames from 'classnames';
import HeaderNavigation from 'components/HeaderNavigation';

import './MobileHeaderNavigation.styles.scss';

type MobileHeaderNavigationProps = {
  className?: string;
  isOpen: boolean;
  closeMenu: () => void;
};

const MobileHeaderNavigation: React.FC<MobileHeaderNavigationProps> = (
  props,
) => {
  const { className, isOpen, closeMenu } = props;

  const baseClass = 'mobile-header-navigation';
  const classes = classNames(baseClass, className, {
    [`${baseClass}--open`]: isOpen,
  });

  return (
    <div className={classes}>
      <div className={`${baseClass}__side-nav`}>
        <HeaderNavigation />
      </div>
      <div className={`${baseClass}__overlay`} onClick={closeMenu} />
    </div>
  );
};

export default MobileHeaderNavigation;
