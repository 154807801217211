import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import { Teacher } from 'models/Teacher';
import { UserSource } from 'searchality-data';
import { AuthBody, RegisterData } from 'types';

const authPath = (routePath: TemplateStringsArray) => `/api/auth/${routePath}`;

function register(userData: RegisterData, source?: UserSource) {
  return publicHttpClient.post<AuthBody>(authPath`register-teacher`, {
    ...userData,
    source,
  });
}

function updateTacher(userData: Teacher) {
  return httpClient.patch<Teacher>('/teacher-users/profile', userData);
}

export default {
  register,
  updateTacher,
};
