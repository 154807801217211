import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Tag.styles.scss';
import './Tag.styles.responsive.scss';

type TagProps = {
  className?: string;
  text: ReactNode;
  children?: ReactNode;
};

const Tag: React.FC<TagProps> = (props) => {
  const { children, className, text } = props;

  const classes = classNames('searchality-tag', className);

  return (
    <div className={classes}>
      <span>{text}</span>
      {children}
    </div>
  );
};

export default Tag;
