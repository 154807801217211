import React from 'react';
import CheckoutContext, { CheckoutContextProps } from './Checkout.context';

type CheckoutProviderProps = {
  children: React.ReactNode;
  data: CheckoutContextProps;
};

const CheckoutProvider: React.FC<CheckoutProviderProps> = (props) => {
  const { children, data } = props;

  return (
    <CheckoutContext.Provider value={data}>{children}</CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
