import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import CandidateProfile from './pages/CandidateProfile';
import CandidatesWrapper from './pages/CandidatesWrapper';
import RequestCandidatesDocuments from './pages/RequestCandidatesDocuments';

export default [
  {
    path: 'new',
    element: CandidatesWrapper,
  },
  {
    path: 'qualified',
    element: CandidatesWrapper,
  },
  {
    path: 'archived',
    element: CandidatesWrapper,
  },
  {
    path: 'hired',
    element: CandidatesWrapper,
  },
  {
    path: ':candidateId',
    element: CandidateProfile,
  },
  {
    path: ':candidateId/request-documents',
    element: RequestCandidatesDocuments,
  },
  {
    index: true,
    element: <Navigate to="new" replace />,
  },
] as Array<ComplexRoute>;
