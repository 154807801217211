import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProfileIcon } from 'icons/Profile.icon.svg';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as LanguageIcon } from 'icons/Language.icon.svg';
import { ReactComponent as ReferralsIcon } from 'icons/Referrals.icon.svg';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import useAppSelector from 'hooks/useAppSelector';
import GoBackButton from 'components/GoBackButton';
import bus from 'modules/bus';
import useAppDispatch from 'hooks/useAppDispatch';
import { teacherProfileBuilderActions } from 'store/slices/teacherProfileBuilder.slice';

import './Start.styles.scss';

const Start: React.FC = () => {
  const { t } = useTranslation();
  const baseClass = 'teacher-profile-build__start';
  const dispatch = useAppDispatch();
  const routes = useAppSelector(teacherProfileBuilderSelectors.selectAllRoutes);

  const startTheBuilder = () => {
    bus.broadcastEvent('JUMP_TO_ROUTE', routes[0]);
    dispatch(teacherProfileBuilderActions.jumpToRoute(routes[0]));
  };

  return (
    <div className={baseClass}>
      <GoBackButton to="/profile" />

      <div className={`${baseClass}__content`}>
        <h3>{t('teacherProfileBuilder.startPage.title')}</h3>
        <p>{t('teacherProfileBuilder.startPage.description')}</p>
        <ul className={`${baseClass}__flow`}>
          <li className={`${baseClass}__flow__stickers`}>
            <span className={`${baseClass}__flow__stickers__personal`}>
              <ProfileIcon />
            </span>
            <span className={`${baseClass}__flow__stickers__education`}>
              <EducationIcon />
            </span>
            <span className={`${baseClass}__flow__stickers__referrals`}>
              <ReferralsIcon />
            </span>

            <span className={`${baseClass}__flow__stickers__positions`}>
              <LanguageIcon />
            </span>
          </li>
          <li className={`${baseClass}__flow__names`}>
            <p>{t('personalInformation')}</p>
            <p>{t('teacherProfileBuilder.startPage.education/experience')}</p>
            <p>{t('teacherProfileBuilder.startPage.workReferrals')}</p>
            <p>{t('teacherProfileBuilder.startPage.targetPositions')}</p>
          </li>
        </ul>
      </div>
      <Button onClick={startTheBuilder} className="get-started">
        {t('getStarted')}
      </Button>
    </div>
  );
};

export default Start;
