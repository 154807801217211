import React, { useMemo, useState } from 'react';
import MainLayout from 'components/MainLayout';
import { useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { routesMapping } from '../../constants';
import Profile from 'router/subrouters/Dashboard/components/Profile';
import authSelectors from 'store/selectors/auth.selectors';
import { convertTeacherFormValuesIntoCard } from '../../utils';
import { useFormState } from 'react-final-form';
import { ImageFile, User } from 'models/User';
import ProfileNotReadyBanner from 'router/subrouters/Dashboard/components/Profile/components/ProfileNotReadyBanner';
import useMandatoryUnfinishedStepsInfo from '../../hooks/useMandatoryUnfinishedStepsInfo';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import {
  loadCurrentUser,
  updateTeacherUser,
  updateUser,
} from 'store/slices/auth.slice';
import { Teacher } from 'models/Teacher';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import api from 'api';
import confirm from 'modules/confirm';
import { teacherProfileBuilderActions } from 'store/slices/teacherProfileBuilder.slice';
import { TeacherFormValues } from '../../models/TeacherFormValues';
import TeacherProfileDataService from '../../services/TeacherProfileData.service';
import classNames from 'classnames';
import GtmService from 'services/GTM.service';

import './ProfilePreview.styles.scss';
import './ProfilePreview.styles.responsive.scss';

const ProfilePreview: React.FC = () => {
  const [submitting, setSumbitting] = useState(false);
  const baseClass = 'teacher-profile-preview';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.selectUser);
  const { values, dirtyFields } = useFormState();
  const { t } = useTranslation();

  const { mandatoryUnfinishedSteps } = useMandatoryUnfinishedStepsInfo();
  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );

  const routeToNavigate = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const navigateToEditProfile = () => {
    navigate(
      `/profile/${
        routeToNavigate ? 'edit/' + routesMapping[routeToNavigate] : 'edit'
      }`,
    );
  };

  const cardInfo = useMemo(() => {
    const data = {
      ...convertTeacherFormValuesIntoCard({
        ...user,
        ...values,
      } as TeacherFormValues & User),
      educationPhilosophyKeyUpdatedAt: dirtyFields?.educationPhilosophy
        ? new Date()
        : user?.educationPhilosophyKeyUpdatedAt,
    };

    return data;
  }, [dirtyFields?.educationPhilosophy, user, values]);

  const onSaveAndExit = async () => {
    const { imageFile, educationPhilosophy, ...restOfValues } = values;
    setSumbitting(true);

    if (dirtyFields['imageFile'] && imageFile) {
      await TeacherProfileDataService.submitTeacherProfileImage(
        imageFile[0],
        (userImageFile: ImageFile) =>
          dispatch(updateUser({ imageFile: userImageFile } as User)),
        (errorMessage: string) => dispatch(popError(errorMessage)),
      );
    }
    if (dirtyFields['educationPhilosophy'] && educationPhilosophy) {
      await TeacherProfileDataService.submitTeacherEducationPhilosophy(
        educationPhilosophy,
        (educationPhilosophyUrl: string) =>
          dispatch(
            updateUser({
              educationPhilosophyUrl: educationPhilosophyUrl,
            } as User),
          ),
        (errorMessage: string) => dispatch(popError(errorMessage)),
      );
    }

    const changes = Object.keys(restOfValues)
      .filter((key) => dirtyFields[key])
      .reduce((acc, curr) => ({ ...acc, [curr]: restOfValues[curr] }), {});

    const submitObject = await TeacherProfileDataService.getTeacherSubmitObject(
      changes as TeacherFormValues,
    );

    const updateResult = await dispatch(
      updateTeacherUser({
        ...JSON.parse(JSON.stringify(submitObject)),
        filledSteps: finishedRoutes,
      } as User),
    );

    if (updateTeacherUser.fulfilled.match(updateResult)) {
      dispatch(popSuccess(t('profileUpdateSuccess')));
      navigate('/profile');
    } else {
      dispatch(popError(t(updateResult.payload as string)));
    }
    dispatch(teacherProfileBuilderActions.statusUpdate('SUBMITTED'));
    setSumbitting(false);
  };

  const publishProfile = async () => {
    await confirm({
      title: t('publishProfileModal.title'),
      confirmBtnText: t('publishProfileModal.button'),
      showCancel: false,
      onSubmit: () => {
        api.auth
          .publishTeacherProfile()
          .then(({ data }) => {
            dispatch(updateTeacherUser(data as Teacher));
            GtmService.teacherFinishSettingProfile();
            dispatch(popSuccess(t('teacherProfilePublishSuccess')));
            dispatch(loadCurrentUser());
          })
          .catch((err) => {
            dispatch(popServerError(err));
          });
      },
    });
  };
  const displayPublishButton =
    mandatoryUnfinishedSteps.length === 0 && user.profileStatus !== 'Published';

  return (
    <MainLayout
      className={classNames('dashboard', `${baseClass}__wrapper`)}
      headerShadow
    >
      <div className={baseClass}>
        <div className={`${baseClass}__actions`}>
          <p>{t('Profile.Header.title')}</p>
          <div>
            <Button variant="outline" onClick={navigateToEditProfile}>
              {t('teacherProfileBuilder.actions.returnToEdit')}
            </Button>
            <Button
              variant={!displayPublishButton ? 'solid' : 'outline'}
              onClick={onSaveAndExit}
              disabled={submitting}
            >
              {t('saveAndExit')}
            </Button>
            {displayPublishButton ? (
              <Button onClick={publishProfile}>{t('publish')}</Button>
            ) : undefined}
          </div>
        </div>
        {mandatoryUnfinishedSteps.length > 0 && <ProfileNotReadyBanner />}
        <Profile cardInfo={cardInfo} />
      </div>
    </MainLayout>
  );
};

export default ProfilePreview;
