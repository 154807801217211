import { useTranslation } from 'react-i18next';
import {
  MatchedPositionsSubscription,
  TeacherMarketingSubscription,
} from 'searchality-data';
import { CommunicationSettingData } from 'types';

export default (): CommunicationSettingData[] => {
  const { t } = useTranslation();
  return [
    {
      id: '1',
      title: t('Communication.essentialInformation'),
      type: 'toggle',
      hint: t('Communication.essentialInformationHint'),
      list: [
        {
          name: 'personalAndSecurityInformation',
          label: t('Communication.personalAndSecurityInformation'),
        },
      ],
    },
    {
      id: '2',
      title: t('Communication.marketingEmails'),
      type: 'group',
      name: 'teacherMarketingSubscription',
      list: Object.entries(TeacherMarketingSubscription).map(
        ([key, value]) => ({
          label: t(`Communication.${key}`),
          value,
        }),
      ),
    },
    {
      id: '3',
      title: t('Communication.matchedPositions'),
      type: 'group',
      name: 'matchedPositionsSubscription',
      list: Object.entries(MatchedPositionsSubscription).map(
        ([key, value]) => ({
          label: t(`Communication.MatchedPositions.${key}`),
          value,
        }),
      ),
    },
  ];
};
