import React, { useMemo } from 'react';
import {
  RouteProps,
  Navigate,
  useLocation,
  useParams,
  matchPath,
} from 'react-router-dom';
import credentialsService from 'services/credentialsService';
import store, { RootState } from 'store';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role } from 'searchality-data';
import authService from 'services/authService';

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
  fallbackRoute?: string;
  authorizedRoles?: Array<Role>;
  validate?: (state?: RootState, params?: Record<string, string>) => string;
} & Omit<RouteProps, 'element'> & {
    element: React.FC | React.ReactNode;
  };

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized = false,
    onlyPublic = false,
    element,
    authorizedRoles,
    validate,
  } = props;

  const user = useAppSelector(authSelectors.selectUser);
  const params = useParams();
  const isLoggedIn = !!credentialsService.token;
  const { pathname } = useLocation();
  const redirectInvalid = validate?.({ ...store.getState() }, params);

  const creatingSchooldInProgress = matchPath('/create-school/*', pathname);

  const isEmailVerificationInProgress = matchPath('/confirm-email', pathname);

  const shouldVerifyEmail = matchPath('/verify-email', pathname);

  const finalRoute = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const authToken = queryParams.get('authToken');
    const utmContent = queryParams.get('utm_content');

    if (((authorized || authorizedRoles) && !isLoggedIn) || redirectInvalid) {
      return (
        <Navigate
          to={{
            pathname: redirectInvalid || `/auth/signin`,
            ...((authToken || utmContent) && {
              search: queryParams.toString(),
            }),
          }}
          state={{ from: pathname }}
          replace
        />
      );
    }

    if (authorizedRoles) {
      const userAllowed =
        user && authService.checkRolesForUser(user, authorizedRoles);
      if (!userAllowed) {
        return <Navigate to="/forbidden" replace />;
      }
    }

    if (isLoggedIn) {
      if (onlyPublic) {
        return <Navigate to="/" />;
      }

      if (
        !user?.emailConfirmed &&
        !isEmailVerificationInProgress &&
        !shouldVerifyEmail
      ) {
        return <Navigate to="/confirm-email" />;
      }
      if (
        user?.emailConfirmed &&
        user?.role === Role.AccountOwner &&
        !user?.schoolGroupId &&
        !creatingSchooldInProgress
      ) {
        return <Navigate to="/create-school" />;
      }
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }

    return element as React.ReactElement;
  }, [
    authorized,
    authorizedRoles,
    isLoggedIn,
    redirectInvalid,
    element,
    user,
    pathname,
    onlyPublic,
    isEmailVerificationInProgress,
    shouldVerifyEmail,
    creatingSchooldInProgress,
  ]);

  return finalRoute;
};

export default EnhancedRoute;
