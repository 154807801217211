import React, { useCallback, useEffect, useState } from 'react';
import PaymentMethodsContext from './PaymentMethods.context';
import api from 'api';
import { PaymentInfo } from 'types';

type BannersProps = {
  children: JSX.Element;
};

const PaymentMethods: React.FC<BannersProps> = (props) => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const updatePaymentInfo = useCallback(
    (paymentInfo: PaymentInfo) => setPaymentInfo(paymentInfo),
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.schools.getPaymentInfo();
        setPaymentInfo(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <PaymentMethodsContext.Provider
      value={{
        isLoading,
        paymentInfo,
        updatePaymentInfo,
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};

export default PaymentMethods;
