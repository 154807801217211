import env from 'env';
import ReactDOM from 'react-dom/client';

export const resizeSearchalityWidget = (
  iframeDocument: Document,
  iframe: HTMLIFrameElement,
) => {
  const bodyChild = iframeDocument?.body?.querySelector('div');

  if (!bodyChild) return;

  iframe.style.height = `${bodyChild.scrollHeight + 40}px`;
};

export const createWidgetIframe = () => {
  const iframe = document.createElement('iframe');
  iframe.style.width = '100%';
  iframe.frameBorder = '0';
  iframe.title = 'Searchality Widget';
  iframe.id = 'searchality-iframe';

  return iframe;
};

export const initWidget = (iframe: HTMLIFrameElement) => {
  const iframeDocument =
    iframe.contentDocument || iframe.contentWindow.document;

  createResizeObserver(iframeDocument, iframe);

  let cssLink = document.getElementById(
    'searchality-widget-css',
  ) as HTMLLinkElement;

  if (!cssLink) {
    cssLink = createLinkElement();
  }

  iframeDocument.head.appendChild(cssLink);

  return ReactDOM.createRoot(iframeDocument.body);
};

export const createResizeObserver = (
  iframeDocument: Document,
  iframe: HTMLIFrameElement,
) => {
  const resizeObserver = new MutationObserver(() => {
    resizeSearchalityWidget(iframeDocument, iframe);
  });

  resizeObserver.observe(iframeDocument.body, {
    childList: true,
    subtree: true,
    attributes: true,
  });
};

export const createLinkElement = () => {
  const cssLink = document.createElement('link');
  cssLink.href = `${env.WIDGET_ENDPOINT}/main.css`;
  cssLink.rel = 'stylesheet';

  return cssLink;
};
