import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Select from 'components/Select';
import { FieldRenderProps } from 'react-final-form';
import Tag from 'components/Tag';
import { ReactComponent as CloseDialog } from 'icons/CloseDialog.icon.svg';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import Input from 'components/Input';
import { Button } from 'ncoded-component-library';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './MultipleSelectField.styles.scss';

type MultipleSelectFieldProps = FieldRenderProps<string[], HTMLElement> & {
  withCustomOptions?: boolean;
  options: OptionValue<string>[];
};

const MultipleSelectField: React.FC<MultipleSelectFieldProps> = (props) => {
  const {
    className,
    options: initialOptions,
    input: { onChange, onBlur, value },
    meta: { error, touched },
    withCustomOptions = false,
    ...rest
  } = props;

  const [shouldDisplayInput, setShouldDisplayInput] = useState(false);
  const [newOption, setNewOption] = useState('');

  const [options, setOptions] = useState(
    value
      ? initialOptions?.filter((opt) => !value?.some((v) => v === opt.value))
      : initialOptions,
  );

  const handleChange = useCallback(
    (optionValue: string) => {
      const option = options.find((el) => el.value === optionValue);
      if (withCustomOptions) {
        setShouldDisplayInput(option.value === 'Other');
      }

      if (option.value !== 'Other') {
        onChange([...value, option.value]);
      }
      setOptions((prevState) =>
        prevState?.filter((opt) => opt.value !== optionValue),
      );
    },
    [onChange, options, value, withCustomOptions],
  );

  const handleRemove = useCallback(
    (optionValue: string) => {
      const newValue = value?.filter((el) => el !== optionValue);
      onChange(!newValue.length ? '' : newValue);
      const oldOption = initialOptions.find((el) => el.value === optionValue);
      setOptions((prevState) =>
        oldOption
          ? initialOptions?.filter((el) => !newValue.includes(el.value))
          : prevState,
      );
    },
    [initialOptions, onChange, value],
  );

  const addNewOption = useCallback(() => {
    setOptions((prev) => [...prev, { label: newOption, value: newOption }]);
    onChange([...value, newOption]);
    setNewOption('');
    setShouldDisplayInput(false);
    setOptions((prevState) =>
      prevState?.filter((opt) => opt.value !== newOption),
    );
  }, [newOption, onChange, value]);

  const tags = useMemo(() => {
    if (!value) {
      return null;
    }

    return (
      <div className="multiple-select-field__tags">
        {value.map((el) => {
          let label;
          if (withCustomOptions) {
            label = el;
          } else {
            label = initialOptions?.find(
              (option) => option.value === el,
            )?.label;
          }

          return (
            <Tag
              key={el}
              text={
                typeof label === 'string' ? (
                  label
                ) : (
                  <>
                    {label?.icon}
                    {label?.text}
                  </>
                )
              }
              className="small"
            >
              <button
                onClick={() => handleRemove(el)}
                className="svg-button-wrapper"
              >
                <CloseDialog />
              </button>
            </Tag>
          );
        })}
      </div>
    );
  }, [handleRemove, initialOptions, value, withCustomOptions]);

  const classes = classNames('multiple-select-field', className);

  return (
    <div className={classes}>
      <Select
        error={error && touched ? error : ''}
        options={options}
        onClose={onBlur}
        {...rest}
        multiple={false}
        onChange={({ value }) => handleChange(value as string)}
      />
      {shouldDisplayInput && (
        <div className="multiple-select-field__custom-option">
          <Input
            value={newOption}
            label="Enter new option ..."
            onChange={(e) => setNewOption(e.target.value)}
          />
          <Button icon={<AddIcon />} onClick={addNewOption} />
        </div>
      )}
      {tags}
    </div>
  );
};

export default MultipleSelectField;
