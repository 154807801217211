import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from './useAppSelector';

export default function () {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);
  const { t } = useTranslation();
  if (schoolGroup) {
    return schoolGroup?.type === 'public school'
      ? t('districtSmall')
      : t('groupSmall');
  }
  return '';
}
