import React, { useMemo } from 'react';
import classNames from 'classnames';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { SchoolGroupStructureType } from 'searchality-data';
import { Button } from 'ncoded-component-library';
import { MongoWhere } from 'types';
import { Params } from 'react-router-dom';
import useRolesSelectOptions from 'router/subrouters/Dashboard/hooks/useRolesSelectOptions';
import useSchoolFromYourGroup from '../../../Vacancies/hooks/useSchoolFromYourGroup';
import { ReactComponent as SelectArrowIcon } from 'icons/SelectArrow.icon.svg';
import useMatchMedia from 'hooks/useMatchMedia';

import './MembersFilters.styles.scss';
import './MembersFilters.styles.responsive.scss';

type MembersFiltersProps = {
  className?: string;
  onFilter: (key: string, value: MongoWhere) => void;
  onReset: () => void;
  filteredBy?: any;
  filterParams: Partial<Params>;
};

const MembersFilters: React.FC<MembersFiltersProps> = (props) => {
  const { className, onFilter, filteredBy, onReset } = props;

  const { t } = useTranslation();

  const {
    schoolGroup: { structureType, schools },
  } = useAppSelector(authSelectors.selectUser);

  const classes = classNames('members-filters', className);

  const permissionOptions = useRolesSelectOptions();

  const schoolOptions = useSchoolFromYourGroup(schools);

  const isPhablet = useMatchMedia('isPhablet');

  const { schoolIds, role } = filteredBy || {};

  const selectedSchool = useMemo(() => {
    if (!schoolIds) return;
    return schoolIds['$in'][0];
  }, [schoolIds]);

  const selectedRole = useMemo(() => {
    if (!role) return;
    return role['$in'][0];
  }, [role]);

  return (
    <div className={classes}>
      <div className="members-filters__select-wrapper">
        <Select
          options={permissionOptions}
          placeholder={t('Members.teamPermisson')}
          value={selectedRole}
          multiple={false}
          onChange={({ value }) => onFilter('role', { $in: [value as string] })}
          icon={<SelectArrowIcon />}
          renderAsPortal={!isPhablet}
          portalClassName="members-filters__filter-portal"
        />
        {structureType !== SchoolGroupStructureType.STANDALONE &&
          schoolOptions.length > 1 && (
            <Select
              options={schoolOptions}
              placeholder={t('Members.schoolWithinGroup')}
              value={selectedSchool}
              multiple={false}
              onChange={({ value }) =>
                onFilter('schoolIds', { $in: [value as string] })
              }
              icon={<SelectArrowIcon />}
              portalClassName="members-filters__filter-portal"
              renderAsPortal={!isPhablet}
            />
          )}
      </div>
      <Button
        variant="link"
        onClick={onReset}
        className={'members-filters__reset'}
      >
        {t('resetFilters')}
      </Button>
    </div>
  );
};

export default MembersFilters;
