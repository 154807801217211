import React from 'react';
import { useTranslation } from 'react-i18next';

import './CandidatePositionInfo.styles.scss';

type CandidatePositionInfoProps = {
  positionTitle: string;
  schoolName: string;
};

const CandidatePositionInfo: React.FC<CandidatePositionInfoProps> = ({
  positionTitle,
  schoolName,
}) => {
  const { t } = useTranslation();

  return (
    <section className="candidate-position-info">
      <p className="candidate-position-info__label">{t('candidateFor')}</p>
      <p className="candidate-position-info__name">{positionTitle}</p>
      <p className="candidate-position-info__school">{schoolName}</p>
    </section>
  );
};

export default CandidatePositionInfo;
