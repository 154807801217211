import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useSortByOptions = (onlyByName = false) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          label: t('oldestApplications'),
          value: 'appliedAt',
        },
        {
          label: t('newestApplications'),
          value: '-appliedAt',
        },
        {
          label: t('lastNameAZ'),
          value: 'lastName',
        },
        {
          label: t('lastNameZA'),
          value: '-lastName',
        },
      ].slice(onlyByName ? 2 : undefined),
    [onlyByName, t],
  );
};

export default useSortByOptions;
