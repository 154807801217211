import Banner from 'components/Banner';
import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProfileIcon } from 'icons/Profile.icon.svg';
import { ReactComponent as LanguageIcon } from 'icons/Language.icon.svg';
import { ReactComponent as HelpIcon } from 'icons/Help.icon.svg';
import { SEARCHALITY_CONTACT_MAIL } from 'constants/links';

import './VacancyCandidatesEmptyState.styles.scss';

const VacancyCandidatesEmptyState: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="vacancy-candidates-empty-state">
      <Banner
        title={t('vacancyCandidatesEmpty.banner1.title')}
        subtitle={t('vacancyCandidatesEmpty.banner1.subtitle')}
        noClose
        icon={<ProfileIcon />}
        iconColor="blue"
        isCollapsible
        collapsedContent={
          <ol>
            <li>{t('vacancyCandidatesEmpty.banner1.item1')}</li>
            <li>{t('vacancyCandidatesEmpty.banner1.item2')}</li>
            <li>{t('vacancyCandidatesEmpty.banner1.item3')}</li>
          </ol>
        }
      />
      <Banner
        title={t('vacancyCandidatesEmpty.banner2.title')}
        subtitle={t('vacancyCandidatesEmpty.banner2.subtitle')}
        noClose
        icon={<HelpIcon />}
        actions={
          <Button
            onClick={() =>
              window.open(`mailto:${SEARCHALITY_CONTACT_MAIL}`, '_blank')
            }
          >
            {t('vacancyCandidatesEmpty.contactSupport')}
          </Button>
        }
      />
      <Banner
        title={t('vacancyCandidatesEmpty.banner3.title')}
        noClose
        icon={<LanguageIcon />}
        iconColor="blue"
        collapsedContent={<p>{t('vacancyCandidatesEmpty.banner3.extra')}</p>}
        isCollapsible
        actions={
          <>
            <Button
              onClick={() =>
                window.open(`mailto:${SEARCHALITY_CONTACT_MAIL}`, '_blank')
              }
            >
              {t('vacancyCandidatesEmpty.contactSupport')}
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                window.open(`https://searchality.com/pricing`, '_blank')
              }
            >
              {t('seePricing')}
            </Button>
          </>
        }
      />
    </div>
  );
};

export default VacancyCandidatesEmptyState;
