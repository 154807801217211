import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicantStage } from 'searchality-data';

const useApplicantStageOptions = () => {
  const { t } = useTranslation();

  const applicantStage = useMemo(
    () =>
      Object.keys(ApplicantStage).map((key) => ({
        label: t(key),
        value: ApplicantStage[key as keyof typeof ApplicantStage],
      })),
    [t],
  );

  return [...applicantStage, { label: t('other'), value: 'Other' }];
};

export default useApplicantStageOptions;
