import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import SchoolProfileReview from './components/SchoolProfileReview/SchoolProfileReview.component';
import SchoolInformationPreview from './components/SchoolInformationPreview';
import PricingPreview from './components/PricingPreview/PricingPreview';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useForm } from 'react-final-form';

import './Review.styles.scss';

type ReviewProps = {
  className?: string;
};

const Review: React.FC<ReviewProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { submit, getState } = useForm();

  const classes = classNames('review', className);

  return (
    <form
      className={classes}
      onSubmit={(ev) => {
        ev.preventDefault();
        submit();
      }}
    >
      <div className="review__top-container">
        <GoBackButton to="../pricing-information" />
        <h1 className="review__top-container__title">
          {t('yourAccountIsReady')}
        </h1>
        <p>{t('accountSummary')}</p>
      </div>
      <div className="cards-array">
        <SchoolProfileReview />
        <SchoolInformationPreview />
        <PricingPreview />
      </div>
      <Button type="submit" disabled={getState()?.submitting}>
        {t('getStarted')}
      </Button>
    </form>
  );
};

export default Review;
