import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import RoleStep from '../RoleStep';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';

type InviteMemberModalProps = {
  onSubmit?: (args: any) => void;
  onClose?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
} & OverlayProps;

const InviteMemberModal: ForwardRefRenderFunction<
  ModalRef,
  InviteMemberModalProps
> = (props, ref) => {
  const { onSubmit = () => {}, onCancel, children } = props;

  const { t } = useTranslation();

  const { submitting, invalid } = useFormState();

  return (
    <Modal
      ref={ref}
      onX={() => {
        props?.onClose?.();
      }}
      title={t('inviteMemberTitle')}
      className={'invite-member-modal'}
      footer={
        <>
          <Button variant="outline" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            type="submit"
            disabled={submitting || invalid}
          >
            {t('invite')}
          </Button>
        </>
      }
    >
      <RoleStep />
      {children}
    </Modal>
  );
};

export default forwardRef(InviteMemberModal);
