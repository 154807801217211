import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useEffect, useState } from 'react';
import { ApplicationStatus } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { popServerError } from 'store/slices/popNotifications.slice';
import { Params } from 'types';
import utils from 'utils';

export default (params?: Partial<Params>): [any, boolean] => {
  const [filters, setFilters] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { _id: id } = useAppSelector(authSelectors.selectUser);

  const fetchFilters = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.applications.getApplicationsFilters(
        id,
        params,
      );
      const { subjects, divisions, statuses } = data;

      setFilters({
        statuses: statuses?.reduce(
          (acc: Record<string, string>, curr: string) => ({
            ...acc,
            [utils.getKeyByValue(ApplicationStatus, curr)]: curr,
          }),
          {},
        ),
        subjects,
        divisions,
      });
    } catch (er) {
      dispatch(popServerError(er));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, id, params]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  return [filters, isLoading];
};
