import React, { useMemo } from 'react';
import classNames from 'classnames';
import useCallbackRef from 'hooks/useCallbackRef';
import useResizeAnimation from 'hooks/useResizeAnimation';
import { Form } from 'react-final-form';
import { Outlet, useLocation } from 'react-router-dom';
import SideNavigation from 'components/SideNavigation';
import { CreateSchoolValues } from 'types';

export type CreateSchoolFormProps = {
  onSubmit: (values: CreateSchoolValues) => void;
  initialValues: CreateSchoolValues;
};
const CreateSchoolForm: React.FC<CreateSchoolFormProps> = (props) => {
  const { onSubmit, initialValues } = props;
  const [section, ref] = useCallbackRef<HTMLElement>();

  const { pathname } = useLocation();

  const { classes: animationClasses } = useResizeAnimation({
    element: section,
    duration: 240,
  });

  const noSidebar = useMemo(
    () => pathname.includes('school-review'),
    [pathname],
  );

  return (
    <section
      ref={ref}
      className={classNames('create-school__layout', animationClasses, {
        'create-school__layout--no-sidenav': noSidebar,
      })}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={() => (
          <>
            <SideNavigation />
            <main>
              <Outlet />
            </main>
          </>
        )}
      />
    </section>
  );
};

export default CreateSchoolForm;
