import { useTranslation } from 'react-i18next';
import { DegreeLevel } from 'searchality-data';

const useDegreeLevelOptions = () => {
  const { t } = useTranslation();

  return Object.keys(DegreeLevel).map((key) => ({
    label: t(`DegreeLevel.${key}`),
    value: DegreeLevel[key as keyof typeof DegreeLevel],
  }));
};

export default useDegreeLevelOptions;
