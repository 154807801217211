import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { required } from 'validations';
import { useUpdateCandidateMutation } from 'api/canidates.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ApplicationSchoolStatus } from 'searchality-data';
import CheckboxField from 'components/CheckboxField';

import './AcceptCandidateModal.styles.scss';
import InputField from 'components/InputField';

type AcceptCandidateModalProps = {
  className?: string;
  candidateId: string;
  firstName: string;
  positionTitle: string;
  schoolName: string;
};

const AcceptCandidateModal: ForwardRefRenderFunction<
  ModalRef,
  AcceptCandidateModalProps
> = (props, ref) => {
  const { className, candidateId, firstName, positionTitle, schoolName } =
    props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [updateCandidate] = useUpdateCandidateMutation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('proceed-applicant-modal', className);

  const handleSubmit = useCallback(
    async (values: {
      message: string;
      includeEmails: boolean;
      emails: string;
    }) => {
      updateCandidate({
        candidateId,
        message: values.message,
        candidateStatus: ApplicationSchoolStatus.IN_PROCESS,
        ...(values.includeEmails &&
          values.emails?.length && {
            ccEmails: values?.emails?.split(',')?.map((email) => email?.trim()),
          }),
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('CandidateAccepted')));
          modal.close();
        })
        .catch((error) => dispatch(popServerError(error)));
    },
    [candidateId, dispatch, modal, t, updateCandidate],
  );

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('Profile.AcceptCandidateModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          message: `${t('Profile.AcceptCandidateModal.initial1', {
            firstName,
          })}${schoolName}${t('Profile.AcceptCandidateModal.initial2', {
            positionTitle,
          })}`,
        }}
        render={({
          handleSubmit,
          submitting,
          invalid,
          values: { includeEmails },
        }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('Profile.AcceptCandidateModal.description')}</p>
            <Field
              name="message"
              component={TextAreaField}
              validate={required()}
              placeholder={t('Profile.AcceptCandidateModal.placeholder')}
              required
            />
            <Field
              name="includeEmails"
              component={CheckboxField}
              label={t('Profile.AcceptCandidateModal.checkbox')}
              type="checkbox"
              isStyledDefault
            />
            <Field
              name="emails"
              component={InputField}
              label={t('Profile.AcceptCandidateModal.emails')}
              disabled={!includeEmails}
            />
            <div className="actions">
              <Button variant="outline" onClick={() => modal.close()}>
                {t('cancel')}
              </Button>
              <Button disabled={submitting || invalid} type="submit">
                {t('Profile.AcceptCandidateModal.confirm')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(AcceptCandidateModal);
