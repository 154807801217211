import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PositionDivision } from 'searchality-data';

const usePositionDivisionOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.entries(PositionDivision).map(({ 0: key, 1: value }) => {
        return {
          label: t(`SchoolLevel.${key}`),
          value: PositionDivision[key as keyof typeof PositionDivision],
        };
      }),
    [t],
  );
};

export default usePositionDivisionOptions;
