import React, { useMemo } from 'react';
import classNames from 'classnames';
import { UploadedReqDocument } from 'models/Candidate';
import { ReactComponent as DocumentIcon } from 'icons/Documents.icon.svg';
import { ReactComponent as PhotoIcon } from 'icons/Photo.icon.svg';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';

import './UploadedDocument.styles.scss';
import './UploadedDocument.styles.responsive.scss';

type UploadedDocumentProps = {
  className?: string;
  document: UploadedReqDocument;
  name: string;
  description: string;
};

const UploadedDocument: React.FC<UploadedDocumentProps> = (props) => {
  const { className, document, name, description } = props;

  const { url, fileType, isDeleted } = document;

  const uploadedFileIcon = useMemo(
    () => (fileType === 'application/pdf' ? <DocumentIcon /> : <PhotoIcon />),
    [fileType],
  );

  const classes = classNames(
    'uploaded-document',
    { 'uploaded-document--deleted': isDeleted },
    className,
  );

  return (
    <div className={classes} onClick={() => window.open(url, '_blank')}>
      {uploadedFileIcon}
      <div className="uploaded-document__info">
        <p className="uploaded-document__info__name">{name}</p>
        <p className="uploaded-document__info__description">{description}</p>
      </div>
      <DoneIcon className="uploaded-document__done" />
    </div>
  );
};

export default UploadedDocument;
