import React, { useRef } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'ncoded-component-library';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';

import './QuestionVideoTag.styles.scss';

type QuestionVideoTagProps = {
  className?: string;
  value: string;
  description: string;
  handleDelete?: () => void;
};

const QuestionVideoTag: React.FC<QuestionVideoTagProps> = (props) => {
  const { className, value, description, handleDelete } = props;

  const { t } = useTranslation();
  const dropdownRef = useRef<DropdownRef>();

  const classes = classNames('question-video-tag', className);

  return (
    <div className={classes}>
      <div className="question-video-tag__info">
        <p className="question-video-tag__info__value">{value}</p>
        <p className="question-video-tag__info__description">{description}</p>
      </div>
      {!!handleDelete && (
        <Dropdown
          trigger={
            <div
              className="question-video-tag__dots"
              onClick={(e) => e.stopPropagation()}
            >
              <span
                className="question-video-tag__dots__dot"
                onClick={(e) => e.stopPropagation()}
              />
              <span className="question-video-tag__dots__dot" />
              <span className="question-video-tag__dots__dot" />
            </div>
          }
          ref={dropdownRef}
        >
          <div
            className="question-video-tag__actions"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className="question-video-tag__actions__action"
              onClick={() => {
                dropdownRef.current.setIsOpen(false);
                handleDelete();
              }}
            >
              <DeleteIcon />
              <p>{t('remove')}</p>
            </span>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default QuestionVideoTag;
