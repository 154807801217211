import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { getFileFromURL } from 'components/ImageCropper/utils';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import api from 'api';
import { School } from 'models/School';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './UploadSchoolLogo.styles.scss';
import './UploadSchoolLogo.styles.responsive.scss';

type UploadSchoolLogoProps = {
  className?: string;
  schoolId?: string;
};

const UploadSchoolLogo: React.FC<UploadSchoolLogoProps> = (props) => {
  const { className, schoolId } = props;

  const [initialValues, setInitialValues] = useState<{ logoImage: File[] }>({
    logoImage: [],
  });

  const { t } = useTranslation();

  const user = useAppSelector(authSelectors.selectUser);
  const dispatch = useAppDispatch();

  const baseClass = 'upload-school-logo';
  const classes = classNames(baseClass, className);

  const handleSubmit = useCallback(
    async ({ logoImage }: { logoImage: File[] }) => {
      let school: School;
      try {
        if (logoImage?.length) {
          const res = await api.user.uploadSchoolLogo(logoImage[0], schoolId);
          school = res.data;
        } else {
          const res = await api.user.deleteSchoolLogo(schoolId);
          school = res.data;
        }

        const schools = user.schools.map((s) =>
          s._id === schoolId ? school : s,
        );

        dispatch(
          updateUser({
            ...user,
            schools,
            schoolGroup: { ...user.schoolGroup, schools },
          }),
        );
        dispatch(popSuccess(t('successfullyUploadLogo')));
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, schoolId, t, user],
  );

  const fetchSchoolLogo = useCallback(async () => {
    const school = user?.schools.find((school) => school?._id === schoolId);

    if (school?.imageFile) {
      const file = await getFileFromURL(
        school.imageFile.url,
        'logo',
        school?.imageFile?.format,
      );
      setInitialValues({ logoImage: [file] });
    } else {
      setInitialValues({ logoImage: [] });
    }
  }, [schoolId, user?.schools]);

  useEffect(() => {
    fetchSchoolLogo();
  }, [fetchSchoolLogo]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        pristine,
        values,
        submitting,
        initialValues,
      }) => {
        return (
          <form className={classes} onSubmit={handleSubmit}>
            <p className={`${baseClass}__title`}>{t('schoolLogo')}</p>
            <Field
              name="logoImage"
              render={(props) => {
                const {
                  input: { value, onChange },
                } = props;

                return (
                  <DropzoneProfilePhoto
                    value={value?.length ? value[0] : null}
                    setFileCallback={(file: File) =>
                      onChange(file ? [file] : [])
                    }
                    cropShape="rect"
                    aspect={5 / 3}
                    maxSize={1048576}
                    accept={{
                      'image/svg+xml': [],
                      'image/png': [],
                      'image/jpeg': [],
                    }}
                  />
                );
              }}
            />
            <div className={`${baseClass}__hint-container`}>
              <InformationIcon />
              <p>{t('schoolLogoHint')}</p>
            </div>
            <Button
              type="submit"
              disabled={
                pristine ||
                (!initialValues?.logoImage?.length &&
                  !values?.logoImage?.length) ||
                submitting
              }
            >
              {t('saveLogo')}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default UploadSchoolLogo;
