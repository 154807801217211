import useAppDispatch from 'hooks/useAppDispatch';
import React, { useCallback } from 'react';
import { Role } from 'searchality-data';
import useAssociationRoleOptions from '../../hooks/useAssociationRoleOptions';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { useUpdateMemberPermissionMutation } from 'api/associations.api';

import './AssociationRolesSelect.styles.scss';
import Select from 'components/Select';
import { loadCurrentUser } from 'store/slices/auth.slice';

type AssociationRolesSelectProps = {
  isReadonly?: boolean;
  currentRole: Role;
  userId: string;
};

const AssociationRolesSelect: React.FC<AssociationRolesSelectProps> = (
  props,
) => {
  const { currentRole, isReadonly, userId } = props;

  const permissionOptions = useAssociationRoleOptions();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [updateMemberPermission] = useUpdateMemberPermissionMutation();

  const updateRole = useCallback(() => {
    updateMemberPermission({ newOwnerId: userId })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('roleSuccessfullyChanged')));
        dispatch(loadCurrentUser());
      })
      .catch(() => dispatch(popError(t('roleChangeFail'))));
  }, [dispatch, t, updateMemberPermission, userId]);

  const updateRoleToAccountOwner = useCallback(async () => {
    await confirm({
      title: t('Members.changeOwnerRoleTitle'),
      className: 'change-owner-modal',
      content: (
        <p className="change-owner-role-description">
          {t('Members.changeOwnerRoleAssociationDesc')}
        </p>
      ),
      confirmBtnText: t('Members.changeOwnerRoleTitleConfirm'),
      onSubmit: () => {
        updateRole();
      },
    });
  }, [t, updateRole]);

  const onMemberRoleChange = useCallback(
    ({ value: selectedRole }: any) => {
      if (selectedRole === Role.AssociationOwner) {
        updateRoleToAccountOwner();
      }
    },
    [updateRoleToAccountOwner],
  );

  if (isReadonly) {
    return <strong>{currentRole}</strong>;
  }

  return (
    <Select
      options={permissionOptions}
      value={currentRole}
      onChange={onMemberRoleChange}
    />
  );
};

export default AssociationRolesSelect;
