import classNames from 'classnames';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import React, { ReactNode, useMemo } from 'react';

import './MainLayout.styles.scss';
import './MainLayout.styles.responsive.scss';

import variables from 'scss/layout.module.scss';

type MainLayoutContextValue = {
  setMainClassName: React.Dispatch<React.SetStateAction<string>>;
};

export const MainLayoutContext = React.createContext<MainLayoutContextValue>({
  setMainClassName: () => {},
});

type MainProps = {
  children: ReactNode;
  className?: string;
  sidebarComponent?: any;
  isGreen?: boolean;
  headerShadow?: boolean;
  isTeacher?: boolean;
  dontShowHeader?: boolean;
};

export const MainLayout: React.FC<MainProps> = ({
  className: pClassName,
  children,
  sidebarComponent,
  isGreen = false,
  headerShadow = false,
  isTeacher = false,
  dontShowHeader,
}) => {
  const isAuth = pClassName?.includes('auth');

  const background = useMemo(() => {
    if (isGreen)
      return 'radial-gradient(112.74% 222.95% at 101.53% 6.84%, #00416A 0%, #007078 49.75%, #BDD8D9 100%)';

    if (isTeacher) {
      return variables['color-primary-dark-blue'];
    }

    return variables['color-gray-f6'];
  }, [isGreen, isTeacher]);

  return (
    <div
      className={classNames(pClassName, 'main-layout', {
        'auth-background': isAuth && !isTeacher,
      })}
    >
      <style>{`#root {background:${background};}`}</style>
      {!dontShowHeader && <Header shadow={headerShadow} />}
      <main>
        {sidebarComponent && <Sidebar>{sidebarComponent}</Sidebar>}
        <article>{children}</article>
      </main>
    </div>
  );
};

export default MainLayout;
