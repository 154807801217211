import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { Button, Select } from 'ncoded-component-library';
import { TargetPosition } from '../../models/TeacherFormValues';
import usePositionTypeOptions from '../../../Vacancies/pages/VacancyWizard/hooks/usePositionTypeOptions';
import usePositionDivisionOptions from '../../../Vacancies/pages/VacancyWizard/hooks/usePositionDivisionOptions';
import useGradeOptions from '../../../Vacancies/pages/VacancyWizard/hooks/useGradeOptions';
import {
  PositionDivision,
  PositionType,
  SubjectGradeLevel,
} from 'searchality-data';
import { useTranslation } from 'react-i18next';
import Tag from 'components/Tag';
import { ReactComponent as CloseDialog } from 'icons/CloseDialog.icon.svg';
import useTeacherLanguageOptions from '../../../Vacancies/pages/VacancyWizard/hooks/useTeacherLanguageOptions';
import Input from 'components/Input';

import './TargetPositionsField.styles.scss';
import './TargetPositionsField.styles.responsive.scss';

type TargetPositionsFieldProps = FieldRenderProps<
  TargetPosition[],
  HTMLElement
> & {
  className?: string;
};

const TargetPositionsField: React.FC<TargetPositionsFieldProps> = (props) => {
  const { className, input, meta } = props;

  const { t } = useTranslation();

  const [positionType, setPositionType] = useState<PositionType>();
  const [positionDivision, setPositionDivision] = useState<PositionDivision>();
  const [subjectGradeLevel, setSubjectGradeLevel] = useState<string>();
  const [foreignLanguage, setForeignLanguage] = useState<string>();
  const [foreignLanguageOther, setForeignLanguageOther] = useState<string>();

  const classes = classNames('target-positions-field', className);

  const positionTypeOptions = usePositionTypeOptions();
  const positionDivisionOptions = usePositionDivisionOptions();
  const gradeSubjectLevelOptions = useGradeOptions(
    positionType as PositionType,
    positionDivision as PositionDivision,
  );
  const teacherLanguageOptions = useTeacherLanguageOptions();

  const { error, touched } = meta;

  const { onBlur, onChange, value } = input;

  const someOfValuesEmpty = useMemo(
    () =>
      !positionDivision ||
      !positionType ||
      !subjectGradeLevel ||
      (subjectGradeLevel ===
        SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE &&
        !foreignLanguage) ||
      (foreignLanguage === 'Other' && !foreignLanguageOther),
    [
      foreignLanguage,
      foreignLanguageOther,
      positionDivision,
      positionType,
      subjectGradeLevel,
    ],
  );

  const clearStates = useCallback(() => {
    setPositionDivision(undefined);
    setPositionType(undefined);
    setSubjectGradeLevel(undefined);
    setForeignLanguage(undefined);
    setForeignLanguageOther(undefined);
  }, []);

  const changeValue = useCallback(() => {
    onChange([
      ...(value ? value : []),
      {
        positionDivision,
        positionType,
        subjectGradeLevel,
        ...(subjectGradeLevel ===
          SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE && {
          language: foreignLanguageOther ?? foreignLanguage,
        }),
      },
    ]);

    clearStates();
  }, [
    clearStates,
    foreignLanguage,
    foreignLanguageOther,
    onChange,
    positionDivision,
    positionType,
    subjectGradeLevel,
    value,
  ]);

  const handleAddTargetPosition = useCallback(() => {
    if (!value) {
      changeValue();

      return;
    }

    const doesAlreadyExist = value.some(
      (el) =>
        el.positionType === positionType &&
        el.positionDivision === positionDivision &&
        el.subjectGradeLevel === subjectGradeLevel &&
        (el.language === foreignLanguageOther ?? foreignLanguage),
    );

    if (doesAlreadyExist) {
      clearStates();

      return;
    }

    changeValue();
  }, [
    changeValue,
    clearStates,
    foreignLanguage,
    foreignLanguageOther,
    positionDivision,
    positionType,
    subjectGradeLevel,
    value,
  ]);

  const handleRemove = useCallback(
    (el: TargetPosition) => {
      const newValues = value?.filter(
        (arrayEl) => JSON.stringify(arrayEl) !== JSON.stringify(el),
      );

      onChange(newValues);
    },
    [onChange, value],
  );

  const tags = useMemo(() => {
    if (!value) {
      return null;
    }

    return (
      <div className="target-positions-field__tags">
        {value.map((el) => {
          return (
            <Tag
              key={JSON.stringify(el)}
              text={`${el.positionType} - ${el.positionDivision} - ${
                el.subjectGradeLevel
              }${el.language ? ` (${el.language}) ` : ''} `}
              className="small"
            >
              <button
                onClick={() => handleRemove(el)}
                className="svg-button-wrapper"
              >
                <CloseDialog />
              </button>
            </Tag>
          );
        })}
      </div>
    );
  }, [handleRemove, value]);

  useEffect(() => {
    if (
      subjectGradeLevel !==
        SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE &&
      (foreignLanguage || foreignLanguageOther)
    ) {
      setForeignLanguage(undefined);
      setForeignLanguageOther(undefined);
    }

    if (foreignLanguage !== 'Other' && foreignLanguageOther) {
      setForeignLanguageOther(undefined);
    }
  }, [foreignLanguage, foreignLanguageOther, subjectGradeLevel]);

  return (
    <div className={classes}>
      <Select
        options={positionTypeOptions}
        onClose={onBlur}
        multiple={false}
        onChange={({ value }) => {
          if (value !== positionType) {
            setSubjectGradeLevel(undefined);
          }
          setPositionType(value as PositionType);
        }}
        value={positionType}
        innerLabel={t('positionType')}
      />
      <Select
        options={positionDivisionOptions}
        onClose={onBlur}
        multiple={false}
        onChange={({ value }) => {
          if (value !== positionDivision) {
            setSubjectGradeLevel(undefined);
          }
          setPositionDivision(value as PositionDivision);
        }}
        value={positionDivision}
        innerLabel={t('schoolLevel')}
      />
      <Select
        options={gradeSubjectLevelOptions}
        onClose={onBlur}
        multiple={false}
        onChange={({ value }) => {
          setSubjectGradeLevel(value as string);
        }}
        value={subjectGradeLevel}
        innerLabel={
          positionType === PositionType.LEADERSHIP_ADMINISTRATOR
            ? t('leadershipRole')
            : t('RoleSubject')
        }
      />
      {subjectGradeLevel ===
        SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE && (
        <>
          <Select
            options={teacherLanguageOptions}
            onClose={onBlur}
            multiple={false}
            onChange={({ value }) => {
              setForeignLanguage(value as string);
            }}
            value={foreignLanguage}
            innerLabel={t('selectLanguage')}
          />
          {foreignLanguage === 'Other' && (
            <Input
              onBlur={onBlur}
              onChange={({ target }) => {
                setForeignLanguageOther(target?.value);
              }}
              value={foreignLanguageOther}
              label={t('pleaseEnterTeacherLanguage')}
            />
          )}
        </>
      )}
      <Button onClick={handleAddTargetPosition} disabled={someOfValuesEmpty}>
        {t('addTargetPosition')}
      </Button>
      {tags}
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default TargetPositionsField;
