import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default (availableDivisions: string[]) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      availableDivisions?.map((value) => {
        return {
          label: t(`SchoolLevelOptions.${value}`),
          value,
        };
      }),
    [availableDivisions, t],
  );

  return options;
};
