import React, { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { Button, Collapsible } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import useAppDispatch from 'hooks/useAppDispatch';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';
import { popError } from 'store/slices/popNotifications.slice';
import CheckoutContext from 'providers/Checkout/Checkout.context';
import { updateCouponValue } from 'store/slices/payment.slice';

import './DiscountCode.styles.scss';

type DiscountCodeProps = {
  className?: string;
};

const DiscountCode: React.FC<DiscountCodeProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { stripePreviewObject } = useContext(CheckoutContext);
  const { discount, isPromoCodeValid } = stripePreviewObject || {};

  const { submitting, pristine, invalid } = useFormState();
  const { submit, change } = useForm();

  const classes = classNames('discount-code', className);

  const handleRemoveCoupon = useCallback(() => {
    dispatch(updateCouponValue(undefined));
    change('discountCode', undefined);
  }, [change, dispatch]);

  useEffect(() => {
    if (isPromoCodeValid === false) {
      dispatch(popError(t('OrderSummary.promoCodeError')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripePreviewObject]);

  return (
    <Collapsible
      className={classes}
      trigger={
        <div className="discount-code__trigger">
          <p>{t('discountCode')}</p>
          <ArrowIcon />
        </div>
      }
    >
      <>
        <form
          className="discount-code__content"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <Field
            name="discountCode"
            component={InputField}
            label={t('introduceDiscountCode')}
            suffixNode={discount && <DoneIcon />}
          />
          <Button
            className={classNames({
              'discount-code__content__submit':
                discount || (pristine && isPromoCodeValid),
            })}
            type="submit"
            variant={discount ? 'outline' : 'solid'}
            disabled={submitting || invalid || (pristine && isPromoCodeValid)}
          >
            {!discount || !pristine ? t('applyNow') : t('applied')}
          </Button>
        </form>
        {discount && (
          <>
            <p className="discount-code__content__code-description">
              {discount?.coupon?.metadata?.description}
            </p>
            <Button variant="link" onClick={handleRemoveCoupon}>
              {t('removeCoupon')}
            </Button>
          </>
        )}
      </>
    </Collapsible>
  );
};

export default DiscountCode;
