import React from 'react';
import classNames from 'classnames';
import ToggleButton from 'components/ToggleButton';
import {
  useGetVacancyQuery,
  useSwitchMatchingMutation,
} from 'api/vacancies.api';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormFieldLabel from 'components/FormFieldLabel';

import './VacancyMatchingToggleSection.styles.scss';
import './VacancyMatchingToggleSection.styles.responsive.scss';

type VacancyMatchingToggleSectionProps = {
  className?: string;
};

const VacancyMatchingToggleSection: React.FC<
  VacancyMatchingToggleSectionProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { vacancyId } = useParams();
  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  const [switchMatching] = useSwitchMatchingMutation();

  const baseClass = 'vacancy-matching-toggle-section';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__text-container`}>
        <FormFieldLabel
          text={t('VacancyMatchingToggleSection.title')}
          hint={t('VacancyMatchingToggleSection.tippyContent')}
        />
        <p className={`${baseClass}__text-container__subtitle`}>
          {t('VacancyMatchingToggleSection.subtitle')}
        </p>
      </div>
      <ToggleButton
        onChange={async (e) => {
          await switchMatching(vacancyId).unwrap();
        }}
        checked={vacancy?.matchingInformation?.isMatchingActive}
      />
    </div>
  );
};

export default VacancyMatchingToggleSection;
