import React from 'react';
import classNames from 'classnames';
import { ProfileCardType } from 'models/ProfileCard.type';
import ProfilePreviewPdfSection from '../ProfilePreviewPdfSection';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { ReactComponent as ResumeIcon } from 'icons/Resume.icon.svg';
import { ReactComponent as CoverLetterIcon } from 'icons/Candidate.icon.svg';

import './ProfileNonAcademicWork.styles.scss';

type ProfileNonAcademicWorkProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  | 'resumeUrl'
  | 'coverLetterUrl'
  | 'resumeKeyUpdatedAt'
  | 'coverLetterKeyUpdatedAt'
>;

const ProfileNonAcademicWork: React.FC<ProfileNonAcademicWorkProps> = (
  props,
) => {
  const {
    className,
    coverLetterUrl,
    resumeUrl,
    resumeKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('profile-non-academic-work', className);

  return (
    <div className={classes}>
      <ProfilePreviewPdfSection
        title={t('Profile.WorkProfile.resume')}
        linkText={t('Profile.WorkProfile.resumeLink')}
        pdfUrl={resumeUrl}
        icon={<ResumeIcon />}
        updatedText={t('updateAt', {
          date: DateService.getUSADateFormat(resumeKeyUpdatedAt ?? new Date()),
        })}
      />
      {coverLetterUrl && (
        <ProfilePreviewPdfSection
          title={t('Profile.WorkProfile.coverLetter')}
          linkText={t('Profile.WorkProfile.coverLetterLink')}
          pdfUrl={coverLetterUrl}
          icon={<CoverLetterIcon />}
          updatedText={t('updateAt', {
            date: DateService.getUSADateFormat(
              coverLetterKeyUpdatedAt ?? new Date(),
            ),
          })}
        />
      )}
    </div>
  );
};

export default ProfileNonAcademicWork;
