import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from './components/Routes/Routes';
import { authRoutes } from './subrouters/Auth';
import { dashboardRoutes } from './subrouters/Dashboard';
import JobApplicationRoutes from './subrouters/JobApplication/JobApplication.routes';
import PageLoader from 'components/PageLoader';

const ApplicationUploadDocumentsNonMemberWrapper = React.lazy(
  () => import('./pages/ApplicationUploadDocumentsNonMemberWrapper'),
);
const DocumentsSubmitted = React.lazy(
  () => import('./pages/DocumentsSubmitted'),
);

const Dashboard = React.lazy(() => import('./subrouters/Dashboard'));
const AuthRouter = React.lazy(() => import('./subrouters/Auth/Auth.router'));
const VerifyEmail = React.lazy(() => import('router/pages/VerifyEmail'));
const ForbiddenPage = React.lazy(() => import('./pages/ForbiddenPage'));
const Error404 = React.lazy(() => import('./pages/Error404'));

const SuccessApplication = React.lazy(
  () => import('./pages/SuccessApplication'),
);
const PublicTeacherProfile = React.lazy(
  () => import('./pages/PublicTeacherProfile'),
);
const JobApplicationRouter = React.lazy(
  () => import('./subrouters/JobApplication/JobApplication.router'),
);
const TeacherSignupWidget = React.lazy(
  () => import('./pages/TeacherSignupWidget'),
);

export default [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<PageLoader />}>
        <AuthRouter />
      </Suspense>
    ),
    onlyPublic: true,
    routes: authRoutes,
  },
  {
    path: 'job-application',
    element: (
      <Suspense fallback={<PageLoader />}>
        <JobApplicationRouter />
      </Suspense>
    ),
    routes: JobApplicationRoutes,
  },
  {
    path: 'teacher-profile/:teacherId',
    element: (
      <Suspense fallback={<PageLoader />}>
        <PublicTeacherProfile />
      </Suspense>
    ),
  },
  {
    path: 'verify-email',
    element: (
      <Suspense fallback={<PageLoader />}>
        <VerifyEmail />
      </Suspense>
    ),
  },
  {
    path: 'upload-documents',
    element: (
      <Suspense fallback={<PageLoader />}>
        <ApplicationUploadDocumentsNonMemberWrapper />
      </Suspense>
    ),
  },
  {
    path: 'documents-submitted',
    element: (
      <Suspense fallback={<PageLoader />}>
        <DocumentsSubmitted />
      </Suspense>
    ),
  },
  {
    path: 'teacher-signup-widget',
    element: (
      <Suspense fallback={<PageLoader />}>
        <TeacherSignupWidget />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Dashboard />
      </Suspense>
    ),
    authorized: true,
    routes: dashboardRoutes,
  },
  {
    path: 'success',
    element: (
      <Suspense fallback={<PageLoader />}>
        <SuccessApplication />
      </Suspense>
    ),
    SuccessApplication,
    onlyPublic: true,
  },
  {
    path: 'forbidden',
    element: (
      <Suspense fallback={<PageLoader />}>
        <ForbiddenPage />
      </Suspense>
    ),
    ForbiddenPage,
    authorized: true,
  },
  {
    index: true,
    element: <Navigate to="auth" replace />,
  },
  {
    path: '/not-found',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Error404 />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<PageLoader />}>
        <Error404 />
      </Suspense>
    ),
  },
] as Array<ComplexRoute>;
