import { createAction, createSlice } from '@reduxjs/toolkit';

type ApplyingToJobState = {
  submitting: boolean;
};

const initialState: ApplyingToJobState = {
  submitting: false,
};

export const updateSubmitting = createAction<boolean>('jobs/updateSubmitting');

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSubmitting, (state, action) => {
      state.submitting = action.payload;
    });
  },
});

export default jobSlice.reducer;
