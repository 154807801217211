import React, { useMemo } from 'react';
import { useGetOpenJobQuery } from 'api/jobs.api';
import PageLoader from 'components/PageLoader';
import useAppSelector from 'hooks/useAppSelector';
import { useForm, useFormState } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Profile from 'router/subrouters/Dashboard/components/Profile';
import ProfileNotReadyBanner from 'router/subrouters/Dashboard/components/Profile/components/ProfileNotReadyBanner';
import ProfilePreviewAction from 'router/subrouters/Dashboard/components/Profile/components/ProfilePreviewAction';
import { extractProfileInfoFromJobApplication } from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/utils';
import authSelectors from 'store/selectors/auth.selectors';
import JobApplicationForm from '../../components/JobApplicationForm';
import JobApplicationHeader from '../../components/JobApplicationHeader';
import { JobApplicationFormValues } from '../../types/JobApplicationFormValues';
import utils from 'utils';
import { useTranslation } from 'react-i18next';

import './JobApplicationPreview.styles.scss';

const JobApplicationPreview: React.FC = () => {
  const { values, valid, dirtyFields } = useFormState();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { submit } = useForm();

  const { jobId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  const user = useAppSelector(authSelectors.selectUser);

  const submitting = useAppSelector(({ jobs }) => jobs.submitting);

  const { pathname } = useLocation();

  const navigateToApplicationForm = () => {
    if (!valid) {
      submit();
    }
    navigate(pathname.replace('/preview', ''));
  };

  const cardInfo = useMemo(
    () => ({
      ...extractProfileInfoFromJobApplication(
        values as JobApplicationFormValues,
        user,
        job,
      ),
      type: utils.mapVacancyTypeToApplicationType(job?.type),
      educationPhilosophyKeyUpdatedAt: dirtyFields?.educationPhilosophy
        ? new Date()
        : user?.educationPhilosophyKeyUpdatedAt,
    }),
    [values, user, job, dirtyFields],
  );

  return (
    <>
      {submitting && (
        <div className="searchality-overlay">
          <PageLoader />
        </div>
      )}
      <div>
        <JobApplicationHeader />
        {cardInfo ? (
          <div className="job-application-preview">
            <ProfilePreviewAction
              onReturn={navigateToApplicationForm}
              onSave={submit}
              disableButtons={submitting}
            />
            <div
              style={{
                display: 'none',
              }}
            >
              <JobApplicationForm />
            </div>
            {!valid && (
              <ProfileNotReadyBanner
                description={t('Profile.Header.jobApplicationError')}
              />
            )}
            <Profile cardInfo={cardInfo} />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default JobApplicationPreview;
