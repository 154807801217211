import React, { useCallback, useMemo } from 'react';
import FormFieldLabel from 'components/FormFieldLabel';
import useAppSelector from 'hooks/useAppSelector';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import WorkExperienceImage from 'assets/images/work-experience.webp';
import TPBBottomActions from '../../components/TPBBottomActions';
import ExperiencesField from 'router/subrouters/JobApplication/components/JobApplicationForm/components/YourProfile/components/ExperiencesField';
import useCurriculumExperienceOptions from 'hooks/selectOptions/useCurriculumExperienceOptions';
import bus from 'modules/bus';
import TwoOptionsSelectField from 'components/TwoOptionsSelectField';
import { CurriculumExperience, YearsOfExperience } from 'searchality-data';

import './WorkExperience.styles.scss';

const WorkExperiencePage: React.FC = () => {
  const { t } = useTranslation();

  const curriculumExperienceOptions = useCurriculumExperienceOptions(true);

  const { submit } = useForm();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );

  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const yearsOfExperience = useMemo(() => {
    const yearsOfExperienceTemp = Object.keys(YearsOfExperience).map((key) => ({
      label: t(`yearsOfExperience.`, {
        years: YearsOfExperience[key as keyof typeof YearsOfExperience],
      }),
      value: YearsOfExperience[key as keyof typeof YearsOfExperience],
    }));
    yearsOfExperienceTemp.shift();

    return yearsOfExperienceTemp;
  }, [t]);

  const onSkip = useCallback(() => {
    bus.broadcastEvent('JUMP_TO_ROUTE', 'Sports/Activities');
  }, []);

  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = dirtyFields['experience'] || dirtyFields['curricula'];

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Sports/Activities');
    }
  };

  return (
    <div className="tpb-work-experience">
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <img src={WorkExperienceImage} alt="credentials" />

        <TPBPageHeading
          text={t('teacherProfileBuilder.careerExprexience.title')}
          description={t('teacherProfileBuilder.careerExprexience.description')}
        />
        <FormFieldLabel
          text={t(
            'teacherProfileBuilder.careerExprexience.workExperienceLabel',
          )}
        />
        <Field name="experience" component={ExperiencesField} />
        <FormFieldLabel
          text={t(
            'teacherProfileBuilder.careerExprexience.previousWorkExperience',
          )}
        />
        <Field
          name="curricula"
          component={TwoOptionsSelectField}
          firstSelectOptions={curriculumExperienceOptions}
          secondSelectOptions={yearsOfExperience}
          firstSelectLabel={t(
            'teacherProfileBuilder.careerExprexience.selectReleventExperience',
          )}
          secondSelectLabel={t(
            'teacherProfileBuilder.careerExprexience.yearsOfExperience',
          )}
          firstOptionName="curriculumExperience"
          secondOptionName="yearsOfExperience"
          otherOptionInputPlaceholder="Please specify"
          hideOnValue={CurriculumExperience.NO_EXPERIENCE}
        />
        <TPBBottomActions canSkip onSkip={onSkip} onSubmit={onSubmit} />
      </TPBPageContent>
    </div>
  );
};

export default WorkExperiencePage;
