import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './TPBBottomActions.styles.scss';

const TPBBottomActions = ({
  canSkip = false,
  onSkip,
  onSubmit,
  submitBtnDisabled = false,
}: {
  onSubmit: () => void;
  canSkip?: boolean;
  onSkip?: () => void;
  submitBtnDisabled?: boolean;
}) => {
  const baseClass = 'tpb-bottom-actions';
  const { t } = useTranslation();

  return (
    <div className={baseClass}>
      {canSkip && (
        <Button
          variant="outline"
          className="tpb-submit"
          type="button"
          onClick={onSkip}
        >
          {t('skipForNow')}
        </Button>
      )}
      <Button
        className="tpb-submit"
        type="button"
        disabled={submitBtnDisabled}
        onClick={onSubmit}
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export default TPBBottomActions;
