import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useRouteTitleMapping from './useRouteTitleMapping';

const useMobileTbHeaderNavigate = () => {
  const ROUTES = useRouteTitleMapping();
  const { pathname } = useLocation();

  const navigateTo = useMemo(() => {
    const currentRoute = pathname?.split('/').pop();

    const routeArray = Object.keys(ROUTES);

    const indexOfCurrentRoute = routeArray.findIndex(
      (el) => el === currentRoute,
    );

    if (indexOfCurrentRoute > 0) {
      return routeArray[indexOfCurrentRoute - 1];
    }
    return '/profile';
  }, [ROUTES, pathname]);

  return navigateTo;
};

export default useMobileTbHeaderNavigate;
