import { useTranslation } from 'react-i18next';

const useRouteTitleMapping = () => {
  const { t } = useTranslation();

  return {
    'profile-status': t('teacherProfileNavItems.profile-status'),
    'personal-information': t('teacherProfileNavItems.personal-information'),
    'contact-information': t('teacherProfileNavItems.contact-information'),
    'work-availability': t('teacherProfileNavItems.work-availability'),
    'education-statement': t('teacherProfileNavItems.education-statement'),
    credentials: t('teacherProfileNavItems.credentials'),
    career: t('teacherProfileNavItems.career'),
    sports: t('teacherProfileNavItems.sports'),
    languages: t('teacherProfileNavItems.languages'),
    publications: t('teacherProfileNavItems.publications'),
    references: t('teacherProfileNavItems.references'),
    'target-positions': t('teacherProfileNavItems.target-positions'),
    'target-locations': t('teacherProfileNavItems.target-locations'),
  };
};

export default useRouteTitleMapping;
