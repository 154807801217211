import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { getCroppedImg, getFileFromURL } from './utils';
import Loading from 'components/Loading';
import { Button } from 'ncoded-component-library';

import './ImageCropper.styles.scss';
import './ImageCropper.styles.responsive.scss';

type Point = {
  x: number;
  y: number;
};

type Area = {
  width: number;
  height: number;
} & Point;

type ImageCropperRef = {
  getFile: (fileName: string) => Promise<File>;
  loading: boolean;
};

type ImageCropperProps = {
  className?: string;
  src: string;
  cropShape?: 'round' | 'rect';
  formatType?: 'image/jpeg' | 'image/png';
  aspect: number;
};

const ImageCropper: ForwardRefRenderFunction<
  ImageCropperRef,
  ImageCropperProps
> = (props, ref) => {
  const {
    className,
    src,
    cropShape = 'round',
    aspect = 1,
    formatType = 'image/jpeg',
  } = props;

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(1);

  const { t } = useTranslation();

  const onCropComplete = useCallback(
    async (_: unknown, croppedAreaPixels: Area) => {
      setPreview('');
      setLoading(true);
      try {
        const croppedImageUrl = await getCroppedImg(
          src,
          croppedAreaPixels,
          formatType,
        );
        setPreview(croppedImageUrl as string);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [formatType, src],
  );

  const getFile = useCallback(
    (fileName: string) => getFileFromURL(preview, fileName, formatType),
    [formatType, preview],
  );

  const handleZoomIn = () =>
    setZoom((prev) => (prev * 1.1 < 5 ? prev * 1.1 : 5));

  const handleZoomOut = () =>
    setZoom((prev) => (prev * 0.9 > 1 ? prev * 0.9 : 1));

  useImperativeHandle(ref, () => ({
    getFile,
    loading,
  }));

  const classes = classNames('image-cropper', className);

  return (
    <div className={classes}>
      <div className="image-cropper__wrapper">
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          showGrid={false}
          cropShape={cropShape}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="image-cropper__preview">
        <div
          className={classNames(
            { 'image-cropper__preview__img': cropShape === 'round' },
            { 'image-cropper__preview__img--rect': cropShape === 'rect' },
          )}
        >
          {preview ? <img src={preview} alt="Preview" /> : <Loading />}
        </div>
        <p>{t('preview')}</p>
        <div className="image-cropper__preview__actions">
          <Button
            variant="outline"
            onClick={handleZoomIn}
            disabled={zoom === 5}
          >
            {t('zoomIn')}
          </Button>
          <Button
            variant="outline"
            onClick={handleZoomOut}
            disabled={zoom === 1}
          >
            {t('zoomOut')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ImageCropper);
