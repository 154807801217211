import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { ReactComponent as RemoveIcon } from 'icons/Delete.icon.svg';
import { useTranslation } from 'react-i18next';

import './JobApplicationSection.styles.scss';
import './JobApplicationSection.styles.responsive.scss';

type JobApplicationSectionProps = {
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  description?: string;
  onFormClear?: () => void;
  withBorder?: boolean;
  withMargin?: boolean;
};

const JobApplicationSection: React.FC<JobApplicationSectionProps> = (props) => {
  const {
    className,
    children,
    title,
    description,
    onFormClear,
    withBorder = true,
    withMargin = true,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'job-application-section';
  const classes = classNames(baseClass, className);

  const headerClasses = classNames({
    [`${baseClass}__header`]: true,
    [`${baseClass}__header--bordered`]: withBorder,
    [`${baseClass}__header--with-margin`]: withMargin,
  });

  return (
    <section className={classes}>
      <div className={headerClasses}>
        <div className={`${baseClass}__header__title-container`}>
          {title && (
            <p className={`${baseClass}__header__title-container__title`}>
              {title}
            </p>
          )}
          {description && (
            <p className={`${baseClass}__header__title-container__description`}>
              {description}
            </p>
          )}
        </div>
        {onFormClear && (
          <Button
            onClick={onFormClear}
            icon={<RemoveIcon />}
            iconPosition="right"
            className="clear-form-button"
          >
            {t('clearForm')}
          </Button>
        )}
      </div>
      {children}
    </section>
  );
};

export default JobApplicationSection;
