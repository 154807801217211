import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return [
    {
      to: 'matched',
      label: t('Applications.matched'),
    },
    {
      to: 'active',
      label: t('Applications.active'),
    },
    {
      to: 'previous',
      label: t('Applications.previous'),
    },
    {
      to: 'successful',
      label: t('Applications.successful'),
    },
  ];
};
