import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig, AxiosError } from 'axios';
import utils from 'utils';
import httpClient from './httpClient';

export type RTKQueryError = {
  status: number;
  message: string;
};

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    RTKQueryError
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await httpClient({
        url: `${url}`,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const errorResponse = {
        error: {
          status: (axiosError as AxiosError)?.response?.status,
          message: utils.getStringError(axiosError as AxiosError),
        },
      };

      return errorResponse as QueryReturnValue<unknown, RTKQueryError, unknown>;
    }
  };

export default axiosBaseQuery;
