import RadioButtonLabel from 'components/RadioButtonLabel';
import useMatchMedia from 'hooks/useMatchMedia';
import { Button, Modal, RadioGroup } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FillVacancyMethod } from 'searchality-data';

import './VacancyFillModal.styles.scss';

const VacancyFillModal: React.ForwardRefRenderFunction<
  ModalRef,
  {
    applicantsCount?: number;
    closeModal: () => void;
    onConfirm: (fillType?: string) => void;
  }
> = ({ closeModal, onConfirm }, ref) => {
  const { t } = useTranslation();

  const [fillType, setFillType] = useState('');
  const isPhablet = useMatchMedia('isPhablet');

  const fillOptions = [
    {
      label: <RadioButtonLabel label={t('internally')} />,
      value: FillVacancyMethod.INTERNALLY,
    },
    {
      label: <RadioButtonLabel label={t('fromCandidateApplications')} />,
      value: FillVacancyMethod.FROM_CANDIDATE_APPLICATIONS,
    },
    {
      label: <RadioButtonLabel label={t('anotherSource')} />,
      value: FillVacancyMethod.ANOTHER_SOURCE,
    },
  ];

  const onFillTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFillType(e.target.value);
  };

  const handleContinue = () => {
    onConfirm(fillType);
  };

  const handleOnClose = useCallback(() => {
    setFillType('');
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      ref={ref}
      title={t('vacancyFillModal.title')}
      className="vacancy-fill-modal"
      onClose={() => setFillType('')}
      footer={
        <>
          <Button variant="outline" onClick={handleOnClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleContinue}>{t('Continue')}</Button>
        </>
      }
    >
      <p>{t('vacancyFillModal.description')}</p>
      <RadioGroup
        options={fillOptions}
        direction={isPhablet ? 'column' : 'row'}
        onChange={onFillTypeChange}
        name="fillType"
        value={fillType}
      />
    </Modal>
  );
};
export default React.forwardRef(VacancyFillModal);
