import { useStripe } from '@stripe/react-stripe-js';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { loadCurrentUser } from 'store/slices/auth.slice';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import {
  updateClientSecretValue,
  updateCouponValue,
} from 'store/slices/payment.slice';
import { useNavigate } from 'react-router-dom';
import { resetState } from 'store/slices/addNewSchool.slice';
import CheckoutContext from 'providers/Checkout/Checkout.context';

const useFinishSubscribe = (selectedPaymentMethodId: string) => {
  const stripe = useStripe();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const clientSecret = useAppSelector(({ payment }) => payment.clientSecret);

  const { paymentInfo } = useContext(PaymentMethodsContext);
  const { setIsLoading } = useContext(CheckoutContext);

  const { t } = useTranslation();

  const paymentMethod = useMemo(
    () =>
      paymentInfo?.paymentMethods?.find(
        (paymentMethodTemp) => paymentMethodTemp.id === selectedPaymentMethodId,
      ),
    [paymentInfo?.paymentMethods, selectedPaymentMethodId],
  );

  const handlePayment = useCallback(async () => {
    if (!clientSecret || !stripe || !paymentMethod?.type) return;
    setIsLoading(true);

    const stripeCall =
      paymentMethod?.type === 'card'
        ? stripe.confirmCardPayment
        : stripe.confirmUsBankAccountPayment;

    const { error, paymentIntent } = await stripeCall(clientSecret);
    if (error) {
      dispatch(popError(error.message));
    } else {
      const { status, description } = paymentIntent;
      if (status === 'succeeded') {
        dispatch(popSuccess(t('paymentMethodAdded')));
      } else {
        dispatch(popSuccess(description));
      }
    }
    setTimeout(() => {
      dispatch(loadCurrentUser());
      dispatch(updateClientSecretValue(''));
      dispatch(updateCouponValue(undefined));
      dispatch(resetState());
      navigate('/my-account/subscription');
      setIsLoading(false);
    }, 2000);
  }, [
    clientSecret,
    dispatch,
    navigate,
    paymentMethod?.type,
    setIsLoading,
    stripe,
    t,
  ]);

  useEffect(() => {
    handlePayment();
  }, [handlePayment]);
};

export default useFinishSubscribe;
