import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';

const useRolesSelectOptions = () => {
  const { t } = useTranslation();

  const {
    schoolGroup: { structureType },
    role: userRole,
  } = useAppSelector(authSelectors.selectUser);

  const { Teacher, AssociationMember, AssociationOwner, ...rest } = Role;

  const options = useMemo(() => {
    let rolesOptions = Object.keys(rest).map((key) => ({
      label: t(`UserRoles.${key}`),
      value: Role[key as keyof typeof Role],
    }));
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      rolesOptions = rolesOptions.filter(
        (option) => option.value !== Role.AccountManager,
      );
    }
    return rolesOptions;
  }, [rest, structureType, t]);

  const allowdPermissionOptions = useMemo(() => {
    switch (userRole) {
      case Role.AccountOwner:
        return options;
      case Role.AccountManager:
        return [
          {
            label: t(`UserRoles.HiringManager`),
            value: Role.HiringManager,
          },
          {
            label: t(`UserRoles.Reviewer`),
            value: Role.Reviewer,
          },
        ];
      case Role.Reviewer:
        return [
          {
            label: t(`UserRoles.Reviewer`),
            value: Role.Reviewer,
          },
        ];
      case Role.HiringManager:
        return [
          {
            label: t(`UserRoles.Reviewer`),
            value: Role.Reviewer,
          },
        ];
    }
  }, [options, t, userRole]);

  return allowdPermissionOptions;
};

export default useRolesSelectOptions;
