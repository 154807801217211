import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from 'types';

import './PaginationInfo.styles.scss';

type PaginationInfoProps = {
  limit?: number;
  name: string;
  className?: string;
} & PaginationProps;

const PaginationInfo: React.FC<PaginationInfoProps> = (props) => {
  const {
    totalItems = 0,
    totalPages = 0,
    currentPage = 0,
    limit = 6,
    name,
    className,
  } = props;
  const [beginning, setBeginning] = useState(0);
  const [ending, setEnding] = useState(0);
  const [navigationData, setNavigationData] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (totalItems !== 0) {
      setBeginning(currentPage ? (currentPage - 1) * limit + 1 : 0);
    }

    setEnding(
      totalItems < currentPage * limit ? totalItems : currentPage * limit,
    );
  }, [currentPage, limit, totalItems, totalPages]);

  useEffect(() => {
    if (totalItems === 0) {
      setNavigationData(
        t('paginationAmount', {
          beginning: 0,
          ending: 0,
          totalItems: totalItems,
          name,
        }),
      );
      return;
    }
    if (beginning !== ending) {
      setNavigationData(
        t('paginationAmount', {
          beginning,
          ending,
          totalItems: totalItems,
          name,
        }),
      );
    } else {
      setNavigationData(
        t('paginationAmount', {
          beginning,
          ending: totalItems,
          totalItems: totalItems,
          name,
        }),
      );
    }
  }, [beginning, ending, name, t, totalItems]);

  const classes = classNames(className, 'searchality-pagination-info');

  return <p className={classes}>{navigationData}</p>;
};

export default PaginationInfo;
