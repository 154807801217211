import React from 'react';
import classNames from 'classnames';
import SearchalityLogo from 'assets/images/searchality-logo.webp';
import { useTranslation } from 'react-i18next';

import './JobApplicationFooter.styles.scss';

type JobApplicationFooterProps = {
  className?: string;
};

const JobApplicationFooter: React.FC<JobApplicationFooterProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'job-application-footer';
  const classes = classNames(baseClass, className);

  return (
    <footer className={classes}>
      <img className={`${baseClass}__image`} src={SearchalityLogo} alt="logo" />
      <p className={`${baseClass}__powered-by`}>{t('poweredBy')}</p>
    </footer>
  );
};

export default JobApplicationFooter;
