import { VacancyType } from 'searchality-data';

const vacancyTypeToRouteMapping = {
  [VacancyType.ACADEMIC]: 'academic',
  [VacancyType.NON_ACADEMIC]: 'non-academic',
};

export default {
  vacancyTypeToRouteMapping,
};
