import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';

const useStandaloneSchoolId = () => {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const standaloneSchoolId = useMemo(() => {
    if (!schoolGroup) return undefined;
    return schoolGroup.structureType === SchoolGroupStructureType.STANDALONE
      ? schoolGroup?.schools[0]._id
      : undefined;
  }, [schoolGroup]);

  return standaloneSchoolId;
};

export default useStandaloneSchoolId;
