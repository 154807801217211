import React, { useMemo } from 'react';
import classNames from 'classnames';
import { SchoolGroupStructureType } from 'searchality-data';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetOpenJobQuery } from 'api/jobs.api';

import './JobApplicationHeader.styles.scss';
import './JobApplicationHeader.styles.responsive.scss';

type JobApplicationHeaderProps = {
  className?: string;
};

const JobApplicationHeader: React.FC<JobApplicationHeaderProps> = (props) => {
  const { className } = props;

  const { jobId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  const { schoolGroup, schools, isFullTimeEmployment, positionTitle } = job;

  const { structureType, name } = schoolGroup || {};
  const { t } = useTranslation();

  const schoolName = useMemo(
    () =>
      structureType === SchoolGroupStructureType.STANDALONE
        ? name
        : `${schools.map(({ name }) => name).join(', ')} ${name}`,
    [name, schools, structureType],
  );

  const employementType = useMemo(
    () =>
      isFullTimeEmployment ? t('fullTimePosition') : t('partTimePosition'),
    [t, isFullTimeEmployment],
  );

  const baseClass = 'job-application-header';
  const classes = classNames(baseClass, className);

  return (
    <header className={classes}>
      <div className={`${baseClass}__content`}>
        <div className={`${baseClass}__content__left`}>
          <p className={`${baseClass}__content__left__title`}>
            {positionTitle}
          </p>
          <div className={`${baseClass}__content__left__school-container`}>
            <p
              className={`${baseClass}__content__left__school-container__name`}
            >
              {schoolName}
            </p>
            <p
              className={`${baseClass}__content__left__school-container__type`}
            >
              {employementType}
            </p>
          </div>
        </div>
        {schools?.[0]?.imageFile && (
          <img src={schools?.[0]?.imageFile.url} alt="logo" />
        )}
      </div>
    </header>
  );
};

export default JobApplicationHeader;
