import React, { useCallback } from 'react';
import PersonalInformation from '../../../../../../components/PersonalInformation';
import confirm from 'modules/confirm';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { logout } from 'store/slices/auth.slice';
import { useTranslation } from 'react-i18next';
import { Role } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

const SchoolPersonalInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { role } = useAppSelector(authSelectors.selectUser);

  const handleDeleteRequest = useCallback(async () => {
    try {
      await api.user.deleteUserAccount();
      dispatch(popSuccess(t('succesfulyDeletedAccount')));
      dispatch(logout());
    } catch (e) {
      dispatch(popServerError(e));
    }
  }, [dispatch, t]);

  const handleDelete = useCallback(async () => {
    if (role === Role.AccountOwner) {
      await confirm({
        title: t('deleteAccountModal.title'),
        content: (
          <p>
            {t('deleteAccountModal.description', {
              name: t('deleteAccountModal.school'),
            })}
          </p>
        ),
        showCancel: false,
        confirmBtnText: t('deleteAccountModal.button'),
        onSubmit: () => {
          navigate('/members');
        },
      });
    } else {
      await confirm({
        title: t('deleteAccountConfirmationModal.title'),
        content: t('deleteAccountConfirmationModal.content'),
        confirmBtnText: t('deleteAccountConfirmationModal.delete'),
        onSubmit: () => {
          handleDeleteRequest();
        },
      });
    }
  }, [handleDeleteRequest, navigate, role, t]);

  return <PersonalInformation handleDelete={handleDelete} />;
};

export default SchoolPersonalInformation;
