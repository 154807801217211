import { ComplexRoute } from 'router/components/Routes/Routes';
import AddVacancyQuestionVideo from './pages/AddVacancyQuestionVideo';
import VacancySettings from './pages/VacancySettings';

export default [
  {
    path: ':schoolId',
    element: AddVacancyQuestionVideo,
  },
  {
    index: true,
    element: VacancySettings,
  },
] as Array<ComplexRoute>;
