import React from 'react';
import FormFieldLabel from 'components/FormFieldLabel';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import TPBBottomActions from '../../components/TPBBottomActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBTopActions from '../../components/TPBTopActions';
import WorkReferenceImage from 'assets/images/work-references.webp';
import ReferencesField from 'router/subrouters/JobApplication/components/JobApplicationForm/components/ReferencesField';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';

import './References.styles.scss';

const ReferencesPage: React.FC = () => {
  const { t } = useTranslation();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = dirtyFields['references'];

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Target positions');
    }
  };

  return (
    <div className="tpb-references">
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <img src={WorkReferenceImage} alt="medal" />
        <TPBPageHeading
          text={t('teacherProfileBuilder.references.title')}
          description={t('teacherProfileBuilder.references.description')}
        />
        <FormFieldLabel
          text={t('teacherProfileBuilder.references.fieldLabel')}
        />
        <Field
          name="references"
          component={ReferencesField}
          validate={required()}
        />
        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default ReferencesPage;
