import React from 'react';
import { Role } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

type ViewGuardProps = {
  children: React.ReactNode | React.ReactNode[];
  roles: Role[];
  message?: string;
  permission?: 'allow' | 'forbid';
};

const ViewGuard: React.FC<ViewGuardProps> = (props) => {
  const { children, roles = [], message, permission = 'allow' } = props;

  const { role: userRole } = useAppSelector(authSelectors.selectUser);

  const renderMessage = message && (
    <small className="view-guard-msg">{message}</small>
  );

  if (!roles.length) {
    return <>{children}</>;
  }

  if (permission === 'allow') {
    return <>{roles.includes(userRole) ? children : renderMessage}</>;
  }

  return <>{roles.includes(userRole) ? renderMessage : children}</>;
};

export default ViewGuard;
