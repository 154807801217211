import React, { useCallback } from 'react';
import MainLayout from 'components/MainLayout';
import AuthSection from 'router/subrouters/Auth/components/AuthSection';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import PasswordField from 'components/PasswordField';
import { composeValidators, required, passwordValidation } from 'validations';
import { Button } from 'ncoded-component-library';
import useQParamsActions from 'hooks/useQueryParams';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import api from 'api';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const { isTeacher } = state || {};
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    params: { token },
  } = useQParamsActions();

  const handleSubmit = useCallback(
    async ({ password }: { password: string; confirmPassword: string }) => {
      try {
        await api.auth.resetPassword(password, token);
        dispatch(popSuccess(t('resetPasswordPage.success')));
        navigate('/');
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, t, token],
  );

  const classes = classNames('auth', { teachers: isTeacher }, 'reset-password');

  return (
    <MainLayout className={classes}>
      <AuthSection className="auth-form">
        {!token ? (
          <h1 style={{ textAlign: 'center' }}>{t('noTokenPresent')}</h1>
        ) : (
          <>
            <h1>{t('resetPassword')}</h1>
            <Form
              onSubmit={handleSubmit}
              validate={({ confirmPassword, password }) =>
                confirmPassword !== password
                  ? { confirmPassword: t('passwordMustMatch') }
                  : {}
              }
              render={({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={handleSubmit} className="auth-section__form">
                  <Field
                    name="password"
                    component={PasswordField}
                    label={t('password')}
                    required
                    validate={composeValidators(
                      required(),
                      passwordValidation(),
                    )}
                    hint={t('passwordHint')}
                  />
                  <Field
                    name="confirmPassword"
                    component={PasswordField}
                    label={t('password')}
                    required
                  />
                  <Button
                    type="submit"
                    disabled={invalid || submitting}
                    className="forgot-password__submit"
                  >
                    {t('resetPassword')}
                  </Button>
                </form>
              )}
            />
          </>
        )}
      </AuthSection>
    </MainLayout>
  );
};

export default ResetPassword;
