import React from 'react';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { ReactComponent as EditIcon } from 'icons/Edit.icon.svg';
import { Button } from 'ncoded-component-library';

import './ReferenceCard.styles.scss';

type ReferenceCardProps = {
  className?: string;
  name: string;
  email?: string;
  phone?: string;
  positionTitle: string;
  schoolName: string;
  handleEdit?: () => void;
  handleDelete?: () => void;
};

const ReferenceCard: React.FC<ReferenceCardProps> = (props) => {
  const {
    className,
    name,
    email,
    phone,
    positionTitle,
    schoolName,
    handleEdit,
    handleDelete,
  } = props;

  const baseClass = 'reference-card';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__top`}>
        <p className={`${baseClass}__top__name`}>{name}</p>
        {email && <p className={`${baseClass}__top__email`}>{email}</p>}
        {phone && <p className={`${baseClass}__top__email`}>{phone}</p>}
      </div>
      <div className={`${baseClass}__bottom`}>
        <p className={`${baseClass}__bottom__position-title`}>
          {positionTitle}
        </p>
        <p className={`${baseClass}__bottom__school-name`}>{schoolName}</p>
      </div>
      <div className={`${baseClass}__action-buttons`}>
        <Button
          icon={<EditIcon />}
          onClick={handleEdit}
          className={`${baseClass}__action-buttons__button`}
        />
        <Button
          icon={<DeleteIcon />}
          onClick={handleDelete}
          className={`${baseClass}__action-buttons__button`}
        />
      </div>
    </div>
  );
};

export default ReferenceCard;
