import React from 'react';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import { Outlet } from 'react-router-dom';
import useNavigationCurrentStep from '../../hooks/useNavigationCurrentStep';
export { default as schoolTypeRoutes } from './routes';

export default function () {
  useNavigationCurrentStep(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);

  return <Outlet />;
}
