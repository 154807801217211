import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button, Checkbox, Modal } from 'ncoded-component-library';
import Pagination from 'components/Pagination';
import { PaginatedResponse, Params } from 'types';
import VacanciesFilters from '../../subrouters/Vacancies/components/VacanciesFilters';
import { useGetVacanciesQuery } from 'api/vacancies.api';
import { Vacancy } from 'models/Vacancy';
import useCallbackRef from 'hooks/useCallbackRef';
import ValueContext from 'providers/ValueContext/Value.context';
import VacancyCardWrapper from 'components/VacancyCard/components/VacancyCardWrapper';
import useAppSelector from 'hooks/useAppSelector';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import { batch } from 'react-redux';
import PageLoader from 'components/PageLoader';

import './SelectVacanciesModal.styles.scss';
import './SelectVacanciesModal.styles.responsive.scss';

type SelectVacanciesModalProps = {
  className?: string;
  onCancel?: () => void;
  onSubmit: (selectedIds: string[]) => void;
  initialParams: Partial<Params>;
  submitBtnText: string;
} & OverlayProps;

const SelectVacanciesModal: React.ForwardRefRenderFunction<
  ModalRef,
  SelectVacanciesModalProps
> = (props, ref) => {
  const { className, onSubmit, onCancel, initialParams, submitBtnText } = props;

  const params = useAppSelector(({ vacanciesParams }) => vacanciesParams);

  const dispatch = useAppDispatch();

  const { selectedIds, manageId, clearIds } = useContext(ValueContext);

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const baseClass = 'select-vacancies-modal';
  const classes = classNames(baseClass, className);

  const { data, isLoading, isFetching } = useGetVacanciesQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const { t } = useTranslation();

  const { items: vacancies, ...pagination } =
    data || ({} as PaginatedResponse<Vacancy>);

  const checkboxGroup = useMemo(
    () =>
      vacancies?.map((vacancy) => (
        <VacancyCardWrapper
          {...vacancy}
          noActions={true}
          key={vacancy._id}
          onClick={() => manageId(vacancy._id)}
          isSelected={selectedIds?.includes(vacancy._id)}
          additionalContent={
            <Checkbox
              onChange={() => manageId(vacancy._id)}
              checked={selectedIds?.includes(vacancy._id)}
            />
          }
        />
      )),
    [manageId, selectedIds, vacancies],
  );

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedIds);
  }, [onSubmit, selectedIds]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(paramsActions.resetFilters('vacanciesParams'));
        dispatch(paramsActions.resetSort('vacanciesParams'));
        dispatch(paramsActions.changeCurrentPage('vacanciesParams', 1));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={classes}
      ref={modalRef}
      onX={() => {
        onCancel?.();
      }}
      onClose={clearIds}
      title={t('Members.selectVacancyIdsTitle')}
      footer={
        <div>
          <Button
            variant="outline"
            onClick={() => {
              onCancel?.();
            }}
          >
            {t('cancel')}
          </Button>
          <Button disabled={selectedIds?.length < 1} onClick={handleOnSubmit}>
            {submitBtnText}
          </Button>
        </div>
      }
    >
      <div className="vacancies">
        <VacanciesFilters
          initialParams={initialParams}
          reducerPath="vacanciesParams"
        />
        {isFetching || isLoading ? (
          <PageLoader />
        ) : pagination.totalItems > 0 ? (
          <>
            <ul className={`checkbox-group`}>{checkboxGroup}</ul>
            {pagination?.totalPages > 1 && (
              <Pagination
                onChange={(pageNumber) =>
                  dispatch(
                    paramsActions.changeCurrentPage(
                      'vacanciesParams',
                      pageNumber,
                    ),
                  )
                }
                {...pagination}
              />
            )}
          </>
        ) : (
          <div>{t('Members.noVacanciesError')}</div>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(SelectVacanciesModal);
