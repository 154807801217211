import { useTranslation } from 'react-i18next';
import { FavouriteOptions } from 'types';

export default () => {
  const { t } = useTranslation();
  return [
    {
      label: t('favoriteCandidates'),
      value: FavouriteOptions.FAV_CANDIDATES,
    },
    {
      label: t('allCandidates'),
      value: FavouriteOptions.ALL_CANDIDATES,
    },
  ];
};
