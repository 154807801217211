import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import { useTranslation } from 'react-i18next';

const usePositionOptions = (): { label: string; value: string }[] => {
  const { role, schoolGroup } = useAppSelector(authSelectors.selectUser);
  const { structureType } = schoolGroup || {};

  const { t } = useTranslation();

  const options = [
    {
      label: t('hiringManager'),
      value: Role.HiringManager,
    },

    {
      label: t('reviewer'),
      value: Role.Reviewer,
    },
  ];

  if (
    structureType === SchoolGroupStructureType.GROUP &&
    role === Role.AccountOwner
  ) {
    return [
      {
        label: t('accountManager'),
        value: Role.AccountManager,
      },
      ...options,
    ];
  }

  return options;
};

export default usePositionOptions;
