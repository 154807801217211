import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import CheckboxField from 'components/CheckboxField';
import { useCloseVacancyMutation } from 'api/vacancies.api';

import './CloseVacancyModal.styles.scss';

type CloseVacancyModalProps = {
  className?: string;
  positionTitle: string;
  vacancyId: string;
  schoolName: string;
};

type CloseVacancyModalValues = {
  message: string;
  skipNotifyingCandidates?: boolean;
};

const CloseVacancyModal: ForwardRefRenderFunction<
  ModalRef,
  CloseVacancyModalProps
> = (props, ref) => {
  const { className, vacancyId, positionTitle, schoolName } = props;

  const dispatch = useAppDispatch();

  const [closeVacancy] = useCloseVacancyMutation();

  const { t } = useTranslation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('close-vacancy-modal', className);

  const handleSubmit = useCallback(
    async (values: CloseVacancyModalValues) => {
      const { message, skipNotifyingCandidates } = values;

      closeVacancy({
        vacancyId,
        shouldNotifyApplicants: !skipNotifyingCandidates,
        ...(!skipNotifyingCandidates && { message }),
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('CloseVacancyModal.success')));
          modal.close();
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [closeVacancy, dispatch, modal, t, vacancyId],
  );

  const validate = useCallback(
    (values: CloseVacancyModalValues) => {
      const { skipNotifyingCandidates, message } = values;
      if (skipNotifyingCandidates) return {};

      if (!message) {
        return { message: t('requiredField') };
      }
    },
    [t],
  );

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('CloseVacancyModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          skipNotifyingCandidates: false,
          message: t('CloseVacancyModal.message', {
            positionTitle,
            schoolName,
          }),
        }}
        validate={validate}
        render={({ handleSubmit, submitting, invalid, form: { submit } }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('CloseVacancyModal.description')}</p>
            <Field name="message" component={TextAreaField} />
            <Field
              name="skipNotifyingCandidates"
              component={CheckboxField}
              type="checkbox"
              isStyledDefault
              label={t('CloseVacancyModal.checkbox')}
            />
            <div className="actions">
              <Button
                disabled={submitting || invalid}
                variant="outline"
                onClick={modal.close}
              >
                {t('cancel')}
              </Button>
              <Button disabled={submitting || invalid} onClick={submit}>
                {t('closeVacancy')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(CloseVacancyModal);
