import { useTranslation } from 'react-i18next';

const useEmploymentTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('fullTime'),
      value: 'fullTime',
    },
    {
      label: t('partTime'),
      value: 'partTime',
    },
    {
      label: t('temporaryContract'),
      value: 'temporaryContract',
    },
  ];
};

export default useEmploymentTypeOptions;
