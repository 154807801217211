import React from 'react';
import { ReactComponent as CurriculumIcon } from 'icons/Curriculum.icon.svg';
import { useTranslation } from 'react-i18next';
import { Publication } from 'models/Teacher';
import PublicationLink from '../../../PublicationLink';

type ProfilePublicationsProps = {
  publications: Publication[];
};

const ProfilePublications: React.FC<ProfilePublicationsProps> = (props) => {
  const { publications } = props;

  const { t } = useTranslation();

  return (
    <div className="profile-section">
      <div className="profile-section__title">
        <CurriculumIcon className="grey-icon" />
        <p>{t('publications')}</p>
      </div>
      {publications?.map(({ _id, url, key, name }) => (
        <PublicationLink
          key={_id}
          name={name}
          isPdf={!!key}
          url={url}
          showFileLink={true}
        />
      ))}
    </div>
  );
};

export default ProfilePublications;
