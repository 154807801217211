import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';
import {
  composeValidators,
  required,
  websiteWithPathValidation,
} from 'validations';
import { Button } from 'ncoded-component-library';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import api from 'api';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './WidgetUrlInput.styles.scss';

type WidgetUrlInputProps = {
  className?: string;
  schoolId: string;
};

const WidgetUrlInput: React.FC<WidgetUrlInputProps> = (props) => {
  const { className, schoolId } = props;

  const user = useAppSelector(authSelectors.selectUser);
  const dispatch = useAppDispatch();

  const classes = classNames('widget-url-input', className);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: { widgetUrl: string }) => {
      try {
        const { data } = await api.schools.updateWidgetUrl(values, schoolId);
        const userCopy = structuredClone(user);

        const newSchools = userCopy.schoolGroup.schools.map((school) => {
          if (schoolId === school._id) {
            return data;
          }
          return school;
        });

        userCopy.schoolGroup.schools = newSchools as any;
        userCopy.schools = newSchools as any;

        dispatch(updateUser(userCopy));
        dispatch(popSuccess(t('successfullyEditWidgetUrl')));
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, schoolId, t, user],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        widgetUrl: user?.schools.find((school) => school?._id === schoolId)
          ?.widgetUrl,
      }}
      render={({ handleSubmit, invalid, pristine }) => (
        <form className={classes} onSubmit={handleSubmit}>
          <p>{t('widgetUrlInputTitle')}</p>
          <p>{t('widgetUrlInputDescription')}</p>
          <Field
            name="widgetUrl"
            label={t('widgetUrl')}
            component={InputField}
            validate={composeValidators(
              required(),
              websiteWithPathValidation(),
            )}
          />
          <Button type="submit" disabled={invalid || pristine}>
            {t('saveWidgetUrl')}
          </Button>
        </form>
      )}
    />
  );
};

export default WidgetUrlInput;
