import React, { useCallback, useMemo, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import { DateTime, Info } from 'luxon';
import Select from 'components/Select';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { Input } from 'ncoded-component-library';

import './YearMonthInput.styles.scss';

export type FieldYearMonthInputFieldProps = FieldRenderProps<
  Date,
  HTMLElement
> & { onlyPast?: boolean };

export const YearMonthInput: React.FC<FieldYearMonthInputFieldProps> = (
  props,
) => {
  const {
    className,
    id,
    labelMonth,
    labelYear,
    label,
    input,
    meta,
    placeholder,
    onlyPast = false,
    disabled = false,
    ...rest
  } = props;

  const { onBlur, onFocus, value, onChange } = input;

  const { touched, error } = meta;
  const [inputTouched, setInputTouched] = useState(false);

  const dateIsValid = value instanceof Date;

  const monthFromValue = dateIsValid
    ? DateTime.fromJSDate(new Date(value)).month
    : '';
  const yearFromValue = dateIsValid
    ? DateTime.fromJSDate(new Date(value)).year
    : '';

  const classes = classNames('root', { 'root--disabled': disabled }, className);

  const selectProps = {
    onBlur,
    onFocus,
    className: 'select',
  };

  const allMonths = useMemo(() => {
    return onlyPast && yearFromValue === DateTime.now().year
      ? Info.months().slice(0, DateTime.now().month)
      : Info.months();
  }, [onlyPast, yearFromValue]);

  const onMonthSelect = useCallback(
    (e: OptionValue<string | number | Record<string, unknown>>) => {
      const selectedMonth = e.value as number;

      const dateAsMoment = dateIsValid
        ? DateTime.fromJSDate(value)
        : DateTime.now().startOf('month');

      onChange(dateAsMoment.set({ month: selectedMonth }).toJSDate());
    },
    [dateIsValid, onChange, value],
  );

  const onYearSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        return onChange(undefined);
      }

      if (!/^(\d){0,}$/.test(e.target.value)) {
        return;
      }

      if (e.target.value.length > 5) {
        return;
      }

      const selectedYear = parseInt(e.target.value);

      const dateAsMoment = dateIsValid
        ? DateTime.fromJSDate(value)
        : DateTime.now().startOf('year');

      onChange(dateAsMoment.set({ year: selectedYear }).toJSDate());
    },
    [dateIsValid, onChange, value],
  );

  const monthOptions = allMonths.map((month, index) => ({
    label: month,
    value: index + 1,
  }));

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <div className={classNames('selects', { 'selects--disabled': disabled })}>
        <div>
          <Input
            value={yearFromValue}
            onChange={onYearSelect}
            innerLabel={labelYear}
            onBlur={() => setInputTouched(true)}
          />
          {(inputTouched || touched) && error && (
            <p className="year-input-error">{error}</p>
          )}
        </div>
        {dateIsValid &&
          !(yearFromValue < 1900 || yearFromValue > DateTime.now().year) && (
            <Select
              value={monthFromValue}
              multiple={false}
              onChange={onMonthSelect}
              innerLabel={labelMonth}
              hasError={touched && error}
              {...selectProps}
              {...rest}
              options={monthOptions}
            />
          )}
      </div>
    </div>
  );
};

export const FieldYearMonthInput = (props: any) => {
  return <Field component={YearMonthInput} {...props} />;
};

export default YearMonthInput;
