import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import Select from 'components/Select';
import useSchoolFromYourGroup from '../../../../hooks/useSchoolFromYourGroup';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import VacancySettingsBanner from '../../components/VacancySettingsBanner';
import VacancySettingsInformation from '../../components/VacancySettingsInformation';

import './VacancySettings.styles.scss';
import './VacancySettings.styles.responsive.scss';
import useQParamsActions from 'hooks/useQueryParams';

type VacancySettingsProps = {
  className?: string;
};

const VacancySettings: React.FC<VacancySettingsProps> = (props) => {
  const { className } = props;
  const {
    schoolGroup: { structureType },
    role,
    schools,
  } = useAppSelector(authSelectors.selectUser);

  const [schoolId, setSchoolId] = useState<string>();

  const { params } = useQParamsActions();

  const schoolsOptions = useSchoolFromYourGroup(schools);
  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;
  const isHiringManager = role === Role.HiringManager;

  const { t } = useTranslation();
  const classes = classNames('vacancy-settings', className);

  const selectedSchool = useMemo(
    () => schools.find((school) => school._id === schoolId),
    [schoolId, schools],
  );

  const { requestedAdditionalInformation } = selectedSchool || {};

  useEffect(() => {
    if (params?.schoolId) {
      setSchoolId(params?.schoolId);
    } else {
      setSchoolId(schools[0]._id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      <h4>{t('VacancySettings.title')}</h4>
      <p className="vacancy-settings__description">
        {t('VacancySettings.description')}
      </p>
      {!isStandalone && !isHiringManager && (
        <Select
          innerLabel={t('school')}
          options={schoolsOptions}
          value={schoolId}
          onChange={({ value }: OptionValue<string>) => setSchoolId(value)}
        />
      )}
      {!requestedAdditionalInformation ? (
        <VacancySettingsBanner schoolId={schoolId} />
      ) : (
        <VacancySettingsInformation
          schoolId={schoolId}
          questions={requestedAdditionalInformation?.followUpQuestions}
          video={requestedAdditionalInformation?.videoDescription}
        />
      )}
    </div>
  );
};

export default VacancySettings;
