import Banner from 'components/Banner';
import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'icons/Dashboard.icon.svg';

type VacancySettingsBannerProps = {
  schoolId: string;
};

const VacancySettingsBanner: React.FC<VacancySettingsBannerProps> = (props) => {
  const { schoolId } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Banner
      noClose
      icon={<DashboardIcon />}
      title={t('VacancySettings.ES.title')}
      subtitle={t('VacancySettings.ES.description')}
      actions={
        <Button
          onClick={() => navigate(`/vacancies/vacancy-settings/${schoolId}`)}
        >
          {t('VacancySettings.ES.button')}
        </Button>
      }
    />
  );
};

export default VacancySettingsBanner;
