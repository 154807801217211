import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FollowupQuestionsField from '../../components/FollowupQuestionsField';
import VideoDescriptionField from '../../components/VideoDescriptionField';
import { Button } from 'ncoded-component-library';
import { useRequestAdditionalVacancyInformationMutation } from 'api/users.api';
import { useNavigate, useParams } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './AddVacancyQuestionVideo.styles.scss';
import './AddVacancyQuestionVideo.styles.responsive.scss';

type FormValues = {
  followUpQuestions: Array<string>;
  videoDescription: string;
};

type AddVacancyQuestionVideoProps = {
  className?: string;
};

const AddVacancyQuestionVideo: React.FC<AddVacancyQuestionVideoProps> = (
  props,
) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { schoolId } = useParams();

  const user = useAppSelector(authSelectors.selectUser);

  const { schoolGroup, schools } = user;

  const { schools: groupSchools } = schoolGroup;

  const initialValues = useMemo(() => {
    const selectedSchool = schools?.find((school) => school._id === schoolId);

    return selectedSchool.requestedAdditionalInformation;
  }, [schoolId, schools]);

  const { t } = useTranslation();

  const [requestAdditionalVacancyInfo] =
    useRequestAdditionalVacancyInformationMutation();

  const baseClass = 'add-vacancy-question-video';
  const classes = classNames(baseClass, className);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const { followUpQuestions, videoDescription } = values;
      requestAdditionalVacancyInfo({
        schoolId,
        data: {
          followUpQuestions,
          videoDescription,
        },
      })
        .unwrap()
        .then((data) => {
          const userSchools = schools.map((school) =>
            school._id === data._id ? data : school,
          );

          const gSchools = groupSchools.map((school) =>
            school._id === data._id ? data : school,
          );

          dispatch(
            updateUser({
              ...user,
              schools: userSchools,
              schoolGroup: {
                ...schoolGroup,
                schools: gSchools,
              },
            }),
          );
          dispatch(popSuccess(t('VacancySettings.Add.success')));
          navigate(`/vacancies/vacancy-settings?schoolId=${data._id}`);
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [
      dispatch,
      groupSchools,
      navigate,
      requestAdditionalVacancyInfo,
      schoolGroup,
      schoolId,
      schools,
      t,
      user,
    ],
  );

  return (
    <div className={classes}>
      <GoBackButton to=".." />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({
          values: { followUpQuestions, videoDescription },
          handleSubmit,
          pristine,
          submitting,
        }) => (
          <form className={`${baseClass}__form`} onSubmit={handleSubmit}>
            <p className={`${baseClass}__form__title`}>
              {t('VacancySettings.Add.title')}
            </p>
            <p className="description">
              {t('VacancySettings.Add.description')}
            </p>
            <div className={`${baseClass}__form__questions`}>
              <p className="title">{t('VacancySettings.Add.questions')}</p>
              <p className="description">
                {t('VacancySettings.Add.questionsDescription')}
              </p>
              <FollowupQuestionsField />
            </div>
            <div className={`${baseClass}__form__video`}>
              <p className="title">{t('VacancySettings.Add.video')}</p>
              <p className="description">
                {t('VacancySettings.Add.videoDescription')}
              </p>
              <VideoDescriptionField />
            </div>
            <Button
              type="submit"
              disabled={
                (!followUpQuestions?.length && !videoDescription) ||
                pristine ||
                submitting
              }
            >
              {t('VacancySettings.Add.button')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default AddVacancyQuestionVideo;
