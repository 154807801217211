import React, { useCallback, useMemo, useState } from 'react';
import GoBackButton from 'components/GoBackButton';
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate, useParams as useUrlParams } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import SchoolProfileCard from './components/SchoolProfileCard';
import confirm from 'modules/confirm';
import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useSchoolProfile from './hooks/useSchoolProfile';
import PageLoader from 'components/PageLoader';
import DateService from 'services/Date.service';
import usePublicSchoolType from 'hooks/usePublicSchoolType';
import MembersTable from '../../../Members/components/MembersTable';
import { useGetMembersQuery } from 'api/members.api';
import { useParams } from 'hooks/useParams';
import { PaginatedResponse } from 'types';
import Pagination from 'components/Pagination';
import { User } from 'models/User';
import LinkedAssociations from '../../components/LinkedAssociations';
import WidgetUrlInput from '../../components/WidgetUrlInput';
import UploadSchoolLogo from 'components/UploadSchoolLogo';
import { Role } from 'searchality-data';

import './SchoolProfile.styles.scss';

const SchoolProfile: React.FC = () => {
  const { schoolId } = useUrlParams();

  const initialParams = {
    $limit: 10,
    $populate: ['schools'],
    $where: {
      schoolIds: { $in: [schoolId] },
    },
  };

  const { params, changeCurrentPage } = useParams(initialParams);

  const { isLoading, hasOngoingVacancies } = useSchoolProfile(schoolId);

  const [isDeleting, setIsDeleting] = useState(false);

  const user = useAppSelector(authSelectors.selectUser);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { schoolGroup, ...restOfUser } = useAppSelector(
    authSelectors.selectUser,
  );

  const publicSchoolType = usePublicSchoolType();

  const { data } = useGetMembersQuery(params);

  const { items: members, ...paginationProps } = data || {};

  const school = useMemo(
    () => schoolGroup.schools.find((el) => el._id === schoolId),
    [schoolGroup.schools, schoolId],
  );

  const isCurrentUserAO = useMemo(
    () => user.role === Role.AccountOwner,
    [user.role],
  );

  const handleOngoingVacancies = useCallback(async () => {
    await confirm({
      title: t('RemoveSchoolModalTitleOngoing.title'),
      content: <p>{t('RemoveSchoolModalTitleOngoing.description')}</p>,
      cancelBtnText: t('RemoveSchoolModalTitleOngoing.cancel'),
      showConfirm: false,
    });
  }, [t]);

  const handleDeleteSchool = useCallback(async () => {
    const { _id, schoolGroupId } = school;
    setIsDeleting(true);
    try {
      await api.schools.deleteSchool(schoolGroupId, _id);
      setIsDeleting(false);
      const newSchools = schoolGroup.schools.filter((el) => el._id !== _id);
      dispatch(
        updateUser({
          ...restOfUser,
          schools: newSchools,
          schoolIds: newSchools.map(({ _id }) => _id),
          schoolGroup: { ...schoolGroup, schools: newSchools },
        }),
      );
      dispatch(popSuccess(t('schoolDeletedSuccess')));
      navigate('../school-settings');
    } catch (error) {
      console.error(error);
      dispatch(popServerError(error));
    } finally {
      setIsDeleting(false);
    }
  }, [dispatch, navigate, restOfUser, school, schoolGroup, t]);

  const handleConfirmationModal = useCallback(async () => {
    await confirm({
      title: t('removeSchoolModal.title', {
        schoolType: publicSchoolType,
      }),
      content: (
        <p>
          {schoolGroup.subscriptionEndDate
            ? t('removeSchoolModal.descriptionWithPrice', {
                date: DateService.getUSADateFormatLong(
                  DateService.getDayBefore(schoolGroup.subscriptionEndDate),
                ),
              })
            : t('removeSchoolModal.description')}
        </p>
      ),
      confirmBtnText: t('removeSchoolModal.buttonText'),
      onSubmit: () => {
        handleDeleteSchool();
      },
    });
  }, [
    handleDeleteSchool,
    publicSchoolType,
    schoolGroup.subscriptionEndDate,
    t,
  ]);

  const baseClass = 'school-profile';

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={baseClass}>
      <GoBackButton to="/my-account/school-settings" />
      <h1>{school?.name}</h1>
      {isCurrentUserAO && (
        <>
          <p className={`${baseClass}__description`}>
            {t('billingInformation')}
          </p>
          <SchoolProfileCard schoolId={school?._id} />
          <Button
            variant="outline"
            className={`${baseClass}__remove-button`}
            onClick={() => {
              !hasOngoingVacancies
                ? handleConfirmationModal()
                : handleOngoingVacancies();
            }}
            disabled={isDeleting}
          >
            {t('removeSchoolFromPlan')}
          </Button>
          <LinkedAssociations schoolId={schoolId} />
          <WidgetUrlInput schoolId={schoolId} />
        </>
      )}
      <UploadSchoolLogo schoolId={schoolId} />
      {isCurrentUserAO && (
        <div className={`${baseClass}__members`}>
          <p className={`${baseClass}__members__title`}>
            {t('membersAndPermissions')}
          </p>
          <p className={`${baseClass}__members__sub`}>
            {t('membersAndPermissionsSub')}
          </p>
          <MembersTable
            members={members}
            className={`${baseClass}__members__table`}
            schoolName={school?.name}
          />
          {(paginationProps as PaginatedResponse<User>)?.totalPages > 1 && (
            <Pagination
              onChange={changeCurrentPage}
              {...(paginationProps as PaginatedResponse<User>)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SchoolProfile;
