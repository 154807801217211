import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { useTranslation } from 'react-i18next';
import confirm from 'modules/confirm';
import api from 'api';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import useSubscriptionStatus from 'router/subrouters/Dashboard/hooks/useSubscriptionStatus';

import './PaymentMethodItem.styles.scss';

type PaymentMethodItemProps = {
  className?: string;
  icon?: JSX.Element;
  label: string;
  isDefault?: boolean;
  id?: string;
  checked?: boolean;
  showDelete?: boolean;
  onClick?: () => void;
};

const PaymentMethodItem: React.FC<PaymentMethodItemProps> = (props) => {
  const {
    className,
    label,
    icon,
    isDefault,
    id,
    checked,
    onClick,
    showDelete,
  } = props;

  const { paymentInfo, updatePaymentInfo } = useContext(PaymentMethodsContext);

  const { t } = useTranslation();

  const subscritpionStatus = useSubscriptionStatus();

  const classes = classNames('payment-method-item', className);

  const handleUpdatePaymentInfo = useCallback(
    (id: string) => {
      const { paymentMethods } = paymentInfo;
      updatePaymentInfo({
        ...paymentInfo,
        paymentMethods: paymentMethods.filter((el) => el.id !== id),
      });
    },
    [paymentInfo, updatePaymentInfo],
  );

  const handleDelete = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      id: string,
      isDefault: boolean,
    ) => {
      event.stopPropagation();

      if (isDefault && paymentInfo?.paymentMethods?.length > 1) {
        await confirm({
          title: t('DeletePaymentMethod.titleDefualt'),
          content: <p>{t('DeletePaymentMethod.descriptionDefualt')}</p>,
          showConfirm: false,
        });
        return;
      }

      if (
        isDefault &&
        paymentInfo?.paymentMethods?.length === 1 &&
        subscritpionStatus !== 'inactive'
      ) {
        await confirm({
          title: t('DeletePaymentMethod.titleDefualtActive'),
          content: <p>{t('DeletePaymentMethod.descriptionDefualtActive')}</p>,
          showConfirm: false,
        });
        return;
      }

      await confirm({
        title: t('DeletePaymentMethod.title'),
        onSubmit: async () => {
          try {
            await api.schools.deletePaymentMethod(id);
            handleUpdatePaymentInfo(id);
          } catch (error) {
            console.error(error);
          }
        },
        className: 'detach-payment-method',
      });
    },
    [
      handleUpdatePaymentInfo,
      paymentInfo?.paymentMethods?.length,
      subscritpionStatus,
      t,
    ],
  );

  return (
    <div className={classes} onClick={onClick}>
      {id ? (
        <div
          className={classNames('checkbox', { 'checkbox--filled': checked })}
        >
          <div />
        </div>
      ) : (
        <AddIcon />
      )}
      <div className="payment-method-item__label">
        {icon}
        <p>{label}</p>
      </div>
      {isDefault && <small>{t('default')}</small>}
      {showDelete && (
        <button
          className="svg-button-wrapper"
          onClick={(ev) => handleDelete(ev, id, isDefault)}
        >
          <DeleteIcon />
        </button>
      )}
    </div>
  );
};

export default PaymentMethodItem;
