import React from 'react';
import './Heading.styles.scss';
import './Heading.styles.responsive.scss';

type HeadingProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

const Heading: React.FC<HeadingProps> = ({ title, subtitle, children }) => {
  const baseClass = 'searchality-heading';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__left`}>
        {title && <h1>{title}</h1>}
        {subtitle && <h6>{subtitle}</h6>}
      </div>
      <div className={`${baseClass}__right`}>{children}</div>
    </div>
  );
};

export default Heading;
