import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import confirm from 'modules/confirm';
import { Button } from 'ncoded-component-library';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';

import './AccountEditWrapper.styles.scss';
import './AccountEditWrapper.styles.responsive.scss';

type AccountEditWrapperProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  goBackTo: string;
  isDirty: boolean;
  handleSave: any;
  submitting: boolean;
};

const AccountEditWrapper: React.FC<AccountEditWrapperProps> = (props) => {
  const {
    className,
    children,
    title,
    subtitle,
    isDirty,
    handleSave,
    submitting,
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const baseClass = 'account-edit-wrapper';
  const classes = classNames(baseClass, className);

  const handleClose = useCallback(async () => {
    if (!isDirty) {
      return navigate('..');
    }
    await confirm({
      title: t('myAccount.SchoolGroupInfo.cancel'),
      onSubmit: () => {
        navigate('..');
      },
    });
  }, [isDirty, navigate, t]);

  return (
    <div className={classes}>
      <div className={`${baseClass}__header`}>
        <div className={`${baseClass}__header__text`}>
          {title && <h1>{title}</h1>}
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div className={`${baseClass}__header__actions`}>
          <Button
            icon={<CloseIcon />}
            iconPosition="right"
            onClick={handleClose}
            variant="outline"
          >
            {t('cancel')}
          </Button>
          <Button
            icon={<DoneIcon />}
            className={`${baseClass}__header__actions__save`}
            iconPosition="right"
            onClick={handleSave}
            disabled={!isDirty || submitting}
          >
            {t('saveChanges')}
          </Button>
        </div>
      </div>
      <div className={`${baseClass}__content`}>{children}</div>
    </div>
  );
};

export default AccountEditWrapper;
