import React from 'react';
import classNames from 'classnames';
import useYourSubscriptionList from '../../hooks/useYourSubscriptionList';
import ReviewCardWrapper from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';

type YourSubscriptionProps = {
  className?: string;
};

const YourSubscription: React.FC<YourSubscriptionProps> = (props) => {
  const { className } = props;

  const list = useYourSubscriptionList();
  const { t } = useTranslation();

  const classes = classNames('your-subscription', className);

  return (
    <ReviewCardWrapper
      className={classes}
      title={t('YourSubscription.title')}
      list={list}
      isInnerTitle
    />
  );
};

export default YourSubscription;
