import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import { batch } from 'react-redux';
import QuestionVideoTag from '../QuestionVideoTag';
import InputField from 'components/InputField';
import { Button } from 'ncoded-component-library';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';

import './VideoDescriptionField.styles.scss';

type VideoDescriptionFieldProps = {
  className?: string;
};

const VideoDescriptionField: React.FC<VideoDescriptionFieldProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { values } = useFormState();
  const { change } = useForm();

  const { videoDescription, video } = values;

  const baseClass = 'video-description-field';
  const classes = classNames(baseClass, className);

  const onAdd = useCallback(() => {
    if (!video) return;

    batch(() => {
      change('videoDescription', video);
      change('video', '');
    });
  }, [change, video]);

  return (
    <div className={classes}>
      {!!videoDescription && (
        <QuestionVideoTag
          value={videoDescription}
          description={t('VacancySettings.Add.videoTagDesc')}
          handleDelete={() => {
            change('videoDescription', '');
          }}
        />
      )}
      {!videoDescription && (
        <div className={`${baseClass}__video-wrapper`}>
          <Field
            name="video"
            component={InputField}
            label={t('VacancySettings.Add.videoLabel')}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onAdd();
              }
            }}
          />
          <Button icon={<AddIcon />} iconPosition="right" onClick={onAdd} />
        </div>
      )}
      <Field name="videoDescription" render={() => null} hidden />
    </div>
  );
};

export default VideoDescriptionField;
