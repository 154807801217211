import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageLevel } from 'searchality-data';

type LanguageLevelKey = keyof typeof LanguageLevel;

const useAnotherLanguageLevel = () => {
  const { t } = useTranslation();

  const anotherLanguageLevel = useMemo(
    () =>
      Object.keys(LanguageLevel).map((key) => ({
        label: t(`LanguageLevel.${key}`),
        value: LanguageLevel[key as LanguageLevelKey],
      })),
    [t],
  );

  return anotherLanguageLevel;
};

export default useAnotherLanguageLevel;
