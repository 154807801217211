import React, { useContext } from 'react';
import classNames from 'classnames';
import PaymentDetailsCard from './components/PaymentDetailsCard';
import PageLoader from 'components/PageLoader';
import SubscriptionCard from './components/SubscriptionCard';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import InvoiceTable from './components/InvoiceTable';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

import './Payment.styles.scss';
import './Payment.styles.responsive.scss';
import { SchoolGroupStructureType } from 'searchality-data';

type PaymentProps = {
  className?: string;
};

const Payment: React.FC<PaymentProps> = (props) => {
  const { className } = props;

  const {
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isLoading, paymentInfo } = useContext(PaymentMethodsContext);

  const haveDefaultPaymentInfo = !!paymentInfo?.paymentMethods?.find(
    ({ isDefault }) => isDefault,
  );

  const classes = classNames('payment', className);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className={classes}>
        <SubscriptionCard />
        {haveDefaultPaymentInfo && <PaymentDetailsCard />}
      </div>
      <div className="payment__manage-subscription">
        <Button onClick={() => navigate('../manage-subscription')}>
          {t('manageSubscription')}
        </Button>
        <p className="descriptive">
          {t(
            structureType === SchoolGroupStructureType.STANDALONE
              ? 'manageSubscriptionDescStandalone'
              : 'manageSubscriptionDesc',
          )}
        </p>
      </div>
      <ValueContextProvider isMultiple>
        <InvoiceTable />
      </ValueContextProvider>
    </>
  );
};

export default Payment;
