import React from 'react';
import classNames from 'classnames';

import './ErrorMessage.styles.scss';

type ErrorMessageProps = {
  message: string;
  className?: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, className }) => {
  const classes = classNames('searchality-error-message', className);

  return (
    <div className={classes}>
      <p>{message}</p>
    </div>
  );
};

export default ErrorMessage;
