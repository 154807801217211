import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/User';
import { TeacherProfileStep } from 'searchality-data';

type TeacherProfileBuilder = {
  allRoutes: TeacherProfileStep[];
  currentRoute?: TeacherProfileStep;
  finishedRoutes?: TeacherProfileStep[];
  nextRoute?: TeacherProfileStep;
  temporaryChanges?: User;
  status: 'UNSET' | 'INITIALIZED' | 'SUBMITTED';
};

const initialState: TeacherProfileBuilder = {
  allRoutes: [],
  status: 'UNSET',
};

const initRoutes = createAction('initRoutes');
const updateFinishedSteps = createAction('updateFinishedSteps');
const nextRoute = createAction('nextRoute');
const jumpToRoute = createAction('jumpToRoute');
const updateStatus = createAction('statusUpdate');

const saveTemporaryChanges = createAction('saveTemporaryChanges');
const clearTemporaryChanges = createAction('clearTemporaryChanges');

const teacherProfileBuilder = createSlice({
  name: 'teacherProfileBuilder',
  initialState,
  reducers: {
    [initRoutes.type]: (
      _,
      action: PayloadAction<{
        allRoutes: TeacherProfileStep[];
        finishedRoutes: TeacherProfileStep[];
        currentRoute: TeacherProfileStep;
      }>,
    ) => {
      const { allRoutes, finishedRoutes, currentRoute } = action.payload;

      return {
        allRoutes,
        finishedRoutes,
        currentRoute,
        status: 'INITIALIZED',
      };
    },
    [nextRoute.type]: (state) => {
      const currentRouteIndex = state.allRoutes.findIndex(
        (route) => route === state.currentRoute,
      );

      state.currentRoute = state.allRoutes[currentRouteIndex + 1];
    },
    [jumpToRoute.type]: (state, action: PayloadAction<TeacherProfileStep>) => {
      state.currentRoute = action.payload;
    },
    [updateStatus.type]: (
      state,
      action: PayloadAction<'UNSET' | 'INITIALIZED' | 'SUBMITTED'>,
    ) => {
      state.status = action.payload;
    },
    [updateFinishedSteps.type]: (
      state,
      action: PayloadAction<TeacherProfileStep[]>,
    ) => {
      state.finishedRoutes = action.payload;
    },
    [saveTemporaryChanges.type]: (state, action: PayloadAction<User>) => {
      state.temporaryChanges = action.payload;
    },
    [clearTemporaryChanges.type]: (state, action: PayloadAction<User>) => {
      state.temporaryChanges = action.payload;
    },
  },
});

export default teacherProfileBuilder.reducer;

export const teacherProfileBuilderActions = teacherProfileBuilder.actions;
