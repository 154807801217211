import classNames from 'classnames';
import React, { useMemo } from 'react';
import Dropzone from 'components/Dropzone';
import { FieldRenderProps } from 'react-final-form';

import './DropzoneField.styles.scss';

type DropzoneFieldProps = FieldRenderProps<File[], HTMLElement>;

const DropzoneField: React.FC<DropzoneFieldProps> = (props) => {
  const {
    className,
    input: { value, onChange },
    meta: { error, touched },
    ...rest
  } = props;

  const classes = classNames(
    'field-dropzone',
    { 'field-dropzone--error': error },
    className,
  );
  const hasError = useMemo(() => error && touched, [error, touched]);

  return (
    <div className={classes}>
      <Dropzone
        className={classNames('collapsabile-dropzone-width', {
          'collapsabile-dropzone-width--preview':
            value?.length && !rest.showLinks,
        })}
        value={value ? value : []}
        onChange={onChange}
        key={value?.[0]?.name}
        {...rest}
      />
      {hasError && <small>{error}</small>}
    </div>
  );
};

export default DropzoneField;
