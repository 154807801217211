import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import authSelectors from 'store/selectors/auth.selectors';

export default function useSubscriptionStatus() {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { isSubscriptionCancelledAtPeriodEnd, subscriptionId } = schoolGroup;

  return useMemo(() => {
    if (subscriptionId && !isSubscriptionCancelledAtPeriodEnd) {
      return 'active';
    }

    return 'inactive';
  }, [isSubscriptionCancelledAtPeriodEnd, subscriptionId]);
}
