import ButtonDropdown from 'components/ButtonDropdown';
import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TeacherProfileStatus } from 'searchality-data';

import '../TPBTopActions.styles.scss';

const PublishedProfileActions = ({
  onSave,
  onCancel,
  saveButtonDsiabled,
}: {
  onSave: (successCallback: () => void) => void;
  saveButtonDsiabled?: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dropdownContenList = [
    {
      label: t('saveAndExit'),
      onClick: () => onSave(() => navigate('/')),
    },
  ];

  return (
    <>
      <p className="profile-status">
        {t('status')}
        <strong>{t(TeacherProfileStatus.PUBLISHED)}</strong>
      </p>
      <Button variant="outline" onClick={onCancel}>
        {t('cancel')}
      </Button>
      <ButtonDropdown
        buttonText={t('saveAndPreview')}
        contentList={dropdownContenList}
        onButtonClick={() => onSave(() => navigate('../preview'))}
        buttonDisabled={saveButtonDsiabled}
      />
    </>
  );
};

export default PublishedProfileActions;
