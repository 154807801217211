import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default (subjects: string[]) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return subjects?.map((value) => ({
      label: t(`subjectGrades.${value}`),
      value,
    }));
  }, [subjects, t]);

  return options;
};
