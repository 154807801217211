import React from 'react';
import classNames from 'classnames';
import { TemplateIllustrationProps } from 'types';

import './TemplateIllustration.styles.scss';

const TemplateIllustration: React.FC<TemplateIllustrationProps> = (props) => {
  const {
    className,
    img,
    title,
    description,
    headingLevel = 1,
    children,
    imgFirst = true,
  } = props;

  const classes = classNames('template-illustration', className);

  const Heading = `h${headingLevel}` as const;

  return (
    <div className={classes}>
      {img && imgFirst && (
        <div className="template-illustration__image-container">{img}</div>
      )}
      <Heading>{title}</Heading>
      {description && <p>{description}</p>}
      {img && !imgFirst && (
        <div className="template-illustration__image-container">{img}</div>
      )}
      {children}
    </div>
  );
};

export default TemplateIllustration;
