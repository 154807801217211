import classNames from 'classnames';
import PhotoPreview from 'components/PhotoPreview';
import { Candidate } from 'models/Candidate';
import React, { useMemo } from 'react';
import ProfileActions from 'router/subrouters/Dashboard/components/Profile/components/ProfileActions';
import DateService from 'services/Date.service';
import { getNameInitials } from 'utils';
import { ReactComponent as FireIcon } from 'icons/Fire.icon.svg';

import './CandidateCard.styles.scss';
import './CandidateCard.styles.responsive.scss';

export type CandidateCardProps = {
  isActive?: boolean;
  onClick?: () => void;
  suffixNode?: React.ReactNode;
  haveActions?: boolean;
} & Pick<
  Candidate,
  | '_id'
  | 'statusInSchool'
  | 'firstName'
  | 'lastName'
  | 'imageFile'
  | 'vacancy'
  | 'createdAt'
  | 'color'
  | 'isFavourite'
  | 'isArchived'
  | 'wasMatched'
>;

const CandidateCard: React.FC<CandidateCardProps> = ({
  statusInSchool,
  firstName,
  _id,
  lastName,
  imageFile,
  createdAt,
  color,
  isActive = false,
  onClick,
  suffixNode,
  haveActions = false,
  isFavourite = false,
  isArchived = false,
  wasMatched = false,
}) => {
  const baseClassname = 'searchality-candidate-card';

  const imageFileSrc = useMemo(() => {
    if (!imageFile) return null;
    const imageSrc128 = imageFile.variants.find((v) => v.width === 128);
    if (imageSrc128) {
      return imageSrc128.url;
    }
    return imageFile.url;
  }, [imageFile]);

  const classes = classNames(baseClassname, {
    [`${baseClassname}--active`]: isActive,
    [`${baseClassname}--clickable`]: !!onClick,
    [`${baseClassname}--actions`]: haveActions,
  });

  return (
    <li className={classes} onClick={onClick}>
      <section className={`${baseClassname}__left`}>
        <PhotoPreview
          src={imageFileSrc}
          placeHolderText={getNameInitials(firstName, lastName)}
          backgroundColor={color}
        />
        <div className={`${baseClassname}__left__info`}>
          <h1>
            {firstName}&nbsp;{lastName}&nbsp;{wasMatched && <FireIcon />}
          </h1>
          <span className={`${baseClassname}__left__info__applied`}>
            <p>Applied: &nbsp;</p>
            <p>{DateService.toDateString(createdAt)}</p>
          </span>
          <span className={`${baseClassname}__left__info__status`}>
            {statusInSchool}
          </span>
        </div>
      </section>
      {suffixNode && (
        <section className={baseClassname + '__right'}>
          {suffixNode}{' '}
          {haveActions && (
            <ProfileActions
              withTitle
              statusInSchool={statusInSchool}
              candidateId={_id}
              isFavourite={isFavourite}
              onlyIcons
              isArchived={isArchived}
            />
          )}
        </section>
      )}
    </li>
  );
};

export default CandidateCard;
