import SliderButtons from 'components/SliderButtons';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import {
  mandatorySteps,
  ProfileStatusCardMapping,
  routesMapping,
} from '../../constants';
import useMandatoryUnfinishedStepsInfo from '../../hooks/useMandatoryUnfinishedStepsInfo';
import './UnfinishedStepsBanner.styles.scss';

const UnfinishedStepsBanner = () => {
  const [currentShowedRouteIndex, setCurrentShowedRouteIndex] = useState(0);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const unfinishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectUnfinishedRoutes,
  );

  const unfinishedOptional = unfinishedRoutes.filter(
    (route) => !mandatorySteps[route],
  );

  const { mandatoryUnfinishedSteps } = useMandatoryUnfinishedStepsInfo();

  const routesToDisplay = mandatoryUnfinishedSteps?.length
    ? mandatoryUnfinishedSteps
    : unfinishedOptional;

  const { title, description } =
    ProfileStatusCardMapping[routesToDisplay[currentShowedRouteIndex]] || {};

  useEffect(() => {
    if (!mandatoryUnfinishedSteps?.length) {
      setCurrentShowedRouteIndex(0);
    }
  }, [mandatoryUnfinishedSteps]);

  return (
    <>
      <div className="unfinised-step-info">
        <div className="unfinised-step-info__content">
          <div className="unfinised-step-info__content__title">
            {t(`${title}`)}
          </div>
          {description ? (
            <div className="unfinised-step-info__content__description">
              {t(description)}
            </div>
          ) : undefined}
          <div className="unfinised-step-info__content__action">
            <Button
              onClick={() =>
                navigate(
                  `../${
                    routesMapping[routesToDisplay[currentShowedRouteIndex]]
                  }`,
                )
              }
            >
              {t('addNow')}
            </Button>
          </div>
        </div>
      </div>
      <div className="unfinised-step-info__actions">
        <SliderButtons
          nextButtonText={t('next')}
          previousButtonText={t('previous')}
          onNext={() =>
            setCurrentShowedRouteIndex((prev) =>
              Math.min(prev + 1, routesToDisplay.length - 1),
            )
          }
          nextButtonDisabled={
            currentShowedRouteIndex === routesToDisplay.length - 1
          }
          previousButtonDisabled={currentShowedRouteIndex === 0}
          onPrevious={() =>
            setCurrentShowedRouteIndex((prev) => Math.max(prev - 1, 0))
          }
        />
      </div>
    </>
  );
};

export default UnfinishedStepsBanner;
