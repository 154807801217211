import useAppDispatch from 'hooks/useAppDispatch';
import { Button, Modal, Select } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { VacancyDeletionReason } from 'searchality-data';
import { useDeleteVacancyMutation } from 'api/vacancies.api';
import paramsActions from 'store/actions/params.actions';
import { Params } from 'types';
import { batch } from 'react-redux';

import './VacancyDeleteConfirmationModal.styles.scss';

const VacancyDeleteConfirmationModal: React.ForwardRefRenderFunction<
  ModalRef,
  {
    vacancyId: string;
    closeModal: () => void;
    onDeleteSuccess?: (vacancyId: string) => void;
    initialParams?: Partial<Params>;
  }
> = ({ vacancyId, closeModal, onDeleteSuccess, initialParams }, ref) => {
  const { t } = useTranslation();

  const [reason, setReason] = useState<string>('');

  const dispatch = useAppDispatch();

  const reasonsForDeletion = Object.entries(VacancyDeletionReason).map(
    ({ 0: key, 1: value }) => ({
      label: t(`VacancyDeletionReason.${key}`),
      value: value as string,
    }),
  );

  const [deleteVacancy] = useDeleteVacancyMutation();

  const handleDeleteVacancy = useCallback(() => {
    return deleteVacancy({ id: vacancyId, reason })
      .unwrap()
      .then((data) => {
        if (data?.deleted) {
          dispatch(popSuccess(t('vacancyDelete.success')));
          onDeleteSuccess && onDeleteSuccess(vacancyId);
          batch(() => {
            dispatch(paramsActions.resetFilters('vacanciesParams'));
            dispatch(paramsActions.changeCurrentPage('vacanciesParams', 1));
            initialParams &&
              dispatch(
                paramsActions.initializeParams(
                  'vacanciesParams',
                  initialParams,
                ),
              );
          });
        } else {
          dispatch(popError(t('vacancyDelete.fail')));
        }
      })
      .catch((err) => {
        dispatch(popServerError(err));
      });
  }, [
    deleteVacancy,
    dispatch,
    initialParams,
    onDeleteSuccess,
    reason,
    t,
    vacancyId,
  ]);

  const handleOnClose = () => {
    setReason('');
    closeModal();
  };

  return (
    <Modal
      ref={ref}
      title={t('vacancyDelete.title')}
      className="vacancy-delete-confirmation-modal"
      footer={
        <>
          <Button variant="outline" onClick={handleOnClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeleteVacancy}>{t('Confirm')}</Button>
        </>
      }
    >
      <p>{t('vacancyDelete.description')}</p>
      <Select
        options={reasonsForDeletion}
        value={reason}
        multiple={false}
        onChange={({ value }) => {
          setReason(value as string);
        }}
      />
    </Modal>
  );
};
export default React.forwardRef(VacancyDeleteConfirmationModal);
