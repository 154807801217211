import { AuthBody } from '../../models/AuthBody';
import { RegisterData } from 'types';
import publicHttpClient from 'api/publicHttpClient';
import httpClient from 'api/httpClient';
import { User } from 'models/User';

const authPath = (routePath: TemplateStringsArray) => `/api/auth/${routePath}`;

function register(userData: RegisterData) {
  return publicHttpClient.post<AuthBody>(authPath`register`, userData);
}

function login({ email, password }: { email: string; password: string }) {
  return publicHttpClient.post<AuthBody>(authPath`login`, {
    username: email,
    password,
  });
}

function confirmAssociationAccount({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  return publicHttpClient.post<AuthBody>(
    authPath`association/confirm-invitation`,
    {
      password,
      token,
    },
  );
}

function sendItAgain() {
  return httpClient.post('/auth/email-verification/resend');
}

function verifyEmail(token: string) {
  return publicHttpClient
    .post<AuthBody>(authPath`email-verification`, { token })
    .then(({ data }) => data);
}

function forgotPassword(email: string) {
  return publicHttpClient.post(authPath`forgot-password`, { email });
}

function resetPassword(password: string, token: string) {
  return publicHttpClient.patch(authPath`reset-password`, { token, password });
}
function changePassword(values: {
  newPassword: string;
  currentPassword: string;
}) {
  return httpClient.patch('auth/change-password', values);
}

function changeEmail(values: {
  currentEmail: string;
  currentPassword: string;
  newEmail: string;
}) {
  return httpClient.patch<User>('auth/change-email', values);
}

function confirmSchoolInvitation(userData: RegisterData & { token: string }) {
  return httpClient.post<AuthBody>('auth/confirm-invitation', { ...userData });
}
function confirmAssociationInvitation(
  userData: RegisterData & { token: string },
) {
  return httpClient.post<AuthBody>('auth/association/confirm-invitation', {
    ...userData,
  });
}

function publishTeacherProfile() {
  return httpClient.post<User>('/teacher-users/profile/publish');
}

export default {
  register,
  login,
  verifyEmail,
  sendItAgain,
  forgotPassword,
  resetPassword,
  changePassword,
  changeEmail,
  confirmSchoolInvitation,
  publishTeacherProfile,
  confirmAssociationAccount,
  confirmAssociationInvitation,
};
