import { Association } from 'models/Association';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssociationType } from 'searchality-data';

export default (associations: Association[]) => {
  const { t } = useTranslation();

  const nationalAssociations = useMemo(
    () =>
      associations?.filter(
        (association) => association.type === AssociationType.NATIONAL,
      ),
    [associations],
  );

  const stateAssociations = useMemo(
    () =>
      associations?.filter(
        (association) => association.type === AssociationType.STATE,
      ),
    [associations],
  );

  const regionalAssociations = useMemo(
    () =>
      associations?.filter(
        (association) => association.type === AssociationType.REGIONAL,
      ),
    [associations],
  );

  const list = useMemo(
    () => [
      ...(nationalAssociations?.length
        ? [
            {
              label: t('nationalAssociations'),
              value: nationalAssociations.reduce((prev, curr, index) => {
                if (index < 5)
                  return `${prev}${curr.name}${
                    nationalAssociations?.length - 1 === index ? '' : '\n'
                  }`;
                if (index === 6) return prev + '\n...';
                return prev;
              }, ''),
            },
          ]
        : []),
      ...(regionalAssociations?.length
        ? [
            {
              label: t('regionalAssociations'),
              value: regionalAssociations.reduce((prev, curr, index) => {
                if (index < 5)
                  return `${prev}${curr.name}${
                    regionalAssociations?.length - 1 === index ? '' : '\n'
                  }`;
                if (index === 6) return prev + '\n...';
                return prev;
              }, ''),
            },
          ]
        : []),
      ...(stateAssociations?.length
        ? [
            {
              label: t('stateAssociations'),
              value: stateAssociations.reduce((prev, curr, index) => {
                if (index < 5)
                  return `${prev}${curr.name}${
                    stateAssociations?.length - 1 === index ? '' : '\n'
                  }`;
                if (index === 6) return prev + '\n...';
                return prev;
              }, ''),
            },
          ]
        : []),
    ],
    [nationalAssociations, regionalAssociations, stateAssociations, t],
  );

  return list;
};
