import React, { useRef, useMemo } from 'react';
import classNames from 'classnames';
import { Collapsible } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropdownArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { CollapsibleRef } from 'ncoded-component-library/build/components/molecules/Collapsible/Collapsible.component';
import CandidateQualification from 'components/CandidateQualification';
import CandidateRequirement from '../CandidateRequirement';
import { QualificationsDescriptionType } from 'models/Candidate';
import QualificationsService from 'services/Qualifications.service';

import './CandidateProfileQualifications.styles.scss';

type CandidateProfileQualificationsProps = {
  className?: string;
  requirementsData: QualificationsDescriptionType[];
};

const CandidateProfileQualifications: React.FC<
  CandidateProfileQualificationsProps
> = (props) => {
  const { className, requirementsData } = props;

  const { t } = useTranslation();

  const dropdownRef = useRef<CollapsibleRef>(null);

  const baseClass = 'candidate-profile-qualifications';
  const classes = classNames(baseClass, className);

  const numberOfCandidatesQualifications = useMemo(
    () => requirementsData?.filter((el) => el.fitCriteria).length || 0,
    [requirementsData],
  );

  const qualificationsNumber = useMemo(
    () => requirementsData?.length || 0,
    [requirementsData?.length],
  );

  const [variant] =
    QualificationsService.getCandidatesQualificationVariantAndMessage(
      numberOfCandidatesQualifications,
      qualificationsNumber,
    );

  return (
    <Collapsible
      ref={dropdownRef}
      className={classes}
      trigger={
        <>
          <div className="collapsabile__title">
            <p>{t('qualifyingRate')}</p>
            <DropdownArrowIcon />
          </div>
          <CandidateQualification
            qualificationsNumber={qualificationsNumber}
            candidateQualifications={numberOfCandidatesQualifications}
            variant={variant}
            showTitle={false}
          />
          <p>{t('basedOnYourJobRequirement')}</p>
        </>
      }
    >
      <div className={`${classes}__content`}>
        {requirementsData?.map((element) => (
          <CandidateRequirement key={element.title} {...element} />
        ))}
      </div>
    </Collapsible>
  );
};

export default CandidateProfileQualifications;
