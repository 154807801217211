import React, { useCallback } from 'react';
import classNames from 'classnames';
import { UploadedReqDocument } from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import UploadedRequestedDocument from '../UploadedRequestedDocument';
import confirm from 'modules/confirm';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { useDeleteSubmittedDocumentMutation } from 'api/canidates.api';

import './DocumentsSubmittedByCandidate.styles.scss';

type DocumentsSubmittedByCandidateProps = {
  className?: string;
  submittedDocuments: UploadedReqDocument[] & {
    title?: string;
    description?: string;
  };
};

const DocumentsSubmittedByCandidate: React.FC<
  DocumentsSubmittedByCandidateProps
> = (props) => {
  const { className, submittedDocuments } = props;

  const { t } = useTranslation();

  const { candidateId } = useParams();
  const dispatch = useAppDispatch();
  const [deleteSubmittedDocument] = useDeleteSubmittedDocumentMutation();

  const handleDeleteAdditionalFile = useCallback(
    async (key: string, documentId: string) => {
      await confirm({
        title: t('AdditionalDocumentModal.delete'),
        onSubmit: () => {
          deleteSubmittedDocument({ candidateId, documentId, key })
            .unwrap()
            .then(() => {
              dispatch(
                popSuccess(
                  t('RequestCandidatesDocuments.deleteAdditionalSuccess'),
                ),
              );
            })
            .catch((e) => dispatch(popServerError(e)));
        },
      });
    },
    [candidateId, deleteSubmittedDocument, dispatch, t],
  );

  const classes = classNames('documents-submitted-by-candidate', className);

  return (
    <div className={classes}>
      <p>{t('RequestCandidatesDocuments.submitted')}</p>
      <div className="documents-submitted-by-candidate__list">
        {submittedDocuments.map((document) => (
          <UploadedRequestedDocument
            key={document._id}
            {...document}
            deleteKey={document.key}
            handleDeleteFile={handleDeleteAdditionalFile}
          />
        ))}
      </div>
    </div>
  );
};

export default DocumentsSubmittedByCandidate;
