import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import PersonalInformationEditWrapper from '../../../../../../../../components/PersonalInformationEditWrapper';
import { Field, Form } from 'react-final-form';
import PasswordField from 'components/PasswordField';
import {
  passwordValidation,
  required,
  composeValidators,
  emailValidation,
} from 'validations';
import { Button } from 'ncoded-component-library';
import InputField from 'components/InputField';
import api from 'api';
import TemplateIllustration from 'components/TemplateIllustration';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import confirmEmail from 'assets/images/confirm-email.webp';
import { updateUser } from 'store/slices/auth.slice';
import confirm from 'modules/confirm';

import './ChangeEmail.styles.scss';

type FormValues = {
  currentEmail: string;
  currentPassword: string;
  newEmail: string;
};

const ChangeEmail: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { emailConfirmed, email } = useAppSelector(authSelectors.selectUser);

  const intervalRef = useRef<NodeJS.Timeout | number>();
  const [timeLeft, setTimeLeft] = useState(0);

  const startCountdown = useCallback(() => {
    setTimeLeft(() => {
      intervalRef.current = setInterval(
        () => setTimeLeft((old) => old - 1),
        1000,
      );
      return 5;
    });
  }, []);

  const onResend = useCallback(async () => {
    try {
      await api.auth.sendItAgain();
      startCountdown();
    } catch (er) {
      dispatch(popServerError(er));
    }
  }, [dispatch, startCountdown]);

  useEffect(() => {
    if (!timeLeft) clearInterval(intervalRef.current as number);
  }, [timeLeft]);

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  const baseClass = 'change-email';

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await confirm({
        title: t('changeEmailConfirmationMessage', { email: values?.newEmail }),
        onSubmit: async () => {
          try {
            const { data } = await api.auth.changeEmail(values);
            dispatch(updateUser(data));
            dispatch(popSuccess(t('changeEmailPage.success')));
          } catch (e) {
            dispatch(popServerError(e));
          }
        },
      });
    },
    [dispatch, t],
  );

  return emailConfirmed ? (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, dirty }) => (
        <PersonalInformationEditWrapper
          title={t('changeEmailPage.title')}
          subtitle={t('changeEmailPage.subtitle')}
          goBackTo=".."
          isDirty={dirty}
        >
          <form className={`${baseClass}__form`} onSubmit={handleSubmit}>
            <Field
              name="currentEmail"
              component={InputField}
              label={t('typeCurrentEmail')}
              validate={composeValidators(required(), emailValidation())}
              required
            />
            <Field
              name="currentPassword"
              component={PasswordField}
              label={t('typeCurrentPassword')}
              validate={composeValidators(required(), passwordValidation())}
              required
            />
            <Field
              name="newEmail"
              component={InputField}
              label={t('typeNewEmail')}
              validate={composeValidators(required(), emailValidation())}
              required
            />
            <Button type="submit">{t('confirmAndUpdateYourEmail')}</Button>
          </form>
        </PersonalInformationEditWrapper>
      )}
    />
  ) : (
    <PersonalInformationEditWrapper goBackTo=".." isDirty={false}>
      <TemplateIllustration
        className={`${baseClass}__confirm`}
        title={t('Auth.confirmEmail')}
        description={t('Auth.confirmEmailDescription', { email })}
        img={
          <div className="illustration-placeholder">
            <img src={confirmEmail} alt="Confirm email" />
          </div>
        }
      >
        <span>
          <span>{t('Auth.cantFindEmail')}</span>
          <Button
            onClick={onResend}
            disabled={timeLeft > 0}
            className="link-button"
          >
            {!timeLeft
              ? t('Auth.sendItAgain')
              : t('Auth.waitFor', { count: timeLeft })}
          </Button>
        </span>
      </TemplateIllustration>
    </PersonalInformationEditWrapper>
  );
};

export default ChangeEmail;
