import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import Experiences from '../Experiences/Experiences.component';
import { ExperienceType } from 'models/Candidate';

import './ExperiencesField.styles.scss';

type ExperienceFieldProps = {
  className?: string;
} & FieldRenderProps<ExperienceType[], HTMLElement>;

const ExperiencesField: React.FC<ExperienceFieldProps> = (props) => {
  const {
    className,
    input: { value, ...input },
    meta: { error, touched },
  } = props;

  const classes = classNames(
    'experience-field',
    { 'experience-field--error': error },
    className,
  );

  return (
    <div className={classes}>
      <Experiences value={value || []} {...input} />
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default ExperiencesField;
