import React from 'react';
import classNames from 'classnames';
import ReviewCardWrapper from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import usePaymentDetails from './hooks/usePaymentDetails';

import './PaymentDetailsCard.styles.scss';

type PaymentDetailsCardProps = {
  className?: string;
};

const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const list = usePaymentDetails();

  const classes = classNames('payment-details-card', className);

  return (
    <ReviewCardWrapper
      className={classes}
      list={list}
      title={t('paymentDetails')}
    />
  );
};

export default PaymentDetailsCard;
