import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import AddBankAccountMethodModal from '../AddBankAccountMethodModal';
import AddCardMethodModal from '../AddCardMethodModal';
import PaymentMethodItem from './components/PaymentMethodItem';
import { ReactComponent as VisaIcon } from 'icons/VisaCard.icon.svg';
import { ReactComponent as MasterIcon } from 'icons/MasterCard.icon.svg';
import { ReactComponent as AmexIcon } from 'icons/AmexCard.icon.svg';
import { ReactComponent as DiscoverIcon } from 'icons/DiscoverCard.icon.svg';
import { ReactComponent as DinersIcon } from 'icons/DinersCard.icon.svg';
import { ReactComponent as JcbIcon } from 'icons/JcbCard.icon.svg';
import { ReactComponent as UnionpayIcon } from 'icons/UnionpayCard.icon.svg';
import { ReactComponent as BankIcon } from 'icons/Bank.icon.svg';
import { PaymentCard, PaymentMethodType } from 'types';
import { loadStripe } from '@stripe/stripe-js';
import env from 'env';
import api from 'api';
import { Elements } from '@stripe/react-stripe-js';

import './PaymentMethods.styles.scss';

type PaymentMethodsProps = {
  className?: string;
  checked: string;
  onClick: (id: string) => void;
  showDelete?: boolean;
};

const PaymentMethods: React.FC<PaymentMethodsProps> = (props) => {
  const { className, checked, onClick, showDelete = false } = props;

  const [bankClientSecret, setBankClientSecret] = useState('');
  const [cardClientSecret, setCardClientSecret] = useState('');

  const { paymentInfo } = useContext(PaymentMethodsContext);

  const addBankAccountRef = useRef(null);
  const addCardRef = useRef(null);

  const { t } = useTranslation();

  const stripePromise = loadStripe(env.STRIPE_KEY);

  const classes = classNames('payment-methods', className);

  const getIcon = useCallback((type: PaymentMethodType, card: PaymentCard) => {
    if (type === 'us_bank_account') return <BankIcon />;

    const { brand } = card;

    if (brand === 'visa') return <VisaIcon />;
    if (brand === 'mastercard') return <MasterIcon />;
    if (brand === 'amex') return <AmexIcon />;
    if (brand === 'discover') return <DiscoverIcon />;
    if (brand === 'diners') return <DinersIcon />;
    if (brand === 'jcb') return <JcbIcon />;
    if (brand === 'unionpay') return <UnionpayIcon />;

    return null;
  }, []);

  const paymentMethodsItems = useMemo(
    () =>
      paymentInfo?.paymentMethods?.map(
        ({ type, id, isDefault, usBankAccount, card }) => (
          <PaymentMethodItem
            key={id}
            id={id}
            showDelete={showDelete}
            icon={getIcon(type, card)}
            checked={checked === id}
            isDefault={isDefault}
            onClick={() => (onClick ? onClick(id) : undefined)}
            label={
              type === 'card'
                ? t('PaymentMethods.cardLabel', {
                    brand:
                      card.brand.slice(0, 1).toUpperCase() +
                      card.brand.slice(1),
                    last4: card.last4,
                  })
                : usBankAccount.bank_name
            }
          />
        ),
      ),
    [checked, getIcon, onClick, paymentInfo?.paymentMethods, showDelete, t],
  );

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { clientSecret },
        } = await api.schools.createSetupIntent(['us_bank_account']);
        setBankClientSecret(clientSecret);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [paymentInfo]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { clientSecret },
        } = await api.schools.createSetupIntent(['card']);
        setCardClientSecret(clientSecret);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [paymentInfo]);

  return (
    <div className={classes}>
      <p>{t('PaymentMethods.title')}</p>
      {paymentMethodsItems}
      <PaymentMethodItem
        showDelete={false}
        icon={<BankIcon />}
        label={t('PaymentMethods.addBankLabel')}
        onClick={() => addBankAccountRef?.current?.open()}
      />
      <PaymentMethodItem
        showDelete={false}
        label={t('PaymentMethods.addCardLabel')}
        onClick={() => addCardRef?.current?.open()}
        icon={
          <span>
            <VisaIcon />
            <MasterIcon />
          </span>
        }
      />

      {bankClientSecret && (
        <Elements
          key={bankClientSecret}
          stripe={stripePromise}
          options={{
            clientSecret: bankClientSecret,
          }}
        >
          <AddBankAccountMethodModal ref={addBankAccountRef} />
        </Elements>
      )}
      {cardClientSecret && (
        <Elements
          key={cardClientSecret}
          stripe={stripePromise}
          options={{
            clientSecret: cardClientSecret,
          }}
        >
          <AddCardMethodModal ref={addCardRef} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentMethods;
