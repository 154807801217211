import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { logout } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import confirm from 'modules/confirm';
import PersonalInformation from '../../../../components/PersonalInformation';

const TeacherPersonalInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDeleteRequest = useCallback(async () => {
    try {
      await api.user.deleteTeacherAccount();
      dispatch(popSuccess(t('succesfulyDeletedAccount')));
      dispatch(logout());
    } catch (e) {
      dispatch(popServerError(e));
    }
  }, [dispatch, t]);

  const handleDelete = useCallback(async () => {
    if (
      await confirm({
        title: t('teacherProfileDelete'),
        confirmBtnText: t('confirm'),
        onSubmit: () => {
          handleDeleteRequest();
        },
      })
    )
      return;
  }, [handleDeleteRequest, t]);

  return <PersonalInformation handleDelete={handleDelete} isTeacher />;
};

export default TeacherPersonalInformation;
