import { createAction, createSlice } from '@reduxjs/toolkit';
import { Vacancy } from 'models/Vacancy';

const setVacancyBuilderValues = createAction<Vacancy>('setValues');

const vacancyBuilderSlice = createSlice({
  name: 'vacancyBuilder',
  initialState: null,
  reducers: {
    [setVacancyBuilderValues.type]: (state, action) => {
      return action.payload;
    },
    clearValues: (state) => {
      return null;
    },
  },
});

export const vacancyBuilderActions = vacancyBuilderSlice.actions;
export default vacancyBuilderSlice.reducer;
