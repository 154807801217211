import React from 'react';
import classNames from 'classnames';
import VacancyEmptyStateImage from 'assets/images/vacancy-empty-state.webp';
import { useTranslation } from 'react-i18next';

import './VacanciesEmptyState.styles.scss';

type VacancyEmptyStateProps = {
  className?: string;
  type?: 'active' | 'drafted' | 'archived';
};

const VacanciesEmptyState: React.FC<VacancyEmptyStateProps> = (props) => {
  const { className, type = 'active' } = props;

  const { t } = useTranslation();

  const baseClass = 'vacancy-empty-state';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>
        {t(`vacanciesEmptyState.${type}.title`)}
      </p>
      <p className={`${baseClass}__subtitle`}>
        {t(`vacanciesEmptyState.${type}.description`)}
      </p>
      <img src={VacancyEmptyStateImage} alt="vacancy empty state" />
    </div>
  );
};

export default VacanciesEmptyState;
