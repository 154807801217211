import React from 'react';
import classNames from 'classnames';
import EducationsCertificate from 'components/EducationCertificates';
import { FieldRenderProps } from 'react-final-form';
import { TeacherCertificate } from 'models/Candidate';

import './EducationCertificatesField.styles.scss';

type EducationCertificateFieldProps = {
  className?: string;
} & FieldRenderProps<TeacherCertificate[], HTMLElement>;

const EducationCertificatesField: React.FC<EducationCertificateFieldProps> = (
  props,
) => {
  const {
    className,
    input: { value, ...input },
    meta: { error, touched },
  } = props;

  const classes = classNames('education-certificates-field', className);

  return (
    <div className={classes}>
      <EducationsCertificate value={value || []} {...input} />
      {error && touched && <p>{error}</p>}
    </div>
  );
};

export default EducationCertificatesField;
