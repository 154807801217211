import React, { useCallback } from 'react';
import PersonalInformationEditWrapper from '../../../../../../../../components/PersonalInformationEditWrapper';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import { passwordValidation, required, composeValidators } from 'validations';
import PasswordField from 'components/PasswordField';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import api from 'api';
import { useNavigate } from 'react-router-dom';

import './ChangePassword.styles.scss';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const baseClass = 'change-password';

  const handleSubmit = useCallback(
    async ({ confirmNewPassword, ...values }: FormValues) => {
      try {
        await api.auth.changePassword(values);
        dispatch(popSuccess(t('changePasswordPage.success')));
        return navigate('..');
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, t],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) =>
        values?.confirmNewPassword !== values?.newPassword
          ? {
              confirmNewPassword: t('passwordMustMatch'),
            }
          : {}
      }
      render={({ handleSubmit, dirty }) => (
        <PersonalInformationEditWrapper
          title={t('changePasswordPage.title')}
          subtitle={t('changePasswordPage.subtitle')}
          goBackTo=".."
          isDirty={dirty}
        >
          <form className={`${baseClass}__form`} onSubmit={handleSubmit}>
            <Field
              name="currentPassword"
              component={PasswordField}
              label={t('typeCurrentPassword')}
              validate={composeValidators(required(), passwordValidation())}
              required
            />
            <Field
              name="newPassword"
              component={PasswordField}
              label={t('newPassword')}
              validate={composeValidators(required(), passwordValidation())}
              required
            />
            <Field
              name="confirmNewPassword"
              component={PasswordField}
              label={t('repeatNewPassoword')}
              required
            />
            <Button type="submit">{t('confirmNewPassoword')}</Button>
          </form>
        </PersonalInformationEditWrapper>
      )}
    />
  );
};

export default ChangePassword;
