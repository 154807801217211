import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { useUpdateCandidateMutation } from 'api/canidates.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { ApplicationSchoolStatus } from 'searchality-data';
import CheckboxField from 'components/CheckboxField';

import './RejectCondidateModal.styles.scss';

type RejectCondidateModalProps = {
  className?: string;
  candidateId: string;
  firstName: string;
  positionTitle: string;
  schoolName: string;
};

type RejectCandidateValues = {
  message: string;
  skipNotification?: boolean;
  saveCandidate?: boolean;
};

const RejectCondidateModal: ForwardRefRenderFunction<
  ModalRef,
  RejectCondidateModalProps
> = (props, ref) => {
  const { className, candidateId, firstName, positionTitle, schoolName } =
    props;

  const dispatch = useAppDispatch();

  const [updateCandidate] = useUpdateCandidateMutation();

  const { t } = useTranslation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('reject-condidate-modal', className);

  const handleSubmit = useCallback(
    async (values: RejectCandidateValues) => {
      const { message, skipNotification, saveCandidate } = values;

      updateCandidate({
        candidateId,
        message,
        skipNotification,
        saveCandidate,
        candidateStatus: ApplicationSchoolStatus.REJECTED,
      })
        .unwrap()
        .then(() => {
          modal.close();
          dispatch(popSuccess(t('applicationRejected')));
        })
        .catch((error) => dispatch(popError(error.message)));
    },
    [candidateId, dispatch, modal, t, updateCandidate],
  );

  const validate = useCallback(
    (values: RejectCandidateValues) => {
      const { skipNotification, message } = values;
      if (skipNotification) return {};

      if (!message) {
        return { message: t('requiredField') };
      }
    },
    [t],
  );

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('Profile.RejectCandidateModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          skipNotification: false,
          saveCandidate: false,
          message: `${t('Profile.RejectCandidateModal.initial1', {
            firstName,
          })}${schoolName}${t(
            'Profile.RejectCandidateModal.initial2',
          )}${schoolName}${t('Profile.RejectCandidateModal.initial3', {
            firstName,
            positionTitle,
          })}`,
        }}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          invalid,
          form: { change, submit },
        }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('Profile.RejectCandidateModal.description')}</p>
            <Field name="message" component={TextAreaField} />
            <Field
              name="skipNotification"
              component={CheckboxField}
              type="checkbox"
              isStyledDefault
              label={t('Profile.RejectCandidateModal.checkboxLabel')}
            />
            <div className="actions">
              <Button
                disabled={submitting || invalid}
                variant="outline"
                onClick={() => {
                  change('saveCandidate', true);
                  submit();
                }}
              >
                {t('Profile.RejectCandidateModal.rejectSave')}
              </Button>
              <Button
                disabled={submitting || invalid}
                onClick={() => {
                  change('saveCandidate', false);
                  submit();
                }}
              >
                {t('Profile.RejectCandidateModal.rejectDelete')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(RejectCondidateModal);
