import { School } from 'models/School';
import { useMemo } from 'react';

const useSchoolFromYourGroup = (schools: School[]) => {
  const schoolsFromYourGroupOptions = useMemo(
    () =>
      schools?.map(({ name, _id }) => ({
        label: name,
        value: _id,
      })),
    [schools],
  );

  return schoolsFromYourGroupOptions;
};

export default useSchoolFromYourGroup;
