import { useContext, useEffect } from 'react';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';

export default function useNavigationCurrentStep(step: number) {
  const { updateCurrentStep } = useContext(CreateSchoolWizardContext);

  useEffect(() => {
    updateCurrentStep(step);
  }, [step, updateCurrentStep]);
}
