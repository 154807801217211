import React from 'react';
import classNames from 'classnames';
import Checkbox, {
  CheckboxProps,
} from 'ncoded-component-library/build/components/molecules/Checkbox/Checkbox.component';

import './ToggleButton.styles.scss';

export type ToggleButtonProps = CheckboxProps;

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { className, ...rest } = props;

  const classes = classNames('toggle-button', className);

  return (
    <div className={classes}>
      <Checkbox {...rest} />
    </div>
  );
};

export default ToggleButton;
