import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import ImageCropper from 'components/ImageCropper';
import Loading from 'components/Loading';
import classNames from 'classnames';
import { popError } from 'store/slices/popNotifications.slice';
import Tippy from '@tippyjs/react';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import { PROFILE_IMAGE_MAX_FILE_SIZE } from 'constants/general';
import { getFileFromURL } from 'components/ImageCropper/utils';

import './PortraitPhotoModal.styles.scss';

type PortraitPhotoModalProps = {
  src: string;
  className?: string;
  setFile: (file: File) => void;
  cropShape?: 'round' | 'rect';
  formatType?: 'image/jpeg' | 'image/png';
  aspect: number;
};

const PortraitPhotoModal: ForwardRefRenderFunction<
  ModalRef,
  PortraitPhotoModalProps
> = (props, ref) => {
  const { src, className, cropShape, aspect, formatType, setFile } = props;

  const [loading, setLoading] = useState(true);
  const [preparingFile, setPreparingFile] = useState(false);
  const [cropperSrc, setCropperSrc] = useState(src);

  const cropperRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleUpload = useCallback(async () => {
    setPreparingFile(true);

    if (!cropperRef.current.loading) {
      try {
        const result = await cropperRef.current.getFile('portrait.jpg');
        setFile(result);
      } catch (error) {
        console.error(error);
      }
    }

    setPreparingFile(false);
  }, [setFile]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (files[0].size < PROFILE_IMAGE_MAX_FILE_SIZE) {
        setCropperSrc((prev) => (files ? URL.createObjectURL(files[0]) : prev));
      } else {
        dispatch(popError(t('PortraitPhotoModal.tooBigFileError')));
      }

      event.target.value = null;
    },
    [dispatch, t],
  );

  const handleUseOriginal = useCallback(async () => {
    const file = await getFileFromURL(src, 'imageName', formatType);

    setFile(file);
  }, [formatType, setFile, src]);

  useEffect(() => {
    if (src) setCropperSrc(src);
  }, [src]);

  const classes = classNames('portrait-photo-modal', className);

  return (
    <Modal
      ref={ref}
      title={t('PortraitPhotoModal.title')}
      className={classes}
      onOpen={() => setTimeout(() => setLoading(false), 500)}
      onClose={() => setLoading(true)}
      footer={
        <>
          {cropShape === 'rect' && (
            <Button variant="outline" onClick={handleUseOriginal}>
              {t('PortraitPhotoModal.originalSize')}
            </Button>
          )}
          <Button variant="outline" onClick={() => inputRef.current.click()}>
            {t('PortraitPhotoModal.useAnotherPhoto')}
          </Button>
          <Button disabled={preparingFile} onClick={handleUpload}>
            {t('upload')}
          </Button>
        </>
      }
    >
      <>
        <input
          hidden
          type="file"
          ref={inputRef}
          onChange={handleOnChange}
          accept="image/*"
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <ImageCropper
              key={cropperSrc}
              src={cropperSrc}
              ref={cropperRef}
              cropShape={cropShape}
              aspect={aspect}
              formatType={formatType}
            />
            {!aspect && (
              <p className="portrait-photo-modal__description">
                {t('PortraitPhotoModal.description')}
                <Tippy
                  className="tooltip-information portrait-photo-modal__description__tooltip"
                  content={t('PortraitPhotoModal.tooltip')}
                  placement="bottom"
                  arrow={true}
                >
                  <InformationIcon />
                </Tippy>
              </p>
            )}
          </>
        )}
      </>
    </Modal>
  );
};

export default forwardRef(PortraitPhotoModal);
