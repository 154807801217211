import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import EducationsField from './components/EducationsField';
import ExperiencesField from './components/ExperiencesField';
import DropzoneField from 'components/DropzoneField';
import { useParams } from 'react-router-dom';
import FormFieldLabel from 'components/FormFieldLabel';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { VacancyType } from 'searchality-data';
import EducationPhilosophySection from './components/EducationPhilosophySection';

import './YourProfile.styles.scss';
import './YourProfile.styles.responsive.scss';

type YourProfileProps = {
  className?: string;
};

const YourProfile: React.FC<YourProfileProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { jobId } = useParams();
  const { data: job } = useGetOpenJobQuery(jobId);

  const baseClass = 'your-profile';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      {job?.type === VacancyType.ACADEMIC && (
        <>
          <FormFieldLabel text={t('educationQualification')} />
          <Field
            name="yourProfile.education"
            component={EducationsField}
            validate={required()}
          />
          <FormFieldLabel text={t('workExperience')} />
          <Field name="yourProfile.experience" component={ExperiencesField} />
        </>
      )}
      <EducationPhilosophySection />
      {job?.isCoverLetterRequired && (
        <>
          <p className={`${baseClass}__tippy-container`}>
            {t('coverLetter')}
            <small>{t('coverLetterDescription')}</small>
          </p>
          <Field
            name="yourProfile.coverLetter"
            component={DropzoneField}
            validate={required()}
          />
        </>
      )}
    </div>
  );
};

export default YourProfile;
