import React, { ReactNode, useMemo } from 'react';
import { ReactComponent as Vacancies } from 'icons/Vacancy.icon.svg';
import { ReactComponent as Candidate } from 'icons/Candidate.icon.svg';
import { ReactComponent as Members } from 'icons/Members.icon.svg';
import { ReactComponent as Settings } from 'icons/Settings.icon.svg';
import { ReactComponent as Question } from 'icons/Question.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { ReactComponent as Widget } from 'icons/WidgetLink.icon.svg';
import env from 'env';
import PhotoPreview from 'components/PhotoPreview';
import { getNameInitials } from 'utils';
import { Role } from 'searchality-data';
import { useLocation } from 'react-router-dom';

type HeaderItem = {
  to: string;
  label: string;
  isWidgetLink?: boolean;
  Icon: ReactNode;
  roles?: Role[];
  permission?: 'allow' | 'forbid';
  disabled?: boolean;
};

type UserRolesHeaderItemsType = {
  [name: string]: HeaderItem[];
};

export default function () {
  const user = useAppSelector(authSelectors.selectUser);

  const { pathname } = useLocation();

  const associationNavigation = useMemo(
    () => [
      {
        to: '/contact-us',
        label: 'help',
        Icon: <Question />,
      },
      {
        to: '/schools',
        label: 'schools',
        Icon: <Vacancies />,
      },
      {
        to: '/association-members',
        label: 'members',
        Icon: <Members />,
      },
      {
        to: '/association-account',
        label: 'accountNavlinkTitle',
        Icon: <Settings />,
        disabled: pathname.includes('association-account/personal-information'),
      },
    ],
    [pathname],
  );

  const UserRolesHeaderItems: UserRolesHeaderItemsType = useMemo(
    () => ({
      other: [
        {
          to: '/contact-us',
          label: 'help',
          Icon: <Question />,
        },
        {
          to: `${env.WIDGET_ENDPOINT}/?clientId=`,
          label: 'website',
          isWidgetLink: true,
          Icon: <Widget />,
          roles: [Role.AccountOwner, Role.AccountManager],
          permission: 'allow',
        },
        {
          to: '/vacancies',
          label: 'vacancies',
          Icon: <Vacancies />,
          disabled: [
            'vacancies/active',
            'vacancies/archived',
            'vacancies/drafted',
          ].some((el) => pathname.includes(el)),
        },
        {
          to: '/candidates',
          label: 'candidatesCapital',
          roles: [
            Role.AccountOwner,
            Role.AccountManager,
            Role.HiringManager,
            Role.Reviewer,
          ],
          Icon: <Candidate />,
          disabled: [
            'candidates/new',
            'candidates/qualified',
            'candidates/archived',
          ].some((el) => pathname.includes(el)),
        },
        {
          to: '/members',
          label: 'members',
          roles: [Role.AccountOwner, Role.AccountManager, Role.HiringManager],
          Icon: <Members />,
        },
        {
          to: '/my-account',
          label: 'accountNavlinkTitle',
          Icon: <Settings />,
          disabled: pathname.includes('my-account/personal-information'),
        },
      ],
      [Role.Teacher]: [
        {
          to: '/contact-us',
          label: 'help',
          Icon: <Question />,
        },
        {
          to: '/applications',
          label: 'opportunities',
          Icon: <Vacancies />,
          disabled: [
            'applications/active',
            'applications/previous',
            'applications/successful',
          ].some((el) => pathname.includes(el)),
        },
        {
          to: '/profile',
          label: 'profile',
          Icon: <Members />,
        },
        {
          to: '/teacher-account',
          label: 'accountNavlinkTitle',
          Icon: <Settings />,
          disabled: pathname.includes('teacher-account/personal-information'),
        },
      ],
      [Role.AssociationOwner]: associationNavigation,
      [Role.AssociationMember]: associationNavigation,
    }),
    [associationNavigation, pathname],
  );

  return useMemo(() => {
    if (!user) return [];

    const { role, firstName, lastName, imageFile, systemColor } = user;

    const tempRole = [
      Role.AssociationMember,
      Role.AssociationOwner,
      Role.Teacher,
    ].includes(role)
      ? role
      : 'other';

    if (!firstName || !lastName || !imageFile) {
      return UserRolesHeaderItems[tempRole];
    }

    return UserRolesHeaderItems[tempRole].map((el: HeaderItem) => {
      if (!['/my-account', '/teacher-account'].includes(el.to)) return el;

      return {
        ...el,
        Icon: (
          <PhotoPreview
            placeHolderText={getNameInitials(firstName, lastName)}
            size="s"
            src={imageFile?.url}
            backgroundColor={systemColor}
          />
        ),
      };
    });
  }, [UserRolesHeaderItems, user]);
}
