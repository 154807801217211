import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import TabsNavigation from 'components/TabsNavigation';
import { useTranslation } from 'react-i18next';
import Heading from 'components/Heading';
import useApplicationsTabs from './hooks/useApplicationsTabs';
import MainLayout from 'components/MainLayout';

import './Applications.styles.scss';
import './Applications.styles.responsive.scss';

const Applications: React.FC = () => {
  const { t } = useTranslation();

  const tabs = useApplicationsTabs();

  const { opportunityId } = useParams();

  const { pathname } = useLocation();

  const shouldHideTabs =
    tabs.some(({ to }) => pathname.includes(to)) && !opportunityId;

  return (
    <MainLayout headerShadow className="dashboard">
      <div className="searchality-applications">
        {shouldHideTabs && (
          <>
            <Heading title={t('opportunities')} />
            <TabsNavigation tabs={tabs} />
          </>
        )}
        <Outlet />
      </div>
    </MainLayout>
  );
};

export default Applications;
