import React from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import YourSubscription from '../../components/YourSubscription';
import { useTranslation } from 'react-i18next';
import UpgradeSubscription from '../../components/UpgradeSubscription';
import SubscriptionActions from '../../components/SubscriptionActions';
import SubscriptionLink from '../../components/SubscriptionLink';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

import './ManageSubscription.styles.scss';

type ManageSubscriptionProps = {
  className?: string;
};

const ManageSubscription: React.FC<ManageSubscriptionProps> = (props) => {
  const { className } = props;

  const {
    schoolGroup: { subscriptionId },
  } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const classes = classNames('manage-subscription', className);

  return (
    <div className={classes}>
      <GoBackButton to={subscriptionId ? '../subscription' : '../'} />
      <h1>{t('ManageSubscription.title')}</h1>
      <p>{t('ManageSubscription.description')}</p>
      <YourSubscription />
      {subscriptionId && (
        <SubscriptionLink
          to="edit"
          className="edit-subscription"
          priceText={t('editSubscriptionTitle')}
          description={t('editSubscriptionDesc')}
        />
      )}
      <UpgradeSubscription />
      <SubscriptionActions />
    </div>
  );
};

export default ManageSubscription;
