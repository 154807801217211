import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import GtmService from 'services/GTM.service';

const usePageViewEvent = (ms = 1000) => {
  const { pathname } = useLocation();

  const viewCalled = useRef(false);

  const pushPageView = (ms: number) => {
    if (viewCalled.current) return;

    setTimeout(() => {
      viewCalled.current = false;
      GtmService.pageView();
    }, ms);

    viewCalled.current = true;
  };

  useEffect(() => {
    pushPageView(ms);
  }, [pathname, ms]);
};

export default usePageViewEvent;
