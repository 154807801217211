import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Card.styles.scss';

export type CardProps = {
  children?: ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const Card: React.FC<CardProps> = (props) => {
  const { className, children, title, subtitle, description, onClick } = props;

  const classes = classNames(['card', className]);

  return (
    <div className={classes} onClick={onClick}>
      {title && <p className="card__title">{title}</p>}
      {subtitle && <p className="card__subtitle">{subtitle}</p>}
      {description && <p className="card__desc">{description}</p>}
      {children}
    </div>
  );
};

export default Card;
