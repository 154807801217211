import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { ReactComponent as DuplicateIcon } from 'icons/Duplicate.icon.svg';
import { Button } from 'ncoded-component-library';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import { Link } from 'react-router-dom';
import {
  SchoolGroupStructureType,
  WidgetIntegrationStatus,
} from 'searchality-data';
import useStepByStepIntegrationList from './hooks/useStepByStepIntegrationList';
import env from 'env';
import { SEARCHALITY_CONTACT_MAIL_SUPPORT } from 'constants/links';

import './Integration.styles.scss';
import './Integration.styles.responsive.scss';

type IntegrationProps = {
  className?: string;
};

const Integration: React.FC<IntegrationProps> = (props) => {
  const { className } = props;

  const user = useAppSelector(authSelectors.selectUser);
  const {
    schoolGroup: { clientId, widgetIntegrationStatus, schools, structureType },
  } = user;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const stepByStepList = useStepByStepIntegrationList();

  const integrationTextCopy = useMemo(
    () => `How to finalize the setup of your account
    Connect our servers to your website to start posting vacancies directly on your school's website.
    
    In order to add the vacancy widget to your website, you will need to copy-paste the code we provide into index.html of the page that you want to show the vacancies by following step by step instructions. This process is included in Searchality's set-up fee, and our customer service team is happy to do this for you. If you prefer not to give us access to your website, this step can be easily completed by your in-house IT team.
    
    If you experience any issues, please reach out to us through the Contact us page.
    
    
    How to connect Searchality to your website
    This process takes 10 minutes and requires knowledge and access to your current website provider.
    
    If you are ready, here are step-by-step instructions to implement the Searchality widget to your website:
    
    The Searchality widget will be placed in an existing page of your choice on your website. In the head tag, of the page, that you want the widget to be placed you need to add the code provided below:
    
    
            <script src="${env.WIDGET_ENDPOINT}/main.js"></script>
            <link
              href="${env.WIDGET_ENDPOINT}/main.css"
              rel="stylesheet"
              id="searchality-widget-css"    
            />
    
    It will import the Searchality widget code to your site, together with its styles.
    
    After adding the code above you are ready to continue with setting up the widget. Within this page you need to create a container element inside body tag on desired position.This element will hold a widget and should have a specific id property (ex. searchality-widget). You can copy the code below:
    
    
        <div id="searchality-widget"></div>
          
    
    At the end of the body tag (before </body>), copy and paste the following code:
    
            <script>
              {
                document.body.onload = () => {
                  window.Searchality.init({
                    elementId: "searchality-widget",
                    clientId: "${clientId}",
                    clientType: "school",
                    version: 2,
                  });
                };
              }
            </script>
    
    Please keep in mind that the elementId should be the Id of the container element that you created in step 2.
    
    Save these settings, refresh your website and the vacancies will be visible.
    
    After completing all of the steps from above you can use your widget to show your school vacancies on your member associations webpage. If you want your vacancies to be also displayed on the widget of associations you are a member of, please copy the url of the page where your school widget is integrated. You can do that on School Settings page and by selecting the exact school.
    
    Please contact us if you need any help or support with this - if you are able to provide us with temporary permissions, we will complete this integration for you.`,
    [clientId],
  );

  const getWidgetStatusIntegration = useCallback(
    ({
      _id,
      name,
      widgetIntegrationStatus,
    }: {
      _id?: string;
      name: string;
      widgetIntegrationStatus: WidgetIntegrationStatus;
    }) => (
      <p key={_id ?? undefined}>
        <strong>{name}</strong>
        <span
          className={
            widgetIntegrationStatus === WidgetIntegrationStatus.COMPLETED
              ? 'blue'
              : 'orange'
          }
        >
          {t(widgetIntegrationStatus)}
        </span>
      </p>
    ),
    [t],
  );

  const isGroup = structureType === SchoolGroupStructureType.GROUP;

  const baseClass = 'integration';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <section className="integration__widget-status">
        <h1>{t('IntegrationPage.widgetIntegration.title')}</h1>
        {getWidgetStatusIntegration({
          widgetIntegrationStatus,
          name: isGroup ? t('schoolGroup') : schools?.[0]?.name,
        })}
        {isGroup &&
          schools?.map(({ _id, widgetIntegrationStatus, name }) =>
            getWidgetStatusIntegration({ _id, widgetIntegrationStatus, name }),
          )}
      </section>
      <section className="integration__how-to-finalize">
        <h1>{t('IntegrationPage.howToSetupCard.title')}</h1>
        <p className="description">
          {t('IntegrationPage.howToSetupCard.description')}
        </p>
        <div className="explanations">
          <p>
            {t('IntegrationPage.howToSetupCard.explanation1')}
            <code className="descriptive">{'index.html'}</code>
            {t('IntegrationPage.howToSetupCard.explanation2')}
          </p>
          <p>
            {t('IntegrationPage.howToSetupCard.issueContact')}
            <Link to="/contact-us">{t('contactUs')}</Link>
            {t('IntegrationPage.howToSetupCard.page')}
          </p>
        </div>
        <Button
          icon={<DuplicateIcon />}
          iconPosition="right"
          variant="outline"
          onClick={() => {
            navigator.clipboard.writeText(integrationTextCopy);
            dispatch(popSuccess(t('copyToClipboard')));
          }}
        />
      </section>
      <section>
        <h1>{t('IntegrationPage.setupCard.title')}</h1>
        <p className="info">
          <InformationIcon />
          {t('IntegrationPage.setupCard.info')}
        </p>
        <p>{t('IntegrationPage.setupCard.stepByStep')}</p>
        <div className="code-wrapper">
          <div className="code-wrapper__section">
            <ol>
              {stepByStepList?.map((listElement) => (
                <li key={listElement.stepDescription}>
                  <p>{listElement.stepDescription}</p>
                  {listElement.code && (
                    <div>
                      <pre>
                        <code>{listElement.code}</code>
                      </pre>
                      <Button
                        icon={<DuplicateIcon />}
                        iconPosition="right"
                        variant="outline"
                        onClick={() => {
                          navigator.clipboard.writeText(listElement.code);
                          dispatch(popSuccess(t('copyToClipboard')));
                        }}
                      />
                    </div>
                  )}
                  {listElement.additionalDescription && (
                    <p>{listElement.additionalDescription}</p>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>
        <p>
          {t('IntegrationPage.setupCard.finish1')}
          <a href={`mailto:${SEARCHALITY_CONTACT_MAIL_SUPPORT}`}>
            {t('IntegrationPage.setupCard.contactUs')}
          </a>
          {t('IntegrationPage.setupCard.finish2')}
        </p>
      </section>
    </div>
  );
};

export default Integration;
