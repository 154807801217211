import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useSubmitMatchingFeedbackMutation } from 'api/matching.api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { Field, Form } from 'react-final-form';
import CheckboxGroupField from 'components/CheckboxGroupField';
import { MatchingFeedbackReason } from 'searchality-data';

import './NegativeFeedbackModal.styles.scss';
import './NegativeFeedbackModal.styles.responsive.scss';

type NegativeFeedbackModalProps = {
  className?: string;
  navigateAfterSubmit?: boolean;
  opportunityId: string;
};

const NegativeFeedbackModal: ForwardRefRenderFunction<
  ModalRef,
  NegativeFeedbackModalProps
> = (props, ref) => {
  const { className, navigateAfterSubmit = true, opportunityId } = props;

  const classes = classNames('negative-feedback-modal', className);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [submitMatchingFeedback] = useSubmitMatchingFeedbackMutation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const options = useMemo(
    () =>
      Object.values(MatchingFeedbackReason).map((value) => ({
        label: value,
        value: value,
      })),
    [],
  );

  const handleNegativeFeedback = useCallback(
    ({ feedbackReasons }: { feedbackReasons: string[] }) => {
      submitMatchingFeedback({
        matchId: opportunityId,
        isTeacherInterested: false,
        feedbackReasons,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('OpportunityFeedback.feedbackSubmit')));
          !navigateAfterSubmit && modal.close();
          navigateAfterSubmit && navigate('../');
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [
      dispatch,
      modal,
      navigate,
      navigateAfterSubmit,
      opportunityId,
      submitMatchingFeedback,
      t,
    ],
  );

  return (
    <Modal ref={modalRef} fullContent className={classes}>
      <p>{t('OpportunityFeedback.Modal.title')}</p>
      <p>{t('OpportunityFeedback.Modal.description')}</p>
      <Form
        onSubmit={handleNegativeFeedback}
        render={({ handleSubmit, values: { feedbackReasons } }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="feedbackReasons"
              component={CheckboxGroupField}
              options={options}
            />
            <Button type="submit" disabled={!feedbackReasons?.length}>
              {t('confirmChoices')}
            </Button>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(NegativeFeedbackModal);
