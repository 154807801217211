import env from 'env';
import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';

type WidgetIntegrationStep = {
  stepDescription: string;
  code?: string;
  additionalDescription?: string;
};

const useStepByStepIntegrationList = (): WidgetIntegrationStep[] => {
  const user = useAppSelector(authSelectors.selectUser);
  const {
    schoolGroup: { clientId },
  } = user;

  const { t } = useTranslation();

  const renderScriptsCode = useMemo(
    () =>
      `
          <script src="${env.WIDGET_ENDPOINT}/main.js"></script>
          <link
            href="${env.WIDGET_ENDPOINT}/main.css"
            rel="stylesheet"
            id="searchality-widget-css"    
          />`,
    [],
  );

  const renderContainerCode = useMemo(
    () =>
      `
    <div id="searchality-widget"></div>
      `,
    [],
  );

  const renderInitCode = useMemo(
    () =>
      `<script>
      {
        document.body.onload = () => {
          window.Searchality.init({
            elementId: "searchality-widget",
            clientId: "${clientId}",
            clientType: "school",
            version: 2,
          });
        };
      }
    </script>`,
    [clientId],
  );

  return [
    {
      stepDescription: t('IntegrationPage.setupCard.step1'),
      code: renderScriptsCode,
      additionalDescription: t('IntegrationPage.setupCard.step1Description'),
    },
    {
      stepDescription: t('IntegrationPage.setupCard.step2'),
      code: renderContainerCode,
    },
    {
      stepDescription: t('IntegrationPage.setupCard.step3'),
      code: renderInitCode,
      additionalDescription: t('IntegrationPage.setupCard.step3Description'),
    },
    {
      stepDescription: t('IntegrationPage.setupCard.step4'),
    },
    {
      stepDescription: t('IntegrationPage.setupCard.step5'),
    },
  ];
};

export default useStepByStepIntegrationList;
