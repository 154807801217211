import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import ErrorBoundary from 'components/ErrorBoundary';
import { useSelector } from 'react-redux';
import PopNotifications from 'modules/PopNotifications';
import authSelectors from 'store/selectors/auth.selectors';
import PageLoader from 'components/PageLoader';
import { popError } from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import refreshTokenService from 'services/refreshToken.service';
import credentialsService from 'services/credentialsService';
import bus from 'modules/bus';
import useAppDispatch from 'hooks/useAppDispatch';
import usePageViewEvent from 'hooks/usePageViewEvent';
import storageService from 'services/storageService';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';

const App: React.FC = () => {
  const loading = useSelector(authSelectors.selectLoading);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  usePageViewEvent();

  useEffect(() => {
    const customLogout = async () => {
      dispatch(popError(t('SessionExpired')));
      refreshTokenService.reinit();
      credentialsService.removeAuthBody();
      storageService.removeItem(STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin);
      navigate('/auth');
    };

    bus.addEventListener('LOGOUT_SIGNAL', customLogout);

    return () => {
      bus.removeEventListener('LOGOUT_SIGNAL', customLogout);
    };
  }, [t, dispatch, navigate]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <PopNotifications />
      <ErrorBoundary fallback={<p>Something went wrong.</p>}>
        <Routes routes={routes} />
      </ErrorBoundary>
    </>
  );
};

export default App;
