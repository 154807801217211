import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import MatchingTargetRed from 'assets/images/matching-target-red.webp';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import ImageContentModal from 'components/ImageContentModal';
import { Role } from 'searchality-data';
import MatchingTarget from 'assets/images/matching-target.webp';
import { useNavigate } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

import './VacancyCandidatesMathingNotPaid.styles.scss';
import './VacancyCandidatesMathingNotPaid.styles.responsive.scss';

type VacancyCandidatesMathingNotPaidProps = {
  className?: string;
};

const VacancyCandidatesMathingNotPaid: React.FC<
  VacancyCandidatesMathingNotPaidProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { role } = useAppSelector(authSelectors.selectUser);
  const modalRef = useRef<ModalRef>();

  const baseClass = 'vacancy-candidates-mathing-not-paid';
  const classes = classNames(baseClass, className);

  const handleSubscribe = useCallback(() => {
    if (role === Role.AccountOwner) {
      return navigate('/my-account/manage-subscription');
    }

    modalRef.current.open();
  }, [navigate, role]);

  return (
    <div className={classes}>
      <img src={MatchingTargetRed} alt="matching-target-red" />
      <div className={`${baseClass}__right`}>
        <p className={`${baseClass}__right__title`}>
          {t('VacancyCandidatesMathingNotPaid.title')}
        </p>
        <p className={`${baseClass}__right__subtitle`}>
          {t('VacancyCandidatesMathingNotPaid.subtitle')}
        </p>
        <div className={`${baseClass}__right__actions-container`}>
          <Button onClick={handleSubscribe}>{t('subscribe')}</Button>
          <a
            href="https://searchality.com/pricing"
            target="_blank"
            rel="noreferrer"
          >
            {t('learnMore')}
          </a>
        </div>
      </div>
      <ImageContentModal
        ref={modalRef}
        imgSrc={MatchingTarget}
        contentText={t('MatchingVacanciesAddBanner.accountOwnerOnly')}
      />
    </div>
  );
};

export default VacancyCandidatesMathingNotPaid;
