import Axios, { AxiosRequestConfig } from 'axios';
import credentialsService from 'services/credentialsService';
import env from 'env';
import bus from 'modules/bus';
import refreshTokenService, {
  AuthEventTypes,
} from 'services/refreshToken.service';

const httpClient = Axios.create({
  baseURL: `${env.SERVER_ENDPOINT}/api/`,
});

const setToken = async (config: AxiosRequestConfig) => {
  const token = credentialsService.token;
  if (token && config.method !== 'OPTIONS') {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`;
  }
};

const refresh = async (config: AxiosRequestConfig) => {
  await new Promise<void>((resolve, reject) => {
    function removeHandlers() {
      clearTimeout(timeout);
      bus.removeEventListener(
        AuthEventTypes.REFRESH_TOKEN_SUCCESS,
        onRefreshTokenSuccess,
      );
      bus.removeEventListener(
        AuthEventTypes.REFRESH_TOKEN_FAIL,
        onRefreshTokenFail,
      );
    }

    function onRefreshTokenSuccess() {
      setToken(config);
      removeHandlers();
      resolve();
    }

    function onRefreshTokenFail() {
      reject(Error('Could not refresh token'));
      removeHandlers();
    }

    bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess,
    );
    bus.addEventListener(AuthEventTypes.REFRESH_TOKEN_FAIL, onRefreshTokenFail);

    const timeout = setTimeout(() => {
      reject(Error('Could not refresh token'));
      removeHandlers();
      refreshTokenService.refreshingAtm = false;
    }, refreshTokenService.checkRefreshingTreshold);
  });
};

httpClient.interceptors.request.use(
  async function (config) {
    await setToken(config);

    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(null, async (error) => {
  const { response, config } = error;

  if (response && [401].includes(response.status)) {
    if (refreshTokenService.triedRefresh) {
      bus.broadcastEvent('LOGOUT_SIGNAL');

      return;
    }

    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
      return httpClient(config);
    }

    try {
      await refreshTokenService.refreshToken();
      return httpClient(config);
    } catch (err) {
      bus.broadcastEvent('LOGOUT_SIGNAL');
      error.preventDefault = true;
    }
  }
  return Promise.reject(error);
});

export default httpClient;
