import React from 'react';
import classNames from 'classnames';
import EmptyScreen from 'components/EmptyScreen';
import MatchingOpportunitiesImg from 'assets/images/matching-opportuniries-empty-state.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';

import './MatchedOpportunitiesEmptyState.styles.scss';
import './MatchedOpportunitiesEmptyState.styles.responsive.scss';

type MatchedOpportunitiesEmptyStateProps = {
  className?: string;
  isProfilePublished: boolean;
};

const MatchedOpportunitiesEmptyState: React.FC<
  MatchedOpportunitiesEmptyStateProps
> = (props) => {
  const { className, isProfilePublished } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = classNames('matched-opportunities-empty-state', className);

  return (
    <>
      <EmptyScreen
        className={classes}
        title={t(
          `MatchedOpportunitiesEmptyState.${
            isProfilePublished ? 'noMatches' : 'notPublished'
          }.title`,
        )}
        description={t(
          `MatchedOpportunitiesEmptyState.${
            isProfilePublished ? 'noMatches' : 'notPublished'
          }.description`,
        )}
        headingLevel={4}
        img={<img src={MatchingOpportunitiesImg} alt="Empty screen" />}
        imgFirst={false}
      />
      {!isProfilePublished && (
        <Button
          className="matched-opportunities-empty-state__button"
          onClick={() => navigate('/profile/edit/profile-status')}
        >
          {t('finishProfileSetup')}
        </Button>
      )}
    </>
  );
};

export default MatchedOpportunitiesEmptyState;
