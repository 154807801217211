import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Input from 'components/Input';
import { Button } from 'ncoded-component-library';
import { ReactComponent as CloseDialog } from 'icons/CloseDialog.icon.svg';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import Tag from 'components/Tag/Tag.component';
import { FieldRenderProps } from 'react-final-form';
import utils from 'utils';

import './Tags.styles.scss';
import './Tags.styles.responsive.scss';

type TagsProps = {
  className?: string;
  readonly?: boolean;
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
};

const Tags: React.FC<TagsProps> = (props) => {
  const { className, values = [], onChange, placeholder, disabled } = props;
  const [newOption, setNewOption] = useState('');
  const baseClass = 'tags';
  const classes = classNames(baseClass, className);

  const extendedValues = useMemo(
    () =>
      Array.isArray(values)
        ? values?.map((value) => ({
            text: value,
            value,
            id: utils.uniqueId(),
          }))
        : [],
    [values],
  );
  const addNewOption = useCallback(() => {
    onChange([...extendedValues.map(({ value }) => value), newOption]);
    setNewOption('');
  }, [extendedValues, newOption, onChange]);

  const handleAddNewOption = useCallback(
    () => newOption && addNewOption(),
    [addNewOption, newOption],
  );

  const onRemoveOption = useCallback(
    (id: string) => {
      onChange(
        extendedValues
          .filter((item) => id !== item.id)
          .map(({ value }) => value),
      );
    },
    [extendedValues, onChange],
  );

  const onKeyDownCapture = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        handleAddNewOption();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [handleAddNewOption],
  );

  return (
    <div className={classes}>
      <section className={`${baseClass}__add-option`}>
        <Input
          value={newOption}
          placeholder={placeholder}
          onChange={(e) => setNewOption(e.target.value)}
          onKeyDownCapture={onKeyDownCapture}
          disabled={disabled}
        />
        <Button
          icon={<AddIcon />}
          type="button"
          onClick={handleAddNewOption}
          disabled={disabled}
        />
      </section>
      {!!values?.length && (
        <section className={`${baseClass}__tags`}>
          {Array.isArray(values) &&
            extendedValues?.map(({ value, text, id }) => {
              return (
                <Tag key={id} text={text} className="small">
                  <button
                    onClick={() => onRemoveOption(id)}
                    type="button"
                    className="svg-button-wrapper"
                    disabled={disabled}
                  >
                    <CloseDialog />
                  </button>
                </Tag>
              );
            })}
        </section>
      )}
    </div>
  );
};

export const TagsField: React.FC<FieldRenderProps<string[], HTMLElement>> = (
  props,
) => {
  const {
    input: { onChange, value },
    ...rest
  } = props;
  return <Tags values={value} onChange={onChange} {...rest} />;
};
export default Tags;
