import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';

import './QuestionVideoEmpty.styles.scss';
import { useNavigate } from 'react-router-dom';

type QuestionVideoEmptyProps = {
  className?: string;
  icon: React.ReactNode;
  description: string;
  title: string;
  buttonText: string;
  schoolId: string;
};

const QuestionVideoEmpty: React.FC<QuestionVideoEmptyProps> = (props) => {
  const { className, icon, description, title, buttonText, schoolId } = props;

  const classes = classNames('question-video-empty', className);
  const navigate = useNavigate();

  return (
    <div className={classes}>
      <p className="question-video-empty__title">{title}</p>
      <div className="question-video-empty__container">
        {icon}
        <p>{description}</p>
      </div>
      <Button
        onClick={() => navigate(`/vacancies/vacancy-settings/${schoolId}`)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default QuestionVideoEmpty;
