import React from 'react';
import { ReactComponent as MessageIcon } from 'icons/Message.icon.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as AttachmentIcon } from 'icons/Attachment.icon.svg';

import './ProfilePreviewPdfSection.styles.scss';

type ProfilePreviewPdfSectionProps = {
  className?: string;
  title: string;
  pdfUrl?: string;
  linkText: string;
  updatedText: string;
  icon?: React.ReactNode;
};

const ProfilePreviewPdfSection: React.FC<ProfilePreviewPdfSectionProps> = (
  props,
) => {
  const {
    title,
    pdfUrl,
    linkText,
    updatedText,
    icon = <MessageIcon />,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames('profile-section', 'profile-pdf-section')}>
      <div className="profile-section__title">
        {icon}
        <p>{title}</p>
      </div>
      {pdfUrl ? (
        <div className="profile-pdf-section__file-container">
          <div className="profile-pdf-section__file-container__iframe-container">
            <div className="profile-pdf-section__file-container__iframe-container__overlay" />
            <iframe
              title={title}
              src={pdfUrl}
              className="profile-pdf-section__file-container__iframe-container__iframe"
            />
          </div>
          <span className="profile-pdf-section__file-container__text">
            <AttachmentIcon />
            <span>
              <p onClick={() => window.open(pdfUrl, '_blank')}>{linkText}</p>
              <p>{updatedText}</p>
            </span>
          </span>
        </div>
      ) : (
        <p className={'no-data'}>{t('noData')}</p>
      )}
    </div>
  );
};

export default ProfilePreviewPdfSection;
