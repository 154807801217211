import { ComplexRoute } from 'router/components/Routes/Routes';
import ChangeEmail from './pages/ChangeEmail';
import ChangePassword from './pages/ChangePassword';
import LoginInformation from './pages/LoginInformation';

export default [
  {
    path: 'change-password',
    element: ChangePassword,
  },
  {
    path: 'change-email',
    element: ChangeEmail,
  },
  {
    index: true,
    element: LoginInformation,
  },
] as Array<ComplexRoute>;
