import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import TwoOptionsSelect from 'components/TwoOptionsSelect/TwoOptionsSelect.component';

import './TwoOptionsSelectField.styles.scss';

type TwoOptionsSelectValue = Record<string, string>;

type TwoOptionsSelectFieldProps = {
  firstOptionName: string;
  secondOptionName: string;
} & FieldRenderProps<TwoOptionsSelectValue[], HTMLElement>;

const TwoOptionsSelectField: React.FC<TwoOptionsSelectFieldProps> = (props) => {
  const {
    className,
    input: { value, ...restInput },
    meta: { error, touched },
    ...rest
  } = props;

  const classes = classNames('two-options-select-field', className);

  return (
    <div className={classes}>
      <TwoOptionsSelect
        {...restInput}
        {...rest}
        value={value || []}
        error={error}
        touched={touched}
      />
      {error && touched && (
        <p className="two-options-select-field__error">{error}</p>
      )}
    </div>
  );
};

export default TwoOptionsSelectField;
