import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { RequestedDocumentStatus, Role } from 'searchality-data';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'ncoded-component-library';
import confirm from 'modules/confirm';
import {
  useCancelRequestedDocumentMutation,
  useRequestAgainDocumentMutation,
} from 'api/canidates.api';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { required } from 'validations';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

import './RequestedDocument.styles.scss';

type RequestedDocumentProps = {
  className?: string;
  title: string;
  status: string;
  description: string;
  documentId: string;
  disableCancel: boolean;
};

const RequestedDocument: React.FC<RequestedDocumentProps> = (props) => {
  const { className, title, status, description, documentId, disableCancel } =
    props;

  const { t } = useTranslation();

  const { role } = useAppSelector(authSelectors.selectUser);

  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const requestAgainRef = useRef<ModalRef>();

  const [cancelRequest] = useCancelRequestedDocumentMutation();
  const [requestAgainDocument] = useRequestAgainDocumentMutation();

  const classes = classNames('requested-document--wrapper', className);

  const handleCancelRequest = useCallback(async () => {
    await confirm({
      title: t('RequestedDocuments.cancel'),
      onSubmit: async () => {
        await cancelRequest({ documentId, candidateId })
          .unwrap()
          .then(() =>
            dispatch(popSuccess(t('RequestedDocuments.cancelSuccess'))),
          )
          .catch((e) => dispatch(popServerError(e)));
      },
    });
  }, [cancelRequest, candidateId, dispatch, documentId, t]);
  const handleRequestAgain = useCallback(
    ({
      additionalDocumentsRequestReason,
    }: {
      additionalDocumentsRequestReason: string;
    }) => {
      requestAgainDocument({
        candidateId,
        documentId,
        additionalDocumentsRequestReason,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('RequestCandidatesDocuments.success')));
          requestAgainRef.current.close();
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [candidateId, dispatch, documentId, requestAgainDocument, t],
  );

  return (
    <div className={classes}>
      <div className="requested-document">
        <div className="requested-document__action">
          <p>
            {title} - {description}
          </p>
          {role !== Role.Reviewer &&
            (status === RequestedDocumentStatus.REQUESTED ? (
              <Button
                className="svg-button-wrapper"
                onClick={handleCancelRequest}
                disabled={disableCancel}
                variant="link"
              >
                {t('cancelRequest')}
              </Button>
            ) : (
              <Button
                className="svg-button-wrapper"
                onClick={() => requestAgainRef?.current?.open()}
                variant="link"
              >
                {t('requestAgain')}
              </Button>
            ))}
        </div>
        {status === RequestedDocumentStatus.REQUESTED ? (
          <div className="searchality-pending" />
        ) : (
          <DoneIcon />
        )}
      </div>
      {status === RequestedDocumentStatus.SUBMITTED && (
        <Modal
          ref={requestAgainRef}
          className="request-again-modal"
          title={t('requestAgain')}
        >
          <Form
            onSubmit={handleRequestAgain}
            render={({ handleSubmit, invalid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <p>{t('requestAgainSpecifyReason')}</p>
                <Field
                  name="additionalDocumentsRequestReason"
                  required
                  component={TextAreaField}
                  maxLength={500}
                  showLengthCount
                  label={t('reasonForRequestingAgain')}
                  validate={required()}
                />
                <Button type="submit" disabled={invalid || submitting}>
                  {t('requestAgain')}
                </Button>
              </form>
            )}
          />
        </Modal>
      )}
    </div>
  );
};

export default RequestedDocument;
