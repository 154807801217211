import React from 'react';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilledCircleIcon } from 'icons/FilledCircle.icon.svg';
import useMandatoryUnfinishedStepsInfo from '../../../hooks/useMandatoryUnfinishedStepsInfo';

import '../TPBTopActions.styles.scss';

type DraftedProfileActionsProps = {
  noStatus?: boolean;
  onPreview: () => void;
  onCancel: () => void;
};

const DraftedProfileActions: React.FC<DraftedProfileActionsProps> = ({
  onPreview,
  noStatus,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { mandatoryUnfinishedSteps } = useMandatoryUnfinishedStepsInfo();

  return (
    <>
      {mandatoryUnfinishedSteps.length && !noStatus ? (
        <>
          <FilledCircleIcon />
          <p>{t('mandatoryFieldsMissing')}</p>
        </>
      ) : undefined}
      <Button variant="outline" onClick={onCancel}>
        {t('cancel')}
      </Button>
      <Button onClick={onPreview}>
        {t('teacherProfileBuilder.actions.previewProfile')}
      </Button>
    </>
  );
};

export default DraftedProfileActions;
