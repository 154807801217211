import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './ApplicationInformationCard.styles.scss';
import './ApplicationInformationCard.styles.responsive.scss';

type ApplicationInformationCardProps = {
  className?: string;
  children?: ReactNode;
};

const ApplicationInformationCard: React.FC<ApplicationInformationCardProps> = (
  props,
) => {
  const { children, className } = props;

  const classes = classNames('application-information-card', className);

  return <div className={classes}>{children}</div>;
};

export default ApplicationInformationCard;
