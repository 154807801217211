import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';
import {
  Application,
  ApplicationRequestedAdditionalInformation,
} from 'models/Candidate';
import { ApplicationsFilters, PaginatedResponse, Params } from 'types';

function createApplication(data: Partial<Application>) {
  return publicHttpClient.post(`api/applications`, data);
}

function uploadRequestedDocuments(token: string, data: FormData) {
  return publicHttpClient.patch<Application>(
    `api/applications/requested-documents`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

function uploadAdditionalInformation(
  token: string,
  data: ApplicationRequestedAdditionalInformation,
) {
  return publicHttpClient.patch<Application>(
    `api/applications/requested-additional-information`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

function getApplication(applicationId: string) {
  return publicHttpClient.get<Application>(`api/applications/${applicationId}`);
}

function uploadApplicationPhoto(applicationId: string, imageFile: File) {
  const formData = new FormData();
  formData.append('file', imageFile);

  return publicHttpClient.post(
    `api/applications/${applicationId}/photo`,
    formData,
  );
}

function uploadPublications(applicationId: string, formData: FormData) {
  return publicHttpClient.post(
    `api/applications/${applicationId}/publications`,
    formData,
  );
}

function uploadCoverLetter(applicationId: string, coverLetterFile: File) {
  const formData = new FormData();
  formData.append('file', coverLetterFile);

  return publicHttpClient.post(
    `api/applications/${applicationId}/cover-letter`,
    formData,
  );
}

function uploadEducationPhilosophy(applicationId: string, file: File) {
  const formData = new FormData();
  formData.append('file', file);

  return publicHttpClient.post(
    `/api/applications/${applicationId}/education-philosophy`,
    formData,
  );
}

function uploadResume(applicationId: string, file: File) {
  const formData = new FormData();
  formData.append('file', file);

  return publicHttpClient.post(
    `/api/applications/${applicationId}/resume`,
    formData,
  );
}

function getApplications(id: string, params: Params) {
  return httpClient.get<PaginatedResponse<Application>>(
    `teacher-users/${id}/applications`,
    { params },
  );
}

function getApplicationsFilters(id: string, params?: Partial<Params>) {
  return httpClient.get<ApplicationsFilters>(
    `teacher-users/${id}/applications/filters`,
    { params },
  );
}

export default {
  createApplication,
  uploadApplicationPhoto,
  uploadCoverLetter,
  getApplications,
  getApplicationsFilters,
  uploadEducationPhilosophy,
  uploadResume,
  uploadPublications,
  uploadRequestedDocuments,
  getApplication,
  uploadAdditionalInformation,
};
