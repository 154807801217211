import { useTranslation } from 'react-i18next';

const useNumberOfReferences = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('noReferenceNeeded'),
      value: '0',
    },
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
  ];
};

export default useNumberOfReferences;
