import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Comment from 'components/Comment';
import AddComment from 'components/AddComment';
import useComments from './hooks/useComments';
import { Button } from 'ncoded-component-library';

import './ProfileComments.styles.scss';

type ProfileCommentsProps = {
  className?: string;
  candidateId: string;
};

const ProfileComments: React.FC<ProfileCommentsProps> = (props) => {
  const { className, candidateId } = props;

  const { comments, haveMore, addComment, loadMore } = useComments(candidateId);

  const { t } = useTranslation();

  const classes = classNames('searchality-profile-comments', className);

  return (
    <div className={classes}>
      <p className="title">{t('teamComments')}</p>
      <div className="searchality-profile-comments__comments">
        {comments.map((el, index) => (
          <Comment key={index} comment={el} />
        ))}
        {!haveMore && (
          <Button onClick={loadMore} variant="link">
            {t('loadMore')}
          </Button>
        )}
      </div>

      <hr />
      <div className="searchality-profile-comments__add-comment">
        <AddComment postComment={(value: string) => addComment(value)} />
      </div>
    </div>
  );
};

export default ProfileComments;
