import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import AcademicCreateVacancyRouter from './subrouters/AcademicCreateVacancy/AcademicCreateVacancy.router';
import AcademicCreateVacancyRoutes from './subrouters/AcademicCreateVacancy/AcademicCreateVacancy.routes';
import NonAcademicCreateVacancyRouter from './subrouters/NonAcademicCreateVacancy/NonAcademicCreateVacancy.router';
import NonAcademicCreateVacancyRoutes from './subrouters/NonAcademicCreateVacancy/NonAcademicCreateVacancy.routes';

export default [
  {
    path: 'non-academic',
    element: NonAcademicCreateVacancyRouter,
    routes: NonAcademicCreateVacancyRoutes,
  },
  {
    path: 'academic',
    element: AcademicCreateVacancyRouter,
    routes: AcademicCreateVacancyRoutes,
  },
  {
    index: true,
    element: <Navigate to="academic" />,
  },
] as Array<ComplexRoute>;
