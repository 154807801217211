import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { ReactComponent as UserIcon } from 'icons/User.icon.svg';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { PROFILE_IMAGE_MAX_FILE_SIZE } from 'constants/general';
import { useDispatch } from 'react-redux';
import { popError } from 'store/slices/popNotifications.slice';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import classNames from 'classnames';
import PortraitPhotoModal from 'components/PortraitPhotoModal';

import './DropzoneProfilePhoto.styles.scss';

type DropzoneProfilePhotoProps = {
  className?: string;
  value?: File;
  shouldOpenCropper?: boolean;
  onChange?: (files: File) => void;
  setFileCallback?: (file: File) => void;
  cropShape?: 'round' | 'rect';
  aspect?: number;
} & DropzoneOptions;

const DropzoneProfilePhoto: React.FC<DropzoneProfilePhotoProps> = (props) => {
  const {
    className,
    disabled,
    value,
    multiple = false,
    maxFiles = 1,
    shouldOpenCropper = true,
    accept = { 'image/*': [] },
    onChange,
    setFileCallback,
    cropShape,
    aspect,
    ...rest
  } = props;

  const [file, setFile] = useState<File>(value);

  const [fileToEdit, setFileToEdit] = useState<string>();
  const [fileToEditType, setFileToEditType] = useState<string>();

  const cropperModalRef = useRef<ModalRef>(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const maxFileSizeAllowed = useMemo(
    () => rest.maxSize ?? PROFILE_IMAGE_MAX_FILE_SIZE,
    [rest.maxSize],
  );

  const handleFile = useCallback(
    (file: File) => {
      if (file.size < maxFileSizeAllowed) {
        if (shouldOpenCropper) {
          setFileToEdit(URL.createObjectURL(file));
          setFileToEditType(file.type);
          cropperModalRef.current.open();
        } else {
          setFile(file);
          setFileCallback?.(file);
        }
      } else {
        dispatch(popError(t('PortraitPhotoModal.tooBigFileError')));
      }
    },
    [dispatch, maxFileSizeAllowed, setFileCallback, shouldOpenCropper, t],
  );

  const handleOnInputRefChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (files[0]) {
        handleFile(files[0]);
      }

      event.target.value = null;
    },
    [handleFile],
  );

  const onDrop = useCallback(
    (files: File[]) => {
      if (files[0]) {
        handleFile(files[0]);
      }
    },
    [handleFile],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: () => {
      dispatch(popError(t('PortraitPhotoModal.tooBigFileError')));
    },
    disabled,
    accept,
    multiple: multiple || maxFiles > 1,
    ...rest,
  });

  const { onClick, ...restGetRootProps } = getRootProps();

  const classes = classNames('dropzone-profile-image', className);

  useEffect(() => {
    if (!value) {
      setFile(null);
    } else {
      setFile(value);
    }
  }, [value]);

  return (
    <div className={classes}>
      {!file ? (
        <div {...restGetRootProps} className="dropzone-profile-image__upload">
          <input {...getInputProps()} />
          <Button
            onClick={onClick}
            disabled={disabled}
            icon={<UserIcon />}
            variant="outline"
          />
          <p>
            {t('uploadFile')}
            <span>{t('orDragAndDrop')}</span>
          </p>
        </div>
      ) : (
        <div
          className={classNames('dropzone-profile-image__upload-preview', {
            'dropzone-profile-image__upload-preview--rect':
              cropShape === 'rect',
          })}
        >
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={handleOnInputRefChange}
            accept={getInputProps()?.accept}
          />
          <img src={URL.createObjectURL(file)} alt="portrait-preview" />
          <div className="dropzone-profile-image__upload-preview__actions">
            <Button
              variant="outline"
              icon={<AddIcon />}
              iconPosition="right"
              onClick={() => inputRef?.current?.click()}
            >
              {t('uploadNewPhoto')}
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                setFile(null);
                setFileCallback?.(null);
              }}
              icon={<DeleteIcon />}
              iconPosition="right"
            >
              {t('deleteCurrentPhoto')}
            </Button>
          </div>
        </div>
      )}

      {shouldOpenCropper && (
        <PortraitPhotoModal
          src={fileToEdit}
          setFile={(file: File) => {
            setFile(file);
            setFileCallback?.(file);
            setFileToEdit('');
            setFileToEditType('');
            cropperModalRef.current.close();
          }}
          ref={cropperModalRef}
          cropShape={cropShape}
          aspect={aspect}
          formatType={fileToEditType as 'image/jpeg' | 'image/png'}
        />
      )}
    </div>
  );
};

export default DropzoneProfilePhoto;
