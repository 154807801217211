import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import InputField from 'components/InputField';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  emailValidation,
  passwordValidation,
  required as req,
} from 'validations';
import AuthSection from 'router/subrouters/Auth/components/AuthSection';
import PasswordField from 'components/PasswordField';
import { RegisterData } from 'types';
import useQParamsActions from 'hooks/useQueryParams';
import classNames from 'classnames';

import './SignupForm.styles.scss';

type SignupFormProps = {
  className?: string;
  onSubmit: (values: RegisterData) => Promise<any>;
  withoutLoginLink?: boolean;
};

const SignupForm: React.FC<SignupFormProps> = (props) => {
  const { onSubmit, withoutLoginLink = false } = props;

  const { t } = useTranslation();

  const { params: queryParams } = useQParamsActions();

  const { token, email } = queryParams || {};

  const { pathname } = useLocation();

  const isSchool = pathname.includes('school');

  const isAssociation = pathname.includes('association');

  const shouldConfrimSchoolInvitation = token && email;

  return (
    <AuthSection className="auth-form">
      {!withoutLoginLink && (
        <div className="auth-section__login">
          <p className="descriptive">{t('alreadyHaveAccount')}</p>
          <Link to="../signin">
            {t(
              isSchool
                ? 'signInSchool'
                : isAssociation
                ? 'signIn'
                : 'signInTeacher',
            )}
          </Link>
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          email: shouldConfrimSchoolInvitation ? email : '',
        }}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className={classNames('auth-section__form', 'signup-form')}
          >
            <Field
              name="firstName"
              component={InputField}
              label={t('firstName')}
              required
              validate={req()}
            />
            <Field
              name="lastName"
              component={InputField}
              label={t('lastName')}
              required
              validate={req()}
            />
            {(isSchool || isAssociation) && (
              <Field
                name="title"
                component={InputField}
                label={t('yourRoleInSchool')}
                required
                validate={req()}
              />
            )}
            <Field
              name="email"
              component={InputField}
              label={t('email')}
              readOnly={shouldConfrimSchoolInvitation}
              required
              validate={composeValidators(req(), emailValidation())}
            />
            <Field
              name="password"
              component={PasswordField}
              label={t('password')}
              required
              validate={composeValidators(req(), passwordValidation())}
              hint={t('passwordHint')}
            />
            <Button type="submit" disabled={hasValidationErrors || submitting}>
              {t('getStarted')}
            </Button>
          </form>
        )}
      />
    </AuthSection>
  );
};

export default SignupForm;
