import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { getNameInitials } from 'utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import authSelectors from 'store/selectors/auth.selectors';
import { ReactComponent as SendIcon } from 'icons/Send.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import PhotoPreview from 'components/PhotoPreview';

import './AddComment.styles.scss';

type AddCommentProps = {
  className?: string;
  postComment: (comment: string) => void;
};

const AddComment: React.FC<AddCommentProps> = (props) => {
  const { className, postComment } = props;

  const [value, setValue] = useState('');

  const { t } = useTranslation();

  const { imageFile, firstName, lastName, systemColor } = useAppSelector(
    authSelectors.selectUser,
  );

  const handlePostComment = useCallback(() => {
    postComment(value);
    setValue('');
  }, [postComment, value]);

  const baseClass = 'add-comment';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <PhotoPreview
        src={imageFile?.url}
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={systemColor}
        size="m"
        className={`${baseClass}__left`}
      />
      <div className={`${baseClass}__right`}>
        <textarea
          rows={3}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={t('commentPlaceholder')}
        />
        <Button
          className={`${baseClass}__right__send`}
          onClick={handlePostComment}
          icon={<SendIcon />}
          disabled={!value}
        />
      </div>
    </div>
  );
};

export default AddComment;
