import React from 'react';
import JobApplicationFooter from '../../components/JobApplicationFooter';
import JobApplicationHeader from '../../components/JobApplicationHeader';
import JobApplicationSection from '../../components/JobApplicationSection';
import { useTranslation } from 'react-i18next';
import JobApplicationLoggedIn from '../../components/JobApplicationLoggedIn';
import JobApplicationNotLoggedIn from '../../components/JobApplicationNotLoggedIn';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import JobApplicationMain from '../../components/JobApplicationMain';
import JobApplicationForm from '../../components/JobApplicationForm';
import { Role, VacancyType } from 'searchality-data';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { useParams } from 'react-router-dom';

type JobApplicationPageProps = {
  className?: string;
};

const JobApplicationPage: React.FC<JobApplicationPageProps> = () => {
  const { t } = useTranslation();
  const user = useAppSelector(authSelectors.selectUser);

  const { jobId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  return (
    <>
      <JobApplicationHeader />
      <JobApplicationMain>
        {job?.type !== VacancyType.NON_ACADEMIC &&
          (user?.role === Role.Teacher ? (
            <JobApplicationSection
              title={t('loggedInAs')}
              withBorder={false}
              withMargin={false}
            >
              <JobApplicationLoggedIn />
            </JobApplicationSection>
          ) : (
            <JobApplicationNotLoggedIn />
          ))}
        <JobApplicationForm />
      </JobApplicationMain>
      <JobApplicationFooter />
    </>
  );
};

export default JobApplicationPage;
