import React from 'react';
import classNames from 'classnames';
import MatchingTarget from 'assets/images/matching-target.webp';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CheckboxField from 'components/CheckboxField';

import './MatchingFeatureWizardForm.styles.scss';

type MatchingFeatureWizardFormProps = {
  className?: string;
};

const MatchingFeatureWizardForm: React.FC<MatchingFeatureWizardFormProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'matching-feature-wizard-form';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <img src={MatchingTarget} alt="Matching target" />
      <div className={`${baseClass}__p-container`}>
        <p className={`${baseClass}__p-container__title`}>
          {t('vacancyWizard.step5.matching.title')}
        </p>
        <p className={`${baseClass}__subtitle`}>
          {t('vacancyWizard.step5.matching.subtitle')}
        </p>
      </div>
      <div className={`${baseClass}__toggle-container`}>
        <div className={`${baseClass}__toggle-container__text-cotainer`}>
          <p className={`${baseClass}__toggle-container__text-cotainer__title`}>
            {t('vacancyWizard.step5.matching.toggleTitle')}
          </p>
          <p className={`${baseClass}__subtitle`}>
            {t('vacancyWizard.step5.matching.toggleSubtitle')}
          </p>
        </div>
        <Field
          name="matchingInformation.isMatchingActive"
          component={CheckboxField}
          type="checkbox"
          isToggleButton
          isStyledDefault
        />
      </div>
    </div>
  );
};

export default MatchingFeatureWizardForm;
