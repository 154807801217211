import { ComplexRoute } from 'router/components/Routes/Routes';
import WorkExperiencePage from './pages/WorkExperience';
import ContactInformationPage from './pages/ContactInformation';
import CredentialsEducationPage from './pages/CredentialsEducation';
import EducationStatementPage from './pages/EducationStatement';
import LanguagesPage from './pages/Languages';
import PersonalInformationPage from './pages/PersonalInformation';
import TeacherProfilePreview from './pages/ProfilePreview';
import ReferencesPage from './pages/References';
import SportsActivitiesPage from './pages/SportsActivities';
import Start from './pages/Start';
import ProfileStatus from './pages/ProfileStatus';
import WorkAvailabilityPage from './pages/WorkAvailability';
import TargetPositionsPage from './pages/TargetPositions';
import TargetLocationsPage from './pages/TargetLocations';
import PublicationsPage from './pages/Publications';

export default [
  {
    path: 'start',
    element: Start,
    validate: ({ teacherProfileBuilder }) => {
      return teacherProfileBuilder.finishedRoutes?.length
        ? '../profile-status'
        : '';
    },
  },
  {
    path: 'profile-status',
    element: ProfileStatus,
    validate: ({ teacherProfileBuilder }) => {
      return !teacherProfileBuilder.finishedRoutes?.length ? '../start' : '';
    },
  },

  {
    path: 'personal-information',
    element: PersonalInformationPage,
  },
  {
    path: 'contact-information',
    element: ContactInformationPage,
  },
  {
    path: 'work-availability',
    element: WorkAvailabilityPage,
  },
  {
    path: 'education-statement',
    element: EducationStatementPage,
  },
  {
    path: 'credentials',
    element: CredentialsEducationPage,
  },
  {
    path: 'career',
    element: WorkExperiencePage,
  },
  {
    path: 'sports',
    element: SportsActivitiesPage,
  },
  {
    path: 'languages',
    element: LanguagesPage,
  },
  {
    path: 'publications',
    element: PublicationsPage,
  },
  {
    path: 'references',
    element: ReferencesPage,
  },
  {
    path: 'target-positions',
    element: TargetPositionsPage,
  },
  {
    path: 'target-locations',
    element: TargetLocationsPage,
  },
  {
    path: 'preview',
    element: TeacherProfilePreview,
  },
  {
    index: true,
    element: ProfileStatus,
    validate: ({ teacherProfileBuilder }) => {
      return teacherProfileBuilder.finishedRoutes?.length
        ? '/profile/edit/profile-status'
        : '/profile/edit/status';
    },
  },
] as Array<ComplexRoute>;
