import React from 'react';
import classNames from 'classnames';

import './Sidebar.styles.scss';
import './Sidebar.styles.responsive.scss';

type SidebarProps = {
  className?: string;
  children: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { className, children } = props;

  const classes = classNames('sidebar', className);

  return <aside className={classes}>{children}</aside>;
};

export default Sidebar;
