import { createContext } from 'react';
import { PaymentInfo } from 'types';

type PaymentMethodsProviderContextProps = {
  isLoading: boolean;
  paymentInfo?: PaymentInfo;
  updatePaymentInfo?: (paymentInfo: PaymentInfo) => void;
};

export default createContext<Partial<PaymentMethodsProviderContextProps>>({});
