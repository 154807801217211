import { useTranslation } from 'react-i18next';
import { ReferralRelationship } from 'searchality-data';

const useRelationshipOptions = () => {
  const { t } = useTranslation();

  return Object.keys(ReferralRelationship).map((key) => ({
    label: t(`relationshipOptions.${key}`),
    value: ReferralRelationship[key as keyof typeof ReferralRelationship],
  }));
};

export default useRelationshipOptions;
