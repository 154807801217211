import classNames from 'classnames';
import React, { ReactNode } from 'react';

import './TPBPageContent.styles.scss';
import './TPBPageContent.styles.responsive.scss';

const TPBPageContent: React.FC<{
  children: ReactNode;
  fullWidth?: boolean;
  centered?: boolean;
}> = ({ children, fullWidth = false, centered = false }) => {
  const classes = classNames('tpb-page-content', {
    'tpb-page-content--full-width': fullWidth,
    'tpb-page-content--centered': centered,
  });
  return <div className={classes}>{children}</div>;
};

export default TPBPageContent;
