import React, { useCallback } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import SchoolCard from '../SchoolCard';
import { Role } from 'searchality-data';

import './SchoolsOfGroup.styles.scss';

const SchoolsOfGroup: React.FC = () => {
  const user = useAppSelector(authSelectors.selectUser);

  const { schoolGroup } = user;

  const { schools } = schoolGroup;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isCurrentUserAO =
    user.role === Role.AccountOwner || user.role === Role.AccountManager;

  const onCardClick = useCallback(
    (id: string) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  return (
    <div className="schools-of-group">
      <p className="schools-of-group__description">{t('schoolsListed')}</p>
      <div className="schools-of-group__list">
        {schools.map((school) => (
          <SchoolCard
            key={school._id}
            onClick={isCurrentUserAO ? onCardClick : undefined}
            {...school}
          />
        ))}
      </div>
    </div>
  );
};

export default SchoolsOfGroup;
