import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import ToggleField from 'components/ToggleField';
import { required } from 'validations';
import SelectField from 'components/SelectField';
import MultipleSelectField from 'components/MultipleSelectField';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role, VacancyStatus } from 'searchality-data';
import { useLocation, useParams } from 'react-router-dom';
import FormFieldLabel from 'components/FormFieldLabel';
import { useLazyGetVacancyQuery } from 'api/vacancies.api';

import './HiringSchoolInformation.styles.scss';
import '../../VacancyWizard.styles.scss';

type HiringSchoolInformationPageProps = {
  title?: string;
};
const HiringSchoolInformationFormFields: React.FC<
  HiringSchoolInformationPageProps
> = () => {
  const { t } = useTranslation();

  const classes = classNames('hiring-school-information');

  const { pathname } = useLocation();
  const { vacancyId } = useParams();

  const [getVacancy, { data: vacancy }] = useLazyGetVacancyQuery();
  const { role, schools } = useAppSelector(authSelectors.selectUser);

  const isEditMode = pathname.includes('edit') && vacancyId;

  const { values } = useFormState();

  const { change } = useForm();

  const { isMultipleSchools } = values;

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const disableEdit = useMemo(() => {
    return (
      (isEditMode && vacancy?.status !== VacancyStatus.DRAFT) ||
      role === Role.Reviewer
    );
  }, [isEditMode, role, vacancy?.status]);

  const schoolOptions = useMemo(() => {
    if (Role.HiringManager === role) {
      return [
        {
          label: schools[0]?.name,
          value: schools[0]?._id,
        },
      ];
    }
    if (!schoolGroup?.schools) return [];
    return schoolGroup.schools?.map(({ name, _id }) => ({
      label: name,
      value: _id,
    }));
  }, [role, schoolGroup.schools, schools]);

  useEffect(() => {
    if (isMultipleSchools === false) {
      change('schoolIds', undefined);
    }
    if (isMultipleSchools) {
      change('schoolId', undefined);
    }
  }, [change, isMultipleSchools]);

  useEffect(() => {
    if (vacancyId) getVacancy(vacancyId);
  }, [getVacancy, vacancyId]);

  return (
    <div className={classes}>
      <FormFieldLabel text={t('vacancyWizard.step1.field1Paragraph')} />
      <Field
        name="isMultipleSchools"
        component={ToggleField}
        validate={required()}
        noLabel="oneSchool"
        yesLabel="moreSchools"
        className={classNames({ disabled: disableEdit })}
      />
      {isMultipleSchools !== undefined && (
        <p>{t('vacancyWizard.step1.field2Paragraph')}</p>
      )}
      {isMultipleSchools === false && (
        <>
          <Field
            name="schoolId"
            options={schoolOptions}
            component={SelectField}
            label={t('selectSchool')}
            validate={required()}
            required
            className={classNames({ disabled: disableEdit })}
          />
        </>
      )}
      {isMultipleSchools && (
        <Field
          name="schoolIds"
          options={schoolOptions}
          component={MultipleSelectField}
          validate={required()}
          label={t('selectSchools')}
          className={classNames({ disabled: disableEdit })}
        />
      )}
    </div>
  );
};

export default HiringSchoolInformationFormFields;
