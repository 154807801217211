import React from 'react';

import api from 'api';
import EditPersonalInformation from '../../../../../../components/EditPersonalInformation';

const SchoolPersonalDetails: React.FC = () => {
  return <EditPersonalInformation apiRequest={api.user.updateUser} />;
};

export default SchoolPersonalDetails;
