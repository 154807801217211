import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Close } from 'icons/Close.icon.svg';
import { ReactComponent as ExpandIcon } from 'icons/Expand.icon.svg';
import Anim from 'components/animations';

import './Banner.styles.scss';

type BannerProps = {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  iconColor?: 'orange' | 'blue' | 'teal';
  showBanner?: boolean;
  onClose?: () => void;
  noClose?: boolean;
  isCollapsible?: boolean;
  collapsedContent?: ReactNode;
  actions?: ReactNode;
};

const Banner: React.FC<BannerProps> = (props) => {
  const {
    children,
    className,
    title,
    subtitle,
    icon,
    onClose,
    iconColor = 'blue',
    showBanner = true,
    noClose = false,
    isCollapsible = false,
    collapsedContent,
    actions,
  } = props;

  const [shouldShowBanner, setShouldShowBanner] = useState(showBanner);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const baseClass = 'banner';

  const toggleCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };

  const classes = classNames(baseClass, className);

  const headerClasses = classNames('banner__header', {
    'banner__header--colapsible': isCollapsible,
  });

  const iconClasses = classNames({
    'banner__header__collapse__trigger--rotated': isCollapsed,
  });

  return (
    <Anim.Collapse active={shouldShowBanner}>
      <div className={classes}>
        <div
          className={headerClasses}
          onClick={() => isCollapsible && toggleCollapsed()}
        >
          {icon && (
            <div
              className={classNames(
                `${baseClass}__header__icon`,
                `${baseClass}__header__icon--${iconColor}`,
              )}
            >
              {icon}
            </div>
          )}
          <div className={`${baseClass}__header__title`}>
            <h3>{title}</h3>
            {subtitle && <p>{subtitle}</p>}
          </div>
          {!noClose && (
            <button
              type="button"
              className="svg-button-wrapper"
              onClick={() => {
                setShouldShowBanner(false);
                onClose?.();
              }}
            >
              <Close />
            </button>
          )}
          {isCollapsible && <ExpandIcon className={iconClasses} />}
        </div>
        <div className={`${baseClass}__content`}>
          <Anim.Collapse active={isCollapsed}>
            <div className={`${baseClass}__content__collapsed`}>
              {collapsedContent}
            </div>
          </Anim.Collapse>
          {children}
        </div>
        {actions && <div className={`${baseClass}__actions`}>{actions}</div>}
      </div>
    </Anim.Collapse>
  );
};

export default Banner;
