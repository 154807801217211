import React from 'react';
import { ReactComponent as WarningIcon } from 'icons/Warning.icon.svg';
import { useTranslation } from 'react-i18next';

import './ProfileNotReadyBanner.styles.scss';

type ProfileNotReadyBannerProps = {
  description?: string;
};

const ProfileNotReadyBanner: React.FC<ProfileNotReadyBannerProps> = (props) => {
  const { t } = useTranslation();

  const { description = t('Profile.Header.errorDescription') } = props;

  const baseClass = 'profile-not-ready-banner';

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__title`}>
        <WarningIcon />
        <p>{t('Profile.Header.errorTitle')}</p>
      </div>
      <p>{description}</p>
    </div>
  );
};

export default ProfileNotReadyBanner;
