import api from 'api';
import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useEffect, useState } from 'react';
import authSelectors from 'store/selectors/auth.selectors';

export default function useComments(id: string) {
  const [haveMore, setHaveMore] = useState(false);

  const [comments, setComments] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { firstName, lastName, imageFile, systemColor } = useAppSelector(
    authSelectors.selectUser,
  );

  const addComment = useCallback(
    async (content: any) => {
      setComments((prev) => [
        {
          content,
          author: { firstName, lastName, imageFile, systemColor },
          isAdding: true,
        },
        ...prev,
      ]);
      try {
        await api.candidates.addComment(id, content);
        setComments(([first, ...rest]) => [
          {
            ...first,
            isAdding: false,
          },
          ...rest,
        ]);
      } catch (error) {
        console.error(error);
        setComments(([, ...rest]) => [...rest]);
      }
    },
    [firstName, id, imageFile, lastName, systemColor],
  );

  const loadMore = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const {
          data: { items, totalPages },
        } = await api.candidates.getComments(id, {
          $sort: '-createdAt',
          $populate: 'author',
          $limit: 6,
          $page: currentPage,
        });
        if (currentPage + 1 > totalPages) {
          setHaveMore(true);
        }
        setComments((prev) => [
          ...prev,
          ...items.filter(
            (el: any) => !prev.some((prevEl) => prevEl._id === el._id),
          ),
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchComments();
  }, [currentPage, id, setComments]);

  return { haveMore, comments, addComment, loadMore };
}
