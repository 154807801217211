import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import Tag from 'components/Tag';
import { ReactComponent as DeleteIcon } from 'icons/CloseDialog.icon.svg';
import usePositionOptions from '../../hooks/usePositionOptions';
import { required } from 'validations';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { Button } from 'ncoded-component-library';
import utils, { getEmailsFromString } from 'utils';
import { Role } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';

import './RoleStep.styles.scss';

type RoleStepProps = {
  className?: string;
};

const RoleStep: React.FC<RoleStepProps> = (props) => {
  const { className } = props;

  const { role } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const options = usePositionOptions();

  const { values } = useFormState();

  const { change, batch } = useForm();

  const { emails, email } = values;

  const handleRemove = useCallback(
    (emails: string[], email: string, change: any) => {
      const newEmails = emails?.filter((el) => el !== email);
      change('emails', newEmails);
    },
    [],
  );
  const onAdd = useCallback(() => {
    const newEmails = getEmailsFromString(email);

    const finalEmails = Array.from(new Set([...(emails || []), ...newEmails]));

    batch(() => {
      change('emails', finalEmails);
      change('email', '');
    });
  }, [batch, change, email, emails]);

  const classes = classNames('role-step', className);

  return (
    <div className={classes}>
      <p>
        {t('Profile.Invite.Modal.description', {
          role: t(`Profile.Invite.Modal.${role}`),
        })}
      </p>
      <ul>
        {options.map(({ value }) => (
          <li key={value}>
            <b>{`${t(`UserRoles.${utils.getKeyByValue(Role, value)}`)}: `}</b>
            {t(
              `Profile.Invite.Modal.description${utils.getKeyByValue(
                Role,
                value,
              )}`,
            )}
          </li>
        ))}
      </ul>
      <p className="field">{t('Members.teamPermisson')}</p>
      <Field
        name="role"
        component={SelectField}
        options={options}
        validate={required()}
        required
      />
      <p className="field">{t('Profile.Invite.Modal.inviteByEmail')}</p>
      <div className="role-step__email-wrapper">
        <Field
          name="email"
          component={InputField}
          placeholder={t('Profile.Invite.Modal.emailPlaceholder')}
        />
        <Button icon={<AddIcon />} iconPosition="right" onClick={onAdd} />
      </div>
      <Field
        name="emails"
        render={(props) => {
          const {
            meta: { touched, error },
          } = props;
          return touched && error ? (
            <small className="emails-error">{error}</small>
          ) : null;
        }}
        hidden
        validate={(value: any) =>
          !value || value.length === 0 ? t('atLeastOneError') : undefined
        }
      />

      <div className="tags">
        {emails?.map((el: string) => (
          <Tag key={el} text={el}>
            <button
              onClick={() => handleRemove(emails, el, change)}
              className="svg-button-wrapper"
              type="button"
            >
              <DeleteIcon />
            </button>
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default RoleStep;
