import React from 'react';
import Pagination from 'components/Pagination';
import PageLoader from 'components/PageLoader';
import { ApplicationStatus } from 'searchality-data';
import ApplicaitonsFilters from '../../components/ApplicaitonsFilters';
import ApplicationCard from 'components/ApplicationCard';
import { useNavigate } from 'react-router-dom';
import {
  teacherApplicationsApi,
  useGetApplicationsQuery,
} from 'api/teacherApplications.api';
import useAppDispatch from 'hooks/useAppDispatch';
import ApplicationEmptyState from '../../components/ApplicationEmptyState';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useParams } from 'hooks/useParams';
import ApplicationsPaginationInfo from '../../components/ApplicationsPaginationInfo';

const initialParams = {
  $where: {
    status: {
      $in: [ApplicationStatus.ACCEPTED],
    },
  },
  $limit: 6,
  $populate: ['vacancy', 'vacancy.schools'],
};

const SuccessfulApplications: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { _id: id } = useAppSelector(authSelectors.selectUser);

  const { params, sortBy, filterBy, changeCurrentPage, resetFilters } =
    useParams(initialParams);

  const { data, isLoading } = useGetApplicationsQuery({ id, params });

  if (isLoading) {
    return <PageLoader />;
  }

  const { items: applications, ...pagination } = data;

  const { $sort, $where } = params;

  return (
    <div className="applications">
      <ApplicaitonsFilters
        onFilter={filterBy}
        filteredBy={$where}
        onReset={resetFilters}
        filterParams={initialParams}
      />
      <ApplicationsPaginationInfo
        limit={0}
        pagination={pagination}
        onSort={sortBy}
        sortedBy={$sort}
      />
      {isLoading || !applications ? (
        <PageLoader />
      ) : applications?.length > 0 ? (
        <ul>
          {applications.map((application) => (
            <ApplicationCard
              key={application?.vacancy._id}
              cardInformation={{
                status: application.status,
                positionTitle: application.vacancy.positionTitle,
                subjectGradeLevel: application.vacancy.subjectGradeLevel,
                school: application.vacancy.schools
                  ?.map((school) => school.name)
                  .join(', '),
                date: application.appliedAt ?? application.createdAt,
              }}
              onClick={() => {
                dispatch(teacherApplicationsApi.util.resetApiState());
                navigate(`../${application._id}`);
              }}
            />
          ))}
        </ul>
      ) : (
        <ApplicationEmptyState />
      )}
      {pagination?.totalPages > 1 && (
        <Pagination {...pagination} onChange={changeCurrentPage} />
      )}
    </div>
  );
};

export default SuccessfulApplications;
