import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useGetVacancyQuery, useVacancyMembersQuery } from 'api/vacancies.api';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import MembersTable from 'router/subrouters/Dashboard/subrouters/Members/components/MembersTable';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import ShareVacancyModal from '../../../../components/ShareVacancyModal';
import { ShareVacancyModalRef } from '../../../../components/ShareVacancyModal/ShareVacancyModal.component';
import { SchoolGroupStructureType, VacancyStatus } from 'searchality-data';
import { ReactComponent as ShareIcon } from 'icons/Share.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

import './VacancyCollaborators.styles.scss';

type VacancyCollaboratorsProps = {
  className?: string;
};

const VacancyCollaborators: React.FC<VacancyCollaboratorsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const {
    schoolGroup: { schools, structureType },
  } = useAppSelector(authSelectors.selectUser);

  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();

  const { vacancyId } = useParams();
  const { data: currentMembers, isLoading } = useVacancyMembersQuery({
    vacancyId,
  });

  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  const { status } = vacancy || {};

  const classes = classNames('vacancy-collaborators', className);

  const showInvite = useMemo(
    () =>
      [
        VacancyStatus.DRAFT,
        VacancyStatus.ONGOING,
        VacancyStatus.PUBLISHED,
      ].includes(status),
    [status],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <MembersTable
        members={currentMembers}
        isReadonly
        schoolName={
          structureType === SchoolGroupStructureType.STANDALONE &&
          schools[0].name
        }
      />
      {showInvite && (
        <>
          <Button
            variant="outline"
            icon={<ShareIcon />}
            iconPosition="right"
            onClick={() => {
              shareVacancyModalRef?.current?.openShareVacancy(vacancyId);
            }}
          >
            {t('Profile.Invite.share')}
          </Button>
          <ShareVacancyModal
            ref={shareVacancyModalRef}
            title={t('ShareVacancyModal.collaboratorsTitle')}
            description={t('ShareVacancyModal.collaboratorsDescription')}
          />
        </>
      )}
    </div>
  );
};

export default VacancyCollaborators;
