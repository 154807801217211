import env from 'env';

const GTM_EVENT_NAME = {
  PAGE_VIEW: 'page-view',
  TEACHER_REGISTERS: 'teacher-registers',
  TEACHER_CONFIRM_EMAIL: 'teacher-confirm-email',
  TEACHER_START_SETTING_PROFILE: 'teacher-start-setting-profile',
  TEACHER_FINISH_SETTING_PROFILE: 'teacher-finish-setting-profile',
} as const;

const pushEvent = (
  eventName: typeof GTM_EVENT_NAME[keyof typeof GTM_EVENT_NAME],
) => {
  if (!env.GTM_ID) return;

  const dataLayer = ((window as any).dataLayer =
    (window as any).dataLayer || []);

  dataLayer.push({ event: eventName });
};

export default {
  pageView() {
    pushEvent('page-view');
  },
  teacherRegisters() {
    pushEvent('teacher-registers');
  },
  teacherConfirmEmail() {
    pushEvent('teacher-confirm-email');
  },
  teacherStartSettingProfile() {
    pushEvent('teacher-start-setting-profile');
  },
  teacherFinishSettingProfile() {
    pushEvent('teacher-finish-setting-profile');
  },
};
