import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import Select from 'components/Select/Select.component';

type SelectFieldProps = FieldRenderProps<string, HTMLElement> & {
  onChangeCallback?: () => void;
};

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    className,
    input: { onBlur, onChange, ...restInput },
    options,
    meta: { error, touched },
    onChangeCallback = () => {},
    ...rest
  } = props;

  return (
    <Select
      wrapperClassName={className}
      error={error && touched ? error : ''}
      options={options}
      {...rest}
      {...restInput}
      onClose={onBlur}
      multiple={false}
      onChange={({ value }) => {
        onChange(value);

        if (restInput.value !== value) {
          onChangeCallback?.();
        }
      }}
    />
  );
};

export default SelectField;
