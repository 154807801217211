import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const getAuth = (state: RootState) => state.auth;

const selectUser = createSelector([getAuth], (auth) => auth.user);

const selectLoading = createSelector([getAuth], (auth) => auth.loading);

export default {
  selectLoading,
  selectUser,
};
