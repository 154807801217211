import React from 'react';
import classNames from 'classnames';
import ImageSection from './components/ImageSection';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { logout } from 'store/slices/auth.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import { ReactComponent as LogoutIcon } from 'icons/Logout.icon.svg';
import InfoWithLabel from 'components/InfoWithLabel';
import MyAccountLinksContainer from 'router/subrouters/Dashboard/components/MyAccountLinksContainer';

import './PersonalInformation.styles.scss';

type PersonalInformationProps = {
  className?: string;
  isTeacher?: boolean;
  handleDelete: () => Promise<void>;
};

const PersonalInformation: React.FC<PersonalInformationProps> = (props) => {
  const { className, isTeacher = false, handleDelete } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { title } = useAppSelector(authSelectors.selectUser);

  const baseClass = 'personal-information';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <ImageSection />
      {!isTeacher && (
        <InfoWithLabel
          label={t('yourRoleInSchool')}
          text={title ?? t('noData')}
          className={`${baseClass}__school-role`}
        />
      )}
      <div className="personal-information__settings">
        <p>{t('settings')}</p>
        <MyAccountLinksContainer />
      </div>
      <div className={`${baseClass}__actions`}>
        <Button
          icon={<LogoutIcon />}
          iconPosition="right"
          variant="outline"
          onClick={() => {
            dispatch(logout());
          }}
        >
          {t('logout')}
        </Button>
        <Button
          className={`${baseClass}__delete`}
          icon={<DeleteIcon />}
          iconPosition="right"
          onClick={handleDelete}
        >
          {t('deleteMyAccount')}
        </Button>
      </div>
    </div>
  );
};

export default PersonalInformation;
