import React, { useMemo } from 'react';
import classNames from 'classnames';
import LinkedAssociationEmptyState from '../LinkedAssociationEmptyState';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import ReviewCardWrapper from '../../../CreateSchool/pages/Review/components/ReviewCardWrapper';
import useAssociationReviewCardList from '../../hooks/useAssociationReviewCardList';
import { useLocation, useNavigate } from 'react-router-dom';

import './LinkedAssociations.styles.scss';

type LinkedAssociationsProps = {
  className?: string;
  schoolId: string;
};

const LinkedAssociations: React.FC<LinkedAssociationsProps> = (props) => {
  const { className, schoolId } = props;

  const user = useAppSelector(authSelectors.selectUser);

  const classes = classNames('linked-associations', className);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const school = useMemo(() => {
    return user?.schools?.find(({ _id }) => schoolId === _id);
  }, [schoolId, user?.schools]);

  const list = useAssociationReviewCardList(school?.associations);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      {school?.associationIds?.length ? (
        <ReviewCardWrapper
          title={t('associationsLinkedToSchools')}
          list={list}
        />
      ) : (
        <LinkedAssociationEmptyState />
      )}
      <Button
        onClick={() =>
          navigate(
            `/my-account/edit-association-information/${schoolId}/choose-associations`,
            { state: { prevPath: pathname } },
          )
        }
      >
        {t(
          school?.associationIds?.length
            ? 'editAssociationList'
            : 'linkYourSchoolToAssociation',
        )}
      </Button>
    </div>
  );
};

export default LinkedAssociations;
