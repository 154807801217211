import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { mandatorySteps } from '../constants';

export default () => {
  const finishedSteps = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );

  const allRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectAllRoutes,
  );

  const mandatoryFinishedSteps = finishedSteps.filter(
    (step) => mandatorySteps[step],
  );

  const percentageOfMandatoryFinishedSteps = (
    (mandatoryFinishedSteps.length / allRoutes.length) *
    100
  ).toFixed(0);

  return {
    mandatoryFinishedSteps,
    percentageOfMandatoryFinishedSteps,
  };
};
