import React from 'react';
import { Outlet } from 'react-router-dom';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import useNavigationCurrentStep from '../../hooks/useNavigationCurrentStep';

export { default as schoolInformationRoutes } from './routes';

export default function () {
  useNavigationCurrentStep(CREATE_SCHOOL_WIZARD.SCHOOL_INFORMATION);

  return <Outlet />;
}
