import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ApplicationStatus } from 'searchality-data';
import BasicTag from 'components/BasicTag';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import './ApplicationCard.styles.scss';
import './ApplicationCard.styles.responsive.scss';

type CardInformation = {
  status: ApplicationStatus;
  positionTitle: string;
  subjectGradeLevel: string;
  school: string;
  date: string;
  expiredAt?: string;
};

type ApplicationCardProps = {
  className?: string;
  onClick?: () => void;
  cardInformation: CardInformation;
};

const ApplicationCard: React.FC<ApplicationCardProps> = (props) => {
  const { onClick, className, cardInformation } = props;

  const { t } = useTranslation();

  const { status, positionTitle, subjectGradeLevel, school, date, expiredAt } =
    cardInformation;

  const formatDate = useCallback((date: string) => {
    const { monthLong, day, year } = DateTime.fromJSDate(new Date(date));
    return `${day} ${monthLong} ${year}`;
  }, []);

  const mapBasicTagVariant = useMemo(() => {
    if (status === ApplicationStatus.DRAFT) {
      return 'orange';
    }

    if (
      [
        ApplicationStatus.REJECTED_BY_SCHOOL,
        ApplicationStatus.VACANCY_CLOSED,
        ApplicationStatus.EXPIRED,
        ApplicationStatus.EXPIRED_HIRED_EXTERNALLY,
        ApplicationStatus.EXPIRED_HIRED_INTERNALLY,
      ].includes(status)
    ) {
      return 'lightGrey';
    }

    return 'outline';
  }, [status]);

  const baseClass = 'application-card';
  const classes = classNames(baseClass, className);

  return (
    <li className={classes} onClick={onClick}>
      <div className={`${baseClass}__information`}>
        <div className={`${baseClass}__information__position`}>
          <p className={`${baseClass}__information__position__school`}>
            {school}
          </p>
          <p className={`${baseClass}__information__position__title`}>
            {positionTitle}
          </p>
          <p className={`${baseClass}__information__position__subject-grade`}>
            {subjectGradeLevel}
          </p>
        </div>
        <div>
          <div className={`${baseClass}__information__date`}>
            <label>
              {status === ApplicationStatus.DRAFT
                ? t('ApplicationCard.started')
                : t('ApplicationCard.applied')}
            </label>
            <p>{formatDate(date)}</p>
          </div>
          {expiredAt && (
            <div className={`${baseClass}__information__date`}>
              <label>{t('ApplicationCard.expiredAt')}</label>
              <p>{formatDate(expiredAt)}</p>
            </div>
          )}
        </div>
      </div>
      <div className={`${baseClass}__status`}>
        <label>{t('status')}</label>
        <BasicTag text={status} variant={mapBasicTagVariant} />
      </div>
    </li>
  );
};

export default ApplicationCard;
