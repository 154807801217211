import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import Checkbox, {
  CheckboxProps,
} from 'ncoded-component-library/build/components/molecules/Checkbox/Checkbox.component';

import './CheckboxGroupField.styles.scss';

type CheckboxGroupFieldProps = FieldRenderProps<string[], HTMLElement> & {
  className?: string;
  options: CheckboxProps[];
  name: string;
  label?: string;
};

const CheckboxGroupField: React.FC<CheckboxGroupFieldProps> = (props) => {
  const {
    className,
    options,
    label,
    input: { onChange, value },
    meta,
  } = props;

  const classes = classNames('checkbox-group-field', className);

  const { error, touched } = meta;

  const updateValue = useCallback(
    (option: any) => {
      if (!value) return [option];

      if (value.includes(option)) {
        return value.filter((el) => el !== option);
      }

      return [...value, option];
    },
    [value],
  );

  return (
    <div className={classes}>
      {label && <p>{label}</p>}
      {options.map((el, index) => (
        <Checkbox
          key={index}
          {...el}
          onChange={() => onChange(updateValue(el.value))}
          checked={value?.includes(el.value as string)}
          className={classNames({
            'ncoded-checkbox--checked': value?.includes(el.value as string),
          })}
        />
      ))}
      {error && touched && <p className="s-input__error">{error}</p>}
    </div>
  );
};

export default CheckboxGroupField;
