import React from 'react';
import { Outlet } from 'react-router-dom';
import Banners from 'providers/Banners/Banners.provider';

import './Dashboard.styles.scss';

const Dashboard: React.FC = () => (
  <Banners>
    <Outlet />
  </Banners>
);

export default Dashboard;
