import React from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import useSubscriptionAction from '../../hooks/useSubscriptionAction';
import UnsubscribeModalComponent from '../../../../pages/Payment/components/UnsubscribeModal/UnsubscribeModal.component';

import './SubscriptionActions.styles.scss';

type SubscriptionActionsProps = {
  className?: string;
};

const SubscriptionActions: React.FC<SubscriptionActionsProps> = (props) => {
  const { className } = props;

  const classes = classNames('subscription-actions', className);

  const {
    schoolGroup: { subscriptionId, isSubscriptionCancelledAtPeriodEnd },
  } = useAppSelector(authSelectors.selectUser);

  const { title, action, unsubscribeRef } = useSubscriptionAction(
    subscriptionId,
    isSubscriptionCancelledAtPeriodEnd,
  );

  return (
    <div className={classes}>
      <p>{title}</p>
      {action}
      <UnsubscribeModalComponent ref={unsubscribeRef} />
    </div>
  );
};

export default SubscriptionActions;
