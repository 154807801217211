import { createAction, createSlice } from '@reduxjs/toolkit';
import { School } from 'models/School';

type AddNewSchool = {
  schools: Partial<School[]>;
};

const initialState: AddNewSchool = {
  schools: [],
};

export const setSchools = createAction<Partial<School[]>>(
  'addNewSchool/setSchools',
);

export const resetState = createAction('addNewSchool/resetState');

const addNewSchoolSlice = createSlice({
  name: 'addNewSchool',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSchools, (state, { payload }) => ({
      ...state,
      schools: payload,
    }));
    builder.addCase(resetState, () => initialState);
  },
});

export default addNewSchoolSlice.reducer;
