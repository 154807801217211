import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from 'searchality-data';

const applicationStatuses = Object.keys(ApplicationStatus).map(
  (key) => ApplicationStatus[key as keyof typeof ApplicationStatus],
);

export default (availableDivisions: Record<string, string>) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Object.values(availableDivisions || {}).map((value) => ({
        label: t(`applicationStatuses.${value}`),
        value: applicationStatuses.find((el) => el === value),
      })),
    [availableDivisions, t],
  );

  return options;
};
