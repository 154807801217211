import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { emailValidation, required, composeValidators } from 'validations';
import GoBackButton from 'components/GoBackButton';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { School } from 'models/School';
import { batch } from 'react-redux';
import useSchoolInformationRoute from '../../../../hooks/useSchoolInformationRoute';
import { setSchools } from 'store/slices/addNewSchool.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './AssociationContactDetails.styles.scss';

type AssociationContactDetailsProps = {
  className?: string;
  goBackRoute?: string;
  isEdit?: boolean;
  handleSubmit?: () => Promise<Record<string, any>>;
  handleDelete?: () => Promise<void>;
};

const AssociationContactDetails: React.FC<AssociationContactDetailsProps> = (
  props,
) => {
  const {
    className,
    goBackRoute,
    isEdit = false,
    handleSubmit,
    handleDelete,
  } = props;

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { getState, submit, change } = useForm();
  const { values, initialValues } = useFormState();

  const navigateRoute = useSchoolInformationRoute(false);

  const {
    schools,
    school,
    associationContactDetails,
    nationalAssociations,
    regionalAssociations,
    stateAssociations,
  } = values || {};

  const classes = classNames('association-contact-details', className);

  const isAssociationListNotEmpty = useMemo(
    () =>
      !!initialValues?.nationalAssociations?.length ||
      !!initialValues?.stateAssociations?.length ||
      !!initialValues?.regionalAssociations?.length,
    [initialValues],
  );

  const isButtonDisabled = useMemo(() => {
    return getState()?.pristine || getState()?.submitting;
  }, [getState]);

  const handleContinue = useCallback(() => {
    const schoolCopy = structuredClone(school as School);

    schoolCopy.associationContactDetails = associationContactDetails;

    schoolCopy.associationIds = [
      ...(nationalAssociations ? nationalAssociations : []),
      ...(stateAssociations ? stateAssociations : []),
      ...(regionalAssociations ? regionalAssociations : []),
    ];

    batch(() => {
      change(
        'schools',
        !schools || pathname?.includes('standalone')
          ? [schoolCopy]
          : [...schools, schoolCopy],
      );
      if (!pathname?.includes('standalone')) {
        change('nationalAssociations', undefined);
        change('regionalAssociations', undefined);
        change('stateAssociations', undefined);
        change('associationContactDetails', undefined);
      }

      const { schoolLogo, ...rest } = schoolCopy || ({} as any);

      if (pathname?.includes('add-new-school')) {
        dispatch(
          setSchools([
            ...schools,
            {
              ...rest,
              ...(schoolLogo?.length && {
                schoolLogoUrl: URL.createObjectURL(schoolLogo?.[0]),
                schoolLogoType: schoolLogo?.[0].type,
              }),
            },
          ]),
        );
      }
      change('school', undefined);
    });

    if (pathname.includes('standalone'))
      return navigate('/create-school/pricing-information');

    if (pathname.includes('add-new-school')) return navigate('../preview');

    navigate('../schools-preview');
  }, [
    associationContactDetails,
    change,
    dispatch,
    nationalAssociations,
    navigate,
    pathname,
    regionalAssociations,
    school,
    schools,
    stateAssociations,
  ]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        if (handleSubmit) {
          return handleSubmit();
        }
        handleContinue();
      }}
    >
      <GoBackButton
        to={
          goBackRoute
            ? goBackRoute
            : pathname.includes('add-new-school')
            ? '../choose-associations'
            : `/create-school/${navigateRoute}/choose-associations`
        }
      />
      <h1>{t('CreateSchoolAssociations.ContactDetails.title')}</h1>
      <p className="description">
        {t('CreateSchoolAssociations.ContactDetails.description')}
      </p>
      <Field
        name="associationContactDetails.fullName"
        component={InputField}
        label={t('nameAndSurname')}
        required
        validate={required()}
      />
      <Field
        name="associationContactDetails.email"
        component={InputField}
        label={t('email')}
        required
        validate={composeValidators(required(), emailValidation())}
      />
      <div>
        <Button type="submit" disabled={isButtonDisabled}>
          {t(handleSubmit ? 'updateAssociationInformation' : 'continue')}
        </Button>
        {isEdit && isAssociationListNotEmpty && (
          <Button variant="outline" onClick={handleDelete}>
            {t('removeAllAssociations')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default AssociationContactDetails;
