import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Button, Modal, RadioGroup } from 'ncoded-component-library';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import SchoolCard from '../../subrouters/MyAccount/pages/SchoolSettings/components/SchoolCard';
import ValueContext from 'providers/ValueContext/Value.context';
import useCallbackRef from 'hooks/useCallbackRef';

import './SelectSchoolModal.styles.scss';
import './SelectSchoolModal.styles.responsive.scss';

type SelectSchoolModalProps = {
  className?: string;
  onSubmit: (selectedSchoolId: string) => void;
  onCancel?: () => void;
  submitBtnText: string;
} & OverlayProps;

const SelectSchoolModal: React.ForwardRefRenderFunction<
  ModalRef,
  SelectSchoolModalProps
> = (props, ref) => {
  const { className, onSubmit, onCancel, submitBtnText } = props;

  const { selectedIds, manageId, clearIds } = useContext(ValueContext);

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const baseClass = 'select-school-modal';
  const classes = classNames(baseClass, className);

  const { t } = useTranslation();

  const { schools } = useAppSelector(authSelectors.selectUser);

  const radioSchoolsOptions = useMemo(
    () =>
      schools?.map(({ _id, name, createdAt }) => ({
        label: <SchoolCard _id={_id} name={name} createdAt={createdAt} />,
        value: _id,
      })),
    [schools],
  );

  const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    manageId(e.target.value);
  };

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedIds[0]);
  }, [onSubmit, selectedIds]);

  useEffect(() => {
    if (modal?.openState === false) {
      clearIds();
    }
  }, [modal, clearIds]);

  return (
    <Modal
      className={classes}
      title={t('Members.selectSchoolForHM')}
      ref={modalRef}
      footer={
        <div>
          <Button
            variant="outline"
            onClick={() => {
              onCancel?.();
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleOnSubmit}
            disabled={radioSchoolsOptions.length === 0 || !selectedIds?.length}
          >
            {submitBtnText}
          </Button>
        </div>
      }
    >
      {radioSchoolsOptions.length ? (
        <RadioGroup
          name="schools"
          options={radioSchoolsOptions}
          onChange={handleSchoolChange}
          value={selectedIds[0]}
          className={`${baseClass}__radio-group`}
        />
      ) : (
        <div>{t('Members.noSchoolsError')}</div>
      )}
    </Modal>
  );
};

export default forwardRef(SelectSchoolModal);
