import React from 'react';
import FormFieldLabel from 'components/FormFieldLabel';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import { required } from 'validations';
import TPBBottomActions from '../../components/TPBBottomActions';
import EducationsField from 'router/subrouters/JobApplication/components/JobApplicationForm/components/YourProfile/components/EducationsField';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';
import ArtboardImage from 'assets/images/artboard.webp';
import EducationCertificatesField from 'components/EducationCertificatesField';

import './CredentialsEducation.styles.scss';

const CredentialsEducationPage: React.FC = () => {
  const { t } = useTranslation();

  const { submit } = useForm();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = dirtyFields['education'] || dirtyFields['certificates'];

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Career/Experience');
    }
  };

  const baseClass = 'tpb-credentials-education';

  return (
    <div className={baseClass}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <img src={ArtboardImage} alt="credentials" />
        <TPBPageHeading
          text={t('teacherProfileNavItems.credentials')}
          description={t('teacherProfileBuilder.credentials.description')}
        />
        <FormFieldLabel text={t('teacherProfileBuilder.credentials.label')} />
        <Field
          name="education"
          component={EducationsField}
          validate={required()}
        />
        <FormFieldLabel text={t('teacherProfileBuilder.certificates.label')} />
        <Field name="certificates" component={EducationCertificatesField} />
        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default CredentialsEducationPage;
