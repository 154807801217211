import React, { useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from 'icons/Star.icon.svg';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { useSubmitMatchingFeedbackMutation } from 'api/matching.api';
import { useParams } from 'react-router-dom';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import NegativeFeedbackModal from '../NegativeFeedbackModal';

import './OpportunityFeedback.styles.scss';
import './OpportunityFeedback.styles.responsive.scss';
import useMatchMedia from 'hooks/useMatchMedia';

type OpportunityFeedbackProps = {
  className?: string;
};

const OpportunityFeedback: React.FC<OpportunityFeedbackProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const modalRef = useRef<ModalRef>();

  const classes = classNames('opportunity-feedback', className);

  const isPhablet = useMatchMedia('isPhablet');

  const { opportunityId } = useParams();

  const dispatch = useAppDispatch();
  const [submitMatchingFeedback] = useSubmitMatchingFeedbackMutation();

  return (
    <div className={classes}>
      <div className="opportunity-feedback__text">
        <p className="opportunity-feedback__text__title">
          {t('OpportunityFeedback.title')}
        </p>
        <p className="opportunity-feedback__text__description">
          {t('OpportunityFeedback.description')}
        </p>
      </div>
      <div className="opportunity-feedback__actions">
        <Button
          variant="outline"
          icon={<StarIcon />}
          iconPosition="right"
          className="opportunity-feedback__actions__yes"
          onClick={async () =>
            await submitMatchingFeedback({
              matchId: opportunityId,
              isTeacherInterested: true,
            })
              .unwrap()
              .then(() =>
                dispatch(popSuccess(t('OpportunityFeedback.feedbackSubmit'))),
              )
          }
        >
          {t('yes')}
        </Button>
        <Button
          variant="outline"
          icon={<CloseIcon />}
          iconPosition="right"
          onClick={() => modalRef?.current?.open()}
        >
          {t(`OpportunityFeedback.no${isPhablet ? 'Mobile' : ''}`)}
        </Button>
      </div>
      <NegativeFeedbackModal ref={modalRef} opportunityId={opportunityId} />
    </div>
  );
};

export default OpportunityFeedback;
