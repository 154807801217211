import React, { ReactNode } from 'react';
import { ReactComponent as EditIcon } from 'icons/Edit.icon.svg';
import classNames from 'classnames';

import './ReviewCard.styles.scss';

export type ReviewCardProps = {
  title: string;
  children?: ReactNode;
  className?: string;
  isInnerTitle?: boolean;
  onEdit?: () => void;
};

const ReviewCard: React.FC<ReviewCardProps> = (props) => {
  const { title, children, className, onEdit, isInnerTitle = false } = props;

  const baseClass = 'searchality-review-card';

  const classes = classNames(baseClass, className);

  return (
    <section className={classes}>
      {(onEdit || !isInnerTitle) && (
        <div className={baseClass + '__heading'}>
          {!isInnerTitle && <h5>{title}</h5>}
          {onEdit && (
            <button className="svg-button-wrapper" onClick={onEdit}>
              <EditIcon />
            </button>
          )}
        </div>
      )}
      <div className={`${baseClass}__content`}>
        {isInnerTitle && <h5>{title}</h5>}
        {children}
      </div>
    </section>
  );
};

export default ReviewCard;
