import { createApi } from '@reduxjs/toolkit/query/react';
import { Association, GroupedAssociation } from 'models/Association';
import { AssociationContactDetails, School } from 'models/School';
import { User } from 'models/User';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

export const associationsApi = createApi({
  reducerPath: 'associations',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Members', 'AssociationSchools', 'AssociationInformation'],
  endpoints: (builder) => ({
    getAssociationSchools: builder.query<
      PaginatedResponse<School>,
      { params: Params }
    >({
      query: ({ params }) => {
        return {
          url: `schools/association-schools`,
          method: 'get',
          params,
        };
      },
      providesTags: ['AssociationSchools'],
    }),
    getAssociationMembers: builder.query<PaginatedResponse<User>, Params>({
      query: (params) => {
        return {
          url: `association-users/current-members`,
          method: 'get',
          params,
        };
      },
      providesTags: ['Members'],
    }),
    getAssociationInformation: builder.query<Association, void>({
      query: () => {
        return {
          url: `associations/my-association`,
          method: 'get',
          params: {
            $populate: ['schools'],
          },
        };
      },
      providesTags: ['AssociationInformation'],
    }),
    updateAssociationInformation: builder.mutation<
      Association,
      { name?: string; website?: string }
    >({
      query: (data) => {
        return {
          url: `associations`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: ['AssociationInformation'],
    }),
    inviteAssociationMembers: builder.mutation<unknown, string[]>({
      query: (emails) => {
        return {
          url: `association-users/invite-members`,
          method: 'post',
          data: {
            emails,
          },
        };
      },
      invalidatesTags: ['Members'],
    }),
    getGroupedAssociations: builder.query<
      GroupedAssociation,
      { state: string; isForTesting: boolean }
    >({
      query: ({ state, isForTesting }) => {
        return {
          url: `associations/grouped`,
          method: 'get',
          params: {
            $where: {
              states: [state],
              isForTesting,
            },
          },
        };
      },
    }),
    deleteAssociationSchool: builder.mutation<void, { schoolId: string }>({
      query: ({ schoolId }) => {
        return {
          url: `associations/schools`,
          method: 'PATCH',
          data: {
            schoolIds: [schoolId],
          },
        };
      },
      invalidatesTags: ['AssociationSchools'],
    }),
    updateAssociationList: builder.mutation<
      void,
      {
        associationIds: string[];
        associationContactDetails:
          | AssociationContactDetails
          | Record<string, never>;
        schoolId: string;
      }
    >({
      query: (reqInformation) => {
        const { schoolId, ...data } = reqInformation;

        return {
          url: `schools/${schoolId}/association-information`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: ['AssociationSchools'],
    }),
    updateMemberPermission: builder.mutation<void, { newOwnerId: string }>({
      query: (data) => {
        return {
          url: `association-users/change-owner`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: ['Members'],
    }),
  }),
});

export const {
  useGetAssociationSchoolsQuery,
  useDeleteAssociationSchoolMutation,
  useGetAssociationMembersQuery,
  useLazyGetAssociationMembersQuery,
  useInviteAssociationMembersMutation,
  useGetAssociationInformationQuery,
  useUpdateAssociationInformationMutation,
  useGetGroupedAssociationsQuery,
  useUpdateAssociationListMutation,
  useUpdateMemberPermissionMutation,
} = associationsApi;
